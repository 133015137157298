var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('MainLayout',{scopedSlots:_vm._u([{key:"header-title",fn:function(){return [_vm._v(_vm._s(_vm.$t("tasks")))]},proxy:true},{key:"header-content",fn:function(){return [_c('HeaderTableContent',{attrs:{"entity":_vm.$t('task'),"location":_vm.filterLocation},on:{"add":_vm.add,"search":_vm.tableSearch,"locationCancel":_vm.locationFilterCancel}})]},proxy:true},{key:"content",fn:function(){return [_c('BulkNavigation',{directives:[{name:"show",rawName:"v-show",value:(_vm.selected.length > 1),expression:"selected.length > 1"}],attrs:{"buttons":_vm.buttons},on:{"edit":function($event){return _vm.$drawer.show('right-bar')},"remove":_vm.removeBulk}}),_c('TasksTable',{attrs:{"selectedTasks":_vm.selected,"searchQuery":_vm.searchQuery,"locationsFilter":_vm.locationFilter,"actionable":""},on:{"update:selectedTasks":function($event){_vm.selected=$event},"update:selected-tasks":function($event){_vm.selected=$event},"editTask":_vm.edit,"removeTask":_vm.remove}}),_c('ModalPlugin',{attrs:{"name":"task-form-modal"}},[_c('task-entity-form',{attrs:{"taskId":_vm.taskId},on:{"taskCreate":_vm.setTaskAsActive,"success":function($event){return _vm.$modal.hide('task-form-modal')}}})],1),_c('DrawerPlugin',{attrs:{"name":"right-bar","type":"right"}},[_c('TasksRightBar',{attrs:{"tasks":_vm.selected.map(function (task) {
            return {
              id: task.id,
              name: task.group
                ? _vm.taskGroup(task.group.id).name
                : _vm.$t('undefined'),
              from: task.schedule.from,
              to: task.schedule.to
            };
          })}})],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }