<template>
  <div :class="$style['auth-leftbar-wrapper']">
    <Logo labeled />
    <div :class="$style['auth-leftbar-wrapper-content']">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "AuthLeftbar",
  components: {},
  props: {},
  computed: {},
  methods: {}
};
</script>

<style lang="scss" module>
.auth-leftbar-wrapper {
  width: 100%;
  height: 100vh;
  background: #f7f8fc;
  padding: 50px;
}
.logo {
  margin-bottom: 50px;
}
.auth-leftbar-wrapper-content {
  height: calc(100% - 100px);
  width: 100%;
  position: relative;
  img {
    max-height: 100%;
    max-width: 100%;
    position: absolute;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
