<template>
  <MainLayout>
    <template #header-title>{{ $t("users") }}</template>
    <template #header-content
      ><HeaderTableContent
        @search="tableSearch"
        @add="addUserEntity"
        :entity="$t('user')"
    /></template>
    <template #content>
      <BulkNavigation
        :buttons="{
          delete: { name: $t('deleteUsers') },
          export: { name: $t('export') }
        }"
        v-show="selected.length"
        @remove="removeBulk"
        @export="exportUsers"
      />
      <UsersTable
        :selectedUsers.sync="selected"
        :searchQuery="searchQuery"
        @editUser="edit"
        @removeUser="remove"
        actionable
      />
      <ModalPlugin name="user-modal">
        <user-entity-form
          :userId="userId"
          @userCreate="setUserAsActive"
          @success="$modal.hide('user-modal')"
        />
      </ModalPlugin>
    </template>
  </MainLayout>
</template>

<script>
import { mapActions } from "vuex";

import MainLayout from "@/layouts/MainLayout";

import UsersTable from "@/components/UsersTable";
import UserEntityForm from "@/components/UserEntityForm";

import BaseNameDisplay from "@/components/ui/BaseNameDisplay";
import HeaderTableContent from "@/components/layout/HeaderTableContent";

import { exportUsers } from "@/api/repositories/users";

export default {
  page: {
    title: "Users",
    meta: [{ name: "description", content: "The Users page." }]
  },
  components: { HeaderTableContent, MainLayout, UsersTable, UserEntityForm },
  data() {
    return {
      selected: [],
      userId: undefined,
      searchQuery: ""
    };
  },
  methods: {
    ...mapActions("users", ["removeUser", "removeUsers"]),
    setUserAsActive(userId) {
      this.userId = userId;
    },
    tableSearch(query) {
      this.searchQuery = query;
    },
    success() {
      this.$modal.hide("user-modal");
    },
    addUserEntity() {
      this.userId = undefined;
      this.$modal.show("user-modal");
    },
    edit(e) {
      this.userId = e.id;
      this.$modal.show("user-modal");
    },
    remove(e) {
      this.$confirmation
        .show({
          items: [
            {
              component: BaseNameDisplay,
              slot: e[0].email
            }
          ],
          action: "delete",
          title: this.$i18n.t("deleteUser"),
          message: this.$i18n.t("deleteUserDescription"),
          width: 440
        })
        .then(() => {
          this.removeUser(e[0].id).then(() => {
            //
          });
        })
        .catch(() => {
          console.log("Deletion canceled");
        });
    },
    removeBulk() {
      this.$confirmation
        .show({
          items: this.selected.map(item => {
            return {
              component: BaseNameDisplay,
              slot: item.email
            };
          }),
          action: "delete",
          title: this.$i18n.t("deleteUsers"),
          message: this.$i18n.t("deleteUsersDescription")
        })
        .then(() => {
          this.removeUsers(this.selected.map(user => user.id)).then(() => {
            this.selected = [];
          });
        })
        .catch(() => {
          console.log("Deletion canceled");
        });
    },
    exportUsers() {
      exportUsers(this.selected.map(user => user.id)).then(response => {
        const downloadLink = response.data.download_link;
        window.location.replace(downloadLink);
      });
    }
  }
};
</script>
