<template>
  <div :class="$style['time-range-picker-wrapper']">
    <TimePicker
      :class="$style['left-picker']"
      :disabled="disabled"
      :loading="loading"
      :format="format"
      :size="size"
      :error="error"
      v-model="fromModel"
      placeholder="00:00"
    />
    <span :class="$style.delimeter"> — </span>
    <TimePicker
      :class="$style['right-picker']"
      :disabled="disabled"
      :loading="loading"
      :format="format"
      :size="size"
      :error="error"
      v-model="toModel"
      placeholder="23:59"
    />
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "TimeRangePicker",
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    format: {
      type: String,
      default: "HH:mm"
    },
    value: {
      type: Object,
      default: () => {
        return {
          from: "12:00",
          to: "12:01"
        };
      }
    },
    size: {
      type: String,
      default: "large"
    },
    error: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    fromModel: {
      get() {
        return this.value.from;
      },
      set(value) {
        if (this.checkIfValidRange(value, this.value.to)) {
          this.$emit("input", {
            from: value,
            to: this.value.to
          });
        }
      }
    },
    toModel: {
      get() {
        return this.value.to;
      },
      set(value) {
        if (this.checkIfValidRange(this.value.from, value)) {
          this.$emit("input", {
            from: this.value.from,
            to: value
          });
        }
      }
    }
  },
  methods: {
    moment,
    checkIfValidRange(from, to) {
      return from && to
        ? this.moment(from, this.format).unix() <
            this.moment(to, this.format).unix()
        : true;
    }
  }
};
</script>

<style lang="scss" module>
.time-range-picker-wrapper {
  height: 60px;
  display: inline-block;
  text-align: center;
  padding: 10px 0 10px 0;
  .left-picker {
    float: left;
    width: 40%;
  }
  .right-picker {
    float: right;
    width: 40%;
  }
  .delimeter {
    line-height: 35px;
    padding: 0 10px 0 10px;
  }
}
</style>
