<template>
  <Modal v-model="visible" :title="title">
    <slot></slot>
  </Modal>
</template>

<script>
import Plugin from "@/plugins/modal";

export default {
  name: "ModalPlugin",
  data() {
    return {
      visible: false
    };
  },
  components: {},
  props: {
    name: {
      type: String,
      default: null
    },
    title: {
      type: String,
      default: undefined
    }
  },
  computed: {},
  methods: {},
  beforeMount() {
    Plugin.EventBus.$on("show", params => {
      if (params.name == this.name) {
        this.visible = true;
        params.resolve(this.visible);
      }
    });
    Plugin.EventBus.$on("hide", params => {
      if (params.name == this.name) {
        this.visible = false;
        params.resolve(this.visible);
      }
    });
  }
};
</script>
