var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$style['worker-entity-form-wrapper']},[_c('base-form',{attrs:{"name":"worker-entity-form","form":_vm.form},on:{"submit":_vm.onSubmit}},[_c('base-field',{attrs:{"label":_vm.$t('name'),"errors":_vm.form.errors.get('first_name')}},[_c('base-input',{attrs:{"name":"first_name","type":"text"},model:{value:(_vm.form.first_name),callback:function ($$v) {_vm.$set(_vm.form, "first_name", $$v)},expression:"form.first_name"}})],1),_c('base-field',{attrs:{"label":_vm.$t('lastName'),"errors":_vm.form.errors.get('last_name')}},[_c('base-input',{attrs:{"name":"last_name","type":"text"},model:{value:(_vm.form.last_name),callback:function ($$v) {_vm.$set(_vm.form, "last_name", $$v)},expression:"form.last_name"}})],1),_c('base-field',{attrs:{"label":_vm.$t('email'),"errors":_vm.form.errors.get('email')}},[_c('base-input',{attrs:{"name":"email","type":"text"},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})],1),_c('base-field',{attrs:{"label":_vm.$t('phone'),"errors":_vm.form.errors.get('phone')}},[_c('base-input',{attrs:{"name":"phone","type":"text"},model:{value:(_vm.form.phone),callback:function ($$v) {_vm.$set(_vm.form, "phone", $$v)},expression:"form.phone"}})],1),_c('base-field',{attrs:{"label":_vm.$t('skills'),"errors":_vm.form.errors.get('skills')}},[_c('base-select-tags',{attrs:{"name":"skills","mode":"multiple","options":_vm.skills,"mappers":{
          options: function (option) {
            return { label: option.name, value: option.id };
          },
          value: function (value) {
            return value.id;
          }
        }},model:{value:(_vm.form.skills),callback:function ($$v) {_vm.$set(_vm.form, "skills", $$v)},expression:"form.skills"}})],1),_c('base-field',{attrs:{"label":_vm.$t('address'),"errors":_vm.form.errors.get('address')}},[_c('base-input-search',{attrs:{"name":"address","fetcher":_vm.addressFetcher},on:{"update":_vm.handleAddressUpdate},model:{value:(_vm.form.address),callback:function ($$v) {_vm.$set(_vm.form, "address", $$v)},expression:"form.address"}})],1),_c('base-field',{attrs:{"label":"GPS","errors":_vm.form.errors.get('gps')}},[_c('base-input-gps',{attrs:{"name":"gps","disabled":""},model:{value:(_vm.form.gps),callback:function ($$v) {_vm.$set(_vm.form, "gps", $$v)},expression:"form.gps"}})],1)],1),_c('base-button',{attrs:{"size":"large","loading":_vm.form.loading,"disabled":_vm.form.loading || _vm.form.errors.any()},on:{"click":_vm.onSubmit}},[_vm._v(_vm._s(_vm.form.hasUpdateType() ? _vm.$t('update') : _vm.$t('create')))])],1)}
var staticRenderFns = []

export { render, staticRenderFns }