<template>
  <div
    @click="model = !value"
    :class="{
      [$style['weekend-switch-wrapper']]: true,
      [$style['active']]: !value
    }"
  >
    <Icon name="calendar" />
    <p :class="$style['weekends']">{{ $t("weekends") }}: {{ $t(status) }}</p>
  </div>
</template>

<script>
import Icon from "../ui/Icon.vue";
export default {
  name: "WeekendSwitch",
  data() {
    return {};
  },
  components: { Icon },
  props: {
    value: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    model: {
      get: () => {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    },
    status() {
      return this.value ? "on" : "off";
    }
  },
  methods: {}
};
</script>

<style lang="scss" module>
.weekend-switch-wrapper {
  cursor: pointer;
  height: 50px;
  min-width: 157px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border: 1px solid #b8c1cc;
  border-radius: 5px;
}
.active {
  background: #d9eefe;
}
.weekends {
  margin-left: 5px;
}
</style>
