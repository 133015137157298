export default class Skill {
  #id;
  #name;

  constructor(skill) {
    this.#id = skill.id;
    this.#name = skill.name;
  }

  getId() {
    return this.#id;
  }

  getName() {
    return this.#name;
  }
}
