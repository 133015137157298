<template>
  <div :class="$style['link-wrapper']" @click="$emit('click')">
    <Icon :class="$style.icon" name="link-arrow" active />
    <div :class="$style.label">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "Link",
  components: {},
  props: {},
  computed: {},
  methods: {}
};
</script>

<style lang="scss" module>
.link-wrapper {
  cursor: pointer;
  .icon {
    float: left;
    margin-top: 4px;
  }
  .label {
    color: #2e92f8;
  }
}
</style>
