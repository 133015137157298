<template>
  <div :class="$style['action-layout-container']">
    <slot />
  </div>
</template>

<script>
export default {
  name: "ActionLayout",
  data() {
    return {};
  },
  components: {},
  props: {},
  computed: {},
  methods: {}
};
</script>

<style lang="scss" module>
.action-layout-container {
  margin: 0 auto;
  height: 100vh;
  width: 100%;
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
