import Vue from "vue";
import { buildStatusUpdateNotification } from "@/services/notifications";

export const listenToConfigurationNotifications = configurationId => {
  Vue.prototype.$socket
    .channel("private-configurations." + configurationId)
    .listen(".build.status", function(e) {
      const notification = new buildStatusUpdateNotification(e);
      notification.show();
    });
};
