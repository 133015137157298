<template>
  <div :class="$style['pic-circle-wrapper']">
    <div
      :class="$style.circle"
      :style="{
        '--fill': fill
      }"
    >
      <div :class="$style['helper-content']" v-if="helper">
        {{ help(helper) }}
      </div>
      <slot></slot>
    </div>
    <div :class="$style.label" v-if="labeled">
      <span v-if="label">{{ label }}</span>
      <span v-else :class="$style['undefined']">Undefined</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "PicCircle",
  props: {
    helper: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: null
    },
    labeled: {
      type: Boolean,
      default: false
    },
    fill: {
      type: String,
      default: "#58d28b"
    }
  },
  computed: {},
  methods: {
    help(type) {
      const splitted = this.label.split(" ");
      switch (type) {
        case "initials":
          if (splitted[1]) {
            return splitted[0][0] + splitted[1][0];
          } else {
            return this.label[0];
          }
        default:
          return null;
      }
    }
  }
};
</script>

<style lang="scss" module>
.pic-circle-wrapper {
  width: fit-content;
  height: 32px;
  .circle {
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--fill);
    border-radius: 100%;
    color: #ffffff;
    float: left;
    .helper-content {
      display: inline-block;
    }
  }
  .label {
    margin-left: 10px;
    line-height: 32px;
    float: right;
  }
  .undefined {
    opacity: 0.5;
  }
}
</style>
