<template>
  <div class="progress-wrapper">
    <Steps progress-dot :current="current">
      <Step v-for="(step, i) in steps" :title="step" :key="i" />
    </Steps>
  </div>
</template>

<script>
import Steps from "ant-design-vue/lib/steps";
import "ant-design-vue/lib/steps/style/css";
const { Step } = Steps;

export default {
  name: "CreationProgressBar",
  data() {
    return {
      steps: [
        this.$i18n.t("chooseWorkers"),
        this.$i18n.t("locations"),
        this.$i18n.t("chooseTasks"),
        this.$i18n.t("createWorkPlan")
      ]
    };
  },
  components: { Steps, Step },
  props: {
    current: {
      type: Number,
      default: 0
    }
  },
  computed: {},
  methods: {}
};
</script>

<style lang="scss">
.progress-wrapper {
  .ant-steps-dot {
    .ant-steps-item-content {
      margin-top: 23px;
      width: 185px;
      left: -10px;
      position: relative;
    }
    .ant-steps-item-tail {
      top: 10px;
      &:after {
        width: 100%;
      }
    }
    .ant-steps-item-icon {
      width: 10px;
      height: 10px;
    }
    .ant-steps-item-title {
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
    }
    .ant-steps-item-finish,
    .ant-steps-item-process {
      .ant-steps-item-container {
        .ant-steps-item-title {
          color: $primary-blue;
        }
      }
    }
    .ant-steps-item-icon .ant-steps-icon-dot {
      width: 24px;
      height: 24px;
    }
    .ant-steps-item-wait {
      .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
        background: #bbbfc9;
      }
      .ant-steps-item-container {
        .ant-steps-item-title {
          color: $primary-black;
        }
      }
    }
    .ant-steps-item-process {
      .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
        position: relative;
        &:after {
          content: "";
          display: block;
          position: absolute;
          top: 3px;
          left: 3px;
          width: 18px;
          height: 18px;
          background: #ffffff;
          border-radius: 100%;
        }
      }
    }
    .ant-steps-item-tail::after {
      background: #bbbfc9;
    }
    .ant-steps-item-finish {
      .ant-steps-item-tail::after {
        background: $primary-blue;
      }
    }
  }
}
</style>
