export default class Location {
  #id;

  #position;
  #schedule;

  constructor(location) {
    this.#id = location.id;
    this.#position = location.position;
  }

  getId() {
    return this.#id;
  }

  getAddress() {
    return this.#position.address;
  }
}
