<template>
  <MainLayout>
    <template #header-title>
      <div v-if="activeBuild">
        <Icon
          name="folder"
          @click="openBuildsList"
          active
          :class="$style['folder-icon']"
        />
        <span>{{ activeBuildTitle }}</span>
        <popover-active-route-plan-actions
          @undo="undo"
          @redo="redo"
          @save="save"
          :updates="updates"
          :updatesSaving="updatesSaving"
          :errorHandles="errorHandles"
        />
      </div>
      <div v-else></div>
    </template>
    <template #header-content v-if="builds.length > 0">
      <div :class="$style['header-content-wrapper']">
        <div :class="$style['calendar-controls']">
          <Icon
            name="calendar"
            :class="$style['datepicker-icon']"
            :active="showDatePicker"
            @click="openDatePicker"
          />
          <Date
            v-model="activeDate"
            :class="$style['invisible-datepicker']"
            :open="showDatePicker"
            @openChange="closeDatePicker"
          />
          <Icon
            v-if="view === 'calendar'"
            name="left"
            :class="$style['datepicker-arrow-left']"
            pointer
            @click="handleDate(-1)"
          />
          <p :class="$style['header-date']">{{ currentCalendarDate }}</p>
          <Icon
            v-if="view === 'calendar'"
            name="right"
            :class="$style['datepicker-arrow-right']"
            pointer
            @click="handleDate"
          />
        </div>
        <div :class="$style['view-types-toggles']">
          <RadioGroup v-model="view" :radios="viewsAvailable" />
          <RadioGroup
            v-show="view == 'calendar'"
            v-model="calendarViewType"
            :radios="calendarViewTypesAvailable"
          />
        </div>
        <WeekendSwitch v-model="showWeekends" />
      </div>
    </template>
    <template #content>
      <div :class="$style['message-wrapper']" v-if="builds.length == 0">
        <div :class="$style.message">
          <span :class="$style['title']">
            {{ $t("thereIsNoBuild") }}
          </span>

          <div :class="$style['button-wrapper']">
            <router-link
              to="/dashboard/route-plan-creation"
              :class="$style.button"
              replace
            >
              <Button>
                <Icon name="plus" fill="#ffffff" />
                <p :class="$style['btn-title']">{{ $t("createPlan") }}</p>
              </Button>
            </router-link>
          </div>
        </div>
      </div>
      <div :class="$style['content-wrapper']" v-else>
        <WorkersSelectorList :workers="workers" v-model="workersSelected" />
        <div
          v-if="
            activeBuild && (activeBuild.status == 2 || activeBuild.status == 3)
          "
        >
          <CalendarView
            v-if="view == 'calendar'"
            :viewType="calendarViewType"
            :showWeekends="showWeekends"
            :dayPlans="dayPlansWithRoutesFilteredBySelectedWorkers"
            :setup="setup"
            :activeUpdates="activeUpdates"
            :activeDate="activeDate"
            :updatesSaving="updatesSaving"
            :errorHandles="errorHandles"
            @dragDrop="handleDragDrop"
            @changeCurrentCalendarDate="dragMonthMove"
            @handleError="handleError"
            @removeErrorHandle="removeErrorHandle"
          />
          <div v-if="view == 'map'" :class="$style['map-wrapper']">
            <MapView
              :dayPlans="dayPlansWithRoutesFilteredBySelectedWorkers"
              :setup="setup"
              :activeDate.sync="activeDate"
            />
          </div>
        </div>
        <div
          v-else-if="
            activeBuild && (activeBuild.status == 0 || activeBuild.status == 1)
          "
        >
          <div :class="$style['last-step']">
            <Icon name="rp_success" :height="282" />
            <p :class="$style.status">{{ $t("workPlanCreationInProgress") }}</p>
            <p :class="$style['status-sub']">
              {{ $t("pleaseWaitOrStartOver") }}
            </p>
            <div class="countdown-section">
              <div class="countdown" v-if="buildEstimation">
                <vue-countdown
                  :time="buildEstimation * 1000"
                  v-slot="{ minutes, seconds }"
                >
                  <span class="count_down" v-if="minutes > 0 || seconds > 0">
                    {{ minutes >= 10 ? minutes : `0${minutes}` }}:{{
                      seconds >= 10 ? seconds : `0${seconds}`
                    }}</span
                  >
                  <span v-else>{{ $t("longerBuild") }}</span>
                </vue-countdown>
              </div>
            </div>
            <div :class="$style['preloader-section']">
              <Loading loading />
            </div>
          </div>
        </div>
      </div>
      <drawer-plugin name="builds-list" type="left">
        <BuildsList :builds="builds" :activeBuild="id" />
      </drawer-plugin>
    </template>
  </MainLayout>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex";
import moment from "moment";
import { EventBus } from "@/main.js";
import { getEstimation } from "@/api/repositories/build";

import VueCountdown from "@chenfengyuan/vue-countdown";

import MainLayout from "@/layouts/MainLayout";

import BuildsList from "@/components/build/BuildsList.vue";
import WeekendSwitch from "@/components/route-plan/WeekendSwitch.vue";
import WorkersSelectorList from "@/components/build/WorkersSelectorList.vue";
import CalendarView from "@/components/build/CalendarView.vue";
import MapView from "@/components/build/MapView.vue";

import { errorHandlingEnabled } from "@/services/notifications";

export default {
  page: {
    title: "Build",
    meta: [{ name: "description", content: "The Build page." }]
  },
  components: {
    MainLayout,
    BuildsList,
    WeekendSwitch,
    WorkersSelectorList,
    CalendarView,
    MapView,
    VueCountdown
  },
  props: {
    id: {
      type: String,
      default: undefined
    }
  },
  provide() {
    return {
      deleteBuild: this.deleteBuild
    };
  },
  directives: {
    "click-outside": {
      bind: function(el, binding, vnode) {
        el.clickOutsideEvent = function(event) {
          if (!(el == event.target || el.contains(event.target))) {
            vnode.context[binding.expression](event);
          }
        };
        document.body.addEventListener("click", el.clickOutsideEvent);
      },
      unbind: function(el) {
        document.body.removeEventListener("click", el.clickOutsideEvent);
      }
    }
  },
  data() {
    return {
      viewsAvailable: [
        {
          value: "calendar",
          label: this.$i18n.t("calendar")
        },
        {
          value: "map",
          label: this.$i18n.t("map")
        }
      ],
      calendarViewTypesAvailable: [
        {
          value: "dayGridMonth",
          label: this.$i18n.t("month")
        },
        {
          value: "timeGridWeek",
          label: this.$i18n.t("week")
        }
      ],

      view: "calendar",
      calendarViewType: "dayGridMonth",
      showDatePicker: false,
      showWeekends: true,

      workersSelected: [],
      activeDate: moment().format("YYYY-MM-DD"),

      updates: [],
      updatesSaving: false,

      errorHandles: [],

      buildEstimation: null
    };
  },
  computed: {
    ...mapGetters("builds", ["activeBuild", "workers"]),
    ...mapState("builds", ["activeBuildId", "builds", "setup", "dayPlans"]),
    dayPlansWithRoutesFilteredBySelectedWorkers() {
      return this.dayPlans.map(dayPlan => {
        if (this.workersSelected.length > 0) {
          return {
            ...dayPlan,
            routes: dayPlan.routes.filter(route => {
              return this.workersSelected.find(
                worker => worker.id == route.worker.id
              );
            })
          };
        }
        return dayPlan;
      });
    },
    activeBuildTitle() {
      return this.activeBuild.title.length > 15
        ? this.activeBuild.title.substring(0, 12) + ".."
        : this.activeBuild.title;
    },
    activeUpdates() {
      return this.updates.filter(update => {
        if (!update.active) {
          return false;
        }

        if (
          this.updates.find(searchUpdate => {
            return (
              searchUpdate.active &&
              searchUpdate.data.eventId == update.data.eventId &&
              searchUpdate.order > update.order
            );
          })
        ) {
          return false;
        }

        return true;
      });
    },
    currentCalendarDate() {
      const date = moment(this.activeDate);

      if (this.view === "map") {
        return date.format("MMMM").toLowerCase() + " " + date.format("D");
      } else {
        if (this.calendarViewType === "timeGridWeek") {
          return `${date.format("MMMM").toLowerCase()} ${date
            .startOf("isoWeek")
            .date()} - ${date.endOf("isoWeek").date()}`;
        }

        return (
          this.$i18n.t(date.format("MMMM").toLowerCase()) +
          " " +
          date.format("YYYY")
        );
      }
    }
  },
  methods: {
    ...mapActions("builds", [
      "setBuildAsActive",
      "deleteBuild",
      "saveBuildUpdates"
    ]),
    openDatePicker() {
      this.showDatePicker = true;
    },
    closeDatePicker(value) {
      if (!value) {
        this.showDatePicker = false;
      }
    },
    dragMonthMove(date) {
      this.activeDate = date.toISOString();
    },
    openBuildsList() {
      this.$drawer.show("builds-list");
    },
    handleDragDrop(eventUpdate) {
      this.updates = this.updates.filter(update => update.active);
      this.updates.push({
        data: eventUpdate,
        active: true,
        order: this.updates.length + 1
      });
    },
    undo() {
      const activeUpdates = this.updates.filter(update => update.active);
      if (activeUpdates.length > 0) {
        const lastActiveUpdate = activeUpdates.reduce((prev, current) =>
          prev.order > current.order ? prev : current
        );
        this.$set(
          this.updates,
          this.updates.findIndex(
            update => update.order == lastActiveUpdate.order
          ),
          {
            data: lastActiveUpdate.data,
            active: false,
            order: lastActiveUpdate.order
          }
        );
        EventBus.$emit("event-move", {
          id: lastActiveUpdate.data.eventId,
          start: lastActiveUpdate.data.from.start,
          end: lastActiveUpdate.data.from.end
        });
      }
    },
    redo() {
      const inactiveUpdates = this.updates.filter(update => !update.active);
      if (inactiveUpdates.length > 0) {
        const firstIncativeUpdate = inactiveUpdates.reduce((prev, current) =>
          prev.order < current.order ? prev : current
        );
        this.$set(
          this.updates,
          this.updates.findIndex(
            update => update.order == firstIncativeUpdate.order
          ),
          {
            data: firstIncativeUpdate.data,
            active: true,
            order: firstIncativeUpdate.order
          }
        );
        EventBus.$emit("event-move", {
          id: firstIncativeUpdate.data.eventId,
          start: firstIncativeUpdate.data.to.start,
          end: firstIncativeUpdate.data.to.end
        });
      }
    },
    async save() {
      this.updatesSaving = true;
      const updates = this.activeUpdates
        .map(update => {
          const date = moment(update.data.to.start);
          return update.data.routePoints.map(routePoint => {
            return {
              type: 1,
              route_point_id: routePoint.getId(),
              data: {
                date_to: date.format("YYYY-MM-DD"),
                time_marker: date.format("hh:mm:ss")
              }
            };
          });
        })
        .flat(2);
      const activeDate = this.activeDate;
      this.saveBuildUpdates({
        updates: updates.length > 0 ? updates : [],
        error_handles: this.errorHandles.length > 0 ? this.errorHandles : []
      }).then(() => {
        this.resetBuildUpdates();
        this.activeDate = activeDate;
        this.updatesSaving = false;
        this.errorHandles = [];
        this.$router.go(this.$router.currentRoute);
      });
    },
    resetBuildUpdates() {
      this.updates = [];
    },
    confirmBuildLeave(next) {
      if (this.updates.length == 0) {
        next();
      } else {
        this.$confirmation
          .show({
            action: "warning",
            title: this.$i18n.t("workPlanHasBeenChanged"),
            message: this.$i18n.t("workPlanHasBeenChangedDescription")
          })
          .then(() => {
            this.resetBuildUpdates();
            next();
          })
          .catch(() => {
            next(false);
          });
      }
    },
    handleDate(i = 1) {
      const date = moment(this.activeDate);
      let obj;

      if (this.calendarViewType === "timeGridWeek") {
        obj = { week: date.week() + i };
      } else {
        obj = { month: date.month() + i };
      }

      this.activeDate = date.set(obj).toISOString();
    },
    handleError(data) {
      this.errorHandles.push(data);
      if (this.errorHandles.length == 1) {
        const notification = new errorHandlingEnabled();
        notification.show();
        EventBus.$emit("popover-active-route-plan-actions_open");
      }
    },
    removeErrorHandle(data) {
      this.errorHandles.splice(
        this.errorHandles.findIndex(
          errorHandle => errorHandle.error_id == data.error_id
        ),
        1
      );
    }
  },
  async mounted() {
    if (!this.id) {
      if (this.builds.length > 0) {
        if (this.activeBuildId) {
          this.$router.push({
            name: "route_plan",
            params: { id: this.activeBuildId }
          });
          await this.setBuildAsActive(this.activeBuildId);
        } else {
          this.$router.push({
            name: "route_plan",
            params: { id: this.builds[0].id }
          });
          await this.setBuildAsActive(this.builds[0].id);
        }
      }
    } else {
      await this.setBuildAsActive(this.id);
    }
    if (this.activeBuild.status == 0 || this.activeBuild.status == 1) {
      getEstimation(this.activeBuild.id).then(response => {
        this.buildEstimation = response.data.estimation.seconds_left;
      });
    }
  },
  watch: {
    dayPlans() {
      if (this.dayPlans[0]) {
        this.activeDate = this.dayPlans[0].date;
      }
    },
    id(buildId) {
      this.setBuildAsActive(buildId);
      this.resetBuildUpdates();
    }
  },
  beforeRouteLeave(to, from, next) {
    this.confirmBuildLeave(next);
  },
  beforeRouteUpdate(to, from, next) {
    this.confirmBuildLeave(next);
  }
};
</script>

<style lang="scss" module>
.folder-icon {
  float: left;
  margin-top: 6px;
  margin-right: 6px;
  cursor: pointer;
}
.header-content-wrapper {
  width: 87%; // костиль
  display: flex;
  justify-content: space-between;
  line-height: 1;
  .view-types-toggles {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0 24px;
    margin-right: 10px;
  }
  .date-wrapper {
    width: 200px;
    display: flex;
    align-items: center;
    input {
      border: none;
      height: 50px;
      line-height: 50px;
    }
    i {
      display: none;
    }
  }
}
.calendar-controls {
  display: flex;
  align-items: center;
}
.header-date {
  width: 175px;
  font-weight: 600;
  font-size: 20px;
  text-align: center;
  line-height: 48px;
  color: $primary-black;
  text-transform: capitalize;
}
.invisible-datepicker {
  width: 1px !important;
  visibility: hidden;
}
.small-calendar-container {
  height: 20px;
}
.datepicker-icon {
  margin-right: 15px;
  cursor: pointer;
}
.datepicker-arrow-left {
  margin-right: 5px;
}
.datepicker-arrow-right {
  margin-left: 15px;
}
.content-wrapper {
  display: grid;
  grid-template-columns: 215px 1fr;
  .map-wrapper {
    display: flex;
    flex-direction: column;
  }
}
.message-wrapper {
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;

  .title {
    font-size: 15px !important;
    font-weight: 600;
  }

  .button-wrapper {
    margin-top: 15px;
    button {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.last-step {
  width: 350px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  text-align: center;
  .status {
    font-weight: 600;
    font-size: 32px;
    line-height: 38px;
    color: $primary-black;
    margin-bottom: 24px;
  }
  .status-sub {
    font-size: 24px;
    line-height: 29px;
    color: #000000;
  }
}

.countdown-section {
  height: 150px;
  display: flex;
  flex-direction: column;
}
.preloader-section {
  height: 150px;
  margin-top: 20px;
}
</style>
