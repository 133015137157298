import * as repositoryLocation from "@/api/repositories/locations";

export const locations = {
  state: {
    locations: []
  },
  getters: {
    location: state => locationId =>
      state.locations.find(location => location.id == locationId)
  },
  mutations: {
    SET_LOCATIONS(state, locations) {
      state.locations = locations;
    },
    ADD_LOCATION(state, location) {
      state.locations.push(location);
    },
    REMOVE_LOCATIONS(state, locationsId) {
      locationsId.forEach(id => {
        const locationIndex = state.locations.findIndex(
          location => id == location.id
        );
        state.locations.splice(locationIndex, 1);
      });
    },
    UPDATE_LOCATION(state, locationUpdated) {
      const locationIndex = state.locations.findIndex(
        location => location.id == locationUpdated.id
      );
      state.locations.splice(locationIndex, 1, locationUpdated);
    },
    RESET(state) {
      state.locations = [];
    }
  },
  actions: {
    initializeLocations({ commit, rootGetters }) {
      return repositoryLocation
        .fetchAllLocations(rootGetters["configurations/activeSetupId"])
        .then(locations => {
          commit("SET_LOCATIONS", locations);
          return locations;
        });
    },
    createLocation({ commit, rootGetters }, data) {
      return repositoryLocation
        .createLocation(rootGetters["configurations/activeSetupId"], data)
        .then(location => {
          commit("ADD_LOCATION", location);
          return location;
        });
    },
    updateLocation({ commit }, data) {
      return repositoryLocation
        .updateLocation(data.id, data.data)
        .then(location => {
          commit("UPDATE_LOCATION", location);
          return location;
        });
    },
    removeLocation({ commit, dispatch }, locationId) {
      return repositoryLocation.deleteLocation(locationId).then(() => {
        commit("REMOVE_LOCATIONS", [locationId]);
        dispatch("tasks/removeLocations", [locationId], { root: true });
      });
    },
    removeLocations({ commit, dispatch }, locationsId) {
      return repositoryLocation.deleteLocations(locationsId).then(() => {
        commit("REMOVE_LOCATIONS", locationsId);
        dispatch("tasks/removeLocations", locationsId, { root: true });
      });
    },
    reset({ commit }) {
      commit("RESET");
    }
  }
};
