<template>
  <div :class="$style['auth-login-form-wrapper']">
    <base-form
      name="login"
      :form="form"
      @submit="onSubmit"
      v-if="success === undefined || success === false"
    >
      <base-field :label="$t('email')" :errors="form.errors.get('email')">
        <base-input name="email" type="text" v-model="form.email" />
      </base-field>
      <base-field :label="$t('password')" :errors="form.errors.get('password')">
        <base-input name="password" type="password" v-model="form.password" />
      </base-field>
      <base-button
        :loading="form.loading"
        :disabled="form.loading || form.errors.any()"
        @click="onSubmit"
      >
        {{ $t("continue") }}
      </base-button>
      <div :class="$style['forgot-password-button']">
        <span @click="$router.push('forgot')">
          {{ $t("forgotPassword") }}
        </span>
      </div>
    </base-form>
    <div v-else-if="success" :class="$style['success']">
      {{ $t("successfullyLoggedIn") }}
    </div>
    <div v-if="success === false" :class="$style['error']">
      {{ errorMessage }}
    </div>
  </div>
</template>

<script>
import Form from "@/services/form/Form.js";
import { loginService } from "@/services/auth";

import BaseForm from "@/components/form/BaseForm";
import BaseField from "@/components/form/BaseField";
import BaseInput from "@/components/form/BaseInput";
import BaseButton from "@/components/form/BaseButton";

export default {
  name: "AuthLoginForm",
  data() {
    return {
      success: undefined,
      errorMessage: "",
      form: new Form(
        {
          email: "",
          password: ""
        },
        {
          email: {
            email: true,
            presence: { allowEmpty: false }
          },
          password: {
            presence: { allowEmpty: false }
          }
        }
      )
    };
  },
  components: { BaseForm, BaseField, BaseButton, BaseInput },
  props: {},
  computed: {},
  methods: {
    onSubmit() {
      this.success = undefined;
      this.form
        .submit(loginService)
        .then(() => {
          this.success = true;
        })
        .catch(error => {
          if(error.response && error.response.status == 403){
            this.errorMessage = error.response.data.errors.code == "0004" ? this.$t("verifyBeforeLogIn") : this.$t("inactiveUser");
          }else {
            this.errorMessage = this.$t("failedCredsLogIn");
          }
          this.success = false;
        });
    }
  }
};
</script>

<style lang="scss" module>
.auth-login-form-wrapper {
  .error {
    width: 100%;
    text-align: center;
    margin-top: 10px;
    color: red;
  }
  .forgot-password-button {
    font-weight: 600;
    color: $primary-blue;
    cursor: pointer;
    margin-top: 15px;
  }
}
</style>
