import moment from "moment";

export default class RouteBreak {
  #id;
  #fromTime;
  #duration;

  constructor(routeBreak) {
    this.#id = routeBreak.id;
    this.#fromTime = routeBreak.break_from;
    this.#duration = routeBreak.duration;
  }

  getId() {
    return this.#id;
  }

  getStartTimeMarker() {
    return this.#fromTime;
  }

  getEndTimeMarker() {
    const start = moment(this.getStartTimeMarker(), "HH:mm:ss");
    const end = start.add(this.getDuration(), "minutes");
    return end.format("HH:mm:ss");
  }

  getDuration() {
    return this.#duration;
  }
}
