<template>
  <div :class="$style['radio-group-wrapper']">
    <AntRadioGroup v-model="model" :size="size" :class="$style['radio-group']">
      <AntRadioButton
        :class="$style['radio-group-button']"
        v-for="radio in radios"
        :key="radio.value"
        :value="radio.value"
        >{{ radio.label }}</AntRadioButton
      >
    </AntRadioGroup>
  </div>
</template>

<script>
import { Group as AntRadioGroup } from "ant-design-vue/lib/radio";
import { Button as AntRadioButton } from "ant-design-vue/lib/radio";
import "ant-design-vue/lib/radio/style/css";

export default {
  name: "RadioGroup",
  components: { AntRadioGroup, AntRadioButton },
  props: {
    value: {
      type: String,
      default: null
    },
    radios: {
      type: Array,
      default() {
        return [];
      }
    },
    size: {
      type: String,
      default: "large"
    }
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    }
  },
  methods: {}
};
</script>

<style lang="scss" module>
.radio-group-wrapper {
  width: 200px;
  border-radius: 10px;
  line-height: 1;
  .radio-group {
    border-radius: 10px;
    width: 100%;
  }
  .radio-group-button {
    text-align: center;
    height: 48px;
    line-height: 48px;
  }
  .radio-group-button:first-child {
    border-radius: 10px 0 0 10px;
  }
  .radio-group-button:last-child {
    border-radius: 0 10px 10px 0;
  }
  @for $i from 1 through 5 {
    .radio-group-button:first-child:nth-last-child(#{$i}),
    .radio-group-button:first-child:nth-last-child(#{$i})
      ~ .radio-group-button {
      width: #{100% / $i};
    }
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    z-index: 1;
    color: #1890ff;
    background: #f3faff;
    border-color: #1890ff;
    box-shadow: none;
  }
}
</style>
