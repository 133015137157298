import { Transformer, DefaultTransformer } from "jsonapi-fractal";

export default class JsonApiCustomTransformer extends Transformer {
  constructor(type, relationships = []) {
    super();
    this.type = type;
    this.relationships = relationships || [];

    for (const rel of this.relationships) {
      this[rel] = entity => {
        return {
          input: entity[rel],
          transformer: new DefaultTransformer(
            entity[rel] && entity[rel].type ? entity[rel].type : rel
          ),
          included: false
        };
      };
    }
  }

  transform(entity) {
    const attributes = { ...entity };

    for (const rel of this.relationships) {
      delete attributes[rel];
    }

    return attributes;
  }
}
