<template>
  <div :class="$style['collapse-wrapper']">
    <AntCollapse expandIconPosition="right" accordion>
      <AntCollapsePanel
        v-for="panel in panels"
        :key="'collapse-panel-' + panel.id"
        :header="panel.title"
        :class="{ [$style.error]: panel.error ? true : false }"
      >
        <slot :name="panel.id"></slot>
      </AntCollapsePanel>
    </AntCollapse>
  </div>
</template>

<script>
import AntCollapse from "ant-design-vue/lib/collapse/Collapse";
import AntCollapsePanel from "ant-design-vue/lib/collapse/CollapsePanel";
import "ant-design-vue/lib/collapse/style/css";

export default {
  name: "BaseCollapse",
  components: { AntCollapse, AntCollapsePanel },
  props: {
    panels: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  computed: {},
  methods: {}
};
</script>

<style lang="scss" module>
.collapse-wrapper {
  .error {
    -webkit-box-shadow: 0px 0px 21px -10px rgba(255, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 21px -10px rgba(255, 0, 0, 0.75);
    box-shadow: 0px 0px 21px -10px rgba(255, 0, 0, 0.75);
  }
}
</style>
