<template>
  <div :class="$style['days-picker-wrapper']">
    <div :class="$style['squires']">
      <div
        v-for="(active, day) in valueOrdered"
        :key="day"
        :class="{
          [$style.active]: active,
          [$style.weekday]: true,
          [$style.large]: size === 'large',
          [$style.small]: size === 'small',
          [$style.medium]: size === 'medium',
          [$style.loading]: loading
        }"
        @click="handler(day)"
      >
        {{
          $t(day)
            .charAt(0)
            .toUpperCase()
        }}
      </div>
    </div>
    <span :class="$style.link" @click="selectWorkingDays">
      {{ $t("selectWorkingDays") }}
    </span>
  </div>
</template>

<script>
export default {
  name: "BaseWeekdaysPicker",
  components: {},
  props: {
    readOnly: {
      type: Boolean,
      default: false
    },
    value: {
      type: Object,
      default() {
        return {
          monday: false,
          tuesday: false,
          wednesday: false,
          thursday: false,
          friday: false,
          saturday: false,
          sunday: false
        };
      }
    },
    size: {
      type: String,
      default: "large"
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    valueOrdered() {
      return {
        monday: this.value.monday,
        tuesday: this.value.tuesday,
        wednesday: this.value.wednesday,
        thursday: this.value.thursday,
        friday: this.value.friday,
        saturday: this.value.saturday,
        sunday: this.value.sunday
      };
    }
  },
  methods: {
    handler(key) {
      if (!this.readOnly && !this.loading) {
        let days = this.value;
        if (
          days[key] &&
          Object.entries(this.value).filter(day => day[1]).length == 1
        )
          return;
        days[key] = !days[key];
        this.$emit("input", days);
      }
    },
    selectWorkingDays() {
      this.$emit("input", {
        monday: true,
        tuesday: true,
        wednesday: true,
        thursday: true,
        friday: true,
        saturday: false,
        sunday: false
      });
    }
  }
};
</script>

<style lang="scss" module>
.days-picker-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .squires {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    .weekday {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      background: $primary-gray;
      border-radius: 1px;
      margin-right: 5px;
      color: $primary-black;
      &.active {
        background: $primary-blue;
        color: #ffffff;
      }
    }
    .large {
      width: 44px;
      height: 32px;
      font-size: 14px;
    }
    .medium {
      width: 34px;
      height: 24px;
      font-size: 11px;
    }
    .small {
      width: 19px;
      height: 19px;
      font-size: 10px;
    }
    .loading {
      opacity: 0.4;
    }
  }

  .link {
    color: $primary-blue;
    cursor: pointer;
    border-bottom: 1px dashed $primary-blue;
  }
}
</style>
