import { jsonApi } from "@/api";

export const fetchAllLocations = setupId => {
  return jsonApi.index("setups/" + setupId + "/locations", {
    include: "position,schedule,tasks"
  });
};

export const createLocation = (setupId, data) => {
  let payload = {
    active: data.active,
    address: data.address,
    longitude: data.gps.longitude,
    latitude: data.gps.latitude,
    monday_from: data.schedule.monday_from,
    monday_to: data.schedule.monday_to,
    tuesday_from: data.schedule.tuesday_from,
    tuesday_to: data.schedule.tuesday_to,
    wednesday_from: data.schedule.wednesday_from,
    wednesday_to: data.schedule.wednesday_to,
    thursday_from: data.schedule.thursday_from,
    thursday_to: data.schedule.thursday_to,
    friday_from: data.schedule.friday_from,
    friday_to: data.schedule.friday_to,
    saturday_from: data.schedule.saturday_from,
    saturday_to: data.schedule.saturday_to,
    sunday_from: data.schedule.sunday_from,
    sunday_to: data.schedule.sunday_to
  };
  let relations = [];

  payload.setup = {
    id: setupId,
    type: "setups"
  };
  relations.push("setup");

  return jsonApi.store("locations", payload, relations, {
    include: "position,schedule,tasks"
  });
};

export const updateLocation = (locationId, data) => {
  let payload = {
    active: data.active,
    address: data.address,
    longitude: data.gps.longitude,
    latitude: data.gps.latitude,
    monday_from: data.schedule.monday_from,
    monday_to: data.schedule.monday_to,
    tuesday_from: data.schedule.tuesday_from,
    tuesday_to: data.schedule.tuesday_to,
    wednesday_from: data.schedule.wednesday_from,
    wednesday_to: data.schedule.wednesday_to,
    thursday_from: data.schedule.thursday_from,
    thursday_to: data.schedule.thursday_to,
    friday_from: data.schedule.friday_from,
    friday_to: data.schedule.friday_to,
    saturday_from: data.schedule.saturday_from,
    saturday_to: data.schedule.saturday_to,
    sunday_from: data.schedule.sunday_from,
    sunday_to: data.schedule.sunday_to
  };
  return jsonApi.update("locations", locationId, payload, [], {
    include: "position,schedule,tasks"
  });
};

export const deleteLocation = locationId => {
  return jsonApi.remove("locations", locationId);
};

export const deleteLocations = locationsId => {
  return jsonApi.bulkDelete("locations", locationsId);
};
