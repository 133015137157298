<template>
  <div :class="$style['schedule-wrapper']">
    <base-field :label="$t('periodicity')">
      <base-select-tags
        :placeholder="$t('periodicity')"
        :loading="loading"
        :options="periodicityOptions"
        v-model="periodicityType"
        @input="onPeriodicityUpdate"
      />
    </base-field>
    <div :class="$style['date-wrapper']" v-if="periodicityType === 'once'">
      <base-field :label="$t('date')">
        <base-date-picker
          :loading="loading"
          v-model="expression.repeatOn"
          @input="onUpdate"
        ></base-date-picker>
      </base-field>
    </div>
    <div v-else>
      <base-radio-group
        v-model="expression.periodicity"
        :radios="regularTypes"
        @input="onPeriodicityUpdate"
      />
      <div
        :class="$style['repeat-on-each']"
        v-show="
          expression.periodicity === 'daily' ||
            expression.periodicity === 'weekly'
        "
      >
        <base-field>
          <span>Repeat on each</span>
          <base-input-number
            :loading="loading"
            :class="$style['input-number']"
            v-model="expression.repeatOn"
            :disabled="loading"
            @input="onRepeatOnUpdate"
          />
          <span v-if="expression.periodicity === 'daily'">{{ $t("day") }}</span>
          <span v-else-if="expression.periodicity === 'weekly'">{{
            $t("week")
          }}</span>
        </base-field>
      </div>
      <div
        :class="$style['repeat-on-each']"
        v-show="
          expression.periodicity === 'weekly' ||
            expression.periodicity === 'monthly' ||
            expression.periodicity === 'yearly'
        "
      >
        <base-field>
          <span>{{ $t("allocate") }} </span>
          <base-input-number
            :loading="loading"
            :class="$style['input-number']"
            :disabled="loading"
            v-model="expression.allocationTimes"
            @input="onAllocationTimesUpdate"
            :max="maxAllocationAvailable"
            :min="1"
          />
          <span>{{ $t("times") }}</span>
        </base-field>
      </div>
      <base-field
        v-if="expression.periodicity === 'yearly'"
        :label="$t('monthType')"
      >
        <base-select-tags
          mode="multiple"
          :options="monthTypes"
          :loading="loading"
          v-model="expression.monthType"
          :disabled="loading"
          @input="onUpdate"
          disableNull
        />
      </base-field>
      <base-field
        v-if="
          expression.periodicity === 'monthly' ||
            expression.periodicity === 'yearly'
        "
        :label="$t('weekType')"
      >
        <base-select-tags
          :options="weekTypes"
          :loading="loading"
          v-model="expression.weekType"
          :disabled="loading"
          @input="onUpdate"
        />
      </base-field>
      <base-field
        v-if="expression.periodicity !== 'daily'"
        :label="$t('onDaysOfWeek')"
      >
        <base-week-days-picker
          :loading="loading"
          v-model="expression.daysOfWeek"
          @input="onUpdate"
        />
      </base-field>
      <base-field :label="$t('startDate') + ' - ' + $t('endDate')">
        <base-date-range-picker
          :loading="loading"
          v-model="dateRange"
          @input="onUpdate"
          pastDisabled
          enormousDisabled
        />
      </base-field>
    </div>
  </div>
</template>

<script>
import { parseExpression, writeExpression } from "@/utils/cron.js";

import BaseField from "@/components/form/BaseField";
import BaseSelectTags from "@/components/form/BaseSelectTags";
import BaseDatePicker from "@/components/form/BaseDatePicker";
import BaseRadioGroup from "@/components/form/BaseRadioGroup";
import BaseInputNumber from "@/components/form/BaseInputNumber";
import BaseWeekDaysPicker from "@/components/form/BaseWeekDaysPicker";
import BaseDateRangePicker from "@/components/form/BaseDateRangePicker";

const today = new Date().toISOString().slice(0, 10);
const todayPlusYear = new Date(
  new Date().setFullYear(new Date().getFullYear() + 1)
)
  .toISOString()
  .slice(0, 10);

export default {
  name: "ScheduleField",
  data() {
    return {
      expression: {
        periodicity: "once",
        repeatOn: today,
        allocationTimes: 5,
        daysOfWeek: {
          monday: false,
          thuesday: false,
          wednesday: false,
          thursday: false,
          friday: false,
          saturday: false,
          sunday: false
        },
        weekType: 1,
        monthType: ["1", "2"]
      },
      from: today,
      to: todayPlusYear,
      periodicityOptions: [
        { label: this.$i18n.t("once"), value: "once" },
        { label: this.$i18n.t("regular"), value: "regular" }
      ],
      regularTypes: [
        { label: this.$i18n.t("daily"), value: "daily" },
        { label: this.$i18n.t("weekly"), value: "weekly" },
        { label: this.$i18n.t("monthly"), value: "monthly" },
        { label: this.$i18n.t("yearly"), value: "yearly" }
      ],
      weekTypes: [
        { label: this.$i18n.t("first"), value: "1" },
        { label: this.$i18n.t("second"), value: "2" },
        { label: this.$i18n.t("third"), value: "3" },
        { label: this.$i18n.t("fourth"), value: "4" },
        // { label: "Fifth", value: "5" },
        // { label: "Sixth", value: "6" },
        { label: this.$i18n.t("last"), value: "0" }
      ],
      monthTypes: [
        { label: this.$i18n.t("january"), value: "1" },
        { label: this.$i18n.t("february"), value: "2" },
        { label: this.$i18n.t("march"), value: "3" },
        { label: this.$i18n.t("april"), value: "4" },
        { label: this.$i18n.t("may"), value: "5" },
        { label: this.$i18n.t("june"), value: "6" },
        { label: this.$i18n.t("july"), value: "7" },
        { label: this.$i18n.t("august"), value: "8" },
        { label: this.$i18n.t("september"), value: "9" },
        { label: this.$i18n.t("october"), value: "10" },
        { label: this.$i18n.t("november"), value: "11" },
        { label: this.$i18n.t("december"), value: "12" }
      ]
    };
  },
  components: {
    BaseField,
    BaseSelectTags,
    BaseDatePicker,
    BaseRadioGroup,
    BaseInputNumber,
    BaseWeekDaysPicker,
    BaseDateRangePicker
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    value: {
      type: Object,
      default() {
        return {
          expression: `@once ${today}`,
          from: null,
          to: null
        };
      }
    }
  },
  computed: {
    maxAllocationAvailable() {
      return this.expression && this.expression.daysOfWeek
        ? Object.entries(this.expression.daysOfWeek).filter(day => day[1])
            .length
        : 0;
    },
    periodicityType: {
      get() {
        return this.expression.periodicity !== "once" ? "regular" : "once";
      },
      set(value) {
        this.expression.periodicity = value === "regular" ? "daily" : "once";
      }
    },
    dateRange: {
      get() {
        return [this.from, this.to];
      },
      set(value) {
        this.from = value[0];
        this.to = value[1];
      }
    }
  },
  methods: {
    writeExpression,
    parseExpression,
    initialize(value) {
      this.expression = this.parseExpression(value.expression);
      this.from = this.value.from;
      this.to = this.value.to;
    },
    onPeriodicityUpdate() {
      if (this.expression.periodicity === "once") {
        this.expression.repeatOn = today;
      } else {
        this.expression.repeatOn = "1";
        this.expression.allocationTimes = "5";
      }
      this.onUpdate();
    },
    onUpdate() {
      const expression = this.writeExpression(
        this.expression.periodicity,
        this.expression.repeatOn,
        this.expression.daysOfWeek,
        this.expression.weekType,
        this.expression.monthType,
        this.expression.allocationTimes
      );
      const value =
        this.expression.periodicity === "once"
          ? {
              expression: expression,
              from: null,
              to: null
            }
          : {
              expression: expression,
              from: this.from,
              to: this.to
            };
      this.$emit("input", value);
    },
    onRepeatOnUpdate() {
      this.expression.repeatOn =
        this.expression.repeatOn === "" || this.expression.repeatOn < 1
          ? "1"
          : this.expression.repeatOn.toString();
      this.onUpdate();
    },
    onAllocationTimesUpdate() {
      this.onUpdate();
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.onUpdate();
    });
    this.initialize(this.value);
  },
  watch: {
    value(value) {
      this.initialize(value);
    }
  }
};
</script>

<style lang="scss" module>
.schedule-wrapper {
  .repeat-on-each {
    padding: 10px 0 10px 0;
    span {
      font-weight: 500;
    }
    .input-number {
      margin: 0 10px 0 10px;
      display: inline-block;
    }
  }
}
</style>
