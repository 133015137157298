<template>
  <div :class="$style['tag-wrapper']">
    <div :class="$style['tag-inner-wrapper']">
      <slot></slot>
      <Icon v-if="showDeleteBtn" :class="$style['tag-icon']" name="close-circle" :height="12" pointer @click="$emit('delete')"/>
    </div>
  </div>
</template>

<script>
export default {
  name: "Tag",
  components: {},
  props: {
    showDeleteBtn: {
      type: Boolean,
      default: false
    }
  },
  computed: {},
  methods: {}
};
</script>

<style lang="scss" module>
.tag-wrapper {
  display: inline-block;
  width: auto;
  height: 25px;
  padding: 6px;
  border-radius: 4px;
  background: #d9eefe;
  color: $primary-black;
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;

  .tag-inner-wrapper {
    display: flex;

    .tag-icon {
      margin-left: 5px;
    }
  }
}
</style>
