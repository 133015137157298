<template>
  <div :class="$style['tags-group-wrapper']">
    <Tag v-if="tags.length > 0">{{ tags[0] }}</Tag>
    <AntTooltip>
      <template #title>{{
        tags.filter((tag, index) => index !== 0).join(", ")
      }}</template>
      <Tag :class="$style['more-tags']" v-if="tags.length > 1"
        >+{{ tags.length - 1 }}</Tag
      >
    </AntTooltip>
  </div>
</template>

<script>
import { Tooltip as AntTooltip } from "ant-design-vue";
import "ant-design-vue/lib/tooltip/style/css";

export default {
  name: "TagsGroup",
  components: { AntTooltip },
  props: {
    tags: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  computed: {},
  methods: {}
};
</script>

<style lang="scss" module>
.tags-group-wrapper {
  .more-tags {
    margin-left: 9px;
    cursor: pointer;
  }
}
</style>
