<template>
  <div :class="$style['map-view-wrapper']">
    <MapDaypicker v-model="mapDayPickerDate" />
    <div :class="$style['map-wrapper']">
      <l-map :zoom="zoom" :center="currentCenter">
        <l-tile-layer :url="url" :attribution="attribution" />
        <l-layer-group v-for="route in routes" :key="route.getId()">
          <l-layer-group>
            <l-marker
              v-for="(visit, index) in route.getVisits()"
              :key="route.getId() + '-' + index"
              :lat-lng="
                visit.isHome()
                  ? {
                      lat: setupObject
                        .getWorkerById(route.getWorkerId())
                        .getHome().latitude,
                      lng: setupObject
                        .getWorkerById(route.getWorkerId())
                        .getHome().longitude
                    }
                  : {
                      lat: visit.getFirstRoutePoint().getGps().latitude,
                      lng: visit.getFirstRoutePoint().getGps().longitude
                    }
              "
              @mouseover="mouseoverRoute(route)"
              @mouseleave="mouseleaveRoute"
            >
              <l-icon :icon-anchor="visit.isHome() ? [20, 20] : [17, 37]">
                <Visit
                  :worker="setupObject.getWorkerById(route.getWorkerId())"
                  :visit="visit"
                  :detailed="routeIsDetailed(route)"
                />
              </l-icon>
            </l-marker>
          </l-layer-group>
          <l-polyline
            :lat-lngs="
              route.getWayPoints().map(wayPoint => {
                return { lat: wayPoint.latitude, lng: wayPoint.longitude };
              })
            "
            :color="setupObject.getWorkerById(route.getWorkerId()).getColor()"
            :weight="5"
            @mouseover="mouseoverRoute(route)"
            @mouseleave="mouseleaveRoute"
          />
        </l-layer-group>
      </l-map>
    </div>
  </div>
</template>

<script>
import MapDaypicker from "@/components/route-plan/MapDaypicker.vue";

import "leaflet/dist/leaflet.css";
import { latLng } from "leaflet";

import {
  LMap,
  LTileLayer,
  LMarker,
  LIcon,
  LLayerGroup,
  LPolyline
} from "vue2-leaflet";

import Visit from "@/components/route-plan/Visit";

import Setup from "@/services/setup/Setup";
import Route from "@/services/build/Route.js";

export default {
  name: "MapView",
  data() {
    return {
      zoom: 13,
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      routeDetailed: null
    };
  },
  components: {
    MapDaypicker,
    LMap,
    LTileLayer,
    LMarker,
    LIcon,
    LLayerGroup,
    LPolyline,
    Visit
  },
  props: {
    activeDate: {
      type: String,
      default: null
    },
    dayPlans: {
      type: Array,
      required: true
    },
    setup: {
      type: Object,
      required: true
    },
    defaultMapCenter: {
      type: Object,
      default() {
        return { latitude: 48.85974, longitude: 2.29522 };
      }
    }
  },
  computed: {
    mapDayPickerDate: {
      get() {
        return this.activeDate;
      },
      set(date) {
        this.$emit("update:activeDate", date);
      }
    },
    routes() {
      const dayPlan = this.dayPlans.find(
        dayPlan => dayPlan.date == this.activeDate
      );

      return dayPlan ? dayPlan.routes.map(route => new Route(route)) : [];
    },
    setupObject() {
      return new Setup(this.setup);
    },
    currentCenter() {
      return this.routes.length > 0
        ? latLng(
            this.setupObject
              .getWorkerById(this.routes[0].getWorkerId())
              .getHome().latitude,
            this.setupObject
              .getWorkerById(this.routes[0].getWorkerId())
              .getHome().longitude
          )
        : latLng(
            this.defaultMapCenter.latitude,
            this.defaultMapCenter.longitude
          );
    }
  },
  methods: {
    routeIsDetailed(route) {
      return this.routeDetailed == route.getId();
    },
    mouseoverRoute(route) {
      this.routeDetailed = route.getId();
    },
    mouseleaveRoute() {
      this.routeDetailed = null;
    }
  }
};
</script>

<style lang="scss" module>
.map-view-wrapper {
  .map-wrapper {
    height: calc(100vh - 80px);
    .leaflet-control-attribution.leaflet-control {
      display: none;
    }
  }
}
</style>
