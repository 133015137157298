<template>
  <div :class="$style['percent-circle-circle-wrapper']">
    <vue-ellipse-progress
      :progress="percentage"
      fontSize="0.7rem"
      :size="40"
      thickness="15%"
    >
      <span slot="legend-value">%</span>
    </vue-ellipse-progress>
  </div>
</template>

<script>
import Vue from "vue";
import VueEllipseProgress from "vue-ellipse-progress";
Vue.use(VueEllipseProgress);

export default {
  name: "PercentCircle",
  props: {
    percentage: {
      type: Number,
      default: 0
    }
  },
  computed: {},
  methods: {}
};
</script>

<style lang="scss" module>
.percent-circle-circle-wrapper {
}
</style>
