<template>
  <GuestLayout>
    <template #illustration>
      <img :src="illustration" />
    </template>
    <template #header>
      <BaseBackButton @click="$router.push('login')" />
    </template>
    <template #content>
      <Grid>
        <div slot="header">{{ $t("resetPassword") }}</div>
        <div slot="form">
          <ForgotPasswordForm />
        </div>
        <div slot="footer">
          {{ $t("dontHaveAccout") }}
          <b @click="$router.push('register')">{{ $t("register") }}</b>
        </div>
      </Grid>
    </template>
  </GuestLayout>
</template>

<script>
import AuthIllustration from "@/assets/auth-img.png";

import GuestLayout from "@/layouts/GuestLayout";
import Grid from "@/components/grid/AuthFormGrid";

import BaseBackButton from "@/components/ui/BaseBackButton";
import ForgotPasswordForm from "@/components/forms/AuthForgotPasswordForm";

export default {
  page: {
    title: "Forgot password",
    meta: [{ name: "description", content: "The Forgot password page." }]
  },
  components: { GuestLayout, BaseBackButton, Grid, ForgotPasswordForm },
  computed: {
    illustration() {
      return AuthIllustration;
    }
  }
};
</script>

<style lang="scss" module>
.forgot-password-description {
}
</style>
