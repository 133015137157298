import { jsonApi, restApi } from "@/api";

export const fetchAllProfiles = companyId => {
  return jsonApi.index("companies/" + companyId + "/profiles", {
    include: "user"
  });
};

export const createUser = data => {
  let payload = {
    email: data.email,
    first_name: data.first_name,
    last_name: data.last_name,
    active: data.status
  };

  return jsonApi.store("users", payload, [], {
    include: "profile"
  });
};

export const updateUser = (userId, data) => {
  let payload = {
    email: data.email,
    first_name: data.first_name,
    last_name: data.last_name,
    active: data.status
  };

  return jsonApi.update("users", userId, payload, [], {
    include: "profile"
  });
};

export const deleteUser = userId => {
  return jsonApi.remove("users", userId);
};

export const deleteUsers = usersId => {
  return jsonApi.bulkDelete("users", usersId);
};

export const exportUsers = usersId => {
  return restApi.post(`/custom/users/export`, {
    users: usersId
  });
};
