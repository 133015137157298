<template>
  <div :class="$style['base-field-wrapper']">
    <span :class="$style.label" v-if="label"
      >{{ label }} <template v-if="required">*</template></span
    >
    <slot :slotInputEvent="slotInputEvent"></slot>
    <div :class="$style['errors']" v-if="hasErrors">
      <span
        :class="$style['error-message']"
        v-for="(error, index) in errors"
        :key="index"
        >{{ error }}</span
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "BaseField",
  data() {
    return {};
  },
  inject: ["formEventBus"],     // TODO fix error `Injection "formEventBus" not found`
  components: {},
  props: {
    label: {
      type: String,
      default: null
    },
    required: {
      type: Boolean,
      default: false
    },
    errors: {
      type: Array,
      default() {
        return [];
      }
    },
    name: {
      type: String,
      default: "default"
    }
  },
  computed: {
    hasErrors() {
      return this.errors.length > 0;
    }
  },
  methods: {
    slotInputEvent(value) {
      this.formEventBus.$emit("fieldUpdate", {
        name: this.name,
        value: value
      });
    }
  }
};
</script>

<style lang="scss" module>
.base-field-wrapper {
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  .label {
    width: 100%;
    font-size: 15px;
    line-height: 20px;
    display: inline-block;
    color: $primary-black;
    font-weight: 600;
    margin-bottom: 15px;
  }
  .errors {
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    width: 100%;
    margin-top: 10px;

    .error-message {
      color: red;
    }
  }
}
</style>
