<template>
  <GuestLayout>
    <template #illustration>
      <img :src="illustration" />
    </template>
    <template #header>
      <steps v-if="!success" :steps="steps" :step="step" @back="step = 1" />
    </template>
    <template #content>
      <Grid>
        <div slot="header">
          <span>{{ $t("createAccount") }}</span>
        </div>
        <div slot="form">
          <register-form
            :step="step"
            @step="step = $event"
            @success="success = true"
          />
        </div>
        <div slot="footer" class="footer">
          <div class="separate-navigation">
            {{ $t("alreadyHaveAccount") }}
            <b @click="$router.push('/login')">{{ $t("login") }}</b>
          </div>
          <LocalizationSelector />
        </div>
      </Grid>
    </template>
  </GuestLayout>
</template>

<script>
import GuestLayout from "@/layouts/GuestLayout";
import Grid from "@/components/grid/AuthFormGrid";
import AuthIllustration from "@/assets/auth-img.png";

import RegisterForm from "@/components/forms/AuthRegisterForm";

import LocalizationSelector from "@/components/LocalizationSelector";

export default {
  page: {
    title: "Register",
    meta: [{ name: "description", content: "The Register page." }]
  },
  data() {
    return {
      success: false,
      step: 1
    };
  },
  methods: {},
  components: { GuestLayout, Grid, RegisterForm, LocalizationSelector },
  computed: {
    illustration() {
      return AuthIllustration;
    },
    steps() {
      return [this.$i18n.t("accoutInfo"), this.$i18n.t("personalInfo")];
    }
  }
};
</script>

<style lang="scss">
.footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
</style>
