<template>
  <div :class="$style['modal-wrapper']">
    <AntModal
      class="form"
      :visible="visible"
      :width="width"
      :title="title"
      :footer="null"
      @ok="visible = false"
      @cancel="visible = false"
      destroyOnClose
    >
      <slot></slot>
    </AntModal>
  </div>
</template>

<script>
import Vue from "vue";
import { Modal as AntModal } from "ant-design-vue";
import "ant-design-vue/lib/modal/style/css";

AntModal.install(Vue);

export default {
  name: "Modal",
  components: { AntModal },
  props: {
    title: {
      type: String
    },
    width: {
      type: Number,
      default: 870
    },
    value: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    visible: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    }
  },
  methods: {}
};
</script>

<style lang="scss" module>
.modal-wrapper {
}
</style>
