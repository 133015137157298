<template>
  <AntSpin :spinning="loading" :delay="delay">
    <slot></slot>
    <Icon name="loading" slot="indicator" />
    <div class="spin-content">
      <slot name="message"></slot>
    </div>
  </AntSpin>
</template>

<script>
import { Spin as AntSpin } from "ant-design-vue";
import "ant-design-vue/lib/spin/style/css";
export default {
  name: "Loading",
  data() {
    return {};
  },
  components: { AntSpin },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    delay: {
      type: Number,
      default: null
    }
  },
  computed: {},
  methods: {}
};
</script>

<style lang="scss" module></style>
