<template>
  <div :class="$style['table-wrapper']">
    <AntTable
      :columns="columns"
      :data-source="data"
      :loading="loading"
      :row-selection="rowSelection"
      :rowKey="rowKey"
    >
      <template
        v-for="column in columns"
        v-key="'table-column-' + column.key"
        :slot="column.key"
      />
      <div
        slot="filterDropdown"
        style="padding: 8px"
        slot-scope="{ setSelectedKeys, confirm, clearFilters }"
      >
        <DateRange
          v-model="dateRangeFilter"
          @input="dateRangeFilterUpdate(confirm, setSelectedKeys)"
        />
        <Button @click="() => handleReset(clearFilters)">Reset</Button>
      </div>
    </AntTable>
  </div>
</template>

<script>
import { Table as AntTable } from "ant-design-vue";
import "ant-design-vue/lib/table/style/css";

export default {
  name: "Table",
  data() {
    return {
      dateRangeFilter: [null, null],
      selectedRowKeys: []
    };
  },
  components: { AntTable },
  props: {
    columns: {
      type: Array,
      require: true
    },
    data: {
      type: Array,
      require: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    rowKey: {
      type: String,
      default: "id"
    }
  },
  computed: {
    rowSelection() {
      const { selectedRowKeys } = this;
      return {
        selectedRowKeys,
        onChange: this.onSelectChange,
        hideDefaultSelections: true,
        selections: [
          {
            key: "all-data",
            text: "Select All Data",
            onSelect: () => {
              this.selectedRowKeys = this.data.map(item => item[this.rowKey]);
            }
          }
        ]
      };
    }
  },
  methods: {
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys;
      this.$emit("select", {
        keys: selectedRowKeys,
        rows: selectedRows
      });
    },
    handleReset(clearFilters) {
      clearFilters();
      this.dateRangeFilter = [null, null];
    },
    dateRangeFilterUpdate(confirm, setSelectedKeys) {
      setSelectedKeys([
        {
          from: this.dateRangeFilter[0],
          to: this.dateRangeFilter[1]
        }
      ]);
      confirm();
    }
  },
  watch: {
    selectedRowKeys(keys) {
      this.$emit("select", {
        keys: keys,
        rows: this.data.filter(row => keys.find(key => row.id == key))
      });
    }
  }
};
</script>

<style lang="scss" module>
.table-wrapper {
  width: 100%;
  margin-top: 22px;
  padding-left: 20px;
  padding-right: 17px;
  border-radius: 8px;
  background: #ffffff;
  border: 1px solid rgba(102, 102, 102, 0.08);
  tr > th {
    background: #ffffff;
    @extend %table-content;
    font-weight: 500;
  }
  td {
    @extend %table-content;
    font-weight: 300;
  }
}
</style>
