<template>
  <div :class="$style['form-field-wrapper']">
    <span :class="$style.label" v-if="label"
      >{{ label }} <template v-if="required">*</template></span
    >
    <slot></slot>
    <span v-if="error" :class="$style['error-message']">{{ error }}</span>
  </div>
</template>

<script>
export default {
  name: "FormField",
  data() {
    return {};
  },
  components: {},
  props: {
    label: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: null
    },
    required: {
      type: Boolean,
      default: false
    },
    error: {
      type: String,
      default: undefined
    }
  },
  computed: {},
  methods: {}
};
</script>

<style lang="scss" module>
.form-field-wrapper {
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  .label {
    width: 100%;
    font-size: 15px;
    line-height: 20px;
    display: inline-block;
    color: $primary-black;
    font-weight: 600;
    margin-bottom: 15px;
  }
  .error-message {
    width: 100%;
    display: inline-block;
    color: red;
    margin-top: 5px;
  }
}
</style>
