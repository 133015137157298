<template>
  <div class="user-entity-form">
    <base-form name="user-entity-form" :form="form" @submit="onSubmit">
      <base-field :label="$t('email')" :errors="form.errors.get('email')">
        <base-input name="email" type="text" v-model="form.email" />
      </base-field>
      <base-field :label="$t('name')" :errors="form.errors.get('first_name')">
        <base-input name="first_name" type="text" v-model="form.first_name" />
      </base-field>
      <base-field
        :label="$t('lastName')"
        :errors="form.errors.get('last_name')"
      >
        <base-input name="last_name" type="text" v-model="form.last_name" />
      </base-field>
      <base-field :label="$t('status')">
        <base-select-tags
          :placeholder="$t('status')"
          :options="statusOptions"
          v-model="form.status"
        />
      </base-field>
      <base-button
        size="large"
        @click="onSubmit"
        :loading="form.loading"
        :disabled="form.loading || form.errors.any()"
        >{{ form.hasUpdateType() ? $t("update") : $t("create") }}</base-button
      >
    </base-form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import Form from "@/services/form/Form.js";

import BaseForm from "@/components/form/BaseForm";
import BaseButton from "@/components/form/BaseButton";
import BaseField from "@/components/form/BaseField";
import BaseInput from "@/components/form/BaseInput";
import BaseSelectTags from "@/components/form/BaseSelectTags";

export default {
  name: "UserEntityForm",
  data() {
    return {
      statusOptions: [
        { label: this.$i18n.t("active"), value: true },
        { label: this.$i18n.t("inactive"), value: false }
      ],
      success: undefined,
      error: undefined,
      form: new Form(
        {
          email: "",
          first_name: "",
          last_name: "",
          status: true
        },
        {
          email: {
            email: true,
            presence: { allowEmpty: false }
          },
          first_name: {
            presence: { allowEmpty: false }
          },
          last_name: {
            presence: { allowEmpty: false }
          },
          status: {
            presence: { allowEmpty: false }
          }
        },
        {
          id: this.userId
        }
      )
    };
  },
  components: { BaseForm, BaseSelectTags, BaseButton, BaseField, BaseInput },
  props: {
    userId: {
      type: [Number, String],
      default: undefined
    }
  },
  computed: {
    ...mapGetters("users", ["user"])
  },
  methods: {
    ...mapActions("users", ["createUser", "updateUser"]),
    initializeUser(userId) {
      const user = this.user(userId);
      if (user) {
        this.form.setData(
          {
            email: user.email,
            first_name: user.profile.first_name,
            last_name: user.profile.last_name,
            status: user.active == 1
          },
          {
            id: userId
          }
        );
      }
    },
    onSubmit() {
      this.success = undefined;
      this.form
        .submit(this.form.hasUpdateType() ? this.updateUser : this.createUser)
        .then(user => {
          this.success = true;

          if (!this.form.hasUpdateType()) {
            this.$emit("userCreate", user.id);
          }

          this.$emit("success");
        })
        .catch(() => {
          this.success = false;
          this.form.errors.record({
            email: [this.$i18n.t("unique")]
          });
        });
    }
  },
  mounted() {
    if (this.form.hasUpdateType()) {
      this.initializeUser(this.userId);
    }
  },
  watch: {
    userId(userId) {
      this.initializeUser(userId);
    }
  }
};
</script>

<style lang="scss">
.user-entity-form {
}
</style>
