<template>
  <div :class="$style['map-daypicker-wrapper']">
    <div :class="$style.prev" @click="goToPrevDates"><Icon name="back" /></div>
    <div class="dates-picker-wrapper">
      <FullCalendar ref="calendar" :options="calendarOptions" />
    </div>
    <div :class="$style.next" @click="goToNextDates"><Icon name="next" /></div>
  </div>
</template>

<script>
import Icon from "@/components/ui/Icon";
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";

import enLocale from "@fullcalendar/core/locales/en-gb";
import deLocale from "@fullcalendar/core/locales/de";

import moment from "moment";

export default {
  name: "MapDaypicker",
  data() {
    return {};
  },
  components: { FullCalendar, Icon },
  props: {
    value: {
      type: String,
      default: null
    }
  },
  computed: {
    calendarOptions() {
      return {
        locale: this.calendarLocale,
        plugins: [dayGridPlugin, interactionPlugin],
        initialView: "dayGridWeek",
        headerToolbar: false,
        firstDay: 1,
        navLinks: true,
        navLinkDayClick: this.dateEvent,
        views: {
          dayGridWeek: {
            dayHeaderFormat: { weekday: "short", day: "numeric" }
          }
        }
      };
    },
    calendarLocale() {
      switch (this.$i18n.locale) {
        case "en":
          return enLocale;
        case "de":
          return deLocale;
        default:
          return enLocale;
      }
    }
  },
  watch: {
    value(date) {
      this.lookingForActive(date);
    }
  },
  methods: {
    dateEvent(date) {
      this.$emit("input", moment(date).format("YYYY-MM-DD"));
    },
    goToPrevDates() {
      this.$refs.calendar.getApi().prev();
      this.lookingForActive(this.value);
    },
    goToNextDates() {
      this.$refs.calendar.getApi().next();
      this.lookingForActive(this.value);
    },
    lookingForActive(date) {
      var sections = document.getElementsByClassName(
        "fc-col-header-cell-cushion"
      );
      let day = date.slice(8);
      let splitted = day.split("");
      if (+splitted[0] === 0) {
        for (let i = 0; i < sections.length; i++) {
          this.addActiveClass(sections[i], splitted[1]);
        }
      } else {
        for (let i = 0; i < sections.length; i++) {
          this.addActiveClass(sections[i], day);
        }
      }
    },
    addActiveClass(currentDate, day) {
      let date = currentDate.textContent.split(" ");
      currentDate.classList.remove("active-date");
      if (date[0] === day) {
        currentDate.classList.add("active-date");
      }
    }
  },
  mounted() {
    if (this.value) {
      if (this.$refs.calendar)
        this.$refs.calendar.getApi().gotoDate(this.value);
      this.lookingForActive(this.value);
    }
  }
};
</script>

<style lang="scss" module>
.map-daypicker-wrapper {
  display: grid;
  grid-template-columns: 22px 1fr 22px;
  grid-template-rows: 52px;
  gap: 0px 0px;
  grid-template-areas: ". . .";
  height: 52px;
  .prev,
  .next {
    border: 1px solid #ffffff;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 5px;
  }
}
</style>
<style lang="scss">
.dates-picker-wrapper {
  .fc .fc-col-header-cell-cushion {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: $primary-black;
    &:hover {
      text-decoration: none;
      color: $primary-blue;
    }
  }
  .fc .fc-scrollgrid-section-liquid {
    display: none;
  }
  .fc .active-date {
    position: relative;
    color: $primary-blue;
    &:after {
      content: "";
      display: block;
      position: absolute;
      bottom: -1px;
      left: 0;
      right: 0;
      width: 100%;
      height: 3px;
      background-color: $primary-blue;
    }
  }
}
</style>
