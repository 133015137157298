<template>
  <div :class="$style['steps-wrapper']">
    <BaseBackButton v-if="current > 1" @click="back" />
    <div :class="$style['step-count']">
      <div :class="$style['step-number']">
        <span>{{ $t("step") }} {{ current }}/{{ totalSteps }}</span>
      </div>
      <div :class="$style['step-description']">
        <div v-for="(name, key) in steps" :key="key + 1">
          <p v-if="current == key + 1">{{ name }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseBackButton from "@/components/ui/BaseBackButton";

export default {
  name: "Steps",
  data() {
    return {};
  },
  components: { BaseBackButton },
  props: {
    steps: {
      type: Array,
      default() {
        return [
          {
            name: "Default"
          }
        ];
      }
    },
    step: {
      type: Number,
      default: 1
    }
  },
  computed: {
    current() {
      return this.validateStep(this.step) ? this.step : 1;
    },
    totalSteps() {
      return this.steps.length;
    }
  },
  methods: {
    back() {
      this.$emit("back", this.step - 1);
    },
    validateStep(step) {
      return step >= 1 && step <= this.totalSteps;
    }
  }
};
</script>

<style lang="scss" module>
.steps-wrapper {
  width: 100%;
  height: 80px;
  .step-count {
    float: right;
    text-align: right;
    .step-number {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: #bdbdbd;
      margin-bottom: 4px;
    }
    .step-description {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      color: #8692a6;
    }
  }
}
</style>
