import Echo from "laravel-echo";
import { getAuthToken } from "@/utils/auth";

const Socket = {
  install(Vue) {
    window.io = require("socket.io-client");
    Vue.prototype.$socket = new Echo({
      broadcaster: "socket.io",
      host: process.env.VUE_APP_API_SOCKET_URL,
      auth: {
        headers: {
          Authorization: getAuthToken()
        }
      }
    });
  }
};

export default Socket;
