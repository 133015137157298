<template>
  <div :class="$style['schedules-wrapper']">
    <div :class="$style['schedule-wrapper-header']">
      <base-icon
        v-if="workerId"
        :class="$style.icon"
        name="plus"
        fill="#2e92f8"
        @click="initializeLocalSchedule"
      />
      <span>{{ $t("schedules") }}</span>
    </div>
    <result v-if="!workerId" :title="$t('createUser')" :class="$style.result">
      <template #extra>
        {{ $t("shouldCreateUser") }}
      </template>
    </result>
    <result
      status="warning"
      v-else-if="workerHasNoSchedule"
      :title="$t('userHasNoSchedule')"
    >
      <template #extra>
        {{ $t("userHasNoScheduleDesciption") }}
      </template>
    </result>
    <div :class="$style.schedules" v-if="workerId && !workerHasNoSchedule">
      <base-collapse
        :panels="
          finalSchedules.map(schedule => {
            return {
              id: schedule.id ? schedule.id : schedule.tempId,
              title:
                schedule.id && schedule.title
                  ? schedule.title
                  : $t('newSchedule'),
              error: intersections.find(
                intersection => intersection.scheduleId == schedule.id
              )
            };
          })
        "
      >
        <worker-schedule-form
          v-for="schedule in finalSchedules"
          :key="'schedule-form-' + schedule.id ? schedule.id : schedule.tempId"
          :weeklyWorkingHours="weeklyWorkingHours"
          :defaultWorkingDays="defaultWorkingDaysData"
          :workerId="workerId"
          :datesIntervals="datesIntervals"
          :schedule="schedule"
          :slot="schedule.id ? schedule.id : schedule.tempId"
          @deleteLocalSchedule="deleteLocalSchedule"
          @datesIntersectionError="datesIntersectionError"
          @datesUpdate="scheduleDatesUpdate"
        />
      </base-collapse>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { scheduleFormatter } from "@/utils/cron.js";

import result from "ant-design-vue/lib/result";
import "ant-design-vue/lib/result/style/css";

import BaseCollapse from "@/components/ui/BaseCollapse";
import BaseIcon from "@/components/ui/BaseIcon";

import WorkerScheduleForm from "@/components/forms/WorkerScheduleForm";

export default {
  name: "WorkerSchedules",
  props: {
    workerId: {
      type: [Number, String],
      default: undefined
    }
  },
  data() {
    return {
      localSchedules: [],
      intersections: []
    };
  },
  components: { result, BaseIcon, BaseCollapse, WorkerScheduleForm },
  computed: {
    ...mapGetters("workers", ["worker"]),
    ...mapState("configurations", ["weeklyWorkingHours", "defaultWorkingDays"]),
    defaultWorkingDaysData() {
      if (!this.defaultWorkingDays) {
        return {
          monday: true,
          tuesday: true,
          wednesday: true,
          thursday: true,
          friday: true,
          saturday: false,
          sunday: false
        };
      }

      return this.defaultWorkingDays[0];
    },
    workerHasNoSchedule() {
      if (this.workerId) {
        return this.finalSchedules.length == 0;
      }

      return undefined;
    },
    workerSchedules() {
      if (this.workerId) {
        return this.worker(this.workerId).schedules;
      }

      return [];
    },
    finalSchedules() {
      return this.workerSchedules.concat(this.localSchedules);
    },
    datesIntervals() {
      return this.workerSchedules.map(schedule => {
        return {
          scheduleId: schedule.id,
          dates: [schedule.from, schedule.to]
        };
      });
    }
  },
  methods: {
    scheduleFormatter,
    initializeLocalSchedule() {
      this.localSchedules.push({
        tempId: "temp-xxxxxxxx".replace(/[xy]/g, function(c) {
          var r = (Math.random() * 16) | 0,
            v = c == "x" ? r : (r & 0x3) | 0x8;
          return v.toString(16);
        })
      });
    },
    deleteLocalSchedule(tempId) {
      const localScheduleIndex = this.localSchedules.findIndex(
        schedule => schedule.tempId == tempId
      );

      this.localSchedules.splice(localScheduleIndex, 1);
    },
    datesIntersectionError(intersections) {
      this.intersections = intersections;
    },
    scheduleDatesUpdate() {
      this.intersections = [];
    }
  }
};
</script>

<style lang="scss" module>
.schedules-wrapper {
  width: 100%;

  .schedule-wrapper-header {
    height: 35px;
    margin-top: 20px;
    margin-top: 20px;
    width: 100%;
    span {
      font-size: 15px;
      font-weight: 500;
      margin-left: 10px;
      float: left;
    }
    .icon {
      margin-top: 2px;
      float: left;
      cursor: pointer;
    }
  }
}
</style>
