<template>
  <div class="wrapper">
    <base-field :label="label">
      <div class="d-flex">
        <base-input class="form-input" v-model="tag" />
        <base-button class="btn" :disabled="!tag" @click="$emit('add', tag)">
          <Icon
            v-if="!hideButtonIcon"
            :name="buttonIcon"
            :height="13"
            fill="#FFF"
          ></Icon>
          <span>{{ buttonLabel }}</span>
        </base-button>
      </div>
      <div class="tags-block-wrapper">
        <base-input
          name="search"
          class="search-input"
          :value="query"
          :placeholder="$t('search')"
          @input="query = $event"
        />
        <div class="tags-wrapper">
          <Tag
            v-for="(tag, idx) in filteredTags"
            :key="idx"
            class="test"
            show-delete-btn
            @delete="$emit('delete', tag.id)"
          >
            {{ tag.name }}
          </Tag>
        </div>
      </div>
    </base-field>
  </div>
</template>

<script>
import Icon from "@/components/ui/Icon";
import BaseField from "@/components/form/BaseField";
import BaseButton from "@/components/form/BaseButton";
import BaseInput from "@/components/form/BaseInput";

export default {
  components: { BaseField, BaseInput, BaseButton, Icon },
  props: {
    label: {
      type: String,
      default: "Items"
    },
    buttonLabel: {
      type: String,
      default: "Add item"
    },
    buttonIcon: {
      type: String,
      default: "done"
    },
    hideButtonIcon: {
      type: Boolean,
      default: false
    },
    tags: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    data: [],
    tag: null,
    query: null
  }),
  computed: {
    filteredTags() {
      return this.query
        ? this.data.filter(el => el.name.includes(this.query))
        : this.tags;
    }
  },
  watch: {
    tags: {
      immediate: true,
      handler(data) {
        this.tag = null;
        this.data = data;
      },
      deep: true
    }
  },
  methods: {}
};
</script>

<style lang="scss" scoped>
.wrapper::v-deep {
  .d-flex {
    display: flex;

    .form-input {
      width: 380px;
    }
  }

  .test {
    height: auto;
    padding: 8px;
    margin: 0 12px 12px 0;
  }

  .btn {
    width: auto;
    min-width: auto;

    button {
      height: auto;
      min-width: auto;
      margin-left: 10px;
      padding: 16px 20px;
      line-height: 16px;
      font-weight: 600;
    }

    span {
      margin-left: 10px;
    }
  }

  .tags-block-wrapper {
    margin-top: 15px;
    padding: 15px 30px;
    border: 1px solid #d9d9d9;
    border-radius: 5px;

    .search-input {
      margin-bottom: 15px;
    }

    .tags-wrapper {
      min-height: 50px;
      max-height: 175px;
      overflow: auto;
    }
  }
}
</style>
