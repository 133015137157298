<template>
  <ActionLayout>
    <div :class="$style['screen-wrapper']">
      <Logo labeled />
      <div :class="$style.loader">
        <Loading :loading="true" />
      </div>
      <span>{{ $t("initializingConfiguration") }}</span>
    </div>
  </ActionLayout>
</template>

<script>
import ActionLayout from "@/layouts/ActionLayout";

export default {
  page: {
    title: "Initializing configuration",
    meta: [
      { name: "description", content: "The Initializing configuration page." }
    ]
  },
  components: { ActionLayout }
};
</script>

<style lang="scss" module>
.screen-wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 50px;
  justify-content: center;
  align-items: center;
  .loader {
    width: 200px;
    height: 10px;
  }
}
</style>
