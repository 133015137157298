export default class Errors {
  constructor(errors = {}) {
    this.errors = errors;
  }

  get(field) {
    if (this.errors[field]) {
      return this.errors[field];
    }
  }

  record(errors) {
    this.errors = errors;
  }

  push(field, errors) {
    let errorsExist = Object.assign({}, this.errors);
    errorsExist[field] = errors;
    this.errors = errorsExist;
  }

  clear(field) {
    if (field) {
      let errorsExist = Object.assign({}, this.errors);
      delete errorsExist[field];
      this.errors = errorsExist;
      return;
    }

    this.errors = {};
  }

  has(field) {
    return !!this.errors[field];
  }

  any() {
    return Object.keys(this.errors).length > 0;
  }
}
