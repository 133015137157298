<template>
  <div :class="{ [$style['input-gps-wrapper']]: true, error: error }">
    <Group compact>
      <Input
        class="input"
        addonBefore="longitude"
        :size="size"
        :disabled="disabled || loading"
        v-model="modelLongitued"
      />
      <Input
        class="input"
        addonBefore="latitude"
        :size="size"
        :disabled="disabled || loading"
        v-model="modelLatitude"
      />
    </Group>
  </div>
</template>

<script>
import Group from "ant-design-vue/lib/input/Group";
export default {
  name: "BaseInputGPS",
  components: { Group },
  props: {
    size: {
      type: String,
      default: "large"
    },
    loading: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    },
    value: {
      type: Object,
      default() {
        return {
          latitude: "",
          longitude: ""
        };
      }
    }
  },
  computed: {
    modelLongitued: {
      get() {
        return this.value.longitude;
      },
      set(value) {
        this.$emit("input", {
          longitude: value,
          latitude: this.value.latitude
        });
      }
    },
    modelLatitude: {
      get() {
        return this.value.latitude;
      },
      set(value) {
        this.$emit("input", {
          longitude: this.value.longitude,
          latitude: value
        });
      }
    }
  }
};
</script>

<style lang="scss" module>
.input-gps-wrapper.error {
  border: 2px solid red;
  border-radius: 5px;
}
</style>
<style lang="scss">
.ant-input-group.ant-input-group-compact {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0 10px;
  &:before {
    display: none;
  }
}
</style>
