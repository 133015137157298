export const localization = {
  state: {
    locale: localStorage.getItem("localization")
  },
  getters: {
    locale: state => (state.locale ? state.locale : "en")
  },
  mutations: {
    SET_LOCALE(state, locale) {
      localStorage.setItem("localization", locale);
      state.locale = locale;
    }
  },
  actions: {
    setLocale({ commit }, locale) {
      commit("SET_LOCALE", locale);
    }
  }
};
