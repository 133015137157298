<template>
  <div :class="$style['time-picker-wrapper']">
    <AntTimePicker
      :class="{ [$style['time-picker']]: true, [$style.error]: error }"
      :format="format"
      :disabled="disabled || loading"
      v-model="model"
      @change="onChange"
      @openChange="onOpenChange"
      :size="size"
      :placeholder="placeholder"
    />
  </div>
</template>

<script>
import moment from "moment";
import { TimePicker as AntTimePicker } from "ant-design-vue";
import "ant-design-vue/lib/time-picker/style/css";

export default {
  name: "TimePicker",
  components: { AntTimePicker },
  props: {
    format: {
      type: String,
      default: "HH:mm"
    },
    value: {
      type: String,
      default: "12:00"
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: "large"
    },
    placeholder: {
      type: String,
      default: "Select time"
    }
  },
  computed: {
    model: {
      get() {
        return this.value ? this.moment(this.value, this.format) : null;
      },
      set(value) {
        return value
          ? this.$emit("input", value.format(this.format))
          : this.$emit("input", null);
      }
    }
  },
  methods: {
    moment,
    onChange(value) {
      this.$emit("change", value);
    },
    onOpenChange(value) {
      if (!value) {
        this.$emit("close");
      }
    }
  }
};
</script>

<style lang="scss" module>
.time-picker-wrapper {
  .time-picker {
    width: 100%;
  }
  .error {
    border: 2px solid red;
    border-radius: 5px;
  }
}
</style>
