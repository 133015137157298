export default class Worker {
  #id;
  #firstName;
  #lastName;
  #phone;
  #color;
  #address;
  #longitude;
  #latitude;

  #skills = [];
  #customDays = [];
  #schedules = [];

  constructor(worker) {
    this.#id = worker.id;
    this.#firstName = worker.first_name;
    this.#lastName = worker.last_name;
    this.#phone = worker.phone;
    this.#color = worker.color;
    this.#address = worker.address;
    this.#latitude = worker.latitude;
    this.#longitude = worker.longitude;
  }

  getId() {
    return this.#id;
  }

  getHome() {
    return {
      address: this.#address,
      latitude: this.#latitude,
      longitude: this.#longitude
    };
  }

  getColor() {
    return this.#color;
  }

  getPhone() {
    return this.#phone;
  }

  getName() {
    return this.#firstName + " " + this.#lastName;
  }
}
