import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import Modal from "@/plugins/modal.js";
import Confirmation from "@/plugins/confirmation.js";
import Drawer from "@/plugins/drawer.js";
import Message from "@/plugins/message.js";
import Notification from "@/plugins/notification.js";
import Socket from "@/plugins/socket.js";
import i18n from "@/plugins/i18n.js";

import VueIntercom from "vue-intercom";

import "@/components/ui/.globals.js";
import "@/components/common/.globals.js";

Vue.config.productionTip = false;

Vue.use(Modal);
Vue.use(Confirmation);
Vue.use(Drawer);
Vue.use(Message);
Vue.use(Socket);
Vue.use(Notification);
Vue.use(VueIntercom, { appId: "kdlxsedu" });

export const EventBus = new Vue();

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount("#app");
