import * as repositoryWorker from "@/api/repositories/workers";

export const workers = {
  state: {
    workers: []
  },
  getters: {
    worker: state => workerId =>
      state.workers.find(worker => worker.id == workerId),
    workers: state => state.workers
  },
  mutations: {
    SET_WORKERS(state, workers) {
      state.workers = workers;
    },
    ADD_WORKER(state, worker) {
      state.workers.push(worker);
    },
    REMOVE_WORKERS(state, workersId) {
      workersId.forEach(id => {
        const workerIndex = state.workers.findIndex(worker => id == worker.id);
        state.workers.splice(workerIndex, 1);
      });
    },
    UPDATE_WORKER(state, data) {
      const workerIndex = state.workers.findIndex(
        worker => worker.id == data.id
      );

      state.workers.splice(workerIndex, 1, data);
    },
    UPDATE_WORKERS_CUSTOM_DAYS(state, workers) {
      workers.forEach(workerUpdated => {
        const workerIndex = state.workers.findIndex(
          worker => worker.id == workerUpdated.id
        );

        let worker = state.workers[workerIndex];
        worker["custom-days"] = workerUpdated["custom-days"];

        state.workers.splice(workerIndex, 1, worker);
      });
    },
    CREATE_WORKER_SCHEDULE(state, data) {
      const workerIndex = state.workers.findIndex(
        worker => worker.id == data.workerId
      );

      let worker = state.workers[workerIndex];
      worker.schedules.push(data.schedule);

      state.workers.splice(workerIndex, 1, worker);
    },
    UPDATE_WORKER_SCHEDULE(state, data) {
      const workerIndex = state.workers.findIndex(
        worker => worker.id == data.workerId
      );

      let worker = state.workers[workerIndex];
      worker.schedules = worker.schedules.filter(
        schedule => schedule.id !== data.schedule.id
      );
      worker.schedules.push(data.schedule);

      state.workers.splice(workerIndex, 1, worker);
    },
    DELETE_WORKER_SCHEDULE(state, data) {
      const workerIndex = state.workers.findIndex(
        worker => worker.id == data.workerId
      );

      let worker = state.workers[workerIndex];
      worker.schedules = worker.schedules.filter(
        schedule => schedule.id !== data.scheduleId
      );

      state.workers.splice(workerIndex, 1, worker);
    },
    REMOVE_SKILL(state, skillId) {
      const workers = state.workers;
      workers.forEach((worker, index) => {
        worker.skills = worker.skills.filter(skill => {
          return skill.id != skillId;
        });
        state.workers.splice(index, 1, worker);
      });
    },
    RESET(state) {
      state.workers = [];
    }
  },
  actions: {
    initializeWorkers({ commit, rootGetters }) {
      return repositoryWorker
        .fetchAllWorkers(rootGetters["configurations/activeSetupId"])
        .then(workers => {
          commit("SET_WORKERS", workers);
          return workers;
        });
    },
    addWorker({ commit, rootGetters }, data) {
      return repositoryWorker
        .createWorker(rootGetters["configurations/activeSetupId"], data)
        .then(worker => {
          commit("ADD_WORKER", worker);
          return worker;
        });
    },
    updateWorker({ commit }, data) {
      return repositoryWorker.updateWorker(data.id, data.data).then(worker => {
        commit("UPDATE_WORKER", worker);
        return worker;
      });
    },
    removeWorker({ commit }, workerId) {
      return repositoryWorker.deleteWorker(workerId).then(() => {
        commit("REMOVE_WORKERS", [workerId]);
      });
    },
    removeWorkers({ commit }, workersId) {
      return repositoryWorker.deleteWorkers(workersId).then(() => {
        commit("REMOVE_WORKERS", workersId);
      });
    },
    updateWorkersCustomDays({ commit }, data) {
      return repositoryWorker
        .updateWorkersCustomDays(data.workersId, data.data)
        .then(workers => {
          commit("UPDATE_WORKERS_CUSTOM_DAYS", workers);
          return workers;
        });
    },
    createWorkerSchedule({ commit }, data) {
      return repositoryWorker
        .createWorkerSchedule(data.workerId, data.data)
        .then(schedule => {
          commit("CREATE_WORKER_SCHEDULE", {
            workerId: data.workerId,
            schedule: schedule
          });
          return schedule;
        });
    },
    updateWorkerSchedule({ commit }, data) {
      return repositoryWorker
        .updateWorkerSchedule(data.scheduleId, data.data)
        .then(schedule => {
          commit("UPDATE_WORKER_SCHEDULE", {
            workerId: data.workerId,
            schedule: schedule
          });
          return schedule;
        });
    },
    deleteWorkerSchedule({ commit }, data) {
      return repositoryWorker.removeWorkerSchedule(data.scheduleId).then(() => {
        commit("DELETE_WORKER_SCHEDULE", {
          workerId: data.workerId,
          scheduleId: data.scheduleId
        });
      });
    },
    removeSkill({ commit }, skillId) {
      commit("REMOVE_SKILL", skillId);
    },
    reset({ commit }) {
      commit("RESET");
    }
  }
};
