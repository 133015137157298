<template>
  <div :class="$style['bulk-navigation-wrapper']">
    <Button
      v-if="buttons.edit"
      :class="$style['edit-btn']"
      @click="$emit('edit')"
    >
      <Icon :name="buttons.edit.icon" :fill="buttons.edit.iconFillColor" />
      <span>{{ buttons.edit.name }}</span>
    </Button>
    <Button
      v-if="buttons.delete"
      :class="$style['delete-btn']"
      type="danger"
      @click="$emit('remove')"
    >
      <Icon name="delete" fill="#1C2A4A" />
      <span>{{ buttons.delete.name }}</span>
    </Button>
    <Button
      v-if="buttons.export"
      :class="$style['edit-btn']"
      @click="$emit('export')"
    >
      <Icon name="users" :fill="buttons.export.iconFillColor" />
      <span>{{ buttons.export.name }}</span>
    </Button>
  </div>
</template>

<script>
export default {
  name: "BulkNavigation",
  data() {
    return {};
  },
  components: {},
  props: {
    buttons: {
      type: Object,
      default() {
        return {
          edit: { name: "", icon: "", iconFillColor: "" },
          delete: { name: "" },
          export: { name: "", iconFillColor: "" }
        };
      }
    }
  },
  computed: {},
  methods: {}
};
</script>

<style lang="scss" module>
.bulk-navigation-wrapper {
  width: fit-content;
  display: flex;
  > * {
    margin-left: 20px;
  }

  > *:first-child {
    margin-left: 0;
  }
  .edit-btn,
  .delete-btn {
    button {
      width: auto;
      background: transparent;
      border: none;
      box-shadow: none;
      display: flex;
      align-items: center;
      padding: 0;
    }
    span {
      @extend %table-content;
      font-weight: 600;
      margin-left: 11px;
    }
  }
}
</style>
