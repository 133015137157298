<template>
  <MainLayout>
    <template #header-title>{{ $t("workers") }}</template>
    <template #header-content
      ><HeaderTableContent
        @search="tableSearch"
        @add="addWorkerEntity"
        :entity="$t('workers')"
    /></template>
    <template #content>
      <BulkNavigation
        :buttons="{
          edit: {
            name: $t('customWorkingDays'),
            icon: 'calendar',
            iconFillColor: '#333333'
          },
          delete: { name: $t('deleteWorkers') }
        }"
        v-show="selected.length"
        @edit="$drawer.show('right-bar')"
        @remove="removeBulk"
      />
      <WorkersTable
        :selectedWorkers.sync="selected"
        :searchQuery="searchQuery"
        @editWorker="edit"
        @removeWorker="remove"
        actionable
      />
      <ModalPlugin name="worker-modal">
        <TwoColumnGrid>
          <template #left-side>
            <worker-entity-form
              :workerId="workerId"
              @workerCreate="setWorkerAsActive"
            />
          </template>
          <template #right-side>
            <worker-schedules-form
              :workerId="workerId"
              @success="$modal.hide('worker-modal')"
            />
          </template>
        </TwoColumnGrid>
      </ModalPlugin>
      <DrawerPlugin name="right-bar" type="right">
        <workers-right-bar :workersSelected="selected" />
      </DrawerPlugin>
    </template>
  </MainLayout>
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";

import WorkerEntityForm from "@/components/forms/WorkerEntityForm";
import WorkerSchedulesForm from "@/components/forms/WorkerSchedulesForm";
import WorkersRightBar from "@/components/right-bars/WorkersRightBar";
import WorkersTable from "@/components/tables/WorkersTable";

import MainLayout from "@/layouts/MainLayout";

import PicCircle from "@/components/ui/PicCircle";
import HeaderTableContent from "@/components/layout/HeaderTableContent";

import TwoColumnGrid from "@/components/grid/TwoColumnGrid";

export default {
  page: {
    title: "Workers",
    meta: [{ name: "description", content: "The Workers page." }]
  },
  data() {
    return {
      workerId: undefined,
      searchQuery: "",
      selected: []
    };
  },
  components: {
    MainLayout,
    TwoColumnGrid,
    HeaderTableContent,
    WorkerEntityForm,
    WorkerSchedulesForm,
    WorkersRightBar,
    WorkersTable
  },
  computed: {
    ...mapGetters("configuration", ["skills", "weeklyWorkingHours"]),
    ...mapGetters("workers", ["worker"]),
    ...mapState("workers", ["workers"])
  },
  methods: {
    ...mapActions("workers", ["removeWorker", "removeWorkers"]),
    setWorkerAsActive(workerId) {
      this.workerId = workerId;
    },
    tableSearch(query) {
      this.searchQuery = query;
    },
    success() {
      this.$modal.hide("worker-modal");
    },
    addWorkerEntity() {
      this.workerId = undefined;
      this.$modal.show("worker-modal");
    },
    edit(event) {
      this.workerId = event.id;
      this.$modal.show("worker-modal");
    },
    remove(event) {
      this.$confirmation
        .show({
          items: event.map(item => {
            return {
              component: PicCircle,
              options: {
                helper: "initials",
                label: item.first_name,
                labeled: true,
                fill: item.color
              }
            };
          }),
          action: "delete",
          title: this.$t("deleteWorker"),
          message: this.$t("deleteWorkerConfirmation")
        })
        .then(() => {
          this.removeWorker(event[0].id).then(() => {
            //
          });
        })
        .catch(() => {});
    },
    removeBulk() {
      this.$confirmation
        .show({
          items: this.selected.map(item => {
            return {
              component: PicCircle,
              options: {
                helper: "initials",
                label: item.first_name + " " + item.last_name,
                labeled: true,
                fill: item.color
              }
            };
          }),
          action: "delete",
          title: this.$t("deleteWorkers"),
          message: this.$t("deleteWorkersConfirmation")
        })
        .then(() => {
          this.removeWorkers(this.selected.map(worker => worker.id)).then(
            () => {
              this.selected = [];
            }
          );
        })
        .catch(() => {
          console.log("Deletion canceled");
        });
    }
  },
  mounted() {
    //
  }
};
</script>
