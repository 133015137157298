<template>
  <ActionLayout>
    <Loading :loading="loading">
      <div :class="$style['verification-container']">
        <Logo labeled />
      </div>
      <div :class="$style.result" v-if="success !== undefined">
        <Icon
          :name="success ? 'done' : 'cross'"
          :fill="success ? '#63eb79' : '#eb6363'"
          :height="35"
        />
        <div
          :class="{
            [$style.message]: true,
            [$style.success]: success,
            [$style.error]: !success
          }"
        >
          <span v-if="success">{{ $t("emailVerified") }}</span>
          <span v-else>{{ $t("emailAlreadyVerified") }}</span>
        </div>
      </div>
      <router-link to="/login" :class="$style.button" replace>
        <Button>{{ $t("goToLogin") }}</Button>
      </router-link>
    </Loading>
  </ActionLayout>
</template>

<script>
import ActionLayout from "@/layouts/ActionLayout";
import { verify } from "@/api/repositories/auth";

export default {
  page: {
    title: "Email verification",
    meta: [{ name: "description", content: "The Email verification page." }]
  },
  data() {
    return {
      expires: undefined,
      signature: undefined,
      success: undefined,
      loading: false
    };
  },
  props: {
    userId: {
      type: String,
      default: undefined
    },
    hash: {
      type: String,
      required: undefined
    }
  },
  components: { ActionLayout },
  methods: {
    verifyEmail() {
      this.loading = true;
      verify({
        userId: this.userId,
        hash: this.hash,
        expires: this.expires,
        signature: this.signature
      })
        .then(() => {
          this.success = true;
          this.loading = false;
        })
        .catch(() => {
          this.success = false;
          this.loading = false;
        });
    }
  },
  mounted() {
    this.expires = this.$route.query.expires;
    this.signature = this.$route.query.signature;

    this.verifyEmail();
  }
};
</script>

<style lang="scss" module>
.verification-container {
  display: flex;
  justify-content: center;
  width: 450px;
  height: 130px;
}
.result {
  text-align: center;
  margin-bottom: 20px;
  .success {
    color: #63eb79;
  }

  .error {
    color: #eb6363;
  }
}
</style>
