<template>
  <AntDrawer
    width="350px"
    :placement="type"
    :visible="visible"
    :get-container="false"
    :wrap-style="{ position: 'absolute' }"
    :maskStyle="{
      background: 'none'
    }"
    @close="close"
  >
    <slot></slot>
  </AntDrawer>
</template>

<script>
import Plugin from "@/plugins/drawer";

import Vue from "vue";
import { Drawer as AntDrawer } from "ant-design-vue";
import "ant-design-vue/lib/drawer/style/css";

AntDrawer.install(Vue);

export default {
  name: "DrawerPlugin",
  data() {
    return {
      visible: false
    };
  },
  components: { AntDrawer },
  props: {
    name: {
      type: String,
      default: "default"
    },
    type: {
      type: String,
      default: "right"
    }
  },
  computed: {},
  methods: {
    close() {
      this.visible = false;
    }
  },
  beforeMount() {
    Plugin.EventBus.$on("show", name => {
      if (name == this.name) {
        this.visible = true;
      }
    });
    Plugin.EventBus.$on("hide", name => {
      if (name == this.name) {
        this.visible = false;
      }
    });
  }
};
</script>
<style lang="scss">
.ant-drawer-left {
  left: 0;
}
</style>
