<template>
  <div :class="$style['collapse-wrapper']">
    <AntCollapse expandIconPosition="right" accordion>
      <AntCollapsePanel v-if="loading" header="Loading.." disabled>
      </AntCollapsePanel>
      <AntCollapsePanel
        v-for="panel in panels"
        :key="'collapse-panel-' + panel.id"
        :header="panel.title"
      >
        <slot :name="panel.id"></slot>
      </AntCollapsePanel>
    </AntCollapse>
  </div>
</template>

<script>
import AntCollapse from "ant-design-vue/lib/collapse/Collapse";
import AntCollapsePanel from "ant-design-vue/lib/collapse/CollapsePanel";
import "ant-design-vue/lib/collapse/style/css";

export default {
  name: "Collapse",
  components: { AntCollapse, AntCollapsePanel },
  props: {
    panels: {
      type: Array,
      default() {
        return [];
      }
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {},
  methods: {}
};
</script>

<style lang="scss" module>
.collapse-wrapper {
}
</style>
