<template>
  <div :class="$style['build-update-form-wrapper']">
    <base-form name="location-entity-form" :form="form">
      <base-field label="Name" :errors="form.errors.get('title')">
        <base-input name="title" type="text" v-model="form.title" />
      </base-field>
      <base-button @click="cancel" type="default" size="large"
        >Cancel</base-button
      >
      <base-button @click="onSubmit" size="large">Save</base-button>
    </base-form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import Form from "@/services/form/Form.js";

import BaseForm from "@/components/form/BaseForm";
import BaseField from "@/components/form/BaseField";
import BaseInput from "@/components/form/BaseInput";
import BaseButton from "@/components/form/BaseButton";

export default {
  name: "BuildUpdateForm",
  data() {
    return {
      success: undefined,
      form: new Form(
        {
          title: ""
        },
        {
          title: {
            presence: { allowEmpty: false }
          }
        },
        { id: this.buildId }
      )
    };
  },
  components: { BaseForm, BaseField, BaseButton, BaseInput },
  props: {
    buildId: {
      type: [Number, String],
      default: undefined
    }
  },
  computed: {
    ...mapGetters("builds", ["build"])
  },
  methods: {
    ...mapActions("builds", ["updateBuild"]),
    onSubmit() {
      this.success = undefined;
      this.form
        .submit(this.updateBuild)
        .then(() => {
          this.success = true;
        })
        .catch(() => {
          this.success = false;
        });

      this.$emit("close");
    },
    initializeBuild(buildId) {
      const build = this.build(buildId);
      if (build) {
        this.form.setData(
          {
            title: build.title
          },
          {
            id: buildId
          }
        );
      }
    },
    cancel() {
      this.$emit("cancel");
    }
  },
  mounted() {
    this.initializeBuild(this.buildId);
  },
  watch: {
    buildId(buildId) {
      this.initializeBuild(buildId);
    }
  }
};
</script>

<style lang="scss" module>
.build-update-form-wrapper {
}
</style>
