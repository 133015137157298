<template>
  <div class="calendar-wrapper">
    <div
      class="previous"
      v-show="dragging"
      @mouseenter="goPreviousMonth"
      @mouseleave="cancelPreviousMonthMove"
    >
      {{ $t("previous") }}
    </div>
    <Loading :loading="true" v-if="updatesSaving" />
    <FullCalendar ref="calendar" :options="calendarOptions" v-else />
    <div
      class="next"
      v-show="dragging"
      @mouseenter="goNextMonth"
      @mouseleave="cancelNextMonthMove"
    >
      {{ $t("next") }}
    </div>
    <ModalPlugin name="assign-error-modal">
      <NotAssignedErrorPopup :day="dayErrorData" />
    </ModalPlugin>
    <ModalPlugin name="optimized-error-modal">
      <NotOptimizedErrorPopup
        :day="dayErrorData"
        @handleError="handleError"
        @removeErrorHandle="removeErrorHandle"
      />
    </ModalPlugin>
    <ModalPlugin name="skipped-optimization-error-modal">
      <SkippedOptimizationErrorPopup :day="dayErrorData" />
    </ModalPlugin>
    <ModalPlugin name="visit-details-modal" :title="$t('visitInformation')">
      <BuildVisitTasksModal :data="visitDetailed" />
    </ModalPlugin>
    <ModalPlugin name="break-details-modal" :title="$t('breakInformation')">
      <BuildModalBreakInformation :data="routeBreakDetailed" />
    </ModalPlugin>
    <ModalPlugin
      name="error-handle-details-modal"
      :title="$t('errorHandleInformation')"
    >
      <BuildModalErrorHandleInformation :data="errorHandleDetailed" />
    </ModalPlugin>
  </div>
</template>

<script>
import moment from "moment";
import FullCalendar from "@fullcalendar/vue";
import enLocale from "@fullcalendar/core/locales/en-gb";
import deLocale from "@fullcalendar/core/locales/de";

import tippy from "tippy.js";
import "tippy.js/dist/tippy.css";

import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";

import { EventBus } from "@/main.js";

import Setup from "@/services/setup/Setup";
import Route from "@/services/build/Route.js";

import NotAssignedErrorPopup from "@/components/route-plan/NotAssignedErrorPopup.vue";
import NotOptimizedErrorPopup from "@/components/route-plan/NotOptimizedErrorPopup.vue";
import SkippedOptimizationErrorPopup from "@/components/route-plan/SkippedOptimizationErrorPopup.vue";
import BuildVisitTasksModal from "@/components/modals/BuildVisitTasksModal";
import BuildModalBreakInformation from "@/components/BuildModalBreakInformation";
import BuildModalErrorHandleInformation from "@/components/BuildModalErrorHandleInformation";

export default {
  name: "Calendar",
  data() {
    return {
      dragging: false,
      previousMonthMove: false,
      nextMonthMove: false,
      monthMoveInterval: undefined,
      dayErrorData: undefined,
      visitDetailed: undefined,
      routeBreakDetailed: undefined,
      errorHandleDetailed: undefined
    };
  },
  components: {
    FullCalendar,
    NotAssignedErrorPopup,
    NotOptimizedErrorPopup,
    SkippedOptimizationErrorPopup,
    BuildVisitTasksModal,
    BuildModalBreakInformation,
    BuildModalErrorHandleInformation
  },
  props: {
    viewType: {
      type: String,
      default: "dayGridMonth",
      validator: function(value) {
        return ["dayGridMonth", "timeGridWeek"].indexOf(value) !== -1;
      }
    },
    showWeekends: {
      type: Boolean,
      default: true
    },
    dayPlans: {
      type: Array,
      required: true
    },
    setup: {
      type: Object,
      required: true
    },
    activeUpdates: {
      type: Array,
      required: true
    },
    activeDate: {
      type: String,
      default: null
    },
    updatesSaving: {
      type: Boolean,
      default: false
    },
    errorHandles: {
      type: Array,
      required: true
    }
  },
  computed: {
    calendarOptions() {
      return {
        locale: this.calendarLocale,
        plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
        initialView: this.viewType,
        weekends: this.showWeekends,
        headerToolbar: false,
        contentHeight: "512",
        firstDay: 1,
        editable: true,
        events: this.events,
        eventDragStart: this.eventDragStart,
        eventDragStop: this.eventDragStop,
        eventDrop: this.eventDrop,
        allDaySlot: true,
        views: {
          timeGridWeek: {
            dayHeaderFormat: { weekday: "short", day: "numeric" }
          }
        },
        eventClick: info => {
          if (info.event.extendedProps.error) {
            const date = moment(info.event.start).format("YYYY-MM-DD");
            const dayPlan = this.dayPlans.find(plan => {
              return plan.date == date;
            });
            const data = {
              date: dayPlan.date,
              errors: dayPlan.errors
            };
            if (dayPlan.errors[0].error_type == 1) {
              this.notAssignedError(data);
            } else if (dayPlan.errors[0].error_type == 2) {
              this.notOptimizedError(data);
            } else if (dayPlan.errors[0].error_type == 3) {
              this.skippedOptimizationError(data);
            }
          } else if (info.event.extendedProps.visit) {
            this.visitDetailed = info.event.extendedProps.visit;
            this.$modal.show("visit-details-modal");
          } else if (info.event.extendedProps.routeBreak) {
            this.routeBreakDetailed = info.event.extendedProps.routeBreak;
            this.$modal.show("break-details-modal");
          } else if (info.event.extendedProps.errorHandle) {
            this.errorHandleDetailed = info.event.extendedProps.errorHandle;
            this.$modal.show("error-handle-details-modal");
          }
        },
        eventMouseEnter: info => {
          if (info.event.extendedProps.driving) {
            const tooltip = new tippy(info.el, {
              content:
                info.event.extendedProps.driving.start +
                " - " +
                info.event.extendedProps.driving.end
            });
            tooltip.show();
          }
        }
      };
    },
    calendarLocale() {
      switch (this.$i18n.locale) {
        case "en":
          return enLocale;
        case "de":
          return deLocale;
        default:
          return enLocale;
      }
    },
    setupObject() {
      return new Setup(this.setup);
    },
    dayPlansForMonth() {
      return this.dayPlans;
    },
    events() {
      switch (this.viewType) {
        case "dayGridMonth":
          return this.eventsForMonthView;
        case "timeGridWeek":
          return this.eventsForWeekView;
        default:
          return [];
      }
    },
    eventsForWeekView() {
      const visits = this.dayPlansForMonth
        .map(dayPlan => {
          return dayPlan.routes.map(route => {
            route = new Route(route);
            return route
              .getVisits()
              .filter(visit => {
                return !visit.isHome();
              })
              .map(visit => {
                const id =
                  "dayplan-" +
                  dayPlan.id +
                  "-route-" +
                  route.getId() +
                  "-visit-" +
                  visit.getFirstRoutePoint().getLocationId();
                const update = this.activeUpdates.find(
                  update => update.data.eventId == id
                );
                return {
                  id: id,
                  title: visit.getFirstRoutePoint().getLocationDescription(),
                  start: update
                    ? update.data.to.start
                    : new Date(
                        (dayPlan.date + " " + visit.getStartTimeMarker()).replace(/-/g, "/")
                      ).toISOString(),
                  end: update
                    ? update.data.to.end
                    : new Date(
                        (dayPlan.date + " " + visit.getEndTimeMarker()).replace(/-/g, "/")
                      ).toISOString(),
                  backgroundColor: this.setupObject
                    .getWorkerById(route.getWorkerId())
                    .getColor(),
                  visit: {
                    visit: visit,
                    workerId: route.getWorkerId()
                  }
                };
              });
          });
        })
        .flat(3);

      const drivings = this.dayPlansForMonth
        .map(dayPlan => {
          return dayPlan.routes.map(route => {
            route = new Route(route);
            return route.getDrivings().map((driving, index) => {
              const id =
                "dayplan-" +
                dayPlan.id +
                "-route-" +
                route.getId() +
                "-driving-" +
                index;
              return {
                id: id,
                title: this.$t("drivingTime"),
                editable: false,
                start: new Date(
                  (dayPlan.date + " " + driving.getStart()).replace(/-/g, "/")
                ).toISOString(),
                end: new Date(
                  (dayPlan.date + " " + driving.getEnd()).replace(/-/g, "/")
                ).toISOString(),
                backgroundColor: this.setupObject
                  .getWorkerById(route.getWorkerId())
                  .getColor(),
                borderColor: "#a8ab0f",
                driving: {
                  start: driving.getStart(),
                  end: driving.getEnd()
                }
              };
            });
          });
        })
        .flat(3);

      // const breaks = this.dayPlansForMonth
      //   .map(dayPlan => {
      //     return dayPlan.routes.map(route => {
      //       route = new Route(route);
      //       return route.getBreaks().map(routeBreak => {
      //         const id =
      //           "dayplan-" +
      //           dayPlan.id +
      //           "-route-" +
      //           route.getId() +
      //           "-break-" +
      //           routeBreak.getId();
      //         return {
      //           id: id,
      //           title:
      //             this.setupObject
      //               .getWorkerById(route.getWorkerId())
      //               .getName() + "'s break",
      //           start: new Date(
      //             dayPlan.date + " " + routeBreak.getStartTimeMarker()
      //           ).toISOString(),
      //           end: new Date(
      //             dayPlan.date + " " + routeBreak.getEndTimeMarker()
      //           ).toISOString(),
      //           backgroundColor: this.setupObject
      //             .getWorkerById(route.getWorkerId())
      //             .getColor(),
      //           borderColor: "#a8ab0f",
      //           routeBreak: {
      //             routeBreak: routeBreak,
      //             workerId: route.getWorkerId()
      //           }
      //         };
      //       });
      //     });
      //   })
      //   .flat(3);

      const errors = this.dayPlansForMonth
        .filter(dayPlan => {
          return dayPlan.status == 0;
        })
        .map(dayPlan => {
          let title;
          let background;

          if (dayPlan.errors[0].error_type == 1) {
            title = this.$i18n.t("tasksAssignmentIssues");
            background = "orange";
          } else if (dayPlan.errors[0].error_type == 2) {
            const errorHandle = this.errorHandles.find(
              errorHandle => errorHandle.error_id == dayPlan.errors[0].id
            );
            title = errorHandle
              ? this.$i18n.t("handledOptimizationError")
              : this.$i18n.t("optmizationError");
            background = "red";
          } else if (dayPlan.errors[0].error_type == 3) {
            title = this.$i18n.t("skippedOptimization");
            background = "grey";
          }

          return {
            id: "error-dayPlan-week-" + dayPlan.id,
            title: title,
            start: moment(dayPlan.date, "YYYY-MM-DD").toISOString(),
            backgroundColor: background,
            borderColor: background,
            textColor: "#000000",
            description: "Test",
            allDay: true,
            editable: false,
            error: true
          };
        });

      const errorHandle = this.dayPlansForMonth
        .filter(dayPlan => {
          return dayPlan["error-handle"];
        })
        .map(dayPlan => {
          return {
            id: "error-dayPlan-month" + dayPlan.id,
            title: this.$i18n.t("handledOptimizationError"),
            start: moment(dayPlan.date, "YYYY-MM-DD").toISOString(),
            backgroundColor: "#c1e3d1",
            borderColor: "#c1e3d1",
            textColor: "#206b43",
            description: "Handled error",
            allDay: true,
            editable: false,
            errorHandle: {
              dayPlan: dayPlan
            }
          };
        });

      return visits
        .concat(errors)
        .concat(errorHandle)
        .concat(drivings);
    },
    eventsForMonthView() {
      const visits = this.dayPlansForMonth
        .map(dayPlan => {
          return dayPlan.routes.map(route => {
            route = new Route(route);
            return route
              .getVisits()
              .filter(visit => {
                return !visit.isHome();
              })
              .map(visit => {
                const id =
                  "dayplan-" +
                  dayPlan.id +
                  "-route-" +
                  route.getId() +
                  "-visit-" +
                  visit.getFirstRoutePoint().getLocationId();
                const update = this.activeUpdates.find(
                  update => update.data.eventId == id
                );
                return {
                  id: id,
                  title: visit.getFirstRoutePoint().getLocationDescription(),
                  start: update
                    ? update.data.to.start
                    : new Date(
                        (dayPlan.date + " " + visit.getStartTimeMarker()).replace(/-/g, "/")
                      ).toISOString(),
                  end: update
                    ? update.data.to.end
                    : new Date(
                        (dayPlan.date + " " + visit.getEndTimeMarker()).replace(/-/g, "/")
                      ).toISOString(),
                  backgroundColor: this.setupObject
                    .getWorkerById(route.getWorkerId())
                    .getColor(),
                  visit: {
                    visit: visit,
                    workerId: route.getWorkerId()
                  }
                };
              });
          });
        })
        .flat(3);

      // const breaks = this.dayPlansForMonth
      //   .map(dayPlan => {
      //     return dayPlan.routes.map(route => {
      //       route = new Route(route);
      //       return route.getBreaks().map(routeBreak => {
      //         const id =
      //           "dayplan-" +
      //           dayPlan.id +
      //           "-route-" +
      //           route.getId() +
      //           "-break-" +
      //           routeBreak.getId();
      //         return {
      //           id: id,
      //           title:
      //             this.setupObject
      //               .getWorkerById(route.getWorkerId())
      //               .getName() + "'s break",
      //           start: new Date(
      //             dayPlan.date + " " + routeBreak.getStartTimeMarker()
      //           ).toISOString(),
      //           end: new Date(
      //             dayPlan.date + " " + routeBreak.getEndTimeMarker()
      //           ).toISOString(),
      //           backgroundColor: this.setupObject
      //             .getWorkerById(route.getWorkerId())
      //             .getColor(),
      //           routeBreak: {
      //             routeBreak: routeBreak,
      //             workerId: route.getWorkerId()
      //           }
      //         };
      //       });
      //     });
      //   })
      //   .flat(3);

      const errors = this.dayPlansForMonth
        .filter(dayPlan => {
          return dayPlan.status == 0;
        })
        .map(dayPlan => {
          let title;
          let background;

          if (dayPlan.errors[0].error_type == 1) {
            title = this.$i18n.t("tasksAssignmentIssues");
            background = "orange";
          } else if (dayPlan.errors[0].error_type == 2) {
            const errorHandle = this.errorHandles.find(
              errorHandle => errorHandle.error_id == dayPlan.errors[0].id
            );
            title = errorHandle
              ? this.$i18n.t("handledOptimizationError")
              : this.$i18n.t("optmizationError");
            background = "red";
          } else if (dayPlan.errors[0].error_type == 3) {
            title = this.$i18n.t("skippedOptimization");
            background = "grey";
          }
          return {
            id: "error-dayPlan-month" + dayPlan.id,
            title: title,
            start: moment(dayPlan.date, "YYYY-MM-DD").toISOString(),
            backgroundColor: background,
            borderColor: background,
            textColor: "#000000",
            description: "Test",
            allDay: true,
            editable: false,
            error: true
          };
        });

      const errorHandle = this.dayPlansForMonth
        .filter(dayPlan => {
          return dayPlan["error-handle"];
        })
        .map(dayPlan => {
          return {
            id: "error-dayPlan-month" + dayPlan.id,
            title: this.$i18n.t("handledOptimizationError"),
            start: moment(dayPlan.date, "YYYY-MM-DD").toISOString(),
            backgroundColor: "#c1e3d1",
            borderColor: "#c1e3d1",
            textColor: "#206b43",
            description: "Handled error",
            allDay: true,
            editable: false,
            errorHandle: {
              dayPlan: dayPlan
            }
          };
        });

      return visits.concat(errors).concat(errorHandle);
    }
  },
  methods: {
    goPreviousMonth() {
      if (!this.previousMonthMove) {
        this.previousMonthMove = true;
        this.monthMoveInterval = setInterval(() => {
          this.$refs.calendar.getApi().prev();
          this.$emit(
            "changeCurrentCalendarDate",
            this.$refs.calendar.getApi().getCurrentData().currentDate
          );
        }, 500);
      }
    },
    cancelPreviousMonthMove() {
      this.previousMonthMove = false;
      clearInterval(this.monthMoveInterval);
    },
    goNextMonth() {
      if (!this.nextMonthMove) {
        this.nextMonthMove = true;
        this.monthMoveInterval = setInterval(() => {
          this.$refs.calendar.getApi().next();
          this.$emit(
            "changeCurrentCalendarDate",
            this.$refs.calendar.getApi().getCurrentData().currentDate
          );
        }, 500);
      }
    },
    cancelNextMonthMove() {
      this.nextMonthMove = false;
      clearInterval(this.monthMoveInterval);
    },
    eventDragStart(e) {
      this.dragging = true;
      e.el.classList.add("pointer-events-none");
      this.$refs.calendar.getApi().updateSize();
    },
    eventDragStop(e) {
      this.dragging = false;
      e.el.classList.remove("pointer-events-none");
      this.$nextTick(() => {
        this.$refs.calendar.getApi().updateSize();
      });
    },
    eventDrop(arg) {
      const eventId = arg.event.id.split("-");
      const dayPlanId = eventId[1];
      const routeId = eventId[3];
      const locationId = eventId[5];
      const routePoints = this.dayPlans
        .find(dayPlan => dayPlan.id == dayPlanId)
        .routes.map(route => new Route(route))
        .find(route => route.getId() == routeId)
        .getVisits()
        .find(visit => visit.getFirstRoutePoint().getLocationId() == locationId)
        .getRoutePoints();
      const change = {
        eventId: arg.event.id,
        routePoints: routePoints,
        from: {
          start: arg.oldEvent.start,
          end: arg.oldEvent.end
        },
        to: {
          start: arg.event.start,
          end: arg.event.end
        }
      };
      this.$emit("dragDrop", change);
    },
    notAssignedError(data) {
      const date = moment(data.date, "YYYY-MM-DD").format("DD MMM YYYY");
      data.errors.forEach(error => {
        error.data.tasks_without_workers.forEach(task => {
          const setupTask = this.setupObject.getTaskById(task.task_id);
          task.description =
            setupTask.getGroup().name +
            " in " +
            setupTask.getLocation().position.address;
        });
      });
      // Вот здесь выбирается первый лог
      const finalData = {
        day: data.errors[0],
        date
      };
      this.dayErrorData = finalData;
      this.$modal.show("assign-error-modal");
    },
    notOptimizedError(data) {
      console.log(data);
      const date = moment(data.date, "YYYY-MM-DD").format("DD MMM YYYY");
      const workers = data.errors[0].data.workers.map(worker => {
        return {
          worker: this.setupObject.getWorkerById(worker.worker_id),
          "time-window": worker["time-window"],
          breaks: worker["breaks"]
        };
      });
      const locations = data.errors[0].data.locations.map(location => {
        return {
          location: this.setupObject.getLocationById(location.location_id),
          "time-window": location["time-window"]
        };
      });
      const tasks = data.errors[0].data.tasks.map(task => {
        return this.setupObject.getTaskById(task.task_id);
      });
      // Вот здесь выбирается первый лог
      this.dayErrorData = {
        error_id: data.errors[0].id,
        errorHandle: this.errorHandles.find(
          errorHandle => errorHandle.error_id == data.errors[0].id
        ),
        day: data.errors[0].data.solution.time_windows_updates,
        drops: data.errors[0].data.solution.locations_drops,
        breaksDurationsUpdates:
          data.errors[0].data.solution.breaks_duration_updates,
        date,
        data: {
          workers: workers,
          locations: locations,
          tasks: tasks
        }
      };
      this.$modal.show("optimized-error-modal");
    },
    skippedOptimizationError(data) {
      const date = moment(data.date, "YYYY-MM-DD").format("DD MMM YYYY");
      this.dayErrorData = {
        date
      };
      this.$modal.show("skipped-optimization-error-modal");
    },
    showPreviousVisit() {},
    showNextVisit() {},
    handleError(data) {
      this.$modal.hide("optimized-error-modal");
      this.$emit("handleError", data);
    },
    removeErrorHandle(data) {
      this.dayErrorData.errorHandle = undefined;
      this.$emit("removeErrorHandle", data);
    }
  },
  watch: {
    viewType() {
      this.$refs.calendar.getApi().changeView(this.viewType);
    },
    activeDate(newDate) {
      this.$refs.calendar.getApi().gotoDate(newDate);
    }
  },
  beforeMount() {
    EventBus.$on(
      "event-move",
      function(data) {
        if (this.$refs.calendar) {
          this.$refs.calendar
            .getApi()
            .getEvents()
            .find(event => event.id == data.id)
            .setStart(data.start);
          this.$refs.calendar
            .getApi()
            .getEvents()
            .find(event => event.id == data.id)
            .setEnd(data.end);
        }
      }.bind(this)
    );
  },
  mounted() {
    this.$refs.calendar.getApi().gotoDate(this.activeDate);
  }
};
</script>

<style lang="scss">
.pointer-events-none {
  z-index: 19 !important;
}
.calendar-wrapper {
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  gap: 0px 0px;
  grid-template-areas: ".";
  height: 100%;
  .previous,
  .next {
    position: absolute;
    top: 0;
    content: ">";
    width: 50px;
    height: 100%;
    display: grid;
    place-items: center;
    background-color: lightgray;
    z-index: 20;
    writing-mode: vertical-rl;
    text-orientation: mixed;
  }
  .previous {
    left: 0;
  }
  .next {
    right: 0;
  }
  .previous:hover,
  .next:hover {
    background-color: rgb(194, 192, 192);
    cursor: pointer;
    opacity: 0.9;
  }
  .fc-timegrid-event .fc-event-time {
    display: none;
  }
  .fc .fc-col-header-cell-cushion {
    font-size: 12px;
    line-height: 20px;
    font-weight: 400;
    color: #000000;
  }
  .fc-dayGridMonth-view {
    .fc .fc-col-header-cell-cushion {
      text-transform: uppercase;
    }
  }
  .fc-timeGridWeek-view {
    .fc-scrollgrid-sync-inner {
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .fc .fc-daygrid-day-top {
    flex-direction: row;
    .fc-daygrid-day-number {
      color: #000000;
      padding-left: 10px;
    }
  }
  .fc-day-other {
    .fc-daygrid-day-number {
      display: none;
    }
  }
  .fc-daygrid-day.fc-day.fc-day-today {
    background-color: #ffffff;
    .fc-daygrid-day-top {
      position: relative;
      &::after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 100%;
        background: $primary-blue;
        height: 28px;
        width: 28px;
      }
      .fc-daygrid-day-number {
        color: #ffffff;
      }
    }
  }
  .fc .fc-timegrid-col.fc-day-today {
    background-color: #ffffff;
  }
  .fc-col-header-cell.fc-day-today {
    position: relative;
    .fc-scrollgrid-sync-inner {
      z-index: 0;
      &::after {
        content: "";
        display: block;
        position: absolute;
        top: 4px;
        left: 50%;
        transform: translate(-108%);
        border-radius: 100%;
        background: $primary-blue;
        height: 24px;
        width: 24px;
      }
    }
    .fc-col-header-cell-cushion {
      z-index: 1;
    }
  }
  .fc .fc-timegrid-slot-minor {
    border-top-style: none;
  }
  .fc .fc-timegrid-slot {
    height: 30px;
  }
}
</style>
