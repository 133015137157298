import { message } from "ant-design-vue";
import "ant-design-vue/lib/message/style/css";

const Message = {
  install(Vue) {
    Vue.prototype.$message = message;
  }
};

export default Message;
