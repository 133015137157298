import RoutePointTask from "@/services/build/RoutePointTask";

export default class RoutePoint {
  id;
  #timeMarkerFrom;
  #timeMarkerTo;
  #routePointTask;
  #routePointTask_;

  constructor(routePoint) {
    this.id = routePoint.id;
    this.#timeMarkerFrom = routePoint.time_marker_from;
    this.#timeMarkerTo = routePoint.time_marker_to;
    this.#routePointTask = routePoint["route-point-task"];
    this.#routePointTask_ = routePoint["route-point-task"]
      ? new RoutePointTask(routePoint["route-point-task"])
      : null;
  }

  isHome() {
    return this.#routePointTask == null;
  }

  getId() {
    return this.id;
  }

  getStartTimeMarker() {
    return this.#timeMarkerFrom;
  }

  getEndTimeMarker() {
    return this.#timeMarkerTo;
  }

  getRoutePointTask() {
    return this.#routePointTask_;
  }

  getTaskId() {
    return !this.isHome() ? this.#routePointTask.task.id : undefined;
  }

  getDuration() {
    return !this.isHome() ? this.#routePointTask.task.duration : undefined;
  }

  getGps() {
    return !this.isHome()
      ? {
          latitude: this.#routePointTask.position.latitude,
          longitude: this.#routePointTask.position.longitude
        }
      : undefined;
  }

  getAddress() {
    return !this.isHome() ? this.#routePointTask.position.address : undefined;
  }

  getPositionId() {
    return !this.isHome() ? this.#routePointTask.position.id : undefined;
  }

  getLocationId() {
    return !this.isHome() ? this.#routePointTask.location.id : undefined;
  }

  getLocationDescription() {
    return !this.isHome()
      ? this.buildLocationDescription(this.#routePointTask.position.address)
      : undefined;
  }

  buildLocationDescription(address) {
    const addressParts = address.split(",");
    return addressParts.slice(0, 2).join(", ");
  }
}
