import { restApi } from "@/api";

export const login = data => {
  return restApi.post("/auth/login", data);
};

export const register = data => {
  return restApi.post("/auth/register", data);
};

export const logout = () => {
  return restApi.get("/auth/logout");
};

export const verify = data => {
  return restApi.get(
    `/auth/verification/verify/${data.userId}/${data.hash}?expires=${data.expires}&signature=${data.signature}`
  );
};

export const forgotPassword = data => {
  return restApi.post(`/auth/password/forgot`, data);
};

export const resetPassword = data => {
  return restApi.post(`/auth/password/reset`, data);
};

export const verifyToken = data => {
  return restApi.post(`/auth/password/verify`, data);
};
