<template>
  <div :class="$style['page-container']">
    <slot />
  </div>
</template>

<script>
export default {
  name: "PageLayout",
  data() {
    return {};
  },
  components: {},
  props: {},
  computed: {},
  methods: {}
};
</script>

<style lang="scss" module>
.page-container {
  padding: 100px;
  background: #ffffff;
}
</style>
