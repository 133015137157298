<template>
  <div class="base-input-file">
    <AntInputFile
      :multiple="false"
      :file-list="fileList"
      @change="handleChange"
      :beforeUpload="() => false"
    >
      <AntButton> <a-icon type="upload" /> {{ $t("upload") }} </AntButton>
    </AntInputFile>
  </div>
</template>

<script>
import { Upload as AntInputFile } from "ant-design-vue";
import { Button as AntButton } from "ant-design-vue";
import "ant-design-vue/lib/upload/style/css";
import "ant-design-vue/lib/button/style/css";

export default {
  name: "BaseInputFile",
  data() {
    return {
      file: null,
      fileList: []
    };
  },
  components: { AntInputFile, AntButton },
  props: {},
  computed: {},
  methods: {
    handleChange({ file, fileList }) {
      this.file = file;
      if (fileList.length > 1) {
        fileList.shift();
      }
      this.fileList = fileList;
      this.$emit("change", file);
    }
  }
};
</script>

<style lang="scss">
.base-input-file {
}
</style>
