import Vue from "vue";
import store from "@/store";
import router from "@/router";

import i18n from "@/plugins/i18n.js";

export class buildStatusUpdateNotification {
  constructor(data) {
    this.data = data;
  }

  async show() {
    Vue.prototype.$notification[this.defineNotificationType()]({
      message: i18n.tc("build") + " #" + this.data.build_id,
      description: i18n.tc("buildStatus_" + this.data.status_code),
      duration: 5
    });

    if (this.data.status_code > 1) {
      await store.dispatch("builds/setBuildAsActive", this.data.build_id);
      await store.dispatch("builds/updateBuildStatus", {
        buildId: this.data.build_id,
        status: this.data.status_code
      });
      router.push({
        name: "route_plan",
        params: { id: this.data.build_id }
      });
    }
  }

  defineNotificationType() {
    switch (this.data.status_code) {
      case 0 || 1:
        return "info";
      case 2:
        return "success";
      case 3:
        return "warning";
      case 4:
        return "error";
    }
  }
}

export class errorHandlingEnabled {
  show() {
    Vue.prototype.$message["info"](i18n.tc("errorHandlingWasEnabled"));
  }
}
