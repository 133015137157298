import axios from "axios";
import { getAuthToken } from "@/utils/auth";
import { deserialize, transform } from "jsonapi-fractal";
import JsonApiCustomTransformer from "@/utils/JsonApiCustomTransformer";
import { message } from "ant-design-vue";
import "ant-design-vue/lib/message/style/css";
import { resetAfterUnauthenticatedError } from "@/services/auth";

const httpClient = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL + "/v1",
  headers: {
    "Content-Type": "application/vnd.api+json",
    "Accept-Language": "en"
  }
});

httpClient.interceptors.request.use(request => {
  request.headers["Authorization"] = getAuthToken();
  request.transformResponse = [
    data => {
      if (data && JSON.parse(data).jsonapi && !JSON.parse(data).errors)
        return deserialize(JSON.parse(data), { changeCase: "snakeCase" });

      return data;
    }
  ];
  request.transformRequest = [
    data => {
      if (data) {
        const serialized = transform()
          .withInput(data.attributes)
          .withTransformer(
            new JsonApiCustomTransformer(data.type, data.relationships || [])
          )
          .serialize();
        return JSON.stringify(serialized);
      }

      return data;
    }
  ];
  return request;
});

httpClient.interceptors.response.use(
  response => {
    switch (response.status) {
      case 200:
        return response.data;
      case 201:
        return response.data;
      default:
        return response;
    }
  },
  error => {
    switch (error.response.status) {
      case 400:
        message.error(
          "Something went wrong. Contact the administration, please."
        );
        break;

      case 401:
        resetAfterUnauthenticatedError();
        break;

      default:
        console.error(error.response.status, error.message);
    }

    return Promise.reject(error);
  }
);

export default httpClient;
