<template>
  <div :class="$style['table-entity-wrapper']">
    <Table
      :columns="finalCollumns"
      :data="finalData"
      :loading="loading"
      @select="select"
    />
  </div>
</template>

<script>
import { EventBus } from "@/main.js";

export default {
  name: "TableEntity",
  inject: ["fetch", "searcher"],
  data() {
    return {
      data: [],
      loading: false,
      defaultColumns: [
        {
          key: "actions",
          customRender: row => {
            return this.$createElement("PopoverTableRowActions", {
              on: {
                edit: function() {
                  this.$emit("edit", row);
                }.bind(this),
                remove: function() {
                  this.$emit("remove", [row]);
                }.bind(this)
              }
            });
          }
        }
      ]
    };
  },
  components: {},
  props: {
    columns: {
      type: Array,
      require: true
    },
    rowKey: {
      type: String,
      default: "id"
    },
    actionAllowed: {
      type: Boolean,
      default: true
    }
  },
  async created() {
    this.fetchData();
  },
  computed: {
    finalData() {
      return this.searcher ? this.data.filter(this.searcher) : this.data;
    },
    finalCollumns() {
      return this.actionAllowed
        ? this.columns.concat(this.defaultColumns)
        : this.columns;
    }
  },
  methods: {
    async fetchData() {
      this.loading = true;
      await this.fetch(this.fetchId).then(data => {
        this.data = data;
      });
      this.loading = false;
    },
    select(rows) {
      this.$emit("select", rows);
    }
  },
  beforeMount() {
    EventBus.$on("table-update", () => {
      this.fetchData();
    });
    EventBus.$on("table-search-update", e => {
      this.fetchData(e);
    });
  }
};
</script>

<style lang="scss" module>
.table-entity-wrapper {
}
</style>
