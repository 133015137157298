<template>
  <div :class="$style['workers-table-wrapper']">
    <BaseTable
      :columns="columns"
      :data="workers"
      :loading="loading"
      :actionable="actionable"
      :search="search"
      @select="select"
      @edit="edit"
      @remove="remove"
    />
  </div>
</template>

<script>
import moment from "moment";
import { mapActions, mapState } from "vuex";
import { scheduleFormatter } from "@/utils/cron";

import BaseTable from "@/components/table/BaseTable";

export default {
  name: "WorkersTable",
  data() {
    return {
      loading: false
    };
  },
  components: { BaseTable },
  props: {
    selectedWorkers: {
      type: Array,
      required: true
    },
    actionable: {
      type: Boolean,
      default: false
    },
    searchQuery: {
      type: String,
      default: ""
    }
  },
  computed: {
    ...mapState("workers", ["workers"]),
    ...mapState("configurations", ["skills"]),
    search() {
      return {
        filter: function(worker) {
          return (
            this.searchQuery == "" ||
            ~worker.first_name
              .toLowerCase()
              .replace(/\s/g, "")
              .indexOf(this.searchQuery.toLowerCase().replace(/\s/g, "")) ||
            ~worker.last_name
              .toLowerCase()
              .replace(/\s/g, "")
              .indexOf(this.searchQuery.toLowerCase().replace(/\s/g, "")) ||
            ~worker.email
              .toLowerCase()
              .replace(/\s/g, "")
              .indexOf(this.searchQuery.toLowerCase().replace(/\s/g, ""))
          );
        }.bind(this)
      };
    },
    columns() {
      return [
        {
          title: this.$i18n.t("name"),
          key: "first_name",
          width: "10%",
          customRender: worker => {
            return this.$createElement("PicCircle", {
              props: {
                helper: "initials",
                label: `${worker.first_name}`,
                labeled: true,
                fill: worker.color
              }
            });
          },
          sorter: (a, b) => {
            if (
              a.first_name.toString().toLowerCase() >
              b.first_name.toString().toLowerCase()
            ) {
              return 1;
            } else {
              return -1;
            }
          },
          sortDirections: ["descend", "ascend"]
        },
        {
          title: this.$i18n.t("lastName"),
          key: "last_name",
          dataIndex: "last_name",
          width: "10%",
          sorter: (a, b) => {
            if (
              a.last_name.toString().toLowerCase() >
              b.last_name.toString().toLowerCase()
            ) {
              return 1;
            } else {
              return -1;
            }
          },
          sortDirections: ["descend", "ascend"]
        },
        {
          title: this.$i18n.t("email"),
          key: "email",
          dataIndex: "email",
          sorter: (a, b) => {
            if (
              a.email.toString().toLowerCase() >
              b.email.toString().toLowerCase()
            ) {
              return 1;
            } else {
              return -1;
            }
          },
          sortDirections: ["descend", "ascend"]
        },
        {
          title: this.$i18n.t("address"),
          key: "address",
          dataIndex: "address",
          width: "20%"
        },
        {
          title: this.$i18n.t("skills"),
          dataIndex: "skills",
          key: "skills",
          customRender: skills => {
            return this.$createElement("TagsGroup", {
              props: {
                tags: this.skills
                  .filter(skill =>
                    skills.find(workerSkill => workerSkill.id == skill.id)
                  )
                  .map(skill => {
                    return skill.name;
                  })
              }
            });
          },
          filters: this.skills.map(skill => {
            return {
              text: skill.name,
              value: skill.id
            };
          }),
          onFilter: (value, record) =>
            (value == -1 && record.skills.length == 0) ||
            record.skills.find(skill => skill.id == value)
        },
        {
          title: this.$i18n.t("schedules"),
          dataIndex: "schedules",
          key: "schedules",
          customRender: schedules => {
            const tags =
              schedules.length == 0
                ? [this.$i18n.t("absent")]
                : schedules.map(schedule => {
                    return this.scheduleFormatter(schedule);
                  });
            return this.$createElement("TagsGroup", {
              props: {
                tags: tags
              }
            });
          },
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon"
          },
          onFilter: (value, record) => {
            if (
              value.from &&
              record.schedules.find(schedule => {
                return moment(value.from).isBefore(moment(schedule.from));
              })
            ) {
              return false;
            }
            if (
              value.to &&
              record.schedules.find(schedule => {
                return moment(value.to).isAfter(moment(schedule.to));
              })
            ) {
              return false;
            }

            return true;
          }
        }
      ];
    }
  },
  methods: {
    ...mapActions("workers", ["initializeWorkers"]),
    scheduleFormatter,
    select(rows) {
      this.$emit("update:selectedWorkers", rows.rows);
    },
    edit(data) {
      this.$emit("editWorker", data);
    },
    remove(data) {
      this.$emit("removeWorker", data);
    }
  },
  mounted() {
    this.loading = true;
    this.initializeWorkers()
      .then(() => {
        this.loading = false;
      })
      .catch(() => {
        this.loading = false;
      });
  }
};
</script>

<style lang="scss" module>
.workers-table-wrapper {
}
</style>
