<template>
  <div :class="$style['tag-wrapper']" @click="$emit('click')">
    <slot></slot>
    <Icon :class="$style['icon-container']" name="link-arrow" active />
  </div>
</template>

<script>
export default {
  name: "Tag",
  components: {},
  props: {},
  computed: {},
  methods: {}
};
</script>

<style lang="scss" module>
.tag-wrapper {
  display: flex;
  justify-content: center;
  width: fit-content;
  padding: 8px 8px 0;
  border-radius: 4px;
  background: #d9eefe;
  color: $primary-black;
  font-weight: 600;
  font-size: 11px;
  line-height: 13px;
  cursor: pointer;

  .icon-container {
    margin: 0 -5px 0 5px;
  }
}
</style>
