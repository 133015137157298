import Vue from "vue";
import Errors from "@/services/form/Errors";
import Validator from "@/services/form/Validator";

// Options available
// id: [Number, String]
// validateOnFieldUpdate: [Boolean]
// resetOnSuccess: [Boolean]

export default class Form {
  constructor(data, validationRules = {}, options = {}) {
    this.eventBus = new Vue();
    this.originalData = data;
    this.options = options;

    this.errors = new Errors();
    this.validator = new Validator(validationRules);

    this.loading = false;

    for (let field in data) {
      this[field] = data[field];
    }
  }

  setData(data, options) {
    for (let field in data) {
      this[field] = data[field];
    }

    if (options) {
      Object.entries(options).forEach(option => {
        this.options[option[0]] = option[1];
      });
    }
  }

  data() {
    let data = {};
    for (let field in this.originalData) {
      data[field] = this[field];
    }

    return data;
  }

  reset() {
    for (let field in this.originalData) {
      this[field] = "";
    }
  }

  submit(service) {
    return new Promise((resolve, reject) => {
      this.loading = true;
      const errors = this.validator.validateForm(this.data());
      if (errors) {
        this.onFail(errors);
      } else {
        const data = this.hasUpdateType()
          ? { id: this.options.id, data: this.data() }
          : this.data();
        service(data)
          .then(response => {
            this.onSuccess();

            resolve(response);
          })
          .catch(error => {
            this.onFail(error);

            reject(error);
          });
      }
    });
  }

  onFieldUpdate(field) {
    this.errors.clear(field);

    if (this.options.validateOnFieldUpdate) {
      const errors = this.validator.validateField(field, this.data());
      if (errors) {
        this.errors.push(field, errors);
      }
    }
  }

  onFail(errors) {
    const filteredErrors = Object.fromEntries(
      Object.entries(errors).filter(error => {
        return this.originalData[error[0]] !== undefined;
      })
    );
    this.errors.record(filteredErrors);
    this.loading = false;
  }

  onSuccess() {
    if (this.options.resetOnSuccess) {
      this.reset();
    }
    this.errors.clear();
    this.loading = false;
  }

  hasUpdateType() {
    return this.options.id ? true : false;
  }
}
