<template>
  <div :class="$style['base-back-button-wrapper']" @click="$emit('click')">
    <Icon name="back" active />
    <span>{{ $t("back") }}</span>
  </div>
</template>

<script>
export default {
  name: "BaseBackButton",
  data() {
    return {};
  },
  components: {},
  props: {},
  computed: {},
  methods: {}
};
</script>

<style lang="scss" module>
.base-back-button-wrapper {
  display: flex;
  cursor: pointer;
  float: left;
  span {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    color: $primary-blue;
  }
}
</style>
