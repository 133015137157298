<template>
  <div class="worker-schedule-breaks-optimization-list">
    <div
      class="worker-schedule-breaks-optimization-list__list"
      v-if="hasBreaks"
    >
      <div
        :class="{
          'worker-schedule-breaks-optimization-list__list__item': true
        }"
        v-for="(scheduleBreak, index) in scheduleBreaks"
        :key="index"
      >
        {{ scheduleBreak.from }} - {{ scheduleBreak.to }}
      </div>
    </div>
    <div class="worker-schedule-breaks-optimization-list__empty" v-else>
      {{ $t('thereIsNoBreaks') }}
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "WorkerScheduleBreaksOptimizationList",
  data() {
    return {};
  },
  components: {},
  props: {
    breaks: {
      type: Array,
      default: () => []
    },
    breaksUpdates: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    hasBreaks() {
      return this.breaks.length > 0;
    },
    scheduleBreaks() {
      return this.breaks.map(scheduleBreak => {
        let breakObject = {};
        breakObject.from = moment(scheduleBreak.from.date).format("HH:mm");
        breakObject.to = moment(scheduleBreak.to.date).format("HH:mm");
        breakObject.update = this.breaksUpdates.find(breakUpdate => {
          return (
            breakUpdate.original_break.from.date == scheduleBreak.from.date &&
            breakUpdate.original_break.to.date == scheduleBreak.to.date
          );
        });
        if (breakObject.update) {
          breakObject.update.new_to = moment(scheduleBreak.from.date)
            .add(breakObject.update.new_duration, "minutes")
            .format("HH:mm");
        }
        return breakObject;
      });
    }
  },
  methods: {}
};
</script>

<style lang="scss">
.worker-schedule-breaks-optimization-list {
}

.worker-schedule-breaks-optimization-list__list__item_update {
  color: $orange;
}
</style>
