<template>
  <div class="popover-route-plan-actions-wrapper">
    <Popover>
      <template v-slot:content>
        <div
          @click="$emit('saveAs', plan)"
          class="route-plan-actions-popover-item"
        >
          <Icon name="save" />
          <p>{{ $t("saveAs") }}</p>
        </div>
        <div
          @click="$emit('rename', plan)"
          class="route-plan-actions-popover-item"
        >
          <Icon name="rename" />
          <p>{{ $t("rename") }}</p>
        </div>
        <div class="divider">
          <Divider />
        </div>
        <div
          @click="$emit('delete', plan)"
          class="route-plan-actions-popover-item"
        >
          <Icon name="delete" />
          <p>{{ $t("deletePlan") }}</p>
        </div>
      </template>
      <Icon name="more" fill="#000000" pointer />
    </Popover>
  </div>
</template>

<script>
import Divider from "ant-design-vue/lib/divider";
import "ant-design-vue/lib/divider/style/css";
import Icon from "@/components/ui/Icon";
import Popover from "@/components/ui/Popover";
export default {
  name: "PopoverRoutePlanActions",
  data() {
    return {};
  },
  components: { Icon, Popover, Divider },
  props: {
    plan: {
      type: Object,
      default: () => {}
    }
  },
  computed: {},
  methods: {}
};
</script>

<style lang="scss">
.popover-route-plan-actions-wrapper {
  .ant-popover-inner-content {
    padding-left: 0;
    padding-right: 0;
  }
  .popover-item {
    display: flex;
    align-items: center;
    margin-bottom: 18px;
    padding: 0 16px;
    cursor: pointer;
    .icon-wrapper {
      margin-right: 20px;
    }
    p {
      margin-bottom: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  .ant-popover-inner-content {
    padding-left: 0;
    padding-right: 0;
  }
}
.divider {
  .ant-divider-horizontal {
    margin: 0;
    margin-bottom: 21px;
  }
}
.route-plan-actions-popover-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  padding: 0 44px 0 9px;
  cursor: pointer;
  .icon-wrapper {
    margin-right: 20px;
  }
  p {
    margin-bottom: 0;
  }
  &:last-child {
    margin-bottom: 7px;
  }
}
</style>
