<template>
  <div :class="$style['auth-forgot-password-form-wrapper']">
    <base-form
      name="forgot-password"
      :form="form"
      @submit="onSubmit"
      v-if="success === undefined || success === false"
    >
      <span :class="$style['forgot-password-description']">
        {{ $t("enterEmailForgotPassword") }}
      </span>
      <base-field
        :label="$t('email')"
        :errors="form.errors.get('email')"
        required
      >
        <base-input name="email" type="text" v-model="form.email" />
      </base-field>
      <base-button
        :loading="form.loading"
        :disabled="form.loading || form.errors.any()"
        @click="onSubmit"
      >
        {{ $t("continue") }}
      </base-button>
    </base-form>
    <span v-if="success">
      {{ $t("instrucrionsSent") }}
    </span>
  </div>
</template>

<script>
import Form from "@/services/form/Form.js";
import { forgotPassword } from "@/api/repositories/auth";

import BaseForm from "@/components/form/BaseForm";
import BaseField from "@/components/form/BaseField";
import BaseInput from "@/components/form/BaseInput";
import BaseButton from "@/components/form/BaseButton";

export default {
  name: "AuthForgotPasswordForm",
  data() {
    return {
      success: undefined,
      form: new Form(
        {
          email: ""
        },
        {
          email: {
            email: true,
            presence: { allowEmpty: false }
          }
        }
      )
    };
  },
  components: { BaseForm, BaseField, BaseButton, BaseInput },
  props: {},
  computed: {},
  methods: {
    onSubmit() {
      this.success = undefined;
      this.form
        .submit(data => {
          return new Promise((resolve, reject) => {
            forgotPassword(data)
              .then(response => {
                resolve(response);
              })
              .catch(error => {
                if (error.status == 422) {
                  reject(error.fields);
                } else {
                  reject(error);
                }
              });
          });
        })
        .then(() => {
          this.success = true;
        })
        .catch(() => {
          this.success = false;
        });
    }
  }
};
</script>

<style lang="scss" module>
.auth-forgot-password-form-wrapper {
}
</style>
