<template>
  <div class="base-time-picker">
    <AntTimePicker
      :class="{
        'base-time-picker__native': true,
        'base-time-picker__native_error': error
      }"
      v-model="model"
      :format="format"
      :disabled="disabled"
      :allowClear="allowClear"
      :size="size"
      :placeholder="placeholder"
      @change="onChange"
      @openChange="onOpenChange"
    />
  </div>
</template>

<script>
import moment from "moment";
import { TimePicker as AntTimePicker } from "ant-design-vue";
import "ant-design-vue/lib/time-picker/style/css";

export default {
  name: "BaseTimePicker",
  components: { AntTimePicker },
  props: {
    format: {
      type: String,
      default: "HH:mm"
    },
    value: {
      type: String,
      default: "12:00"
    },
    disabled: {
      type: Boolean,
      default: false
    },
    allowClear: {
      type: Boolean,
      default: false
    },
    nullable: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: "large"
    },
    placeholder: {
      type: String,
      default: "Select time"
    }
  },
  computed: {
    model: {
      get() {
        return this.value ? this.moment(this.value, this.format) : null;
      },
      set(value) {
        if (value) {
          this.$emit("input", value.format(this.format));
        } else if (this.nullable) {
          this.$emit("input", null);
        }
      }
    }
  },
  methods: {
    moment,
    onChange(value) {
      this.$emit("change", value);
    },
    onOpenChange(value) {
      if (!value) {
        this.$emit("close");
      }
    }
  }
};
</script>

<style lang="scss" module>
.base-time-picker {
}
.base-time-picker__native {
  width: 100%;
}
.base-time-picker__native_error {
  border: 2px solid red;
  border-radius: 5px;
}
</style>
