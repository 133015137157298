<template>
  <PageLayout>
    <div :class="$style['privacy-wrapper']">
      <Logo labeled />
      <h1 :class="$style['header']">Datenschutzerklärung</h1>
      <div :class="$style['text']">
        <p>
          Wir, die ImmoTrack AG (Wir oder ImmoTrack), mit Sitz an der
          Grabenstrasse 25, Baar, SchweizText, eingetragen im Handelsregister
          des Kantons Zug unter der Nummer CHE-327.313.901, sind Betreiberin der
          Website www.immotrack.com und des webbasierten Prozessmanagement-Tools
          «ImmoTrack» ("Web-Applikation") auf der Website sowie einer mobilen
          App ("App"). Wir sind daher verantwortlich für die Erhebung,
          Verarbeitung und Nutzung Ihrer personenbezogenen Daten und den
          gesetzeskonformen Umgang mit diesen Daten.
          <br /><br />
          Wir verpflichten uns zu einem verantwortungsvollen Umgang mit Ihren
          personenbezogenen Daten. Folglich erachten wir es als
          selbstverständlich, den gesetzlichen Anforderungen des schweizerischen
          Bundesgesetzes über den Datenschutz (DSG), der Verordnung zum
          Bundesgesetz über den Datenschutz (VDSG), des
          Telekommunikationsgesetzes (FMG) sowie, falls anwendbar, den
          Bestimmungen der EU-Datenschutzgrundverordnung (DSGVO) und anderen
          Bestimmungen des schweizerischen und europäischen Datenschutzrechts zu
          genügen.
          <br /><br />
          Bitte nehmen Sie zur Kenntnis, dass die nachfolgenden Informationen
          von Zeit zu Zeit überprüft und geändert werden. Wir empfehlen Ihnen
          daher, diese Datenschutzerklärung regelmässig einzusehen.
          <br /><br />
          Im Folgenden möchten wir Sie darüber informieren, wie wir Ihre
          personenbezogenen Daten verarbeiten.
        </p>
        <h2>
          1. Umfang und Zweck der Erhebung, Verarbeitung und Nutzung der
          personenbezogenen Daten
        </h2>
        <h3>1.1. Beim Besuch unserer Website</h3>
        <p>
          Beim Besuch unserer Website speichern unsere Server temporär jeden
          Zugriff in einer Protokolldatei. Die folgenden Daten werden dabei ohne
          Ihr Zutun erfasst und bis zur automatisierten Löschung, nach 26
          Monaten, von uns gespeichert:
        </p>
        <ul>
          <li>die IP-Adresse des anfragenden Rechners;</li>
          <li>
            der Name des Inhabers des IP-Adressbereichs (i.d.R. Ihr
            Internet-Access-Provider);
          </li>
          <li>der Name und die URL der abgerufenen Datei;</li>
          <li>die Website, von der aus auf unsere URL zugegriffen wurde;</li>
          <li>
            das Land, von welchem aus der Zugriff aus unsere Website erfolgt;
          </li>
          <li>
            das Betriebssystem Ihres Rechners und der von Ihnen verwendete
            Browser (Anbieter, Version und Sprache) und
          </li>
          <li>die IP-Adresse des anfragenden Rechners;</li>
          <li>das verwendete Übertragungsprotokoll (bspw. HTTP/1.1).</li>
        </ul>
        Die Erhebung und Verarbeitung dieser Daten erfolgt zum Zweck, die
        Nutzung unserer Website zu ermöglichen (Verbindungsaufbau), die
        Systemsicherheit- und stabilität dauerhaft zu gewährleisten und die
        Optimierung unseres Internetangebotes zu ermöglichen sowie zu internen
        statistischen Zwecken.
        <br /><br />
        Nur im Falle eines Angriffs auf die Netzwerkinfrastruktur von
        <a href="https://workplanner.com">www.workplanner.com</a> oder bei einem
        Verdacht auf eine andere unerlaubte oder missbräuchliche Website-Nutzung
        wird die IP-Adresse zur Aufklärung und Abwehr ausgewertet und
        gegebenenfalls im Rahmen eines Strafverfahrens zur Identifikation und
        zum zivil- oder strafrechtlichen Vorgehen gegen die betreffenden Nutzer
        verwendet. <br /><br />
        In den vorangehend beschriebenen Zwecken besteht unser berechtigtes
        Interesse an der Datenverarbeitung im Sinne von Art. 6 Abs. 1 lit. f
        DSGVO.
        <br /><br />
        Schliesslich verwenden wir bei Ihrem Besuch unserer Website Cookies und
        andere Anwendungen, die auf Cookies basieren. Weiterführende
        Informationen dazu finden Sie unter den Abschnitten “Cookies” und
        “Tracking-Tools”.

        <h3>1.2. Wenn Sie uns per E-Mail kontaktieren</h3>
        Sie haben die Möglichkeit, mit uns in Kontakt zu treten und uns eine
        E-Mail zu senden. Hierfür erheben wir zwingend Ihre E-Mail-Adresse.
        <br /><br />

        Sie sind für die Mitteilung und/oder die übermittelten Inhalte
        verantwortlich, die Sie uns zusenden. Wir empfehlen Ihnen, keine
        vertraulichen Daten zu übermitteln. Personenbezogene Daten werden nur
        erhoben, wenn Sie uns diese freiwillig zur Verfügung stellen. Deshalb
        sind Sie selbst dafür verantwortlich, welche Daten Sie uns übermitteln.
        Um Ihre Fragen beantworten zu können, bitten wir Sie gegebenenfalls
        darum, uns zusätzliche Informationen zur Verfügung stellen, z.B. Ihre
        Adresse, Telefonnummer, u.a. Wir erfassen nur personenbezogene Daten von
        Ihnen, wenn dies notwendig ist, um Ihre Fragen zu beantworten oder die
        von Ihnen angeforderten Dienstleistungen zu erbringen. Die Bearbeitung
        dieser Daten ist deshalb zur Durchführung von vorvertraglichen resp.
        vertraglichen Art. 6 Abs. 1 lit. b DSGVO erforderlich bzw. liegt in
        unserem berechtigten Interesse gemäss Art. 6 Abs. 1 lit. f DSGVO.
        <h3>1.3. Bei Nutzung des Kontaktformulars</h3>
        Um mittels Formular mit uns in Kontakt zu treten, ist die
        wahrheitsgemässe Eingabe folgender personenbezogener Daten erforderlich:
        <ul>
          <li>Firma*;</li>
          <li>Name*;</li>
          <li>Telefon;</li>
          <li>E-Mail-Adresse*;</li>
          <li>Nachricht*.</li>
        </ul>
        Die mit * gekennzeichneten Felder müssen zwingend ausgefüllt werden.
        <br /><br />
        Wir benötigen diese Angaben, um Ihre Kontaktanfrage zu bearbeiten. Die
        Bearbeitung dieser Daten ist deshalb zur Durchführung von
        vorvertraglichen resp. vertraglichen Massnahmen gemäss Art. 6 Abs. 1
        lit. b DSGVO erforderlich bzw. liegt in unserem berechtigten Interesse
        gemäss Art. 6 Abs. 1 lit. f DSGVO.
        <h3>1.4. Wenn Sie sich für unseren Newsletter anmelden</h3>
        Wenn Sie sich für unseren Newsletter anmelden, erfassen wir die
        folgenden Daten von Ihnen:
        <ul>
          <li>E-Mail-Adresse*.</li>
        </ul>
        Die mit * gekennzeichneten Felder müssen zwingend ausgefüllt werden.
        <br /><br />
        Wir verwenden für unseren Newsletterversand den
        Double-Opt-In-Mechanismus. Nach der Absendung der Anmeldung erhalten Sie
        von uns eine E-Mail, in welcher ein Bestätigungslink enthalten ist. Um
        sich definitiv für den Newsletter anzumelden, müssen Sie diesen Link
        bestätigen. Wir verwenden Ihre Daten für den Newsletterversand so lange,
        bis Sie Ihre Zustimmung widerrufen. Ihre Zustimmung können Sie jederzeit
        widerrufen. Zudem finden Sie jeweils in allen Newsletter-E-Mails einen
        Abmeldelink.<br /><br />
        Unser Newsletter kann einen sog. Web Beacon (Zählpixel) oder ähnliche
        technische Mittel enthalten. Bei einem Web Beacon handelt es sich um ein
        1×1 Pixel grosse, nicht sichtbare Grafik, die mit der Benutzer-ID des
        jeweiligen Newsletter-Abonnenten im Zusammenhang steht.<br /><br />
        Zu jedem versendeten Newsletter gibt es Informationen zur verwendeten
        Adressdatei, dem Betreff und der Anzahl versandter Newsletter. Darüber
        hinaus kann eingesehen werden, welche Adressen den Newsletter noch nicht
        bekommen haben, an welche Adressen er versandt wurde und bei welchen
        Adressen der Versand fehlgeschlagen ist. Ersichtlich ist auch, welche
        Adressen den Newsletter geöffnet haben. Und schliesslich kann eingesehen
        werden, welche Adressen sich ausgetragen haben. Wir nutzen diese Daten
        zu statistischen Zwecken und zur Optimierung des Newsletters in Bezug
        auf Inhalt und Struktur. Dies ermöglicht uns, die Informationen und
        Angebote in unserem Newsletter besser auf die individuellen Interessen
        der Empfänger auszurichten. Der Zählpixel wird gelöscht, wenn Sie den
        Newsletter löschen. <br /><br />
        Um den Einsatz des Web Beacon in unserem Newsletter zu unterbinden,
        stellen Sie bitte, falls dies nicht bereits standardmässig der Fall ist,
        Ihr Mailprogramm so ein, dass in Nachrichten kein HTML angezeigt wird.
        Auf den nachfolgenden Seiten finden Sie Erläuterungen, wie Sie diese
        Einstellung bei den gängigsten E-Mail-Programmen vornehmen können.
        <ul>
          <li>
            <a
              href="https://support.office.com/de-de/article/Lesen-von-E-Mail-Nachrichten-im-Nur-Text-Format-16dfe54a-fadc-4261-b2ce-19ad072ed7e3"
              >Microsoft Outlook</a
            >
          </li>
          <li>
            <a
              href="https://support.apple.com/de-de/guide/mail/viewing-preferences-cpmlprefview/mac"
              >Mail für Mac („Entfernte Inhalte in Nachrichten laden“)</a
            >
          </li>
        </ul>
        Mit der Registrierung zu unserem Newsletter erteilen Sie uns Ihre
        Einwilligung zur Verarbeitung der angegebenen personenbezogenen Daten im
        Sinne von Art. 6 Abs. 1 lit. a DSGVO für den regelmässigen Versand des
        Newsletters an die von Ihnen angegebene Adresse und für die statistische
        Auswertung des Nutzungsverhaltens und die Optimierung des Newsletters.
        <h3>1.5. Beim Erstellen eines Benutzerkontos</h3>
        Auf unserer Website haben Sie die Möglichkeit ein Benutzerkonto zu
        erstellen. Bei der Registrierung erheben wir folgende Daten (zwingend*):
        <ul>
          <li>E-Mail-Adresse*;</li>
          <li>Passwort*;</li>
          <li>
            Zustimmung zu den Nutzungsbedingungen und Bestätigung der
            Kenntnisnahme der Datenschutzerklärung*.
          </li>
        </ul>
        Die mit * gekennzeichneten Felder müssen zwingend ausgefüllt werden.
        <br /><br />
        Wir bearbeiten diese Daten, um Ihnen die Nutzung unserer Web-Applikation
        bzw. unserer App und die Erfüllung der in diesem Zusammenhang mit Ihnen
        abgeschlossenen Verträge zu ermöglichen. Die rechtliche Grundlage der
        Bearbeitung Ihrer personenbezogenen Daten liegt in den vorvertraglichen
        Massnahmen und der Durchführung eines Vertrags im Sinne von Art. 6 Abs.
        1 lit. b DSGVO sowie unserem berechtigten Interesse im Sinne von Art. 6
        Abs. 1 lit. f DSGVO.
        <h2>
          2. Beim Nutzen der Funktionalitäten unserer Web-Applikation und der
          App
        </h2>
        Bei der Nutzung der Web-Applikation auf der Website oder der App können
        Sie als registrierter Nutzer verschiedene Informationen eingeben,
        insbesondere Namen von Ihren Mitarbeitern, aber auch Namen und
        Kontaktdaten von Ihren eigenen Kunden.<br /><br />
        Datenschutzrechtlich sind Sie als registrierter Nutzer für diese
        Datenerhebungen verantwortlich. Wir sind lediglich Auftragsverarbeiter.
        Sie sind daher bei Datenbearbeitungen in der Web-Applikation und der App
        verantwortlich für die Einhaltung der anwendbaren datenschutzrechtlichen
        Bestimmungen. Sie sind insbesondere verpflichtet, Drittpersonen, deren
        Personendaten Sie in der Web-Applikation oder der App bearbeiten, über
        die Datenbearbeitung zu informieren. <br /><br />
        Es gibt jedoch auch einzelne Datenbearbeitungen in der Web-Applikation
        und der App, für welche wir verantwortlich oder mitverantwortlich sind.
        Es handelt sich hierbei insbesondere um das Live-Tracking sowie den
        automatischen Routenplaner. Nachfolgend informieren wir u.a. über diese
        beiden Datenverarbeitungen. <br /><br />
        <h3>2.1. Beim Erstellen eines Mitarbeiterprofils</h3>
        Sie haben bei der Nutzung unserer Web-Applikation bzw. unserer App die
        Möglichkeit, ein Mitarbeiterprofil zu erstellen. Bei der Erstellung des
        Mitarbeiterprofils können Sie folgende Daten eingeben (zwingend*):
        <ul>
          <li>Vor- und Nachname*;</li>
          <li>Adresse*;</li>
          <li>Postleitzahl und Ortschaft*;</li>
          <li>Telefonnummer*;</li>
          <li>E-Mail-Adresse*;</li>
          <li>Primary Skills*;</li>
          <li>Secondary Skills*;</li>
          <li>Stundenansatz in CHF;</li>
          <li>Nationalität;</li>
          <li>Sprache*;</li>
          <li>Geschlecht;</li>
          <li>Geburtsdatum;</li>
          <li>Nummer Kontrollschild Fahrzeug;</li>
          <li>Angaben zum Arbeitsvertrag*;</li>
          <li>AHV-Nummer;</li>
          <li>Profilbild.</li>
        </ul>
        Die mit * gekennzeichneten Felder müssen zwingend ausgefüllt werden.
        <br /><br />
        Wir bearbeiten diese Daten in Ihrem Auftrag, um Ihnen die Nutzung
        unserer Web-Applikation bzw. unserer App und die Erfüllung der in diesem
        Zusammenhang mit Ihnen abgeschlossenen Verträge zu ermöglichen.
        <h3>2.2. Beim Erstellen eines Kundenprofils</h3>
        Sie haben bei der Nutzung unserer Web-Applikation bzw. unserer App die
        Möglichkeit, ein Kundenprofil zu erstellen. Bei der Erstellung des
        Kundenprofils können Sie folgende Daten eingeben (zwingend*):
        <ul>
          <li>Firma*;</li>
          <li>Geschäftsadresse*;</li>
          <li>Telefonnummer der Ansprechperson;</li>
          <li>E-Mail-Adresse der Ansprechperson;</li>
          <li>Homepage.</li>
        </ul>
        Die mit * gekennzeichneten Felder müssen zwingend ausgefüllt werden.
        <br /><br />

        Wir bearbeiten diese Daten in Ihrem Auftrag, um Ihnen die Nutzung
        unserer Web-Applikation bzw. unserer App und die Erfüllung der in diesem
        Zusammenhang mit Ihnen abgeschlossenen Verträge zu ermöglichen.
        <h3>2.3. Beim Erstellen eines Kontaktprofils</h3>
        Sie haben bei der Nutzung unseres Web-Applikation bzw. unserer App die
        Möglichkeit, ein Profil für eine Kontaktperson zu erstellen. Bei der
        Erstellung des Kontaktprofils können Sie folgende Daten eingeben
        (zwingend*):
        <ul>
          <li>Firma*;</li>
          <li>Abteilungsname;</li>
          <li>Vor- und Nachname*;</li>
          <li>Profilbild;</li>
          <li>E-Mail-Adresse*;</li>
          <li>Telefonnummer.</li>
        </ul>
        Die mit * gekennzeichneten Felder müssen zwingend ausgefüllt werden.<br /><br />

        Wir bearbeiten diese Daten in Ihrem Auftrag, um Ihnen die Nutzung
        unserer Web-Applikation bzw. unserer App und die Erfüllung der in diesem
        Zusammenhang mit Ihnen abgeschlossenen Verträge zu ermöglichen.
        <h3>2.4. Beim Erstellen eines Liegenschaftsprofils</h3>
        Sie haben bei der Nutzung unserer Web-Applikation bzw. unserer App die
        Möglichkeit, ein Liegenschaftsprofil zu erstellen. Bei der Erstellung
        des Liegenschaftsprofils können Sie folgende Daten eingeben (zwingend*):
        <ul>
          <li>Name der Ansprechperson*;</li>
          <li>Telefonnummer der Ansprechperson;</li>
          <li>Adresse der zu betreuenden Liegenschaft*;</li>
          <li>Art der zu betreuenden Liegenschaft*;</li>
          <li>Subliegenschaften;</li>
          <li>Beschreibung der Liegenschaft;</li>
          <li>Liste der zu erfüllenden Aufgaben*;</li>
          <li>Bearbeitungszeitraum;</li>
          <li>Stundenansatz.</li>
        </ul>
        Die mit * gekennzeichneten Felder müssen zwingend ausgefüllt werden.<br /><br />

        Wir bearbeiten diese Daten in Ihrem Auftrag, um Ihnen die Nutzung
        unserer Web-Applikation bzw. unserer App und die Erfüllung der in diesem
        Zusammenhang mit Ihnen abgeschlossenen Verträge zu ermöglichen.
        <h3>
          2.5. Verwendung der Web-Applikation bzw. der App mit aktiviertem
          Live-Tracking
        </h3>
        Die in der Web-Applikation integrierte Live-Tracking-Funktion kann die
        geographische Lage von Endgeräten bestimmen. Meist handelt es sich dabei
        um die mobilen Endgeräte von Mitarbeitern, von denen ein
        Mitarbeiterprofil erstellt wurde und welche die App benutzen, um die
        Erledigung von Arbeiten in Liegenschaften zu optimieren und
        nachzuweisen. Mit der Tracking-Funktion wird primär dem Schutzgedanken
        gegenüber den Mitarbeitenden Rechnung getragen. Es kommt regelmässig
        vor, dass Auftraggeber die Arbeitsleistungen von Mitarbeitenden zu
        Unrecht beanstanden bzw. die Verantwortung für Schäden zu Unrecht
        Mitarbeitenden zuschieben. Die Tracking-Funktion schützt die
        Mitarbeitenden vor solchen ungerechtfertigten Vorwürfen, indem sie den
        Nachweis der korrekten Erfüllung erleichtert bzw. die Zurückweisung von
        Vorwürfen unterstützt. Zudem unterstützt die Funktion den Arbeitgeber
        bei der Optimierung der Arbeitsprozesse und der Verbesserung des Field
        Force-Managements. Dies kommt den Mitarbeitenden zugute.
        <br /><br />
        Aus datenschutzrechtlichen Gründen ist die Aktivierung des GPS-Trackings
        jedoch freiwillig. Die Mitarbeiter haben die Möglichkeit und auch das
        Recht, das Tracking zu de-aktivieren, ohne dadurch einen rechtlichen
        Nachteil zu erleiden. In diesem Fall werden keine Trackingdaten erhoben.
        Uns ist dann ein Rückschluss auf die Gerätenutzer nicht möglich.
        <br /><br />
        Mithilfe von übermittelten Geräte-Identifikationsmerkmalen (Geräte-ID)
        können wir die Tracking-Informationen dem jeweiligen Nutzer des mobilen
        Endgerätes zuordnen und seine jeweilige Position auf Karten in der
        Web-Applikation oder der App anzeigen, sofern die Tracking-Funktion
        aktiviert ist.
        <br /><br />
        Wir bearbeiten diese Daten, um den Nutzern der Web-Applikation oder der
        App die Live-Tracking-Funktion anbieten zu können. Zweck dieser Funktion
        ist es u.a., eine effiziente Arbeitsplanung zu ermöglichen, indem einem
        Mitarbeiter je nach aktuellem Standort allenfalls auch spontane Aufgaben
        zugeteilt werden können. Dem Arbeitgeber ermöglicht diese Funktion eine
        Verbesserung des sog. Field Force-Managements. Aus Sicht des
        Mitarbeiters hat die Tracking-Funktion den Vorteil, dass er dadurch die
        korrekte Arbeitserledigung nachweisen kann. Zudem schützt die Funktion
        den Mitarbeitenden auch vor ungerechtfertigten Vorwürfen.
        <h3>2.6. Automatischer Routenplaner</h3>
        Die in Ziff. 2.5 erwähnten Ortungsdaten werden auch für die
        Funktionalität "automatischer Routenplaner" verwendet. Zusammen mit
        weiteren Informationen (z.B. der erwartbaren Zeitdauer für die
        Erledigung von Arbeiten, Erfahrungswerten für Fahrrouten, etc.) wird bei
        dieser Funktionalität jeweils ein optimaler Routenplan für die
        verschiedenen Einsätze eines Mitarbeiters bestimmt. Der Routenplan wird
        dem Nutzer in der App angezeigt.
        <br /><br />
        Zur Bestimmung des optimalen Routenplans werden die verfügbaren
        Informationen mithilfe von Technologien der künstlichen Intelligenz und
        entsprechender Algorithmen ausgewertet.
        <h3>2.7. Bei der weiteren Nutzung der Web-Applikation und der App</h3>
        Durch die Nutzung der Web-Applikation und der App nach Ihren
        individuellen Bedürfnissen generieren Sie eine Vielzahl weiterer Daten,
        die wir zusätzlich zu anderen in dieser Datenschutzerklärung genannten
        Daten verarbeiten (wie Name, Vorname, Adresse, Telefonnummer,
        E-Mail-Adresse, Berufsbezeichnung und Funktion, Geburtsdatum). Die Art
        der verarbeiteten Daten sowie Art und Umfang der Datenverarbeitung wird
        durch Ihre Nutzung bestimmt.
        <br /><br />
        Zum Beispiel können Sie:
        <ul>
          <li>Eine To Do Liste erstellen;</li>
          <li>Fotografien hochladen;</li>
          <li>Kommentare eingeben;</li>
          <li>
            Reports erstellen (u.a. basierend auf Daten aus der automatischen
            Routenplanung gemäss Ziff. 2.6);
          </li>
          <li>Task-Verwaltung in der Dashboard-Funktion;</li>
          <li>usw.</li>
        </ul>
        Wir verarbeiten diese Daten im Zusammenhang mit der Nutzung der
        Web-Applikation und der App oder der von Ihnen gewählten
        Dienstleistungen, um Ihre Anfragen zu prüfen und zu erfüllen, um die
        erforderlichen vertraglichen Leistungen zu erfüllen und um Ihnen eine
        effiziente und zentrale Verwaltung der Daten zu ermöglichen. Dies kann
        neben der Verarbeitung der Daten im Rahmen der Web-Applikation und der
        App selbst auch die Kontaktaufnahme mit Ihnen über die von Ihnen
        angegebenen Kontaktdaten oder - sofern die Funktion nicht deaktiviert
        ist - die Anzeige von Push-Mitteilungen umfassen. Die Datenbearbeitungen
        über die mobile App, welche durch den Mitarbeitenden initiiert werden,
        z.B. das Heraufladen von Fotos als Nachweis der Leistungserfüllung,
        dienen auch dem Schutz des Mitarbeitenden vor ungerechtfertigten
        Beanstandungen und Vorwürfen seitens der Auftraggeber. Mit den
        bearbeiteten Daten kann der Nachweis der korrekten Leistungserfüllung
        erbracht werden.
        <br /><br />
        Wir verwenden die Nutzerdaten auch dazu, um mittels Technologien der
        künstlichen Intelligenz die Dienstleistungen zu optimieren bzw. um
        genauere oder kundenspezifischer Informationen anbieten zu können.
        Hierzu analysieren wir Nutzerdaten mithilfe von Algorithmen.
        <h2>
          3. Cookies
        </h2>
        Cookies helfen unter vielen Aspekten, Ihren Besuch auf unserer Website
        einfacher, angenehmer und sinnvoller zu gestalten. Cookies sind
        Informationsdateien, die Ihr Webbrowser automatisch auf der Festplatte
        Ihres Computers speichert, wenn Sie unsere Website besuchen.

        <br /><br />
        Wir setzen Cookies beispielsweise ein, um Ihre ausgewählten Leistungen
        und Eingaben beim Ausfüllen eines Formulars auf der Website temporär zu
        speichern, damit Sie die Eingabe beim Aufruf einer anderen Unterseite
        nicht wiederholen müssen. Cookies werden gegebenenfalls auch eingesetzt,
        um Sie nach der Registrierung auf der Website als registrierten Benutzer
        identifizieren zu können, ohne dass Sie sich beim Aufruf einer anderen
        Unterseite erneut einloggen müssen.

        <br /><br />
        Die meisten Internet-Browser akzeptieren Cookies automatisch. Sie können
        Ihren Browser jedoch so konfigurieren, dass keine Cookies auf Ihrem
        Endgerät gespeichert werden oder stets ein Hinweis erscheint, wenn Sie
        ein neues Cookie erhalten. Auf den nachfolgenden Seiten finden Sie
        Erläuterungen, wie Sie die Verarbeitung von Cookies bei den gängigsten
        Browsern konfigurieren können:
        <ul>
          <li>
            <a
              href="https://support.microsoft.com/de-de/help/17442/windows-internet-explorer-delete-manage-cookies"
              >Microsofts Windows Internet Explorer</a
            >
          </li>
          <li>
            <a
              href="https://support.microsoft.com/en-us/help/10635/windows-phone-change-privacy-and-other-browser-settings"
              >Microsofts Windows Internet Explorer Mobile</a
            >
          </li>
          <li>
            <a
              href="https://support.mozilla.org/de/kb/cookies-erlauben-und-ablehnen"
              >Mozilla Firefox</a
            >
          </li>
          <li>
            <a href="https://support.google.com/chrome/answer/95647?hl=de"
              >Google Chrome für Desktop</a
            >
          </li>
          <li>
            <a
              href="https://support.google.com/chrome/answer/2392709?hl=de&co=GENIE.Platform%3DAndroid&oco=1"
              >Google Chrome für Mobile</a
            >
          </li>
          <li>
            <a
              href="https://support.apple.com/kb/PH17191?locale=en_US&viewlocale=de_DE"
              >Apple Safari für Desktop</a
            >
          </li>
          <li>
            <a href="https://support.apple.com/de-de/HT201265"
              >Apple Safari für Mobile</a
            >
          </li>
        </ul>
        Die Deaktivierung von Cookies kann dazu führen, dass Sie nicht alle
        Funktionen unserer Website nutzen können.
        <br /><br />
        Die Rechtsgrundlage für die Verarbeitung der personenbezogenen Daten zu
        den oben genannten Zwecken liegt in unserem berechtigten Interesse
        gemäss Art. 6 Abs. 1 lit. f DSGVO an der Gewährleistung der
        Funktionalität und Optimierung unserer Webseiten. Bei rein analytischen
        Cookies stützen wir die Verarbeitung Ihrer personenbezogenen Daten auf
        Ihre Einwilligung, die Sie über den Cookie-Banner erteilen.
        <h2>
          4. Tracking-Tools
        </h2>
        <h3>4.1. Google Analytics</h3>
        Wir nutzen Google Analytics, ein Webanalyse-Dienst von Google Ireland
        Limited, Google Building Gordon House, 4 Barrow St, Dublin, D04 E5W5,
        Irland bzw. Google Inc., 1600 Amphitheater Pkwy, Mountain View, CA
        94043-1351, USA. Google Analytics verwendet Methoden, die eine Analyse
        der Benutzung der Website ermöglichen, wie zum Beispiel Cookies. Die
        durch den Cookie erzeugten Informationen über Ihre Benutzung dieser
        Website werden an die Server der Anbieter dieser Dienste übertragen,
        dort gespeichert und für uns aufbereitet. Zusätzlich zu den unter Ziff.
        1 aufgeführten Daten erhalten wir dadurch unter Umständen folgende
        Informationen:
        <ul>
          <li>
            Navigationspfad, den ein Besucher auf der Website beschreitet;
          </li>
          <li>Verweildauer auf der Website oder Unterseite;</li>
          <li>die Unterseite, auf welcher die Website verlassen wird,</li>
          <li>
            das Land, die Region oder die Stadt, von wo aus ein Zugriff erfolgt,
          </li>
          <li>
            Endgerät (Typ, Version, Farbtiefe, Auflösung, Breite und Höhe des
            Browserfensters);
          </li>
          <li>Wiederkehrender oder neuer Besucher;</li>
          <li>Browseranbieter/Version;</li>
          <li>Das verwendete Betriebssystem;</li>
          <li>Die Referrer URL (zuvor besuchte Webseite);</li>
          <li>Hostname des zugreifenden Rechners (IP-Adresse), und</li>
          <li>Uhrzeit der Serveranfrage.</li>
        </ul>
        Vor der Übermittlung der Daten an den Anbieter wird die IP-Adresse durch
        die Aktivierung der IP-Anonymisierung („anonymizeIP“) auf dieser Website
        vor der Übermittlung innerhalb der Mitgliedstaaten der Europäischen
        Union oder in anderen Vertragsstaaten des Abkommens über den
        Europäischen Wirtschaftsraum, wie auch in der Schweiz, gekürzt. Die im
        Rahmen von Google Analytics von Ihrem Browser übermittelte anonymisierte
        IP-Adresse wird nicht mit anderen Daten von Google zusammengeführt. Nur
        in Ausnahmefällen wird die volle IP-Adresse an einen Server von Google
        in den USA übertragen und dort gekürzt. In diesen Fällen stellen wir
        durch vertragliche Garantien sicher, dass Google ein ausreichendes
        Datenschutzniveau einhält.
        <br /><br />
        Die Informationen werden verwendet, um die Nutzung der Website
        auszuwerten, um Reports über die Websiteaktivitäten zusammenzustellen
        und um weitere mit der Websitenutzung und der Internetnutzung verbundene
        Dienstleistungen zu Zwecken der Marktforschung und bedarfsgerechten
        Gestaltung dieser Internetseiten zu erbringen. Google kann diese
        Informationen gegebenenfalls an Dritte übertragen, sofern dies
        gesetzlich vorgeschrieben ist oder soweit Dritte diese Daten im Auftrag
        von Google verarbeiten. Gemäss Google wird in keinem Fall die IP-Adresse
        mit anderen den Nutzer betreffenden Daten in Verbindung gebracht werden.
        <br /><br />
        Die Rechtsgrundlage der Verarbeitung der Daten für diesen Zweck liegt in
        unserem berechtigten Interesse an der Optimierung unserer Website und
        der erhöhten Personalisierung unserer Dienstleistungen nach Art. 6 Abs.
        1 lit. f DSGVO.
        <br /><br />
        Nutzer können die Erfassung der durch den Cookies erzeugten Daten
        (einschliesslich der IP-Adresse), die sich auf die Nutzung der Website
        beziehen, durch Google sowie die Verarbeitung dieser Daten durch Google
        verhindern, indem sie das unter dem folgenden Link verfügbare
        Browser-Plugin herunterladen und installieren:
        <a href="http://tools.google.com/dlpage/gaoptout?hl=de"
          >http://tools.google.com/dlpage/gaoptout?hl=de</a
        >
        <br /><br />
        Ein Opt-Out-Cookie wird auf Ihrem Gerät gespeichert. Wenn Sie Cookies
        löschen, muss der Link erneut angeklickt werden.
        <h3>4.2. Google Tag Manager</h3>
        Wir verwenden Google Tag Manager, ein Dienst von Google Ireland Limited,
        Google Building Gordon House, 4 Barrow St, Dublin, D04 E5W5, Ireland,
        bzw. Google Inc., 1600 Amphithea-tre Pkwy, Mountain View, CA 94043-1351,
        USA, um Cookies und Pixel für Tracking-Tools und andere Tools zu
        verwalten. Das Tag Manager-Tool selbst ist eine cookielose Domain und
        sammelt keine personenbezogenen Daten. Stattdessen löst das Tool andere
        Tags aus, die ihrerseits Daten sammeln können. Wenn Sie eine
        Deaktivierung auf Haupt- oder Cookie-Ebene vorgenommen haben, bleibt
        diese für alle mit dem Google Tag Manager implementierten Tracking-Tags
        bestehen.
        <br /><br />
        Diese Verarbeitung beruht auf unserem berechtigten Interesse im Sinne.
        Sie können dieser Datenverarbeitung jederzeit widersprechen.
        <h2>
          5. Google Maps
        </h2>
        Wir verwenden Google Maps API (Application Programming Interface,
        “Google Maps”) von Google Ireland Limited, Gordon House, Barrow Street,
        Dublin 4, Ireland, bzw. Google Inc., 1600 Amphitheatre Pkwy, Mountain
        View, CA 94043-1351, USA, auf unserer Website für die visuelle
        Darstellung von geographischen Informationen (Karten). Durch die Nutzung
        von Google Maps werden Informationen über die Nutzung unserer Website,
        einschliesslich Ihrer IP-Adresse, an einen Server von Google in den USA
        übertragen und dort gespeichert.
        <br /><br />
        Die rechtliche Grundlage für die Verarbeitung der Daten zu diesem Zweck
        liegt in unserem berechtigten Interesse gemäss Art. 6 Abs. 1 lit. f
        DSGVO. Sie können dieser Datenverarbeitung jederzeit widersprechen.
        <br /><br />
        Es ist möglich, Google Maps zu deaktivieren und Datenübertragungen an
        Google zu verhindern, wenn Sie JavaScript in Ihrem Browser deaktivieren.
        Wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall die
        Kartenanzeige nicht nutzen können.
        <br /><br />
        Weitere Informationen über die Erhebung, Verarbeitung und Nutzung Ihrer
        Daten durch Google und Ihre diesbezüglichen Rechte finden Sie in den
        Datenschutzbestimmungen von Google unter
        <a href="https://policies.google.com/privacy"
          >https://policies.google.com/privacy</a
        >, sowie in den zusätzlichen Nutzungsbedingungen für Google Maps oder
        Google Earth unter:
        <a href="https://www.google.com/help/terms_maps/"
          >https://www.google.com/help/terms_maps/</a
        >.
        <h2>
          6. Social Media
        </h2>
        Auf unserer Website haben wir Links zu unseren Social-Media-Auftritten
        in den folgenden sozialen Netzwerken eingebaut:
        <ul>
          <li>
            LinkedIn Corp., 1000 W. Maude Avenue, Sunnyvale, CA 94085, USA;
          </li>
          <li>
            Facebook Inc., 1601 S. California Ave, Palo Ave, CA 04304, USA.
          </li>
        </ul>
        Wenn Sie auf die entsprechenden Symbole der sozialen Netzwerke klicken,
        werden Sie automatisch zu unserem Profil des entsprechenden sozialen
        Netzwerks weitergeleitet. Um dort die Funktionen des jeweiligen
        Netzwerks nutzen zu können, müssen Sie sich teilweise in Ihr
        Benutzerkonto für das jeweilige Netzwerk einloggen.
        <br /><br />
        Wenn Sie einen Link zu einem unserer Social-Media-Profile öffnen, wird
        eine direkte Verbindung zwischen Ihrem Browser und dem Server des
        betreffenden sozialen Netzwerks hergestellt. Dadurch erhält das Netzwerk
        die Information, dass Sie unsere Webseite mit Ihrer IP-Adresse besucht
        und auf den Link zugegriffen haben. Wenn Sie auf einen Link zu einem
        Netzwerk zugreifen, während Sie in Ihrem Konto im betreffenden Netzwerk
        angemeldet sind, kann der Inhalt unserer Seite mit Ihrem Profil des
        Netzwerks verknüpft werden, d.h. das Netzwerk kann Ihren Besuch auf
        unserer Website direkt mit Ihrem Benutzerkonto verknüpfen. Wenn Sie dies
        verhindern wollen, sollten Sie sich vor dem Anklicken der entsprechenden
        Links ausloggen. Eine Zuordnung erfolgt in jedem Fall, wenn Sie sich
        nach dem Anklicken des Links in das betreffende Netzwerk einloggen.
        <br /><br />
        Weitere Informationen über Zweck und Umfang der Datenerhebung und
        -verarbeitung durch die oben genannten sozialen Netzwerke und Ihre
        jeweiligen Rechte und Datenschutzoptionen finden Sie in den jeweiligen
        Datenschutzrichtlinien.
        <h2>
          7. Social Media Plugins
        </h2>
        <h3>7.1. YouTube</h3>
        Unsere Website enthält Links bzw. Verknüpfungen zum Angebot von YouTube.
        Generell gilt, dass wir für die Inhalte von Internetseiten, auf die
        verlinkt wird, nicht verantwortlich sind. Für den Fall, dass Sie einem
        Link auf YouTube folgen, weisen wir aber darauf hin, dass YouTube die
        Daten ihrer Nutzer (z.B. persönliche Informationen, IP-Adresse)
        entsprechend ihrer eigenen Datenschutzrichtlinie abspeichert und für
        geschäftliche Zwecke nutzt. Auf unserer Website binden wir auch bei
        YouTube gespeicherte Videos unmittelbar ein. Bei dieser Einbindung
        werden in Teilbereichen eines Browserfensters Inhalte der YouTube
        Internetseite abgebildet. Die YouTube-Videos werden jedoch erst durch
        gesondertes Anklicken abgerufen. Diese Technik wird auch „Framing“
        genannt. Wenn Sie eine (Unter-)Seite unserer Website aufrufen, auf der
        YouTube-Videos in dieser Form eingebunden sind, wird eine Verbindung zu
        den YouTube-Servern hergestellt und dabei der Inhalt durch Mitteilung an
        Ihren Browser auf der Website dargestellt. Die Einbindung von
        YouTube-Inhalten erfolgt nur im "erweiterten Datenschutzmodus". Diesen
        stellt YouTube selbst bereit und sichert damit zu, dass YouTube zunächst
        keine Cookies auf Ihrem Gerät speichert. Beim Aufruf der betreffenden
        Seiten werden allerdings die IP-Adresse sowie weitere Daten übertragen
        und so insbesondere mitgeteilt, welche unserer Internetseiten Sie
        besucht haben. Diese Information lässt sich jedoch nicht Ihnen zuordnen,
        sofern Sie sich nicht vor dem Seitenaufruf bei YouTube oder einem
        anderen Google-Dienst (z. B. Google+) angemeldet haben bzw. dauerhaft
        angemeldet sind. Sobald Sie die Wiedergabe eines eingebundenen Videos
        durch Anklicken starten, speichert YouTube durch den erweiterten
        Datenschutzmodus auf Ihrem Gerät nur Cookies, die keine
        personenbezogenen Daten enthalten, es sei denn Sie sind aktuell bei
        einem Google-Dienst angemeldet. Diese Cookies lassen sich durch
        entsprechende Browsereinstellungen und -erweiterungen verhindern.
        Adresse und Link zu den Datenschutzhinweisen des Drittanbieters:
        Google/YouTube: Google Ireland Limited, Gordon House, Barrow Street,
        Dublin 4, Irland –
        <a href="https://policies.google.com/privacy"
          >https://policies.google.com/privacy</a
        >, Opt-Out:
        <a href="https://adssettings.google.com/authenticated"
          >https://adssettings.google.com/authenticated</a
        >.
        <h2>
          8. Speicherung der Daten
        </h2>
        Wir speichern Ihre Daten in der Amazon Cloud. Amazon Cloud ist eine
        Plattform des Unternehmens Amazon.com Inc. Die Rechenzentren befinden
        sich in Frankfurt am Main, Deutschland. Die Bearbeitung dieser Daten im
        Rahmen der Software stützen wir auf unser berechtigtes Interesse im
        Sinne von Art. 6 Abs. 1 lit. f DSGVO an einer kundenfreundlichen und
        effizienten Kundendatenverwaltung sowie auf die Durchführung von
        vertraglichen Massnahmen gemäss Art. 6 Abs. 1 lit. b DSGVO.
        <h2>
          9. Weitergabe der Daten an Dritte
        </h2>
        Wir geben Ihre personenbezogenen Daten nur weiter, wenn Sie ausdrücklich
        eingewilligt haben, hierfür eine gesetzliche Verpflichtung besteht oder
        dies zur Durchsetzung unserer Rechte, insbesondere zur Durchsetzung von
        Ansprüchen aus dem Vertragsverhältnis, erforderlich ist. Darüber hinaus
        geben wir Ihre Daten an Dritte weiter, soweit dies die für die Nutzung
        der Website, die Verarbeitung Ihrer Kontaktanfragen, das Versenden von
        Marketingmitteilungen und die Analyse Ihres Nutzerverhaltens notwendig
        sind. Die Nutzung der hierzu weitergebenen Daten durch die Dritten ist
        streng auf die genannten Zwecke beschränkt.
        <br /><br />
        Verschiedene Drittdienstleister werden in dieser Datenschutzerklärung
        explizit erwähnt (bspw. im Abschnitt “Tracking-Tools”). Weitere
        Dienstleister, an die über die Website erhobene personenbezogene Daten
        weitergegeben werden oder zu denen sie Zugang haben oder haben können,
        ist unser Hosting-Provider netcupGmbH, Daimlerstrasse 25, D-76185
        Karlsruhe. Die Daten werden zum Zweck der Bereitstellung und Pflege der
        Funktionalitäten unserer Website weitergegeben. Bei dieser Verarbeitung
        stützen wir uns auf unsere berechtigten Interessen im Sinne von Art. 6
        Abs. 1 lit. f DSGVO. 1 lit. f GDPR.
        <h2>
          10. Übermittlung personenbezogener Daten ins Ausland
        </h2>
        Wir können Ihre Daten zum Zweck der in dieser Datenschutzerklärung
        beschriebenen Datenverarbeitung an Dritte (beauftragte Dienstleister)
        mit Sitz im Ausland übertragen.
        <br /><br />
        Solche Drittfirmen sind verpflichtet, die Privatsphäre von Personen im
        gleichen Masse zu schützen wie wir. Wenn das Datenschutzniveau in einem
        Land nicht dem schweizerischen oder europäischen Niveau entspricht,
        stellen wir vertraglich sicher, dass der Schutz Ihrer personenbezogenen
        Daten jederzeit demjenigen in der Schweiz oder der EU entspricht.
        Bestimmte in dieser Datenschutzerklärung erwähnte Drittanbieter haben
        ihren Sitz in den USA (siehe Abschnitt 4. “Tracking-Tools). Weitere
        Erläuterungen zu den Daten, die in die USA übermittelt werden können,
        finden Sie unter Abschnitt 4. “Tracking Tools” und Abschnitt 11.
        “Hinweis zur Datenübermittlungen in die USA”.
        <h2>
          11. Hinweis zur Datenübermittlungen in die USA
        </h2>
        Der Vollständigkeit halber weisen wir darauf hin, dass die US-Behörden
        im Rahmen der US-Gesetzgebung Überwachungsmassnahmen ergreifen können,
        die eine generelle Speicherung aller von der Europäischen Union oder der
        Schweiz in die USA übermittelten Daten ermöglichen. Dies geschieht ohne
        Unterscheidung, Einschränkung oder Ausnahme, auf der Grundlage des
        verfolgten Ziels und ohne objektive Kriterien, die es erlauben würden,
        den Zugang der US-Behörden zu personenbezogenen Daten und ihre
        anschliessende Verwendung auf spezifische, streng begrenzte Zwecke zu
        beschränken, die den Zugang zu diesen Daten rechtfertigen.
        <br /><br />
        Darüber hinaus möchten wir darauf hinweisen, dass es in den USA keine
        Rechtsmittel für die Betroffenen aus den EU-Mitgliedstaaten oder der
        Schweiz gibt, die es ihnen ermöglichen würden, Zugang zu den sie
        betreffenden Daten zu erhalten und ihre Berichtigung oder Löschung zu
        erwirken, und dass es keinen wirksamen Rechtsschutz gegen allgemeine
        Zugangsrechte der US-Behörden gibt. Wir weisen die betroffene Person
        ausdrücklich auf diese Rechts- und Sachlage hin, damit sie eine
        informierte Entscheidung über die Zustimmung zur Verwendung ihrer Daten
        treffen kann.
        <br /><br />
        Für Personen, die in den EU-Mitgliedstaaten oder der Schweiz ansässig
        sind, beachten Sie bitte, dass die USA aus Sicht der Europäischen Union
        und der Schweiz unter anderem aufgrund der in diesem Abschnitt genannten
        Punkte, nicht über ein ausreichendes Datenschutzniveau verfügen. Soweit
        wir in dieser Datenschutzerklärung erklärt haben, dass die Empfänger von
        Daten (wie etwa Google) in den USA ansässig sind, werden wir durch
        Vertrag sicherstellen, dass Ihre Daten von unseren Partnern angemessen
        geschützt werden.
        <h2>
          12. Recht auf Auskunft, Löschung und Berichtigung
        </h2>
        Sie können der Datenverarbeitung, insbesondere der Datenverarbeitung im
        Zusammenhang mit Direktmarketing (z.B. gegen Werbe-E-Mails), jederzeit
        widersprechen. Sie haben die folgenden Rechte:
        <br /><br />
        <b>Recht auf Auskunft:</b> Sie haben das Recht, jederzeit unentgeltlich
        Einsicht in Ihre bei uns gespeicherten personenbezogenen Daten zu
        verlangen, wenn wir diese verarbeiten. So haben Sie die Möglichkeit zu
        prüfen, welche personenbezogene Daten wir über Sie verarbeiten, und dass
        wir diese gemäss den geltenden Datenschutzbestimmungen verwenden.
        <br /><br />
        <b>Recht auf Berechtigung:</b> Sie haben das Recht, unrichtige oder
        unvollständige personenbezogene Daten berichtigen zu lassen und über die
        Berichtigung informiert zu werden. Wir informieren in diesem Fall die
        Empfänger der betroffenen Daten über die vorgenommenen Anpassungen,
        sofern dies nicht unmöglich oder mit unverhältnismässigem Aufwand
        verbunden ist. <br /><br />
        <b>Recht auf Löschung:</b> Sie haben das Recht, dass Ihre
        personenbezogenen Daten unter bestimmten Umständen gelöscht werden. Im
        Einzelfall kann das Recht auf Löschung ausgeschlossen sein. <br /><br />
        <b>Recht auf Einschränkung der Verarbeitung:</b> Sie haben unter
        bestimmten Voraussetzungen das Recht, zu verlangen, dass die
        Verarbeitung Ihrer personenbezogenen Daten eingeschränkt wird.
        <br /><br />
        <b>Recht auf Datenübertragung:</b> Sie unter bestimmten Umständen das
        Recht, von uns die personenbezogenen Daten, welche Sie uns
        bereitgestellt haben, unentgeltlich in einem lesbaren Format zu
        erhalten. <br /><br />
        <b>Recht auf Beschwerde bei einer Aufsichtsbehörde:</b> Sofern Sie Ihren
        Wohnsitz in einem EU oder EWR Mitgliedstaat haben, haben Sie das Recht,
        bei einer zuständigen Aufsichtsbehörde gegen die Art und Weise der
        Verarbeitung Ihrer personenbezogenen Daten Beschwerde einzureichen.
        <br /><br />
        <b>Widerrufsrecht:</b> Sie haben grundsätzlich das Recht, eine erteilte
        Einwilligung jederzeit zu widerrufen. In der Vergangenheit auf Ihre
        Einwilligung gestützte Verarbeitungstätigkeiten werden durch Ihren
        Widerruf allerdings nicht unrechtmässig.
        <h2>
          13. Aufbewahrungsfristen
        </h2>
        Wir speichern persönliche Daten nur so lange wie nötig, um:
        <ul>
          <li>
            Ihnen Dienstleistungen zu erbringen, die Sie angefordert haben oder
            für Zwecke, zu denen Sie Ihre Zustimmung gegeben haben, und/oder
          </li>
          <li>
            im Rahmen unserer berechtigten Interessen die oben genannten
            Tracking-, Werbe- und Analysedienste zu ermöglichen.
          </li>
        </ul>
        Bitte beachten Sie, dass für bestimmte Daten besondere gesetzliche
        Aufbewahrungsfristen gelten können. Diese Daten müssen von uns bis zum
        Ende der Aufbewahrungsfrist gespeichert werden. Danach müssen z.B.
        Geschäftskommunikation oder abgeschlossene Verträge bis zu 10 Jahre
        aufbewahrt werden. Wir sperren solche Daten in unserem System und
        verwenden sie ausschliesslich zur Erfüllung unserer gesetzlichen
        Verpflichtungen.
        <h2>
          14. Datensicherheit
        </h2>
        Wir bedienen uns geeigneter technischer und organisatorischer
        Sicherheitsmassnahmen, um Ihre bei uns gespeicherten personenbezogenen
        Daten gegen Manipulation, teilweisen oder vollständigen Verlust und
        gegen unbefugten Zugriff Dritter zu schützen. Unsere
        Sicherheitsmassnahmen werden entsprechend der technologischen
        Entwicklung fortlaufend angepasst.
        <br /><br />
        Auch den unternehmensinternen Datenschutz nehmen wir sehr ernst. Unsere
        Mitarbeiter und die von uns beauftragten Dienstleistungsunternehmen sind
        von uns zur Verschwiegenheit und zur Einhaltung der
        datenschutzrechtlichen Bestimmungen verpflichtet. Überdies wird diesen
        der Zugriff auf die personenbezogenen Daten nur soweit notwendig
        gewährt.
        <h2>
          15. Kontakt
        </h2>
        Wenn Sie Fragen zum Datenschutz haben, wenn Sie Informationen wünschen
        oder die Löschung Ihrer persönlichen Daten beantragen möchten, wenden
        Sie sich bitte per E-Mail an admin@immotrack.com oder per Post an die
        folgende Adresse.
        <br /><br />
        ImmoTrack AG
        <br /><br />
        Grabenstrasse 25
        <br /><br />
        CH-6340 Baar
        <br /><br />
        Stand: März 2021
      </div>
    </div>
  </PageLayout>
</template>

<script>
import PageLayout from "@/layouts/PageLayout";

export default {
  page: {
    title: "Privacy",
    meta: [{ name: "description", content: "The Privacy page." }]
  },
  components: { PageLayout }
};
</script>

<style lang="scss" module>
.privacy-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
}
</style>
