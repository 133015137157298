const Modal = {
  install(Vue) {
    this.EventBus = new Vue();

    Vue.prototype.$modal = {
      show(name) {
        return new Promise(resolve => {
          Modal.EventBus.$emit("show", {
            name: name,
            resolve: resolve
          });
        });
      },
      hide(name) {
        return new Promise(resolve => {
          Modal.EventBus.$emit("hide", {
            name: name,
            resolve: resolve
          });
        });
      }
    };
  }
};

export default Modal;
