<template>
  <div :class="$style['auth-reset-password-form-wrapper']">
    <base-field label="Email" v-if="success === undefined || success === false">
      <base-input name="email" type="text" v-model="email" disabled />
    </base-field>
    <base-form
      name="reset-password"
      :form="form"
      @submit="onSubmit"
      v-if="success === undefined || success === false"
    >
      <base-field
        label="Password"
        :errors="form.errors.get('password')"
        required
      >
        <base-input name="password" type="password" v-model="form.password" />
      </base-field>
      <base-field
        label="Re-type password"
        :errors="form.errors.get('password_confirmation')"
        required
      >
        <base-input
          name="password_confirmation"
          type="password"
          v-model="form.password_confirmation"
        />
      </base-field>
      <base-button
        :loading="form.loading"
        :disabled="form.loading || form.errors.any()"
        @click="onSubmit"
      >
        Update password
      </base-button>
    </base-form>
    <p :class="$style.success" v-if="success">
      Password was successfuly updated.
    </p>
  </div>
</template>

<script>
import Form from "@/services/form/Form.js";
import { resetPassword } from "@/api/repositories/auth";

import BaseForm from "@/components/form/BaseForm";
import BaseField from "@/components/form/BaseField";
import BaseInput from "@/components/form/BaseInput";
import BaseButton from "@/components/form/BaseButton";

export default {
  name: "AuthResetPasswordForm",
  data() {
    return {
      success: undefined,
      form: new Form(
        {
          password: "",
          password_confirmation: ""
        },
        {
          password: {
            presence: { allowEmpty: false },
            format: {
              pattern: /^(?=.*[a-z])(?=.*[A-Z]).{6,}$/,
              message: "must be at least 6 characters, lowercase and uppercase"
            }
          },
          password_confirmation: {
            presence: { allowEmpty: false },
            equality: "password"
          }
        }
      )
    };
  },
  components: { BaseForm, BaseField, BaseButton, BaseInput },
  props: {
    token: {
      type: String,
      default: ""
    },
    email: {
      type: String,
      default: ""
    }
  },
  computed: {},
  methods: {
    onSubmit() {
      this.success = undefined;
      this.form
        .submit(data => {
          return new Promise((resolve, reject) => {
            const otherData = {
              token: this.token,
              email: this.email
            };
            resetPassword({ ...data, ...otherData })
              .then(response => resolve(response))
              .catch(error => {
                if (error.status == 422) {
                  reject(error.fields);
                } else {
                  reject(error);
                }
              });
          });
        })
        .then(() => {
          this.success = true;
          setTimeout(() => {
            this.$router.push("login");
          }, 3000);
        })
        .catch(() => {
          this.success = false;
        });
    }
  }
};
</script>

<style lang="scss" module>
.auth-reset-password-form-wrapper {
  .success {
    margin-top: 20px;
  }
}
</style>
