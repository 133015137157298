<template>
  <div :class="$style['tabs-wrapper']">
    <AntTabBar>
      <AntTabPane v-for="tab in tabs" :key="tab.label" :tab="tab.label">
        <slot :name="tab.label"></slot>
      </AntTabPane>
    </AntTabBar>
  </div>
</template>

<script>
import { Tabs as AntTabBar } from "ant-design-vue";
import { TabPane as AntTabPane } from "ant-design-vue/lib/tabs";
import "ant-design-vue/lib/tabs/style/css";
export default {
  name: "Tabs",
  components: { AntTabBar, AntTabPane },
  props: {
    tabs: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  computed: {},
  methods: {}
};
</script>

<style lang="scss" module>
.tabs-wrapper {
}
</style>
