import * as repositoryUsers from "@/api/repositories/users";

export const users = {
  state: {
    users: []
  },
  getters: {
    user: state => userId => state.users.find(user => user.id == userId)
  },
  mutations: {
    SET_USERS(state, profiles) {
      state.users = profiles.map(profile => {
        return {
          id: profile.user.id,
          email: profile.user.email,
          active: profile.user.active,
          profile: {
            id: profile.id,
            first_name: profile.first_name,
            last_name: profile.last_name
          }
        };
      });
    },
    ADD_USER(state, user) {
      state.users.push(user);
    },
    REMOVE_USERS(state, usersId) {
      usersId.forEach(id => {
        const userIndex = state.users.findIndex(user => id == user.id);
        state.users.splice(userIndex, 1);
      });
    },
    UPDATE_USER(state, userUpdated) {
      const userIndex = state.users.findIndex(
        user => user.id == userUpdated.id
      );
      state.users.splice(userIndex, 1, userUpdated);
    },
    RESET(state) {
      state.users = [];
    }
  },
  actions: {
    initializeUsers({ commit, rootGetters }) {
      return repositoryUsers
        .fetchAllProfiles(rootGetters["auth/user"].profile.company.id)
        .then(profiles => {
          commit("SET_USERS", profiles);
          return users;
        });
    },
    createUser({ commit }, data) {
      return repositoryUsers.createUser(data).then(user => {
        commit("ADD_USER", user);
        return user;
      });
    },
    updateUser({ commit }, data) {
      console.log(data);
      return repositoryUsers.updateUser(data.id, data.data).then(user => {
        commit("UPDATE_USER", user);
        return user;
      });
    },
    removeUser({ commit }, userId) {
      return repositoryUsers.deleteUser(userId).then(() => {
        commit("REMOVE_USERS", [userId]);
      });
    },
    removeUsers({ commit }, usersId) {
      return repositoryUsers.deleteUsers(usersId).then(() => {
        commit("REMOVE_USERS", usersId);
      });
    },
    reset({ commit }) {
      commit("RESET");
    }
  }
};
