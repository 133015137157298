<template>
  <div class="build-modal-error-handle-information">
    {{ $t("errorWasHandledByCommiting") }}
    {{
      errorHandleAccept
        ? $t("workersTimeWindowsSuggestionAccept")
        : $t("taskDrops")
    }}
    {{ $t("strategy") }}
    <BaseTable
      v-if="errorHandleAccept"
      :columns="workersColumns"
      :data="errorHandleAccept"
      disableSelection
    />
    <BaseTable
      v-if="errorHandleTaskDrops"
      :columns="tasksColumns"
      :data="errorHandleTaskDrops"
      disableSelection
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import BaseIcon from "@/components/ui/BaseIcon";
import BaseTable from "@/components/table/BaseTable";

import BasePicCircle from "@/components/ui/BasePicCircle";

export default {
  name: "BuildModalErrorHandleInformation",
  data() {
    return {
      workersColumns: [
        {
          title: this.$i18n.t("name"),
          width: "30%",
          customRender: accept => {
            return this.$createElement(BasePicCircle, {
              scopedSlots: {
                default: () =>
                  this.$createElement(BaseIcon, {
                    props: {
                      name: "task",
                      fill: "#ffffff"
                    }
                  })
              },
              props: {
                label: accept.worker.first_name + " " + accept.worker.last_name,
                labeled: true
              }
            });
          }
        },
        {
          title: this.$i18n.t("oldWorkingHours"),
          width: "35%",
          customRender: accept => {
            return accept.timeWindowOld[0] + " - " + accept.timeWindowOld[1];
          }
        },
        {
          title: this.$i18n.t("newWorkingHours"),
          width: "35%",
          customRender: accept => {
            return accept.timeWindowNew[0] + " - " + accept.timeWindowNew[1];
          }
        }
      ],
      tasksColumns: [
        {
          title: this.$i18n.t("location"),
          width: "50%",
          customRender: taskDrop => {
            return taskDrop.location;
          }
        },
        {
          title: this.$i18n.t("taskType"),
          width: "25%",
          customRender: taskDrop => {
            return this.$createElement(BasePicCircle, {
              scopedSlots: {
                default: () =>
                  this.$createElement(BaseIcon, {
                    props: {
                      name: "task",
                      fill: "#ffffff"
                    }
                  })
              },
              props: {
                label: taskDrop.task,
                labeled: true
              }
            });
          }
        },
        {
          title: this.$i18n.t("duration"),
          customRender: taskDrop => {
            return taskDrop.duration + " min";
          }
        }
      ]
    };
  },
  components: { BaseTable },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters("builds", ["worker", "task", "taskGroup"]),
    errorData() {
      return this.data["dayPlan"]["error-handle"].data["error_data"];
    },
    errorHandleAccept() {
      return this.data["dayPlan"]["error-handle"].data["accept_suggestion"]
        ? this.errorData.solution["time_windows_updates"]
            .filter(timeWindowUpdate => timeWindowUpdate["worker_id"])
            .map(timeWindowUpdate => {
              const workerHandled = this.errorData.workers.find(
                worker => worker.worker_id == timeWindowUpdate["worker_id"]
              );
              return {
                worker: this.worker(timeWindowUpdate["worker_id"]),
                timeWindowNew: timeWindowUpdate["time_window"],
                timeWindowOld: workerHandled["time-window"]
              };
            })
        : false;
    },
    errorHandleTaskDrops() {
      return this.data["dayPlan"]["error-handle"].data["tasks_drops"]
        ? this.data["dayPlan"]["error-handle"].data["tasks_drops"].map(
            (task, index) => {
              return {
                id: index,
                task: this.task(task.id).group.name,
                location: this.task(task.id).location.position.address,
                duration: this.task(task.id).duration
              };
            }
          )
        : false;
    }
  },
  methods: {}
};
</script>

<style lang="scss">
.build-modal-error-handle-information {
}
</style>
