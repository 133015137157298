<template>
  <div :class="$style['worker-schedule-working-hours-input-wrapper']">
    <TimePicker v-model="startTime" />
    <span :class="$style['hours']">{{ workingHours }} h</span>
    <TimePicker v-model="endTime" disabled />
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "WorkerScheduleWorkingHoursInput",
  data() {
    return {};
  },
  components: {},
  props: {
    value: {
      type: String,
      default: "08:00:00"
    },
    workingHoursPerWeek: {
      type: Number,
      required: true
    },
    percentageOfEmployment: {
      type: Number,
      required: true
    },
    reservedTime: {
      type: [String, Number],
      required: true
    },
    activeDaysInWeek: {
      type: Object,
      required: true
    },
    scheduleBreaks: {
      type: Array,
      required: true
    }
  },
  computed: {
    startTime: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    },
    workingMinutesPerDay() {
      const workingTime =
        (((this.workingHoursPerWeek /
          Object.entries(this.activeDaysInWeek).filter(day => day[1]).length) *
          this.percentageOfEmployment) /
          100) *
        60;
      return (
        workingTime -
        (this.reservedTime > workingTime ? workingTime : this.reservedTime)
      );
    },
    scheduleBreaksAdditionalTime() {
      let scheduleBreaksAdditionalTime = 0;
      const startMoment = moment(this.startTime, "HH:mm");
      const endMoment = startMoment
        .clone()
        .add(this.workingMinutesPerDay, "minutes");

      this.scheduleBreaks.forEach(scheduleBreak => {
        const scheduleBreakFrom = moment(scheduleBreak.from, "HH:mm");
        const scheduleBreakTo = moment(scheduleBreak.to, "HH:mm");

        if (
          scheduleBreakFrom.isAfter(endMoment) ||
          scheduleBreakTo.isBefore(startMoment)
        ) {
          return;
        }
        var duration = moment
          .duration(
            scheduleBreakTo.diff(
              scheduleBreakFrom.isBefore(startMoment)
                ? startMoment
                : scheduleBreakFrom
            )
          )
          .asMinutes();

        scheduleBreaksAdditionalTime = scheduleBreaksAdditionalTime + duration;
      });
      return scheduleBreaksAdditionalTime;
    },
    endTime() {
      const startMoment = moment(this.startTime, "HH:mm");
      const endMoment = startMoment
        .clone()
        .add(
          this.workingMinutesPerDay + this.scheduleBreaksAdditionalTime,
          "minutes"
        );
      return endMoment.day() == startMoment.day()
        ? endMoment.format("HH:mm")
        : "23:59";
    },
    workingHours() {
      const startMoment = moment(this.startTime, "HH:mm");
      const endMoment = startMoment
        .clone()
        .add(this.workingMinutesPerDay, "minutes");
      var duration = moment.duration(
        (endMoment.day() == startMoment.day()
          ? endMoment
          : moment("23:59", "HH:mm")
        ).diff(startMoment)
      );
      return +(
        Math.round(
          (this.endTime == "23:59"
            ? duration.asMinutes() - this.scheduleBreaksAdditionalTime
            : duration.asMinutes()) /
            60 +
            "e+1"
        ) + "e-1"
      );
    }
  },
  methods: {}
};
</script>

<style lang="scss" module>
.worker-schedule-working-hours-input-wrapper {
  width: 100%;
  display: flex;
  .hours {
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
