import moment from "moment";

export default class Visit {
  #routePoints;

  constructor(routePoints) {
    this.#routePoints = routePoints.sort((a, b) => {
      const prev = moment(a.getStartTimeMarker(), "hh:mm:ss");
      const next = moment(b.getStartTimeMarker(), "hh:mm:ss");
      return prev.isBefore(next) ? -1 : 1;
    });
  }

  getRoutePoints() {
    return this.#routePoints;
  }

  getFirstRoutePoint() {
    return this.#routePoints[0];
  }

  getLastRoutePoint() {
    return this.#routePoints[this.#routePoints.length - 1];
  }

  getStartTimeMarker() {
    return this.getFirstRoutePoint().getStartTimeMarker();
  }

  getEndTimeMarker() {
    return this.getLastRoutePoint().getEndTimeMarker();
  }

  isHome() {
    return this.getFirstRoutePoint().isHome();
  }
}
