<template>
  <div :class="$style['header-table-content-wrapper']">
    <Button
      v-if="location"
      :class="$style['location-indicator']"
      type="dashed"
      @click="$emit('locationCancel')"
    >
      <span>{{ location }}</span>
    </Button>
    <div :class="$style.actions">
      <div
        @click="searchVisible = !searchVisible"
        v-if="!searchVisible"
        :class="$style.search"
      >
        <Icon name="search" fill="#1c2a4a" :height="15" />
        <span>{{ $t("search") }}</span>
      </div>
      <div v-if="searchVisible" :class="$style.search">
        <Input v-model="searchQuery" />
        <Icon
          @click="
            searchVisible = !searchVisible;
            searchQuery = '';
          "
          name="cross"
          fill="#1c2a4a"
          :height="15"
        />
      </div>
    </div>
    <Button :class="$style.action" @click="add"
      >{{ $t("add") }} {{ entity }}</Button
    >
  </div>
</template>

<script>
import Input from "@/components/ui/Input";

export default {
  name: "HeaderTableContent",
  components: { Input },
  props: {
    entity: {
      type: String
    },
    location: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      searchQuery: "",
      searchVisible: false
    };
  },
  computed: {},
  methods: {
    add() {
      this.$emit("add");
    }
  },
  watch: {
    searchQuery(query) {
      this.$emit("search", query);
    }
  }
};
</script>

<style lang="scss" module>
.header-table-content-wrapper {
  width: fit-content;
  display: flex;
  float: right;
  .action,
  .location-indicator {
    width: fit-content;
  }
  .actions {
    margin-right: 20px;
    display: flex;
  }
  .actions div {
    padding: 0 10px 0 10px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    span {
      margin-left: 11px;
    }
    float: left;
  }
}
</style>
