import { listenToConfigurationNotifications } from "@/services/socket";
import * as repositoryConfiguration from "@/api/repositories/configurations";
import * as repositorySetup from "@/api/repositories/setup";

export const configurations = {
  state: {
    initializing: false,
    configurations: [],
    activeConfigurationId:
      localStorage.getItem("configuration_id") || undefined,

    weeklyWorkingHours: 0,
    defaultWorkingDays: null,
    taskGroups: [],
    skills: [],
    checkpoints: []
  },
  getters: {
    initialized: state => state.configurations.length > 0,

    configuration: state => configurationId =>
      state.configurations.find(
        configuration => configuration.id == configurationId
      ),
    activeConfiguration: state =>
      state.configurations.find(
        configuration => configuration.id == state.activeConfigurationId
      ),
    activeSetupId: state => {
      const configuration = state.configurations.find(
        configuration => configuration.id == state.activeConfigurationId
      );

      return configuration ? configuration.setup.id : undefined;
    },

    taskGroup: state => taskGroupId =>
      state.taskGroups.find(taskGroup => taskGroup.id == taskGroupId),
    skill: state => skillId => state.skills.find(skill => skill.id == skillId),
    checkpoint: state => checkpointId =>
      state.checkpoints.find(checkpoint => checkpoint.id == checkpointId)
  },
  mutations: {
    SET_INITIALIZE_STATUS(state, status) {
      state.initializing = status;
    },
    SET_CONFIGURATIONS(state, configurations) {
      state.configurations = configurations;
    },
    SET_ACTIVE_CONFIGURATION(state, configurationId) {
      state.activeConfigurationId = configurationId;
      localStorage.setItem("configuration_id", configurationId);
    },
    SET_SETUP(state, setup) {
      state.weeklyWorkingHours = setup["weekly_working_hours"];
      state.defaultWorkingDays = setup["default_working_days"];
      state.taskGroups = setup["task-groups"];
      state.skills = setup.skills;
      state.checkpoints = setup.checkpoints;
    },
    SET_WEEKLY_WORKING_HOURS(state, weeklyWorkingHours) {
      state.weeklyWorkingHours = weeklyWorkingHours;
    },
    SET_DEFAULT_WORKING_DAYS(state, defaultWorkingDays) {
      state.defaultWorkingDays = defaultWorkingDays;
    },
    ADD_TASK_GROUP(state, taskGroup) {
      state.taskGroups.push(taskGroup);
    },
    REMOVE_TASK_GROUP(state, taskGroupId) {
      const taskGroupIndex = state.taskGroups.findIndex(
        taskGroup => taskGroup.id == taskGroupId
      );
      state.taskGroups.splice(taskGroupIndex, 1);
    },
    ADD_SKILL(state, skill) {
      state.skills.push(skill);
    },
    REMOVE_SKILL(state, skillId) {
      const skillIndex = state.skills.findIndex(skill => skill.id == skillId);
      state.skills.splice(skillIndex, 1);
    },
    ADD_CHECKPOINT(state, checkpoint) {
      state.checkpoints.push(checkpoint);
    },
    REMOVE_CHECKPOINT(state, checkpointId) {
      const checkpointIndex = state.checkpoints.findIndex(
        checkpoint => checkpoint.id == checkpointId
      );
      state.checkpoints.splice(checkpointIndex, 1);
    },
    RESET(state) {
      state.initializing = false;
      state.configurations = [];
      state.activeConfigurationId = undefined;
      state.weeklyWorkingHours = 0;
      state.taskGroups = [];
      state.skills = [];
      state.checkpoints = [];

      localStorage.removeItem("configuration_id");
    }
  },
  actions: {
    setInitializeStatus({ commit }, status) {
      commit("SET_INITIALIZE_STATUS", status);
    },
    initializeConfigurations({ commit }) {
      return new Promise(resolve => {
        repositoryConfiguration.fetchAll().then(configurations => {
          commit("SET_CONFIGURATIONS", configurations);
          resolve(configurations);
        });
      });
    },
    setActiveConfiguration({ commit, getters }, configurationId) {
      return new Promise(resolve => {
        const configurationSetup = getters.configuration(configurationId).setup;
        if (configurationSetup) {
          repositorySetup.fetchById(configurationSetup.id).then(setup => {
            commit("SET_ACTIVE_CONFIGURATION", configurationId);
            commit("SET_SETUP", setup);
            listenToConfigurationNotifications(configurationId);
            resolve();
          });
        } else {
          resolve();
        }
      });
    },
    updateWeeklyWorkingHours({ commit, getters }, weeklyWorkingHours) {
      return repositorySetup
        .update(getters.activeSetupId, {
          weekly_working_hours: weeklyWorkingHours
        })
        .then(() => {
          commit("SET_WEEKLY_WORKING_HOURS", weeklyWorkingHours);
        });
    },
    updateDefaultWorkingDays({ commit, getters }, defaultWorkingDays) {
      return repositorySetup
        .update(getters.activeSetupId, {
          default_working_days: [defaultWorkingDays]
        })
        .then(() => {
          commit("SET_DEFAULT_WORKING_DAYS", [defaultWorkingDays]);
        });
    },
    createTaskGroup({ commit, getters }, taskGroupName) {
      return repositorySetup
        .createTaskGroup(getters.activeSetupId, {
          name: taskGroupName
        })
        .then(response => {
          commit("ADD_TASK_GROUP", {
            id: response.id,
            name: response.name
          });
        });
    },
    removeTaskGroup({ commit, dispatch }, taskGroupId) {
      return repositorySetup.removeTaskGroup(taskGroupId).then(() => {
        commit("REMOVE_TASK_GROUP", taskGroupId);
        dispatch("tasks/removeGroup", taskGroupId, { root: true });
      });
    },
    createSkill({ commit, getters }, skillName) {
      return repositorySetup
        .createSkill(getters.activeSetupId, {
          name: skillName
        })
        .then(response => {
          commit("ADD_SKILL", {
            id: response.id,
            name: response.name
          });
        });
    },
    removeSkill({ commit, dispatch }, skillId) {
      return repositorySetup.removeSkill(skillId).then(() => {
        commit("REMOVE_SKILL", skillId);
        dispatch("tasks/removeSkill", skillId, { root: true });
        dispatch("workers/removeSkill", skillId, { root: true });
      });
    },
    createCheckpoint({ commit, getters }, checkpointName) {
      return repositorySetup
        .createCheckpoint(getters.activeSetupId, { name: checkpointName })
        .then(response => {
          commit("ADD_CHECKPOINT", { id: response.id, name: response.name });
        });
    },
    removeCheckpoint({ commit, dispatch }, checkpointId) {
      return repositorySetup.removeCheckpoint(checkpointId).then(() => {
        commit("REMOVE_CHECKPOINT", checkpointId);
        dispatch("tasks/removeCheckpoint", checkpointId, { root: true });
      });
    },
    reset({ commit }) {
      commit("RESET");
    }
  }
};
