<template>
  <div :class="$style['visit-wrapper']">
    <div :class="{ [$style.details]: true, [$style.detailed]: detailed }">
      <PicCircle
        v-if="visit.isHome()"
        :fill="worker.getColor()"
        :label="worker.getName()"
        helper="initials"
      />
      <Icon
        v-else
        name="route-point"
        :fill="worker.getColor()"
        :height="30"
        :width="30"
      />
      <div :class="$style.text" v-show="detailed">
        <span :class="$style.name">{{
          visit.getFirstRoutePoint().getLocationDescription()
        }}</span>
        <span :class="$style.address">{{
          visit.isHome()
            ? worker.getHome().address
            : visit.getFirstRoutePoint().getAddress()
        }}</span>
        <span :class="$style.gps">{{
          visit.isHome()
            ? worker.getHome().longitude + " " + worker.getHome().latitude
            : visit.getFirstRoutePoint().getGps().longitude +
              " " +
              visit.getFirstRoutePoint().getGps().latitude
        }}</span>
      </div>
    </div>
    <div
      v-for="routePoint in visit.getRoutePoints()"
      :key="routePoint.getId()"
      :class="{ [$style['route-point']]: true, [$style['detailed']]: detailed }"
    >
      <div :class="$style['time-marker']" v-show="detailed">
        {{ routePoint.getStartTimeMarker() }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Visit",
  data() {
    return {};
  },
  components: {},
  props: {
    visit: {
      type: Object,
      required: true
    },
    detailed: {
      type: Boolean,
      default: false
    },
    worker: {
      type: Object,
      require: true
    }
  },
  computed: {},
  methods: {}
};
</script>

<style lang="scss" module>
.visit-wrapper {
  width: fit-content;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  .route-point {
    margin-top: 5px;
    width: fit-content;
    height: auto;
    padding: 5px;
    border-radius: 5px;
    display: flex;
    .time-marker {
      font-size: 13px;
      font-weight: bold;
    }
  }
  .details {
    padding: 5px;
    border-radius: 5px;
    display: flex;
    width: 300px;
    .text {
      text-align: left;
      padding-left: 5px;
      display: flex;
      flex-direction: column;
      .name {
        width: 100%;
        font-size: 15px;
        font-weight: bold;
      }
      .address {
        width: 100%;
        font-size: 12px;
      }
      .gps {
        width: 100%;
        font-size: 11px;
        opacity: 0.6;
      }
    }
  }
  .detailed {
    background: #ffffff;
  }
}
</style>
