import Locations from "@/views/Locations.vue";
import Workers from "@/views/Workers.vue";
import Tasks from "@/views/Tasks.vue";
import Settings from "@/views/Settings.vue";
import Build from "@/views/Build.vue";
import BuildCreation from "@/views/BuildCreation.vue";
import Users from "@/views/Users.vue";

const routes = [
  {
    path: "/dashboard/route-plan/:id?",
    name: "route_plan",
    component: Build,
    props: true
  },
  {
    path: "/dashboard/route-plan-creation",
    name: "route_plan_creation",
    component: BuildCreation
  },
  {
    path: "/dashboard/locations",
    name: "locations",
    component: Locations
  },
  {
    path: "/dashboard/workers",
    name: "workers",
    component: Workers
  },
  {
    path: "/dashboard/tasks/:locationId?",
    name: "tasks",
    component: Tasks,
    props: true
  },
  {
    path: "/dashboard/configuration",
    name: "configuration",
    component: Settings
  },
  {
    path: "/dashboard/users",
    name: "users",
    component: Users,
    onlyAdmin: true
  }
];

export default routes.map(route => {
  return { ...route, meta: { public: false, onlyAdmin: route.onlyAdmin } };
});
