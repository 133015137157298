<template>
  <div class="worker-schedule-breaks">
    <div class="worker-schedule-breaks__add-break-button" @click="addBreak">
      {{ $t("addBreak") }}
    </div>
    <div class="worker-schedule-breaks__message" v-if="hasMessage">
      {{ message }}
    </div>
    <div class="worker-schedule-breaks__breaks" v-if="hasBreaks">
      <div
        :class="{
          breaks__break: true
        }"
        v-for="(scheduleBreak, index) in scheduleBreaks"
        :key="index"
      >
        <div class="break__footer">
          <div class="footer__delete-button" @click="deleteBreak(index)">
            {{ $t("deleteBreak") }} ✕
          </div>
        </div>
        <BaseDayTimeIntervalsWithHoursCounter
          v-model="scheduleBreaks[index]"
          :class="{
            breaks__break_interscepted:
              hasBreaksInterceptions &&
              hasBreaksInterceptions.find(b => b == index) !== undefined
          }"
          @update="breaksWithIdUpdated(index)"
        />
      </div>
    </div>
    <div class="worker-schedule-breaks__no-breaks" v-else>
      {{ $t("workerHasNoBreaks") }}
    </div>
  </div>
</template>

<script>
import BaseDayTimeIntervalsWithHoursCounter from "@/components/BaseDayTimeIntervalsWithHoursCounter";

export default {
  name: "WorkerScheduleBreaks",
  data() {
    return {
      scheduleBreaks: [],
      message: "",
      timer: null,
      breaksCountLimit: 4
    };
  },
  components: { BaseDayTimeIntervalsWithHoursCounter },
  props: {
    value: {
      type: Object,
      default: () => {
        return {
          validation: true,
          breaks: [],
          validBreaks: []
        };
      }
    },
    name: {
      type: String,
      default: "default"
    }
  },
  computed: {
    hasBreaks() {
      return this.scheduleBreaks.length > 0;
    },
    hasMessage() {
      return this.message.length > 0;
    },
    breaksCountLimitReached() {
      return this.scheduleBreaks.length == this.breaksCountLimit;
    },
    hasBreaksInterceptions() {
      const timeSegments = this.scheduleBreaks;
      if (timeSegments.length === 1) return false;

      // Sorting original this.scheduleBreaks too each update
      timeSegments.sort((timeSegment1, timeSegment2) =>
        timeSegment1["from"].localeCompare(timeSegment2["from"])
      );

      let intersections = [];
      for (let i = 0; i < timeSegments.length - 1; i++) {
        const currentEndTime = timeSegments[i]["to"];
        const nextStartTime = timeSegments[i + 1]["from"];

        if (currentEndTime > nextStartTime) {
          intersections.push(i);
          intersections.push(i + 1);
        }
      }

      return intersections.length > 0 ? [...new Set(intersections)] : false;
    },
    result() {
      return {
        validation: !this.hasBreaksInterceptions,
        breaks: this.scheduleBreaks,
        validBreaks: this.scheduleBreaks.filter((scheduleBreak, index) => {
          return (
            !this.hasBreaksInterceptions ||
            this.hasBreaksInterceptions.find(
              interceptionBreak => interceptionBreak === index
            ) == undefined
          );
        })
      };
    }
  },
  methods: {
    addBreak() {
      if (this.breaksCountLimitReached) {
        this.showMessage(
          this.$i18n.t("breaksLimitReached", { limit: this.breaksCountLimit })
        );
        return;
      }
      this.scheduleBreaks.push({
        from: "11:00",
        to: "12:00"
      });
      this.$emit("input", this.result);
    },
    deleteBreak(index) {
      this.scheduleBreaks.splice(index, 1);
      this.$emit("input", this.result);
    },
    showMessage(message) {
      this.message = message;
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.message = "";
      }, 5000);
    },
    breaksWithIdUpdated() {
      this.$emit("input", this.result);
    }
  },
  mounted() {
    this.scheduleBreaks = this.value.breaks;
    this.$emit("input", this.result);
  },
  watch: {
    "value.breaks"(breaks) {
      this.scheduleBreaks = breaks;
    }
  }
};
</script>
{

<style lang="scss">
.worker-schedule-breaks {
  width: 100%;
  min-width: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 10px;
}
.worker-schedule-breaks__add-break-button {
  width: 100%;
  height: 30px;
  background: none;
  border: 1px solid $primary-blue;
  border-radius: 5px;
  color: $primary-blue;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.worker-schedule-breaks__breaks {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.breaks__break {
  padding: 2px;
}

.breaks__break_interscepted {
  border-radius: 5px;
  border: 1px dashed $orange;
}

.break__footer {
  width: 100%;
  display: flex;
  justify-content: right;
}

.footer__delete-button {
  width: fit-content;
  font-size: 11px;
  color: $status_red;
  cursor: pointer;
}

.worker-schedule-breaks__message {
  color: $orange;
  font-size: 11px;
}
</style>
