<template>
  <div :class="$style['skipped-optimization-popup-wrapper']">
    <h2 :class="$style.title">{{ $t("optimizationSkipped") }}</h2>
    <p :class="$style.sub">
      {{ $t("optimizationSkippedDescription") }}
    </p>
  </div>
</template>

<script>
export default {
  name: "SkippedOptimizationErrorPopup",
  data() {
    return {};
  },
  components: {},
  props: {
    day: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  computed: {},
  methods: {}
};
</script>

<style lang="scss" module>
.skipped-optimization-popup-wrapper {
  color: $primary-black;
  text-align: center;
  .title {
    font-weight: 600;
    font-size: 24px;
    line-height: 25px;
    margin-bottom: 4px;
  }
  .date {
    font-size: 16px;
    line-height: 25px;
    margin-bottom: 21px;
  }
  .task-wrapper {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  .sub {
    font-weight: 600;
    font-size: 16px;
    line-height: 25px;
  }
}
</style>
