<template>
  <div :class="$style['two-column-grid-wrapper']">
    <AntRow :gutter="24" type="flex" justify="space-between">
      <AntCol :span="12">
        <slot name="left-side"></slot>
      </AntCol>
      <AntCol :span="12">
        <slot name="right-side"></slot>
      </AntCol>
    </AntRow>
  </div>
</template>

<script>
import { Row as AntRow, Col as AntCol } from "ant-design-vue";
import "ant-design-vue/lib/row/style/css";
import "ant-design-vue/lib/col/style/css";

export default {
  name: "TwoColumnGrid",
  components: { AntRow, AntCol },
  props: {},
  computed: {},
  methods: {}
};
</script>

<style lang="scss" module>
.two-column-grid-wrapper {
}
</style>
