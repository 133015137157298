<template>
  <div class="base-checkbox">
    <AntCheckbox v-model="model"><slot /></AntCheckbox>
  </div>
</template>

<script>
import { Checkbox as AntCheckbox } from "ant-design-vue";

export default {
  name: "BaseCheckbox",
  data() {
    return {};
  },
  components: { AntCheckbox },
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    }
  },
  methods: {}
};
</script>

<style lang="scss">
.base-checkbox {
}
</style>
