import { jsonApi } from "@/api";
import { writeExpression } from "@/utils/cron.js";

export const fetchAllWorkers = setupId => {
  return jsonApi.index("setups/" + setupId + "/workers", {
    include: "schedules,custom-days,skills"
  });
};

export const createWorker = (setupId, data) => {
  let payload = {
    first_name: data.first_name,
    last_name: data.last_name,
    email: data.email,
    phone: data.phone,
    address: data.address,
    latitude: data.gps.latitude,
    longitude: data.gps.longitude,
    // Remove active field
    active: true
  };
  let relations = [];

  if (data.skills) {
    payload.skills = data.skills.map(skill => {
      return { id: skill.id };
    });
    relations.push("skills");
  }

  payload.setup = {
    id: setupId,
    type: "setups"
  };
  relations.push("setup");

  return jsonApi.store("workers", payload, relations, {
    include: "schedules,custom-days,skills"
  });
};

export const updateWorker = (workerId, data) => {
  let payload = {
    first_name: data.first_name,
    last_name: data.last_name,
    email: data.email,
    phone: data.phone,
    address: data.address,
    latitude: data.gps.latitude,
    longitude: data.gps.longitude,
    // Remove active field
    active: true
  };
  let relations = [];

  if (data.skills) {
    payload.skills = data.skills.map(skill => {
      return { id: skill.id };
    });
    relations.push("skills");
  }

  return jsonApi.update("workers", workerId, payload, relations, {
    include: "schedules,custom-days,skills"
  });
};

export const deleteWorker = taskId => {
  return jsonApi.remove("workers", taskId);
};

export const deleteWorkers = tasksId => {
  return jsonApi.bulkDelete("workers", tasksId);
};

export const updateWorkersCustomDays = (workersId, data) => {
  return jsonApi.bulkUpdate("workers/bulk/custom-days", workersId, data, {
    include: "custom-days"
  });
};

export const createWorkerSchedule = (workerId, data) => {
  const payload = {
    title: data.title,
    employment_percentage: parseInt(data.employmentPercentage),
    weekly_working_hours: parseInt(data.weekly_working_hours),
    breaks: data.breaks.breaks.length > 0 ? data.breaks.breaks : null,
    start_of_working_day: data.start_of_working_day,
    reserved_time: parseInt(data.reservedTime),
    expression: writeExpression("weekly", 1, data.weekdays),
    from: data.dates[0],
    to: data.dates[1],
    worker: {
      type: "workers",
      id: workerId
    }
  };

  return jsonApi.store("worker-schedules", payload, ["worker"]);
};

export const updateWorkerSchedule = (workerScheduleId, data) => {
  const payload = {
    title: data.title,
    employment_percentage: parseInt(data.employmentPercentage),
    weekly_working_hours: parseInt(data.weekly_working_hours),
    breaks: data.breaks.breaks.length > 0 ? data.breaks.breaks : null,
    start_of_working_day: data.start_of_working_day,
    reserved_time: parseInt(data.reservedTime),
    expression: writeExpression("weekly", 1, data.weekdays),
    from: data.dates[0],
    to: data.dates[1]
  };
  return jsonApi.update("worker-schedules", workerScheduleId, payload);
};

export const removeWorkerSchedule = workerScheduleId => {
  return jsonApi.remove("worker-schedules", workerScheduleId);
};
