export const auth = {
  state: {
    token: localStorage.getItem("token"),
    user: JSON.parse(localStorage.getItem("user"))
  },
  getters: {
    authenticated: state => !!state.token,
    user: state => state.user,
    isAdmin: state => (state.user ? state.user.isAdmin : false)
  },
  mutations: {
    SET_TOKEN(state, token) {
      state.token = token;
      localStorage.setItem("token", token);
    },
    SET_USER(state, user) {
      state.user = JSON.parse(user);
      localStorage.setItem("user", user);
    },
    DELETE_TOKEN(state) {
      state.token = null;
      localStorage.removeItem("token");
    },
    DELETE_USER(state) {
      state.user = null;
      localStorage.removeItem("user");
    }
  },
  actions: {
    login({ commit }, data) {
      commit("SET_TOKEN", data.token);
      commit("SET_USER", JSON.stringify(data.user));
    },
    logout({ commit }) {
      commit("DELETE_TOKEN");
      commit("DELETE_USER");
    }
  }
};
