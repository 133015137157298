import moment from "moment";
import i18n from "@/plugins/i18n.js";

export const parseExpression = expression => {
  const expressionSplitted = expression.split(" ");
  const type = expressionSplitted[0];
  const each = expressionSplitted[1];
  const daysOfWeek = expressionSplitted[2];
  const daysOfWeekOptional = expressionSplitted[3];
  const allocationOptional = expressionSplitted[4];

  switch (type) {
    case "@once":
      return {
        periodicity: "once",
        repeatOn: each,
        repeatOnText: each,
        daysOfWeek: null,
        weekType: 1,
        monthType: ["1"],
        allocationTimes: 0
      };
    case "@daily":
      return {
        periodicity: "daily",
        repeatOn: each,
        repeatOnText: i18n.tc("each") + " " + each + " " + i18n.tc("day"),
        daysOfWeek: null,
        weekType: 1,
        monthType: ["1"],
        allocationTimes: 0
      };
    case "@weekly":
      return {
        periodicity: "weekly",
        repeatOn: each,
        repeatOnText: i18n.tc("each") + " " + each + " " + i18n.tc("week"),
        daysOfWeek: {
          monday: daysOfWeek ? daysOfWeek.split(",").includes("1") : false,
          tuesday: daysOfWeek ? daysOfWeek.split(",").includes("2") : false,
          wednesday: daysOfWeek ? daysOfWeek.split(",").includes("3") : false,
          thursday: daysOfWeek ? daysOfWeek.split(",").includes("4") : false,
          friday: daysOfWeek ? daysOfWeek.split(",").includes("5") : false,
          saturday: daysOfWeek ? daysOfWeek.split(",").includes("6") : false,
          sunday: daysOfWeek ? daysOfWeek.split(",").includes("7") : false
        },
        weekType: 1,
        monthType: ["1"],
        allocationTimes: daysOfWeekOptional
          ? daysOfWeekOptional
          : daysOfWeek.split(",").length
      };
    case "@monthly":
      return {
        periodicity: "monthly",
        repeatOn: 1,
        repeatOnText:
          i18n.tc("each") +
          " " +
          (each == 0 ? i18n.tc("last") : each) +
          " " +
          i18n.tc("week"),
        daysOfWeek: {
          monday: daysOfWeek ? daysOfWeek.split(",").includes("1") : false,
          tuesday: daysOfWeek ? daysOfWeek.split(",").includes("2") : false,
          wednesday: daysOfWeek ? daysOfWeek.split(",").includes("3") : false,
          thursday: daysOfWeek ? daysOfWeek.split(",").includes("4") : false,
          friday: daysOfWeek ? daysOfWeek.split(",").includes("5") : false,
          saturday: daysOfWeek ? daysOfWeek.split(",").includes("6") : false,
          sunday: daysOfWeek ? daysOfWeek.split(",").includes("7") : false
        },
        weekType: each,
        monthType: ["1"],
        allocationTimes: daysOfWeekOptional
          ? daysOfWeekOptional
          : daysOfWeek.split(",").length
      };
    case "@yearly":
      return {
        periodicity: "yearly",
        repeatOn: 1,
        repeatOnText:
          i18n.tc("each") +
          " " +
          (daysOfWeek == 7 ? i18n.tc("last") : daysOfWeek) +
          " " +
          i18n.tc("week") +
          ", " +
          i18n.tc("each") +
          " " +
          each +
          " " +
          i18n.tc("month"),
        daysOfWeek: {
          monday: daysOfWeek
            ? daysOfWeekOptional.split(",").includes("1")
            : false,
          tuesday: daysOfWeek
            ? daysOfWeekOptional.split(",").includes("2")
            : false,
          wednesday: daysOfWeek
            ? daysOfWeekOptional.split(",").includes("3")
            : false,
          thursday: daysOfWeek
            ? daysOfWeekOptional.split(",").includes("4")
            : false,
          friday: daysOfWeek
            ? daysOfWeekOptional.split(",").includes("5")
            : false,
          saturday: daysOfWeek
            ? daysOfWeekOptional.split(",").includes("6")
            : false,
          sunday: daysOfWeek
            ? daysOfWeekOptional.split(",").includes("7")
            : false
        },
        weekType: daysOfWeek,
        monthType: each.split(","),
        allocationTimes: allocationOptional
          ? allocationOptional
          : daysOfWeekOptional.split(",").length
      };
  }
};

export const writeExpression = (
  type,
  repeatOn,
  daysOfWeek,
  weekType,
  monthType,
  allocationTimes
) => {
  if (daysOfWeek) {
    console.log(
      daysOfWeek,
      allocationTimes,
      Object.entries(daysOfWeek).filter(day => {
        return day[1];
      }).length
    );
  }
  switch (type) {
    case "once":
      return "@once " + (repeatOn ? repeatOn : "2021-05-20");
    case "daily":
      return "@daily " + (repeatOn ? repeatOn : "1");
    case "weekly":
      return (
        "@weekly " +
        (repeatOn ? repeatOn : "1") +
        " " +
        (daysOfWeek
          ? Object.entries(daysOfWeek)
              .filter(day => {
                return day[1];
              })
              .map(day => {
                switch (day[0]) {
                  case "monday":
                    return 1;
                  case "tuesday":
                    return 2;
                  case "wednesday":
                    return 3;
                  case "thursday":
                    return 4;
                  case "friday":
                    return 5;
                  case "saturday":
                    return 6;
                  case "sunday":
                    return 7;
                }
              })
              .join(",")
          : "1,2,3,4,5") +
        (daysOfWeek &&
        allocationTimes >=
          Object.entries(daysOfWeek).filter(day => {
            return day[1];
          }).length
          ? ""
          : daysOfWeek
          ? " " + allocationTimes
          : "")
      );
    case "monthly":
      return (
        "@monthly " +
        weekType +
        " " +
        (daysOfWeek
          ? Object.entries(daysOfWeek)
              .filter(day => {
                return day[1];
              })
              .map(day => {
                switch (day[0]) {
                  case "monday":
                    return 1;
                  case "tuesday":
                    return 2;
                  case "wednesday":
                    return 3;
                  case "thursday":
                    return 4;
                  case "friday":
                    return 5;
                  case "saturday":
                    return 6;
                  case "sunday":
                    return 7;
                }
              })
              .join(",")
          : "1,2,3,4,5") +
        (daysOfWeek &&
        allocationTimes >=
          Object.entries(daysOfWeek).filter(day => {
            return day[1];
          }).length
          ? ""
          : daysOfWeek
          ? " " + allocationTimes
          : "")
      );
    case "yearly":
      return (
        "@yearly " +
        monthType.join(",") +
        " " +
        weekType +
        " " +
        (daysOfWeek
          ? Object.entries(daysOfWeek)
              .filter(day => {
                return day[1];
              })
              .map(day => {
                switch (day[0]) {
                  case "monday":
                    return 1;
                  case "tuesday":
                    return 2;
                  case "wednesday":
                    return 3;
                  case "thursday":
                    return 4;
                  case "friday":
                    return 5;
                  case "saturday":
                    return 6;
                  case "sunday":
                    return 7;
                }
              })
              .join(",")
          : "1,2,3,4,5") +
        (daysOfWeek &&
        allocationTimes >=
          Object.entries(daysOfWeek).filter(day => {
            return day[1];
          }).length
          ? ""
          : daysOfWeek
          ? " " + allocationTimes
          : "")
      );
  }
};

export const scheduleFormatter = schedule => {
  const from = schedule.from
    ? schedule.from == moment().format("YYYY-MM-DD")
      ? i18n.tc("today")
      : moment(schedule.from).format("YYYY-MM-DD")
    : false;
  const to = schedule.to
    ? schedule.to == moment().format("YYYY-MM-DD")
      ? i18n.tc("today")
      : moment(schedule.to).format("YYYY-MM-DD")
    : false;
  if (from && to) {
    return i18n.tc("from") + " " + from + " " + i18n.tc("to") + " " + to;
  } else if (from) {
    return i18n.tc("from") + " " + from;
  } else if (to) {
    return i18n.tc("to") + " " + to;
  } else {
    return "∞";
  }
};
