<template>
  <div :class="$style['worker-entity-form-wrapper']">
    <base-form name="worker-entity-form" :form="form" @submit="onSubmit">
      <base-field :label="$t('name')" :errors="form.errors.get('first_name')">
        <base-input name="first_name" type="text" v-model="form.first_name" />
      </base-field>
      <base-field
        :label="$t('lastName')"
        :errors="form.errors.get('last_name')"
      >
        <base-input name="last_name" type="text" v-model="form.last_name" />
      </base-field>
      <base-field :label="$t('email')" :errors="form.errors.get('email')">
        <base-input name="email" type="text" v-model="form.email" />
      </base-field>
      <base-field :label="$t('phone')" :errors="form.errors.get('phone')">
        <base-input name="phone" type="text" v-model="form.phone" />
      </base-field>
      <base-field :label="$t('skills')" :errors="form.errors.get('skills')">
        <base-select-tags
          name="skills"
          mode="multiple"
          v-model="form.skills"
          :options="skills"
          :mappers="{
            options: option => {
              return { label: option.name, value: option.id };
            },
            value: value => {
              return value.id;
            }
          }"
        />
      </base-field>
      <base-field :label="$t('address')" :errors="form.errors.get('address')">
        <base-input-search
          name="address"
          v-model="form.address"
          :fetcher="addressFetcher"
          @update="handleAddressUpdate"
        />
      </base-field>
      <base-field label="GPS" :errors="form.errors.get('gps')">
        <base-input-gps name="gps" v-model="form.gps" disabled />
      </base-field>
    </base-form>
    <base-button
      size="large"
      @click="onSubmit"
      :loading="form.loading"
      :disabled="form.loading || form.errors.any()"
      >{{ form.hasUpdateType() ? $t('update') : $t('create') }}</base-button
    >
  </div>
</template>

<script>
import { OpenStreetMapProvider } from "leaflet-geosearch";
import { mapGetters, mapActions, mapState } from "vuex";

import Form from "@/services/form/Form.js";

import BaseForm from "@/components/form/BaseForm";
import BaseButton from "@/components/form/BaseButton";
import BaseField from "@/components/form/BaseField";
import BaseInput from "@/components/form/BaseInput";
import BaseSelectTags from "@/components/form/BaseSelectTags";
import BaseInputSearch from "@/components/form/BaseInputSearch";
import BaseInputGps from "@/components/form/BaseInputGps";

export default {
  name: "WorkerEntityForm",
  data() {
    return {
      success: undefined,
      error: undefined,
      provider: new OpenStreetMapProvider(),
      form: new Form(
        {
          first_name: "",
          last_name: "",
          email: "",
          phone: "",
          skills: [],
          address: "",
          gps: undefined
        },
        {
          first_name: {
            presence: { allowEmpty: false }
          },
          last_name: {
            presence: { allowEmpty: false }
          },
          email: {
            presence: { allowEmpty: false },
            format: {
              pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|([^-@]+([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              message: this.$i18n.t("invalidFormat")
            }
          },
          phone: {
            format: {
              pattern: /^$|^(\+\d{1,2}\s?)?1?-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
              message: this.$i18n.t("invalidFormat")
            }
          },
          address: {
            presence: { allowEmpty: false }
          }
        },
        {
          id: this.workerId
        }
      )
    };
  },
  components: {
    BaseForm,
    BaseButton,
    BaseField,
    BaseInput,
    BaseSelectTags,
    BaseInputSearch,
    BaseInputGps
  },
  props: {
    workerId: {
      type: [Number, String],
      default: undefined
    }
  },
  computed: {
    ...mapGetters("workers", ["worker"]),
    ...mapState("configurations", ["skills"]),
    addressFetcher() {
      return {
        method: query => {
          return this.provider.search({ query: query });
        },
        mapper: result => {
          return {
            label: result.label,
            value: result.label,
            longitude: result.x.toString(),
            latitude: result.y.toString()
          };
        }
      };
    }
  },
  methods: {
    ...mapActions("workers", ["addWorker", "updateWorker"]),
    initializeWorker(workerId) {
      const worker = this.worker(workerId);
      if (worker) {
        this.form.setData(
          {
            first_name: worker.first_name,
            last_name: worker.last_name,
            email: worker.email,
            phone: worker.phone,
            skills: worker.skills,
            address: worker.address,
            gps: {
              longitude: worker.longitude,
              latitude: worker.latitude
            }
          },
          {
            id: workerId
          }
        );
      }
    },
    onSubmit() {
      this.success = undefined;
      this.form
        .submit(this.form.hasUpdateType() ? this.updateWorker : this.addWorker)
        .then(worker => {
          this.success = true;

          if (!this.form.hasUpdateType()) {
            this.$emit("workerCreate", worker.id);
          }

          this.$emit("success");
        })
        .catch(() => {
          this.success = false;
        });
    },
    handleAddressUpdate(data) {
      this.form.setData({
        gps: {
          longitude: data.longitude,
          latitude: data.latitude
        }
      });
    }
  },
  mounted() {
    if (this.form.hasUpdateType()) {
      this.initializeWorker(this.workerId);
    }
  },
  watch: {
    workerId(workerId) {
      this.initializeWorker(workerId);
    }
  }
};
</script>

<style lang="scss" module>
.worker-entity-form-wrapper {
}
</style>
