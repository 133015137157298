import Vue from "vue";
import VueI18n from "vue-i18n";

import en from "@/langs/en.json";
import de from "@/langs/de.json";

const defaultLocale = "en";

const languages = {
  en: en,
  de: de
};

const messages = Object.assign(languages);

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: defaultLocale,
  fallbackLocale: "de",
  messages
});

export default i18n;
