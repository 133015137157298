<template>
  <div :class="$style['right-bar-wrapper']">
    <Tabs :tabs="tabs">
      <template :slot="tabs[0].label">
        <Plane :class="$style.plane">
          <div :class="$style.info">
            <Icon name="list" />
            <span>{{ $t("dateRangeDescription") }}</span>
          </div>
          <FormField
            :class="$style['with-out-margin']"
            placeholder="Start date - End date"
            :error="errors['range']"
          >
            <DateRange
              :loading="loading"
              :error="errors['range']"
              :format="format"
              v-model="range"
            />
          </FormField>
          <Button :loading="loading" @click="rangeUpdate">
            <Icon name="done" fill="#ffffff" :height="13" />
            <span>{{ $t("save") }}</span>
          </Button>
        </Plane>
      </template>
      <template :slot="tabs[1].label">
        <Plane :class="$style.plane">
          <div :class="$style.info">
            <Icon name="list" />
            <span>{{ $t("dateBordersDescription") }}</span>
          </div>
          <FormField
            :class="$style['with-out-margin']"
            placeholder="Start border date - End border date"
            :error="errors['border']"
          >
            <DateRange
              :loading="loading"
              :error="errors['border']"
              :format="format"
              v-model="borders"
            />
          </FormField>
          <Button :loading="loading" @click="bordersUpdate">
            <Icon name="done" fill="#ffffff" :height="13" />
            {{ $t("save") }}
          </Button>
        </Plane>
      </template>
    </Tabs>
    <p>{{ $t("tasks") }}</p>
    <PlaneList :items="tasks.map(task => task.name)" />
  </div>
</template>

<script>
import { mapActions } from "vuex";

import moment from "moment";
import { EventBus } from "@/main.js";

export default {
  name: "TasksRightBar",
  data() {
    return {
      errors: [],
      loading: false,
      format: "YYYY-MM-DD",
      tabs: [
        { label: this.$i18n.t("dateRange") },
        { label: this.$i18n.t("dateBorders") }
      ],
      range: [null, null],
      borders: [null, null]
    };
  },
  components: {},
  props: {
    tasks: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  computed: {},
  methods: {
    ...mapActions("tasks", ["updateTasksScheduleDates"]),
    moment,
    async rangeUpdate() {
      (this.loading = true),
        await this.updateTasksScheduleDates({
          tasksId: this.tasks.map(task => task.id),
          data: {
            from: this.range[0],
            to: this.range[1]
          }
        });
      this.loading = false;
      EventBus.$emit("table-update");
    },
    async bordersUpdate() {
      this.loading = true;
      const tasksFrom = this.tasks.filter(task =>
        this.moment(this.borders[0], this.format).isAfter(task.from)
      );
      const tasksTo = this.tasks.filter(task =>
        this.moment(task.from, this.format).isBefore(this.borders[1])
      );
      if (tasksFrom.length > 0)
        await this.updateTasksScheduleDates({
          tasksId: tasksFrom.map(task => task.id),
          data: {
            from: this.borders[0]
          }
        });
      if (tasksTo.length > 0)
        await this.updateTasksScheduleDates({
          tasksId: tasksTo.map(task => task.id),
          data: {
            to: this.borders[1]
          }
        });
      this.loading = false;
    }
  }
};
</script>

<style lang="scss" module>
.right-bar-wrapper {
  .info {
    display: flex;
    span {
      margin-left: 5px;
    }
  }
  .plane {
    margin-bottom: 10px;
  }
}
</style>
