import restApiClient from "@/api/clients/restApiClient";
import jsonApiClient from "@/api/clients/jsonApiClient";

export const restApi = {
  get(url, params = {}) {
    return restApiClient.get(url, params);
  },
  post(url, payload, options) {
    return restApiClient.post(url, payload, options);
  },
  patch(url, payload) {
    return restApiClient.patch(url, payload);
  },
  remove(url) {
    return restApiClient.delete(url);
  }
};

export const jsonApi = {
  index(entity, params = {}) {
    return jsonApiClient.get(entity, {
      params: params
    });
  },
  show(entity, id, params = {}) {
    return jsonApiClient.get(entity + "/" + id, {
      params: params
    });
  },
  store(entity, payload, relations, params) {
    return jsonApiClient.post(
      entity,
      {
        type: entity,
        attributes: payload,
        relationships: relations
      },
      {
        params: params
      }
    );
  },
  update(entity, id, payload, relations, params) {
    payload.id = id.toString();
    return jsonApiClient.patch(
      entity + "/" + id,
      {
        type: entity,
        attributes: payload,
        relationships: relations
      },
      {
        params: params
      }
    );
  },
  remove(entity, id) {
    return jsonApiClient.delete(entity + "/" + id);
  },
  bulkUpdate(entity, ids, payload, params) {
    const defaultParams = {
      "filter[id]": ids.join(",")
    };
    return jsonApiClient.patch(
      entity,
      {
        type: entity,
        attributes: payload
      },
      {
        params: { ...defaultParams, ...params }
      }
    );
  },
  bulkDelete(entity, ids) {
    return jsonApiClient.delete(entity + "/bulk", {
      params: {
        "filter[id]": ids.join(",")
      }
    });
  }
};
