<template>
  <div :class="$style['working-days-editor-wrapper']">
    <p :class="$style['working-days-editor-header']">
      {{ $t("editWorkingDays") }}
    </p>
    <vc-calendar
      :locale="calendarLocale"
      :class="{ [$style.calendar]: true }"
      :attributes="attributes"
      :first-day-of-week="2"
      :masks="{ title: 'MMM YYYY', weekdays: 'WWW' }"
      @dayclick="onDayClick"
      @update:from-page="pageChange"
    >
    </vc-calendar>
    <div :class="$style['edit-description']">
      <p :class="{ [$style.editable]: edit, [$style.working]: true }">
        {{ $t("workingDays") }}
      </p>
      <p :class="{ [$style.editable]: edit, [$style['no-working']]: true }">
        {{ $t("NonWorkingDays") }}
      </p>
    </div>
    <Button
      v-if="!edit"
      type="link"
      :class="$style['edit-button']"
      @click="updateEditMode"
      :loading="loading"
    >
      {{ $t("editWorkingDays") }}
    </Button>
    <div :class="$style['edit-controller']" v-if="edit">
      <Button type="dashed" @click="updateEditMode">
        {{ $t("cancel") }}
      </Button>
      <Button @click="save" :disabled="updates.length == 0" :loading="loading">
        {{ $t("save") }}
      </Button>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import VCalendar from "v-calendar";
import dateFormat from "dateformat";

import moment from "moment";

Vue.use(VCalendar, {
  componentPrefix: "vc"
});

export default {
  name: "WorkingDaysEditor",
  components: {},
  data() {
    return {
      edit: false,
      page: { year: null, month: null },
      updates: []
    };
  },
  props: {
    workers: {
      type: Array,
      default() {
        return [];
      }
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  mounted() {},
  computed: {
    calendarLocale() {
      return this.$i18n.locale;
    },
    attributes() {
      let attributes = this.workingDays
        .map(day => {
          return {
            key: "working-day",
            dot: {
              class: !this.edit ? "working-day-dot" : "dot-invisible"
            },
            highlight: {
              class: this.edit ? "working-day-highlight" : "highlight-invisible"
            },
            dates: day,
            popover: {
              hideIndicator: true,
              label:
                "Working day for: " +
                this.workingDaysCustomData
                  .filter(date => {
                    return Object.entries(date)[0][0] == day;
                  })
                  .map(date => {
                    return Object.entries(date)[0][1].name;
                  })
                  .join(", ")
            }
          };
        })
        .concat(
          this.dayOffs.map(day => {
            return {
              key: "day-off",
              dot: {
                class: !this.edit ? "day-off-dot" : "dot-invisible"
              },
              highlight: {
                class: this.edit ? "day-off-highlight" : "highlight-invisible"
              },
              dates: day,
              popover: {
                hideIndicator: true,
                label:
                  "Day off for: " +
                  this.dayOffsCustomData
                    .filter(date => {
                      return Object.entries(date)[0][0] == day;
                    })
                    .map(date => {
                      return Object.entries(date)[0][1].name;
                    })
                    .join(", ")
              }
            };
          })
        );
      return attributes;
    },
    dayOffs() {
      return [
        ...new Set(
          [].concat.apply(
            [],
            this.preparedWorkers.map(worker => {
              return worker.dayOffs;
            })
          )
        )
      ];
    },
    dayOffsCustomData() {
      return [
        ...new Set(
          [].concat.apply(
            [],
            this.preparedWorkers.map(worker => {
              return worker.dayOffs.map(dayOff => {
                return {
                  [dayOff]: {
                    id: worker.id,
                    name: worker.name
                  }
                };
              });
            })
          )
        )
      ];
    },
    workingDays() {
      return [
        ...new Set(
          [].concat.apply(
            [],
            this.preparedWorkers.map(worker => {
              return worker.workingDays.map(workingDay => {
                return workingDay;
              });
            })
          )
        )
      ];
    },
    workingDaysCustomData() {
      return [
        ...new Set(
          [].concat.apply(
            [],
            this.preparedWorkers.map(worker => {
              return worker.workingDays.map(workingDay => {
                return {
                  [workingDay]: {
                    id: worker.id,
                    name: worker.name
                  }
                };
              });
            })
          )
        )
      ];
    },
    preparedWorkers() {
      return this.workers
        .filter(worker => {
          return worker.schedules.length > 0;
        })
        .map(worker => {
          return {
            id: worker.id,
            name: worker.firstName + " " + worker.lastName,
            dayOffs: this.getDaysOnMonth(this.page)
              .filter(day => {
                return (
                  (worker["day-offs"]
                    .map(dayOff => dayOff.day)
                    .includes(day.date) ||
                    (worker["schedules"].find(schedule => {
                      return this.dayIsInsideSchedulePeriod(
                        day.date,
                        schedule.from_date,
                        schedule.to_date
                      );
                    }) &&
                      Object.entries(
                        worker["schedules"].find(schedule => {
                          return this.dayIsInsideSchedulePeriod(
                            day.date,
                            schedule.from_date,
                            schedule.to_date
                          );
                        }).weekdays
                      )
                        .filter(status => !status[1])
                        .map(status => {
                          const dictionary = {
                            monday: 1,
                            tuesday: 2,
                            wednesday: 3,
                            thursday: 4,
                            friday: 5,
                            saturday: 6,
                            sunday: 7
                          };
                          return dictionary[status[0]];
                        })
                        .includes(parseInt(day.weekday)) &&
                      !worker["working-days"]
                        .map(day => day.day)
                        .includes(day.date)) ||
                    this.updates.filter(update => {
                      return update.date == day.date && !update.status;
                    }).length > 0) &&
                  this.updates.filter(update => {
                    return update.date == day.date && update.status;
                  }).length == 0
                );
              })
              .map(day => day.date),
            workingDays: this.getDaysOnMonth(this.page)
              .filter(day => {
                return (
                  (worker["working-days"]
                    .map(day => day.day)
                    .includes(day.date) ||
                    (worker["schedules"].find(schedule => {
                      return this.dayIsInsideSchedulePeriod(
                        day.date,
                        schedule.from_date,
                        schedule.to_date
                      );
                    }) &&
                      Object.entries(
                        worker["schedules"].find(schedule => {
                          return this.dayIsInsideSchedulePeriod(
                            day.date,
                            schedule.from_date,
                            schedule.to_date
                          );
                        }).weekdays
                      )
                        .filter(status => status[1])
                        .map(status => {
                          const dictionary = {
                            monday: 1,
                            tuesday: 2,
                            wednesday: 3,
                            thursday: 4,
                            friday: 5,
                            saturday: 6,
                            sunday: 7
                          };
                          return dictionary[status[0]];
                        })
                        .includes(parseInt(day.weekday)) &&
                      !worker["day-offs"]
                        .map(day => day.day)
                        .includes(day.date)) ||
                    this.updates.filter(update => {
                      return update.date == day.date && update.status;
                    }).length > 0) &&
                  this.updates.filter(update => {
                    return update.date == day.date && !update.status;
                  }).length == 0
                );
              })
              .map(day => day.date)
          };
        });
    }
  },
  methods: {
    dateFormat,
    onDayClick(day) {
      if (this.edit) {
        let dayUpdate = this.updates.find(update => {
          return update.date == day.id;
        });
        if (!dayUpdate) {
          this.updates.push({
            date: day.id,
            status: this.dayOffs.find(el => el === day.id)
          });
        } else {
          dayUpdate.status = !dayUpdate.status;
          this.updates = this.updates.filter(update => {
            return update.date !== day.id;
          });
        }
      }
    },
    pageChange(page) {
      if (!(page.month === this.page.month && page.year === this.page.year)) {
        this.page = page;
      }
    },
    getDaysOnMonth(page) {
      if (page.year && page.month) {
        let month = page.month - 1;
        var date = new Date(page.year, month);
        var days = [];
        while (date.getMonth() === month) {
          days.push({
            date: this.dateFormat(new Date(date), "yyyy-mm-dd"),
            weekday: this.dateFormat(new Date(date), "N")
          });
          date.setDate(date.getDate() + 1);
        }
        return days;
      }
      return [];
    },
    updateEditMode() {
      if (this.edit) {
        this.updates = [];
      }
      this.edit = !this.edit;
    },
    save() {
      this.$emit("update", this.updates);
      this.updateEditMode();
    },
    dayIsInsideSchedulePeriod(day, from, to) {
      const momentDay = moment(day);
      if (from && momentDay.isBefore(moment(from))) {
        return false;
      }
      if (to && momentDay.isAfter(moment(to))) {
        return false;
      }
      return true;
    }
  }
};
</script>

<style lang="scss">
.vc-container {
  border: none;
  .working-day-dot {
    background: $primary-blue !important;
  }
  .day-off-dot {
    background: $primary-black !important;
  }
  .dot-invisible {
    display: none;
  }
  .working-day-highlight {
    border: 1px solid $primary-blue !important;
  }
  .day-off-highlight {
    border: 1px solid $primary-black !important;
  }
  .vc-day-layer + .vc-day-layer {
    .day-off-highlight {
      border: transparent solid 3px !important;
      box-shadow: 0px 0px 0px 1px $primary-black inset;
    }
  }
  .highlight-invisible {
    display: none;
  }
}
</style>

<style lang="scss" module>
.working-days-editor-wrapper {
  border: 1px solid #b8c1cc;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 13px;
  text-align: center;
  .disabled {
    opacity: 0.5;
  }
  .working-days-editor-header {
    font-size: 16px;
    font-weight: 600;
  }
  .calendar {
    margin-bottom: 10px;
  }
  .edit-description {
    display: flex;
    .no-working,
    .working {
      position: relative;
      font-weight: 300;
      font-size: 14px;
      line-height: 25px;
      color: #000000;
      margin-left: 20px;
      &:before {
        content: "";
        display: block;
        position: absolute;
        border: none;
        left: -12px;
        top: 8px;
        width: 8px;
        height: 8px;
        border-radius: 100%;
      }
    }
    .working {
      &:before {
        background: $primary-blue;
      }
      &.editable {
        &:before {
          border: 1px solid $primary-blue;
          background: transparent;
        }
      }
    }
    .no-working {
      &:before {
        background: $primary-black;
      }
      &.editable {
        &:before {
          border: 1px solid $primary-black;
          background: transparent;
        }
      }
    }
  }
  .edit-controller {
    width: 100%;
    display: flex;
    text-align: center;
    button {
      width: 95%;
      flex: 1;
      align-items: stretch;
    }
  }
}
</style>
