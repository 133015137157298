<template>
  <div class="base-day-time-intervals-with-hours-counter">
    <BaseTimePicker v-model="from" />
    <span class="base-day-time-intervals-with-hours-counter__difference">{{
      difference ? difference + " h" : "-"
    }}</span>
    <BaseTimePicker v-model="to" />
  </div>
</template>

<script>
import moment from "moment";

import BaseTimePicker from "@/components/BaseTimePicker";

export default {
  name: "BaseDayTimeIntervalsWithHoursCounter",
  data() {
    return {};
  },
  components: { BaseTimePicker },
  props: {
    value: {
      type: Object,
      default: () => {
        return {
          from: "00:00",
          to: "00:00"
        };
      }
    }
  },
  computed: {
    from: {
      get() {
        return this.value.from;
      },
      set(value) {
        let model = this.value;
        model.from = value;

        if (this.difference < 0) {
          model.to = value;
        }

        this.$emit("input", model);
        this.$emit("update", model);
      }
    },
    to: {
      get() {
        return this.value.to;
      },
      set(value) {
        let model = this.value;
        model.to = value;

        if (this.difference < 0) {
          model.from = value;
        }

        this.$emit("input", model);
        this.$emit("update", model);
      }
    },
    difference() {
      const startMoment = moment(this.from, "HH:mm");
      const endMoment = moment(this.to, "HH:mm");
      var duration = moment.duration(endMoment.diff(startMoment));
      return +(Math.round(duration.asMinutes() / 60 + "e+1") + "e-1");
    }
  },
  methods: {}
};
</script>

<style lang="scss">
.base-day-time-intervals-with-hours-counter {
  width: 100%;
  display: flex;
}
.base-day-time-intervals-with-hours-counter__difference {
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
