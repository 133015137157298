<template>
  <Modal :width="width" v-model="visible">
    <div :class="$style['confirmation-wrapper']">
      <Icon :class="$style.icon" :name="icon" fill="#FF7946" :height="61" />
      <p :class="$style.title">{{ title }}</p>
      <div :class="$style.list" v-if="items">
        <component
          :class="$style['list-item']"
          v-for="(item, index) in items"
          :key="index"
          :is="item.component"
          v-bind="{
            ...item.options
          }"
        >
          <slot v-if="item.slot">{{ item.slot }}</slot>
        </component>
      </div>
      <p>{{ message }}</p>
      <div :class="$style.footer">
        <Button type="default" @click="cancel">{{ $t("cancel") }}</Button>
        <Button @click="confirm">{{ $t("confirm") }}</Button>
      </div>
    </div>
  </Modal>
</template>

<script>
import Plugin from "@/plugins/confirmation";

export default {
  name: "ConfirmationPlugin",
  data() {
    return {
      visible: false,
      action: undefined,
      title: undefined,
      message: undefined,
      items: undefined,
      reject: undefined,
      resolve: undefined,
      width: undefined
    };
  },
  components: {},
  props: {
    name: {
      type: String,
      default: null
    }
  },
  computed: {
    icon() {
      switch (this.action) {
        case "delete":
          return "cross";
        case "warning":
          return "warning";
        default:
          return "cross";
      }
    }
  },
  methods: {
    confirm() {
      this.visible = false;
      this.resolve();
    },
    cancel() {
      this.visible = false;
      this.reject();
    }
  },
  beforeMount() {
    Plugin.EventBus.$on("show", data => {
      this.visible = true;
      this.resolve = data.resolve;
      this.reject = data.reject;
      this.width = data.params.width;
      this.items = data.params.items;
      this.action = data.params.action;
      this.title = data.params.title;
      this.message = data.params.message;
    });
  }
};
</script>

<style lang="scss" module>
.confirmation-wrapper {
  text-align: center;
  .icon {
    display: inline-block;
  }
  .list {
    display: inline-block;
    padding-top: 20px;
    padding-bottom: 20px;
    .list-item {
      margin-bottom: 10px;
    }
  }
  .title {
    font-weight: 500;
    font-size: 25px;
  }
  .footer {
    margin-top: 15px;
    width: 100%;
    display: flex;
    button {
      height: 56px;
      width: 90%;
      flex: 1;
      align-items: stretch;
    }
  }
}
</style>
