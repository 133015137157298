const Drawer = {
  install(Vue) {
    this.EventBus = new Vue();

    Vue.prototype.$drawer = {
      show(name) {
        Drawer.EventBus.$emit("show", name);
      },
      hide(name) {
        Drawer.EventBus.$emit("hide", name);
      }
    };
  }
};

export default Drawer;
