<template>
  <div :class="$style['logo-wrapper']">
    <symbol id="logo">
      <path
        d="M29.5917 15.1857C29.5917 7.22165 23.0277 0.764211 14.9255 0.751971C6.92127 0.739765 0.339402 7.09145 0.215011 14.9577C0.147207 18.8747 1.70774 22.6495 4.53622 25.4105H4.51892L14.0153 35.4545C14.2619 35.7153 14.6081 35.863 14.9704 35.8619C15.3326 35.8608 15.6779 35.711 15.9228 35.4487L25.2867 25.4105H25.2694C28.0403 22.7051 29.5964 19.024 29.5917 15.1857Z"
        fill="#2e92f8"
      />
      <path
        d="M14.9303 24.0694C19.8312 24.0694 23.8041 20.1696 23.8041 15.3589C23.8041 10.5483 19.8312 6.64844 14.9303 6.64844C10.0295 6.64844 6.05664 10.5483 6.05664 15.3589C6.05664 20.1696 10.0295 24.0694 14.9303 24.0694Z"
        fill="white"
      />
    </symbol>

    <svg
      viewBox="0 0 30 36"
      :class="{
        [$style.logo]: true,
        [$style[size]]: size
      }"
    >
      <use :xlink:href="'#logo'" />
    </svg>

    <span
      v-if="labeled"
      :class="{
        [$style.label]: true,
        [$style[size]]: size
      }"
      >WorkPlanner</span
    >
  </div>
</template>

<script>
export default {
  name: "Logo",
  data() {
    return {};
  },
  components: {},
  props: {
    size: {
      type: String,
      default: "middle"
    },
    labeled: {
      type: Boolean,
      default: false
    }
  },
  methods: {}
};
</script>

<style lang="scss" module>
.logo-wrapper {
  display: flex;
  .label {
    height: 100%;
    color: $primary-blue;
  }
  .label.small {
    font-weight: 600;
    font-size: 20px;
    margin-left: 10px;
  }
  .label.middle {
    font-weight: 600;
    font-size: 30px;
    margin-left: 15px;
  }
  .label.large {
    font-weight: 600;
    font-size: 45px;
    margin-left: 15px;
  }
  .logo.small {
    height: 30px;
  }
  .logo.middle {
    height: 50px;
  }
  .logo.large {
    height: 70px;
  }
}
</style>
