<template>
  <div :class="$style['settings-skills-form-wrapper']">
    <base-field :label="$t('defaultWorkingHoursPerWeek')">
      <base-range-picker
        magnitude="h"
        :loading="loading"
        :triggerDelay="1000"
        :value="weeklyWorkingHours"
        :min="0"
        :max="168"
        @change="handleUpdateHours"
      />
    </base-field>
    <base-field :label="$t('defaultWorkingDays')">
      <base-week-days-picker
        v-model="defaultWorkingDaysData"
        :loading="loading"
      />
    </base-field>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

import BaseField from "@/components/form/BaseField";
import BaseRangePicker from "@/components/form/BaseRangePicker";
import BaseWeekDaysPicker from "@/components/form/BaseWeekDaysPicker";

export default {
  name: "SettingsSetupForm",
  data() {
    return {
      success: undefined,
      error: undefined,
      loading: false
    };
  },
  components: { BaseField, BaseRangePicker, BaseWeekDaysPicker },
  props: {},
  computed: {
    ...mapState("configurations", ["weeklyWorkingHours", "defaultWorkingDays"]),
    defaultWorkingDaysData: {
      get() {
        if (!this.defaultWorkingDays) {
          return {
            monday: true,
            tuesday: true,
            wednesday: true,
            thursday: true,
            friday: true,
            saturday: false,
            sunday: false
          };
        }

        return this.defaultWorkingDays[0];
      },
      set(value) {
        this.loading = true;
        this.updateDefaultWorkingDays(value)
          .then(() => {
            this.success = true;
            this.loading = false;
          })
          .catch(error => {
            this.error = error;
            this.success = false;
            this.loading = false;
          });
      }
    }
  },
  methods: {
    ...mapActions("configurations", [
      "updateWeeklyWorkingHours",
      "updateDefaultWorkingDays"
    ]),
    handleUpdateHours(value) {
      this.success = undefined;
      this.loading = true;
      this.updateWeeklyWorkingHours(value)
        .then(() => {
          this.success = true;
          this.loading = false;
        })
        .catch(error => {
          this.error = error;
          this.success = false;
          this.loading = false;
        });
    }
  }
};
</script>

<style lang="scss" module>
.settings-skills-form-wrapper {
}
</style>
