<template>
  <div :class="$style['plane-list-wrapper']">
    <base-plane v-for="item in items" :key="item">
      <BasePicCircle helper="initials" :label="item" labeled />
    </base-plane>
  </div>
</template>

<script>
import BasePlane from "@/components/ui/BasePlane";
import BasePicCircle from "@/components/ui/BasePicCircle";

export default {
  name: "PlaneList",
  data() {
    return {};
  },
  components: { BasePlane, BasePicCircle },
  props: {
    items: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  computed: {},
  methods: {}
};
</script>

<style lang="scss" module>
.plane-list-wrapper {
  > * {
    margin-top: 10px;
  }
  > *:first-child {
    margin-top: 0px;
  }
}
</style>
