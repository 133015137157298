<template>
  <div :class="$style['auth-form-grid-wrapper']">
    <div :class="$style['content']">
      <div :class="$style.header">
        <slot name="header"></slot>
      </div>
      <div :class="$style.form">
        <slot name="form"></slot>
      </div>
    </div>
    <div :class="$style.footer">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "AuthFormGrid",
  components: {},
  props: {},
  computed: {},
  methods: {}
};
</script>

<style lang="scss" module>
.auth-form-grid-wrapper {
  display: grid;
  width: 100%;
  height: 100%;
  position: relative;
  .content {
    height: auto;
    align-self: center;
    .header {
      margin-bottom: 20px;
      color: $primary-black;
      width: 100%;
      font-weight: 700;
      font-size: 28px;
    }
  }
  .footer {
    width: 100%;
    font-size: 16px;
    align-self: flex-end;
    font-weight: 600;
    color: $primary-black;
    position: absolute;
    margin-bottom: 50px;
    b {
      color: $primary-blue;
      cursor: pointer;
    }
  }
}
</style>
