import { validate as validator } from "validate.js";
import i18n from "@/plugins/i18n.js";

export default class Validator {
  constructor(rules = {}) {
    this.rules = rules;
    this.validator = validator;
    this.addCustomValidators();
    this.addErrorMessages();
  }

  getRules() {
    return this.rules;
  }

  getRulesForField(field) {
    return this.rules[field];
  }

  validateField(field, form) {
    const errors = this.validateForm(form);

    return errors && errors[field] ? errors[field] : false;
  }

  validateForm(form) {
    const formErrors = this.validator(form, this.getRules(), {
      fullMessages: false
    });

    return formErrors ? formErrors : false;
  }

  addCustomValidators() {
    // Validate field's object value by it's validation property, so field can validate itself
    this.validator.validators.validationByObjectProperty = function(
      value,
      options
      // key,
      // attributes
    ) {
      if (value.validation) {
        return null;
      } else {
        return (
          i18n.tc("invalidFormat") +
          ". " +
          (options.suggest ? options.suggest : "")
        );
      }
    };
  }

  addErrorMessages() {
    this.validator.validators.presence.message = i18n.tc("presence");
    this.validator.validators.email.message = i18n.tc("isNotValidEmail");
    this.validator.validators.equality.message = i18n.tc("passwordEqualityError");
    this.validator.validators.numericality.message = i18n.tc("numericality");
  }
}
