<template>
  <div :class="$style['input-search-wrapper']">
    <AntSelect
      show-search
      :class="{ [$style.search]: true, [$style.error]: error }"
      :loading="loading"
      :disabled="disabled || loading"
      :placeholder="placeholder"
      :value="model"
      :default-active-first-option="true"
      :show-arrow="false"
      :filter-option="false"
      :not-found-content="null"
      :size="'large'"
      @search="handleSearch"
      @change="handleChange"
    >
      <AntSelectOption v-for="d in data" :key="d.value">
        {{ d.label }}
      </AntSelectOption>
    </AntSelect>
  </div>
</template>

<script>
import { Select as AntSelect } from "ant-design-vue";
import "ant-design-vue/lib/select/style/css";

const AntSelectOption = AntSelect.Option;

export default {
  name: "InputSearch",
  components: { AntSelect, AntSelectOption },
  data() {
    return {
      data: [],
      timer: null
    };
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: "Placeholder"
    },
    value: {
      type: String,
      default: ""
    },
    error: {
      type: Boolean,
      default: false
    },
    fetcher: {
      type: Object,
      default: () => {
        return {
          method: () => {
            return new Promise(resolve => {
              resolve([]);
            });
          },
          mapper: data => {
            return data.map(item => {
              return item;
            });
          }
        };
      }
    }
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    }
  },
  methods: {
    async handleSearch(value) {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.fetcher.method(value).then(result => {
          const filtered = result.filter(
            (v, i, a) => a.findIndex(t => t.label === v.label) === i
          );
          if (value) {
            this.data = filtered.map(this.fetcher.mapper);
          }
        });
      }, 1500);
    },
    async handleChange(value) {
      const data = this.data.find(item => {
        return item.value == value;
      });
      this.model = value;
      this.$emit("update", data);
    }
  }
};
</script>

<style lang="scss" module>
.input-search-wrapper {
  min-width: 100px;
  max-width: 395px;
  width: 100%;
  position: relative;
  .search {
    width: 100%;
  }
  .error {
    border: 2px solid red;
    border-radius: 5px;
  }
}
</style>
