<template>
  <div :class="$style['time-minutes-picker-wrapper']">
    <AntTimePicker
      :class="{ [$style['time-picker']]: true, [$style.error]: error }"
      :format="'HH:mm'"
      placeholder="HH:MM"
      :disabled="disabled || loading"
      v-model="model"
      :size="size"
    />
  </div>
</template>

<script>
import moment from "moment";
import { TimePicker as AntTimePicker } from "ant-design-vue";
import "ant-design-vue/lib/time-picker/style/css";

export default {
  name: "TimeMinutesPicker",
  data() {
    return {};
  },
  components: { AntTimePicker },
  props: {
    value: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: "large"
    }
  },
  computed: {
    model: {
      get() {
        return this.value
          ? this.moment("00:00", "HH:mm").add(this.value, "minutes")
          : null;
      },
      set(value) {
        return value
          ? this.$emit(
              "input",
              Math.round(
                value.diff(this.moment("00:00", "HH:mm"), "minutes", true)
              ).toString()
            )
          : this.$emit("input", null);
      }
    }
  },
  methods: { moment }
};
</script>

<style lang="scss" module>
.time-minutes-picker-wrapper {
  .time-picker-wrapper {
    .time-picker {
      width: 100%;
    }
    .error {
      border: 2px solid red;
      border-radius: 5px;
    }
  }
}
</style>
