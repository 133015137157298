<template>
  <div :class="$style['button-wrapper']">
    <AntButton
      :type="type"
      block
      :size="size"
      html-type="button"
      :loading="loading"
      :disabled="disabled"
      @click="handleClick"
      :class="size"
    >
      <slot></slot>
    </AntButton>
  </div>
</template>

<script>
import { Button as AntButton } from "ant-design-vue";
import "ant-design-vue/lib/button/style/css";

export default {
  name: "Button",
  components: { AntButton },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: "primary"
    },
    size: {
      type: String,
      default: "default"
    }
  },
  methods: {
    handleClick() {
      this.$emit("click");
    }
  },
  computed: {}
};
</script>

<style lang="scss" module>
.button-wrapper {
  width: 100%;
  .icon-wrapper {
    display: inline-block;
    margin-right: 5px;
  }
}
</style>
<style lang="scss">
.large {
  height: 55px;
  min-width: 151px;
}
.default {
  height: 36px;
  min-width: 125px;
}
</style>
