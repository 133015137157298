<template>
  <div :class="$style['auth-register-form-wrapper']">
    <div :class="$style['forms']" v-if="!success">
      <base-form
        name="register-step-1"
        :form="formStep1"
        @submit="onSubmitFormStep1"
        v-if="step == 1"
      >
        <base-field
          :label="$t('email')"
          :errors="formStep1.errors.get('email')"
          required
        >
          <base-input name="email" type="text" v-model="formStep1.email" />
        </base-field>
        <base-field
          :label="$t('password')"
          :errors="formStep1.errors.get('password')"
          required
        >
          <base-input
            name="password"
            type="password"
            v-model="formStep1.password"
          />
        </base-field>
        <base-field
          :label="$t('retypePassword')"
          :errors="formStep1.errors.get('password_confirmation')"
          required
        >
          <base-input
            name="password_confirmation"
            type="password"
            v-model="formStep1.password_confirmation"
          />
        </base-field>
        <base-button
          :loading="formStep1.loading"
          :disabled="
            formStep1.loading || formStep1.errors.any() || !termsAccepted
          "
          @click="onSubmitFormStep1"
        >
          {{ $t("continue") }}
        </base-button>
        <span :class="$style['terms-info']">
          <BaseCheckbox v-model="termsAccepted"
            >{{ $t("agreementConfirmation") }}
            {{ $t("agreementConfirmation") }}
            <a href="/privacy">{{ $t("privacy") }}</a> {{ $t("and") }}
            <a href="/terms">{{ $t("terms") }}</a></BaseCheckbox
          >
        </span>
      </base-form>

      <base-form
        name="register-step-2"
        :form="formStep2"
        @submit="onSubmitFormStep2"
        v-if="step == 2"
      >
        <base-field
          :label="$t('name')"
          :errors="formStep2.errors.get('first_name')"
          required
        >
          <base-input
            name="first_name"
            type="text"
            v-model="formStep2.first_name"
          />
        </base-field>
        <base-field
          :label="$t('lastName')"
          :errors="formStep2.errors.get('last_name')"
          required
        >
          <base-input
            name="last_name"
            type="text"
            v-model="formStep2.last_name"
          />
        </base-field>
        <base-field
          :label="$t('companyName')"
          :errors="formStep2.errors.get('company_name')"
          required
        >
          <base-input
            name="company_name"
            type="text"
            v-model="formStep2.company_name"
          />
        </base-field>
        <base-field
          :label="$t('phone')"
          :errors="formStep2.errors.get('phone_number')"
          required
        >
          <base-input
            name="phone_number"
            type="text"
            v-model="formStep2.phone_number"
          />
        </base-field>
        <base-button
          :loading="formStep2.loading"
          :disabled="formStep2.loading || formStep2.errors.any()"
          @click="onSubmitFormStep2"
        >
          {{ $t("register") }}
        </base-button>
      </base-form>
    </div>
    <div v-else-if="success">
      <span>{{ $t("verifyEmailByLink") }}</span>
    </div>
  </div>
</template>

<script>
import Form from "@/services/form/Form.js";
import { registerService } from "@/services/auth";

import BaseForm from "@/components/form/BaseForm";
import BaseField from "@/components/form/BaseField";
import BaseInput from "@/components/form/BaseInput";
import BaseButton from "@/components/form/BaseButton";
import BaseCheckbox from "@/components/BaseCheckbox";

export default {
  name: "AuthRegisterForm",
  data() {
    return {
      termsAccepted: false,
      success: undefined,
      formStep1: new Form(
        {
          email: "",
          password: "",
          password_confirmation: ""
        },
        {
          email: {
            email: true,
            presence: { allowEmpty: false }
          },
          password: {
            presence: { allowEmpty: false },
            format: {
              pattern: /^(?=.*[a-z])(?=.*[A-Z]).{6,}$/,
              message: this.$t("passwordRuleMessage")
            }
          },
          password_confirmation: {
            presence: { allowEmpty: false },
            equality: "password"
          }
        },
        {
          validateOnFieldUpdate: true
        }
      ),
      formStep2: new Form(
        {
          first_name: "",
          last_name: "",
          company_name: "",
          phone_number: ""
        },
        {
          first_name: {
            presence: { allowEmpty: false }
          },
          last_name: {
            presence: { allowEmpty: false }
          },
          company_name: {
            presence: { allowEmpty: false }
          },
          phone_number: {
            numericality: true,
            presence: { allowEmpty: false }
          }
        }
      )
    };
  },
  components: { BaseForm, BaseField, BaseButton, BaseInput, BaseCheckbox },
  props: {
    step: {
      type: Number,
      required: true
    }
  },
  computed: {},
  methods: {
    onSubmitFormStep1() {
      if (!this.termsAccepted) return false;
      this.success = undefined;
      this.formStep1
        .submit(() => {
          return new Promise(resolve => {
            resolve();
          });
        })
        .then(() => {
          this.$emit("step", 2);
        });
    },
    onSubmitFormStep2() {
      this.success = undefined;
      this.formStep2
        .submit(formStep2Data => {
          return new Promise((resolve, reject) => {
            const formStep1Data = this.formStep1.data();
            const registerData = { ...formStep1Data, ...formStep2Data };
            registerService(registerData)
              .then(response => {
                resolve(response);
              })
              .catch(error => {
                if (error.status == 422) {
                  reject(error.fields);
                } else {
                  reject(error);
                }
              });
          });
        })
        .then(() => {
          this.success = true;
          this.$emit("success");
        })
        .catch(errors => {
          this.formStep1.onFail(errors);
          this.success = false;

          if (this.formStep1.errors.any()) {
            this.$emit("step", 1);
          }
        });
    }
  }
};
</script>

<style lang="scss" module>
.auth-register-form-wrapper {
  .error {
    width: 100%;
    text-align: center;
    margin-top: 10px;
    color: red;
  }

  .terms-info {
    margin-top: 30px;
    width: 100%;
    text-align: center;
    display: inline-block;
  }
}
</style>
