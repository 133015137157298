<template>
  <div :class="$style['location-entity-form-wrapper']">
    <base-form name="location-entity-form" :form="form" @submit="onSubmit">
      <two-column-grid>
        <template #left-side>
          <base-field
            :label="$t('address')"
            :errors="form.errors.get('address')"
          >
            <base-input-search
              name="address"
              v-model="form.address"
              :fetcher="addressFetcher"
              @update="handleAddressUpdate"
            />
          </base-field>
          <base-field label="GPS" :errors="form.errors.get('gps')">
            <base-input-gps name="gps" v-model="form.gps" disabled />
          </base-field>
        </template>
        <template #right-side>
          <base-field
            :label="$t('preferableSchedule')"
            :errors="form.errors.get('schedule')"
          >
            <LocationScheduleField name="schedule" v-model="form.schedule" />
          </base-field>
        </template>
      </two-column-grid>
      <base-button
        size="large"
        @click="onSubmit"
        :loading="form.loading"
        :disabled="form.loading || form.errors.any()"
        >{{ form.hasUpdateType() ? $t("update") : $t("create") }}</base-button
      >
    </base-form>
  </div>
</template>

<script>
import { OpenStreetMapProvider } from "leaflet-geosearch";
import { mapActions, mapGetters } from "vuex";

import Form from "@/services/form/Form.js";

import BaseForm from "@/components/form/BaseForm";
import BaseButton from "@/components/form/BaseButton";
import BaseField from "@/components/form/BaseField";
import BaseInputSearch from "@/components/form/BaseInputSearch";
import BaseInputGps from "@/components/form/BaseInputGps";
import LocationScheduleField from "@/components/form/LocationScheduleField";

import TwoColumnGrid from "@/components/grid/TwoColumnGrid";

export default {
  name: "LocationEntityForm",
  data() {
    return {
      success: undefined,
      error: undefined,
      provider: new OpenStreetMapProvider(),
      form: new Form(
        {
          address: "",
          gps: undefined,
          schedule: undefined
        },
        {
          address: {
            presence: { allowEmpty: false }
          },
          gps: {
            presence: { allowEmpty: false }
          },
          schedule: {
            presence: { allowEmpty: false }
          }
        },
        {
          id: this.locationId
        }
      )
    };
  },
  components: {
    BaseForm,
    BaseButton,
    BaseField,
    TwoColumnGrid,
    BaseInputSearch,
    BaseInputGps,
    LocationScheduleField
  },
  props: {
    locationId: {
      type: [Number, String],
      default: undefined
    }
  },
  computed: {
    ...mapGetters("locations", ["location"]),
    addressFetcher() {
      return {
        method: query => {
          return this.provider.search({ query: query });
        },
        mapper: result => {
          return {
            label: result.label,
            value: result.label,
            longitude: result.x.toString(),
            latitude: result.y.toString()
          };
        }
      };
    }
  },
  methods: {
    ...mapActions("locations", ["createLocation", "updateLocation"]),
    handleAddressUpdate(data) {
      this.form.setData({
        gps: {
          longitude: data.longitude,
          latitude: data.latitude
        }
      });
    },
    initializeLocation(locationId) {
      const location = this.location(locationId);
      if (location) {
        this.form.setData(
          {
            address: location.position.address,
            gps: {
              latitude: location.position.latitude,
              longitude: location.position.longitude
            },
            schedule: location.schedule
          },
          {
            id: locationId
          }
        );
      }
    },
    onSubmit() {
      this.success = undefined;
      this.form
        .submit(
          this.form.hasUpdateType() ? this.updateLocation : this.createLocation
        )
        .then(location => {
          this.success = true;

          if (!this.form.hasUpdateType()) {
            this.$emit("locationCreate", location.id);
          }

          this.$emit("success");
        })
        .catch(error => {
          this.success = false;
          console.log(error);
        });
    }
  },
  mounted() {
    if (this.form.hasUpdateType()) {
      this.initializeLocation(this.locationId);
    }
  },
  watch: {
    locationId(locationId) {
      this.initializeLocation(locationId);
    }
  }
};
</script>

<style lang="scss" module>
.location-entity-form-wrapper {
}
</style>
