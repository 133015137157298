<template>
  <MainLayout>
    <template #header-title>{{ $t("configurations") }}</template>
    <template #content>
      <div class="wrappper">
        <div :class="$style['settings-wrapper']">
          <div :class="$style['container']">
            <setup-form />
            <base-field :label="$t('excelImport')">
              <base-input-file @change="excelImport" :loading="loading" />
            </base-field>
          </div>
          <div :class="$style['wide-container']">
            <multiple-input-tag
              :tags="taskGroups"
              :label="$t('typesForTask')"
              :button-label="$t('addTask')"
              @add="createTaskGroup"
              @delete="removeTaskGroup"
            />
            <multiple-input-tag
              :tags="skills"
              :label="$t('workerSkills')"
              :button-label="$t('addSkill')"
              @add="createSkill"
              @delete="removeSkill"
            />
            <multiple-input-tag
              :tags="checkpoints"
              :label="$t('checkpoints')"
              :button-label="$t('addCheckpoint')"
              @add="createCheckpoint"
              @delete="removeCheckpoint"
            />
          </div>
        </div>
      </div>
    </template>
  </MainLayout>
</template>

<script>
import { mapState, mapActions } from "vuex";
import MainLayout from "@/layouts/MainLayout";

import SetupForm from "@/components/forms/SettingsSetupForm";
import MultipleInputTag from "@/components/forms/SettingsMultipleInputTag";
import BaseField from "@/components/form/BaseField";
import BaseInputFile from "@/components/BaseInputFile";

import { importExcel } from "@/api/repositories/configurations";

export default {
  components: {
    MainLayout,
    SetupForm,
    MultipleInputTag,
    BaseField,
    BaseInputFile
  },
  page: {
    title: "Settings",
    meta: [{ name: "Settings", content: "The Settings page." }]
  },
  data() {
    return {
      loading: false
    };
  },
  computed: {
    ...mapState("configurations", [
      "taskGroups",
      "skills",
      "checkpoints",
      "activeConfigurationId"
    ])
  },
  methods: {
    ...mapActions("configurations", [
      "createTaskGroup",
      "removeTaskGroup",
      "createSkill",
      "removeSkill",
      "createCheckpoint",
      "removeCheckpoint"
    ]),
    excelImport(file) {
      this.loading = true;
      this.$message["loading"](this.$i18n.t("importProccessing"));
      importExcel(this.activeConfigurationId, file)
        .then(() => {
          this.$message["success"](this.$i18n.t("importSuccess"));
          document.location.reload();
        })
        .catch(() => {
          this.loading = false;
          this.$message["error"](this.$i18n.t("importError"));
        });
    }
  }
};
</script>

<style lang="scss" module>
.settings-wrapper {
  width: 100%;
  height: 100%;
  background: #ffffff;
  border: 1px solid rgba(102, 102, 102, 0.08);
  border-radius: 8px;
  padding-bottom: 34px;
  padding-left: 32px;
  .container {
    width: 380px;
    margin-top: 34px;
  }
  .wide-container {
    width: 780px;
  }
}
</style>
<style lang="scss">
.settings-header {
  padding-left: 10px;
  margin-bottom: 14px;
  @extend %page-header;
}
</style>
