<template>
  <div :class="$style['input-wrapper']">
    <component
      :is="inputComponent"
      :class="{ [$style.error]: error }"
      :size="size"
      :disabled="disabled || loading"
      :addonBefore="addonBefore"
      v-model="model"
      :placeholder="placeholder"
      :name="name"
    />
  </div>
</template>

<script>
import { Input as AntInput } from "ant-design-vue";
import Password from "ant-design-vue/lib/input/Password";
import "ant-design-vue/lib/input/style/css";

export default {
  name: "Input",
  components: {
    AntInput
  },
  props: {
    type: {
      type: String,
      default: "text",
      validator: function(value) {
        return ["text", "password"].indexOf(value) !== -1;
      }
    },
    size: {
      type: String,
      default: "large"
    },
    loading: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    value: {
      type: String,
      default: ""
    },
    addonBefore: {
      type: String,
      default: null
    },
    error: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ""
    },
    name: {
      type: String,
      default: null
    }
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    },
    inputComponent() {
      switch (this.type) {
        case "text":
          return AntInput;
        case "password":
          return Password;
        default:
          return Password;
      }
    }
  },
  methods: {}
};
</script>

<style lang="scss" module>
.input-wrapper {
  .error {
    border: 2px solid red;
    border-radius: 5px;
    height: 54px;
    input {
      border: 1px solid transparent;
    }
  }
  input {
    height: 50px;
  }
}
</style>
