<template>
  <div :class="$style['workers-list-wrapper']">
    <Loading :loading="loading">
      <Input
        :class="$style['search-input']"
        v-model="query"
        :placeholder="$t('search')"
      />
      <div :class="$style.header">
        <span :class="$style['header-title']">{{ $t("workers") }}</span>
        <Icon
          :class="$style['header-icon']"
          name="workers-list"
          @click="filterClicked"
        />
      </div>
      <div
        v-for="(worker, index) in result"
        :key="index"
        :class="[$style.worker]"
      >
        <Checkbox
          :value="isWorkerChecked(worker.id)"
          :class="[$style.checkbox]"
          @input="workerCheckboxUpdate(worker.id)"
        >
          {{ worker.first_name + " " + worker.last_name }}
        </Checkbox>
        <div
          :class="$style['worker-indicator']"
          :style="{
            '--worker-color': worker.color
          }"
        ></div>
      </div>
    </Loading>
  </div>
</template>

<script>
import Icon from "@/components/ui/Icon";
import Checkbox from "@/components/ui/Checkbox";

export default {
  name: "WorkersList",
  data() {
    return {
      checked: [],
      query: "",
      searchResult: []
    };
  },
  components: { Icon, Checkbox },
  props: {
    value: {
      type: Array,
      default() {
        return [];
      }
    },
    workers: {
      type: [Array],
      required: true
    }
  },
  async mounted() {
    this.initialize();
  },
  computed: {
    loading() {
      return this.workers.length == 0;
    },
    result() {
      return this.hasSearchResults() ? this.searchResult : this.workers;
    }
  },
  methods: {
    initialize() {
      this.checked = this.workers.map(worker => worker);
      this.$emit("input", this.checked);
    },
    isWorkerChecked(workerId) {
      return !!this.checked.find(worker => worker.id == workerId);
    },
    workerCheckboxUpdate(workerId) {
      if (this.isWorkerChecked(workerId)) {
        if (this.willBeEmpty()) {
          return false;
        }
        this.checked.splice(
          this.checked.findIndex(worker => worker.id == workerId),
          1
        );
      } else {
        this.checked.push(this.workers.find(worker => worker.id == workerId));
      }

      this.$emit("input", this.checked);
    },
    willBeEmpty() {
      return this.checked.length == 1;
    },
    search(query) {
      if (query.length > 0) {
        this.searchResult = this.workers.filter(worker => {
          return (worker.first_name + " " + worker.last_name)
            .toLowerCase()
            .includes(query.toLowerCase());
        });
      } else {
        this.searchResult = [];
      }
    },
    hasSearchResults() {
      return this.searchResult.length > 0;
    },
    filterClicked() {
      this.$emit("filterClicked");
    }
  },
  watch: {
    query: function(query) {
      this.search(query);
    },
    workers: function() {
      this.initialize();
    }
  }
};
</script>

<style lang="scss" module>
.workers-list-wrapper {
  background: #f7f8fc;
  width: 215px;
  height: 100vh;
  padding-right: 20px;
  .search-input {
    margin-bottom: 15px;
  }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 14px;
    .header-title {
      font-weight: 600;
      font-size: 14px;
      color: $primary-black;
    }
    .header-icon {
      margin-top: 5px;
      cursor: pointer;
    }
  }
  .worker {
    height: 30px;
    margin-bottom: 5px;
    .checkbox {
      float: left;
    }
    .worker-indicator {
      background: var(--worker-color);
      height: 15px;
      width: 15px;
      float: right;
      border-radius: 20px;
      margin-top: 2.5px;
    }
  }
}
</style>
