import { jsonApi } from "@/api";

export const fetchById = setupId => {
  return jsonApi.show("setups", setupId, {
    include: "workers,locations,tasks,skills,task-groups,checkpoints"
  });
};

export const update = (setupId, data) => {
  return jsonApi.update("setups", setupId, data);
};

export const createTaskGroup = (setupId, data) => {
  return jsonApi.store(
    "task-groups",
    {
      name: data.name,
      setup: { id: setupId, type: "setups" }
    },
    ["setup"]
  );
};

export const removeTaskGroup = taskGroupId => {
  return jsonApi.remove("task-groups", taskGroupId);
};

export const createSkill = (setupId, data) => {
  return jsonApi.store(
    "skills",
    {
      name: data.name,
      setup: { id: setupId, type: "setups" }
    },
    ["setup"]
  );
};

export const removeSkill = skillId => {
  return jsonApi.remove("skills", skillId);
};

export const createCheckpoint = (setupId, data) => {
  return jsonApi.store(
    "checkpoints",
    {
      name: data.name,
      setup: { id: setupId, type: "setups" }
    },
    ["setup"]
  );
};

export const removeCheckpoint = checkpointId => {
  return jsonApi.remove("checkpoints", checkpointId);
};

///

export const show = id => {
  return jsonApi.show("setups", id, {
    include: "workers,locations,tasks,skills,task-groups,checkpoints"
  });
};

export const showSetupWithAllRelations = id => {
  return jsonApi.show("setups", id, {
    include:
      "workers,locations,tasks.location,tasks.group,skills,task-groups,checkpoints"
  });
};
