export default class Task {
  #id;
  #duration;
  #location;
  #group;

  #schedule;

  constructor(task) {
    this.#id = task.id;
    this.#duration = task.duration;
    this.#location = task.location;
    this.#group = task.group;
  }

  getId() {
    return this.#id;
  }

  getDuration() {
    return this.#duration;
  }

  getLocation() {
    return this.#location;
  }

  getGroup() {
    return this.#group;
  }
}
