<template>
  <div :class="$style['popover-table-row-actions-wrapper']">
    <Popover>
      <div :class="$style.content" slot="content">
        <div :class="$style['popover-row']" @click="edit">
          <span>{{ $t("edit") }}</span>
          <Icon name="edit" fill="#000000" :class="$style.icon" />
        </div>
        <div :class="$style['popover-row']" @click="remove">
          <span>{{ $t("delete") }}</span>
          <Icon name="delete" fill="#000000" :class="$style.icon" />
        </div>
      </div>
      <Icon name="more" fill="#000000" pointer />
    </Popover>
  </div>
</template>

<script>
export default {
  name: "PopoverTableRowActions",
  data() {
    return {};
  },
  components: {},
  props: {},
  computed: {},
  methods: {
    edit() {
      this.$emit("edit");
    },
    remove() {
      this.$emit("remove");
    }
  }
};
</script>

<style lang="scss" module>
.content {
  width: fit-content;
  .popover-row {
    cursor: pointer;
    border-radius: 5px;
    padding: 5px 10px 5px 10px;
    width: 100%;
    .icon {
      float: left;
      margin-right: 10px;
    }
  }
  .popover-row:hover {
    background: $primary-gray;
  }
}
</style>
