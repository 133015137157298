<template>
  <div :class="$style['status-label-wrapper']">
    <div
      :class="{
        [$style.circle]: true,
        [$style.is_active]: status,
        [$style.is_inactive]: !status
      }"
    ></div>
    <span class="label">{{ statusName }}</span>
  </div>
</template>

<script>
export default {
  name: "StatusLabel",
  components: {},
  props: {
    status: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    statusName() {
      switch (this.status) {
        case true:
          return this.$i18n.t('active');
        default:
          return this.$i18n.t('inactive');
      }
    }
  },
  methods: {}
};
</script>

<style lang="scss" module>
.status-label-wrapper {
  width: fit-content;
  height: 20px;
  display: flex;
  .circle {
    padding-left: 10px;
    &:before {
      content: "";
      width: 14px;
      height: 14px;
      border-radius: 50%;
      display: inline-block;
      vertical-align: middle;
      margin: -1px 5px 0 -10px;
    }

    &.is_active {
      &:before {
        background-color: $status_green;
      }
    }

    &.is_inactive:before {
      background-color: $status_red;
    }

    &.is_deleted:before {
      background-color: $primary-gray;
    }
  }
  .label {
    margin-top: 1px;
    margin-left: 5px;
  }
}
</style>
