var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('MainLayout',{scopedSlots:_vm._u([{key:"header-title",fn:function(){return [_vm._v(_vm._s(_vm.$t("workers")))]},proxy:true},{key:"header-content",fn:function(){return [_c('HeaderTableContent',{attrs:{"entity":_vm.$t('workers')},on:{"search":_vm.tableSearch,"add":_vm.addWorkerEntity}})]},proxy:true},{key:"content",fn:function(){return [_c('BulkNavigation',{directives:[{name:"show",rawName:"v-show",value:(_vm.selected.length),expression:"selected.length"}],attrs:{"buttons":{
        edit: {
          name: _vm.$t('customWorkingDays'),
          icon: 'calendar',
          iconFillColor: '#333333'
        },
        delete: { name: _vm.$t('deleteWorkers') }
      }},on:{"edit":function($event){return _vm.$drawer.show('right-bar')},"remove":_vm.removeBulk}}),_c('WorkersTable',{attrs:{"selectedWorkers":_vm.selected,"searchQuery":_vm.searchQuery,"actionable":""},on:{"update:selectedWorkers":function($event){_vm.selected=$event},"update:selected-workers":function($event){_vm.selected=$event},"editWorker":_vm.edit,"removeWorker":_vm.remove}}),_c('ModalPlugin',{attrs:{"name":"worker-modal"}},[_c('TwoColumnGrid',{scopedSlots:_vm._u([{key:"left-side",fn:function(){return [_c('worker-entity-form',{attrs:{"workerId":_vm.workerId},on:{"workerCreate":_vm.setWorkerAsActive}})]},proxy:true},{key:"right-side",fn:function(){return [_c('worker-schedules-form',{attrs:{"workerId":_vm.workerId},on:{"success":function($event){return _vm.$modal.hide('worker-modal')}}})]},proxy:true}])})],1),_c('DrawerPlugin',{attrs:{"name":"right-bar","type":"right"}},[_c('workers-right-bar',{attrs:{"workersSelected":_vm.selected}})],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }