import { notification } from "ant-design-vue";
import "ant-design-vue/lib/notification/style/css";

const Notification = {
  install(Vue) {
    Vue.prototype.$notification = notification;
  }
};

export default Notification;
