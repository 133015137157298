var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$style['task-entity-form-wrapper']},[_c('TwoColumnGrid',{scopedSlots:_vm._u([{key:"left-side",fn:function(){return [_c('base-form',{attrs:{"name":"task-entity-form","form":_vm.form},on:{"submit":_vm.onSubmit}},[_c('base-field',{attrs:{"label":_vm.$t('taskType'),"errors":_vm.form.errors.get('task-type')}},[_c('base-select-tags',{attrs:{"name":"task-type","options":_vm.taskGroups,"mappers":{
              options: function (option) {
                return { label: option.name, value: option.id };
              },
              value: function (value) {
                return value.id;
              }
            }},on:{"input":function($event){return _vm.form.errors.clear('task-type')}},model:{value:(_vm.form['task-type']),callback:function ($$v) {_vm.$set(_vm.form, 'task-type', $$v)},expression:"form['task-type']"}})],1),_c('base-field',{attrs:{"label":_vm.$t('location'),"errors":_vm.form.errors.get('location')}},[_c('base-select-tags',{attrs:{"name":"location","options":_vm.locations,"mappers":{
              options: function (option) {
                return { label: option.position.address, value: option.id };
              },
              value: function (value) {
                return value.id;
              }
            }},on:{"input":function($event){return _vm.form.errors.clear('location')}},model:{value:(_vm.form.location),callback:function ($$v) {_vm.$set(_vm.form, "location", $$v)},expression:"form.location"}})],1),_c('base-field',{attrs:{"label":_vm.$t('duration'),"errors":_vm.form.errors.get('duration')}},[_c('base-minutes-picker',{attrs:{"name":"duration"},on:{"input":function($event){return _vm.form.errors.clear('duration')}},model:{value:(_vm.form.duration),callback:function ($$v) {_vm.$set(_vm.form, "duration", $$v)},expression:"form.duration"}})],1),_c('base-field',{attrs:{"label":_vm.$t('skills'),"errors":_vm.form.errors.get('skills')}},[_c('base-select-tags',{attrs:{"name":"skills","mode":"multiple","options":_vm.skills,"mappers":{
              options: function (option) {
                return { label: option.name, value: option.id };
              },
              value: function (value) {
                return value.id;
              }
            },"disabled":_vm.form.worker},on:{"input":_vm.skillsAssignChanged},model:{value:(_vm.form.skills),callback:function ($$v) {_vm.$set(_vm.form, "skills", $$v)},expression:"form.skills"}})],1),_c('base-field',{attrs:{"label":_vm.$t('worker'),"errors":_vm.form.errors.get('worker')}},[_c('base-select-tags',{attrs:{"name":"worker","options":_vm.workers,"allowClear":"","mappers":{
              options: function (option) {
                return { label: option.first_name, value: option.id };
              },
              value: function (value) {
                return value.id;
              }
            },"disabled":_vm.form.skills.length > 0},on:{"input":_vm.workerAssignChanged},model:{value:(_vm.form.worker),callback:function ($$v) {_vm.$set(_vm.form, "worker", $$v)},expression:"form.worker"}})],1),_c('base-field',{attrs:{"label":_vm.$t('checkpoints'),"errors":_vm.form.errors.get('checkpoints')}},[_c('base-select-tags',{attrs:{"name":"checkpoints","mode":"multiple","options":_vm.checkpoints,"allowClear":"","mappers":{
              options: function (option) {
                return { label: option.name, value: option.id };
              },
              value: function (value) {
                return value.id;
              }
            }},on:{"input":_vm.checkpointsAssignChanged},model:{value:(_vm.form.checkpoints),callback:function ($$v) {_vm.$set(_vm.form, "checkpoints", $$v)},expression:"form.checkpoints"}})],1)],1)]},proxy:true},{key:"right-side",fn:function(){return [_c('base-field',{attrs:{"errors":_vm.form.errors.get('schedule')}},[_c('schedule-field',{attrs:{"name":"schedule"},model:{value:(_vm.form.schedule),callback:function ($$v) {_vm.$set(_vm.form, "schedule", $$v)},expression:"form.schedule"}})],1)]},proxy:true}])}),_c('base-button',{attrs:{"size":"large","loading":_vm.form.loading,"disabled":_vm.form.loading || _vm.form.errors.any()},on:{"click":_vm.onSubmit}},[_vm._v(_vm._s(_vm.form.hasUpdateType() ? _vm.$t("update") : _vm.$t("create")))])],1)}
var staticRenderFns = []

export { render, staticRenderFns }