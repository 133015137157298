import Login from "@/views/Login.vue";
import Register from "@/views/Register.vue";
import EmailVerification from "@/views/EmailVerification.vue";
import ForgotPassword from "@/views/ForgotPassword.vue";
import ResetPassword from "@/views/ResetPassword.vue";
import Privacy from "@/views/Privacy.vue";
import TermsOfUse from "@/views/TermsOfUse.vue";

const routes = [
  {
    path: "/login",
    name: "login",
    component: Login,
    onlyLoggedOut: true
  },
  {
    path: "/register",
    name: "register",
    component: Register,
    onlyLoggedOut: true
  },
  {
    path: "/verify/:userId/:hash",
    name: "verify",
    component: EmailVerification,
    props: true,
    onlyLoggedOut: true
  },
  {
    path: "/forgot",
    name: "forgot",
    component: ForgotPassword,
    onlyLoggedOut: true
  },
  {
    path: "/reset",
    name: "reset",
    component: ResetPassword,
    onlyLoggedOut: true
  },
  {
    path: "/privacy",
    name: "privacy",
    component: Privacy
  },
  {
    path: "/terms",
    name: "terms",
    component: TermsOfUse
  }
];

export default routes.map(route => {
  const meta = {
    public: true,
    onlyLoggedOut: route.onlyLoggedOut
  };
  return { ...route, meta };
});
