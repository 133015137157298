var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$style['map-view-wrapper']},[_c('MapDaypicker',{model:{value:(_vm.mapDayPickerDate),callback:function ($$v) {_vm.mapDayPickerDate=$$v},expression:"mapDayPickerDate"}}),_c('div',{class:_vm.$style['map-wrapper']},[_c('l-map',{attrs:{"zoom":_vm.zoom,"center":_vm.currentCenter}},[_c('l-tile-layer',{attrs:{"url":_vm.url,"attribution":_vm.attribution}}),_vm._l((_vm.routes),function(route){return _c('l-layer-group',{key:route.getId()},[_c('l-layer-group',_vm._l((route.getVisits()),function(visit,index){return _c('l-marker',{key:route.getId() + '-' + index,attrs:{"lat-lng":visit.isHome()
                ? {
                    lat: _vm.setupObject
                      .getWorkerById(route.getWorkerId())
                      .getHome().latitude,
                    lng: _vm.setupObject
                      .getWorkerById(route.getWorkerId())
                      .getHome().longitude
                  }
                : {
                    lat: visit.getFirstRoutePoint().getGps().latitude,
                    lng: visit.getFirstRoutePoint().getGps().longitude
                  }},on:{"mouseover":function($event){return _vm.mouseoverRoute(route)},"mouseleave":_vm.mouseleaveRoute}},[_c('l-icon',{attrs:{"icon-anchor":visit.isHome() ? [20, 20] : [17, 37]}},[_c('Visit',{attrs:{"worker":_vm.setupObject.getWorkerById(route.getWorkerId()),"visit":visit,"detailed":_vm.routeIsDetailed(route)}})],1)],1)}),1),_c('l-polyline',{attrs:{"lat-lngs":route.getWayPoints().map(function (wayPoint) {
              return { lat: wayPoint.latitude, lng: wayPoint.longitude };
            }),"color":_vm.setupObject.getWorkerById(route.getWorkerId()).getColor(),"weight":5},on:{"mouseover":function($event){return _vm.mouseoverRoute(route)},"mouseleave":_vm.mouseleaveRoute}})],1)})],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }