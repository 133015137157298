<template>
  <div :class="$style['select-wrapper']">
    <AntSelect
      :class="{ [$style.select]: true, [$style.error]: error }"
      :size="size"
      :mode="mode"
      :options="finalOptions"
      :loading="loading || fetching"
      :disabled="disabled || loading || fetching"
      v-model="model"
      :showArrow="true"
    >
      <template v-if="loading" slot="notFoundContent">
        Loading..
      </template>
    </AntSelect>
  </div>
</template>

<script>
import { Select as AntSelect } from "ant-design-vue";
import "ant-design-vue/lib/select/style/css";

export default {
  name: "Select",
  components: { AntSelect },
  data() {
    return {
      fetching: false,
      fetchedOptions: null
    };
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: "large"
    },
    mode: {
      type: String,
      default: "default"
    },
    placeholder: {
      type: String,
      default: "Placeholder"
    },
    value: {
      type: Array,
      default() {
        return [];
      }
    },
    error: {
      type: Boolean,
      default: false
    },
    options: {
      type: Array,
      default() {
        return [];
      }
    },
    optionsFetcher: {
      type: Object,
      default() {
        return {
          fetch: false,
          method: undefined,
          mapper: undefined
        };
      }
    }
  },
  computed: {
    model: {
      get() {
        return !this.loading && !this.fetching ? this.value : null;
      },
      set(value) {
        this.$emit("input", value);
        this.detectAction(value);
      }
    },
    finalOptions() {
      if (this.fetchedOptions) {
        return this.fetchedOptions;
      }
      return this.options;
    }
  },
  async created() {
    if (this.optionsFetcher.fetch) {
      this.fetching = true;
      await this.optionsFetcher.method().then(options => {
        this.fetchedOptions = options.map(this.optionsFetcher.mapper);
      });
      this.fetching = false;
    }
  },
  methods: {
    detectAction(value) {
      if (this.mode !== "default") {
        if (this.value.length > value.length) {
          const difference = this.value.filter(x => !value.includes(x));
          this.$emit("delete", difference);
        } else {
          const difference = value.filter(x => !this.value.includes(x));
          this.$emit("add", difference);
        }
      }
    }
  }
};
</script>

<style lang="scss" module>
.select-wrapper {
  min-width: 100px;
  width: 100%;
  .select {
    width: 100%;
  }
  .error {
    border: 2px solid red;
    border-radius: 5px;
  }
}
</style>
<style lang="scss">
.ant-select {
  .ant-select-selection--multiple {
    .ant-select-selection__rendered {
      height: 50px;
      .ant-select-selection__choice {
        background: #d9eefe;
        margin-top: 9px;
        color: $primary-black;
      }
    }
    .ant-select-arrow {
      cursor: pointer;
      top: 27px;
    }
  }
}
</style>
