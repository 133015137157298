<template>
  <div :class="$style['not-assigned-popup-wrapper']">
    <h2 :class="$style.title">{{ $t("tasksAreNotAssigned") }}</h2>
    <p :class="$style.date">{{ day.date }}</p>
    <div :class="$style['task-wrapper']" v-for="(task, i) in tasks" :key="i">
      <PicCircle :labeled="true" :label="task.description"
        ><slot><Icon fill="#ffffff" name="task"/></slot
      ></PicCircle>
    </div>
    <p :class="$style.sub">
      {{ $t("thereIsNoWorkerToHandleTasks") }}
    </p>
  </div>
</template>

<script>
import PicCircle from "@/components/ui/PicCircle";

export default {
  name: "NotAssignedErrorPopup",
  data() {
    return {};
  },
  components: { PicCircle },
  props: {
    day: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  computed: {
    tasks() {
      return this.day.day.data.tasks_without_workers;
    }
  },
  methods: {}
};
</script>

<style lang="scss" module>
.not-assigned-popup-wrapper {
  color: $primary-black;
  text-align: center;
  .title {
    font-weight: 600;
    font-size: 24px;
    line-height: 25px;
    margin-bottom: 4px;
  }
  .date {
    font-size: 16px;
    line-height: 25px;
    margin-bottom: 21px;
  }
  .task-wrapper {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  .sub {
    font-weight: 600;
    font-size: 16px;
    line-height: 25px;
  }
}
</style>
