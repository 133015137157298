<template>
  <div class="localization-selector">
    <country-flag :country="flag" size="small" class="flag" />
    <AntSelect
      class="localization-selector__input"
      size="large"
      :options="locales"
      :showArrow="false"
      :value="locale"
      @change="setLocale"
    />
  </div>
</template>

<script>
import { Select as AntSelect } from "ant-design-vue";
import "ant-design-vue/lib/select/style/css";

import { mapGetters } from "vuex";

import CountryFlag from "vue-country-flag";

export default {
  name: "LocalizationSelector",
  data() {
    return {
      locales: [
        { value: "en", label: "En" },
        { value: "de", label: "De" }
      ]
    };
  },
  components: { AntSelect, CountryFlag },
  props: {},
  computed: {
    ...mapGetters("localization", ["locale"]),
    flag() {
      switch (this.locale) {
        case "en":
          return "gb";
        default:
          return this.locale;
      }
    }
  },
  methods: {
    setLocale(locale) {
      this.$i18n.locale = locale;
      this.$store.dispatch("localization/setLocale", locale);
      window.location.reload();
    }
  }
};
</script>

<style lang="scss">
.localization-selector {
  width: 65px;
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 5px;
}
.localization-selector__input {
  width: 45px;
}
.flag {
}
</style>
