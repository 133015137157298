<template>
  <div :class="$style['main-layout-container']">
    <AntLayout :class="$style['layout-wrapper']">
      <ALayoutSider width="68px" :class="$style['sidebar-layout']">
        <Sidebar></Sidebar>
      </ALayoutSider>
      <AntLayout>
        <AntHeader>
          <Header :contentOn="headerContentOn">
            <template #title><slot name="header-title"></slot></template>
            <template #content><slot name="header-content"></slot></template>
          </Header>
        </AntHeader>
        <AntContent>
          <slot name="content"></slot>
        </AntContent>
      </AntLayout>
    </AntLayout>
    <Modal />
  </div>
</template>

<script>
import { Layout as AntLayout } from "ant-design-vue";
import ALayoutSider from "ant-design-vue/lib/layout/Sider";
import Layouts from "ant-design-vue/lib/layout/layout";
import Header from "@/components/layout/Header";
import Sidebar from "@/components/layout/Sidebar";

import "ant-design-vue/lib/layout/style/css";

export default {
  name: "MainLayout",
  data() {
    return {};
  },
  components: {
    AntLayout,
    ALayoutSider,
    Sidebar,
    AntHeader: Layouts.Header,
    Header,
    AntContent: Layouts.Content
  },
  props: {
    headerContentOn: {
      type: String,
      default: "right"
    }
  },
  computed: {},
  methods: {}
};
</script>

<style lang="scss">
.ant-layout-sider {
  background: #ffffff;
  box-shadow: 6px 0px 18px rgba(0, 0, 0, 0.06);
  z-index: 3;
  position: "fixed";
}
.ant-layout-header {
  background: #ffffff;
  border-bottom: 1px solid $primary-gray;
  padding: 0;
  z-index: 2;
}
.ant-layout-content {
  background: #f7f8fc;
  position: relative;
  overflow: "hidden";
  z-index: 0;
  padding: 20px;
}
</style>

<style lang="scss" module>
.main-layout-container {
  margin: 0 auto;
  .layout-wrapper {
    min-height: 100vh;
  }
  .sidebar-layout {
    height: auto;
  }
}
</style>
