import { jsonApi, restApi } from "@/api";

export const fetchAll = () => {
  return jsonApi.index("configurations");
};

export const importExcel = (configurationId, file) => {
  let formData = new FormData();
  formData.append("file", file);
  return restApi.post(
    `/custom/configurations/${configurationId}/import/excel`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }
  );
};
