import * as repositoryTask from "@/api/repositories/tasks";

export const tasks = {
  state: {
    tasks: []
  },
  getters: {
    // tasks: (state, getters, rootState, rootGetters) =>
    //   state.tasks.map(task => {
    //     task.group = rootGetters["configurations/taskGroup"](task.group.id);
    //     task.location = rootGetters["locations/location"](task.location.id);
    //     task.skills = task.skills.map(skill =>
    //       rootGetters["configurations/skill"](skill.id)
    //     );
    //     return task;
    //   }),
    task: state => taskId => state.tasks.find(task => task.id == taskId)
  },
  mutations: {
    SET_TASKS(state, tasks) {
      state.tasks = tasks;
    },
    ADD_TASK(state, task) {
      state.tasks.push(task);
    },
    REMOVE_TASKS(state, tasksId) {
      tasksId.forEach(id => {
        const taskIndex = state.tasks.findIndex(task => id == task.id);
        state.tasks.splice(taskIndex, 1);
      });
    },
    UPDATE_TASK(state, taskUpdated) {
      const taskIndex = state.tasks.findIndex(
        task => task.id == taskUpdated.id
      );
      state.tasks.splice(taskIndex, 1, taskUpdated);
    },
    UPDATE_TASKS_SCHEDULE_DATES(state, tasks) {
      console.log("mut", tasks);
      tasks.forEach(taskUpdated => {
        const taskIndex = state.tasks.findIndex(
          task => task.id == taskUpdated.id
        );

        let task = state.tasks[taskIndex];
        task.schedule.from = taskUpdated.schedule.from;
        task.schedule.to = taskUpdated.schedule.to;

        state.tasks.splice(taskIndex, 1, task);
      });
    },
    REMOVE_GROUP(state, groupId) {
      const tasks = state.tasks.filter(
        task => task.group && task.group.id == groupId
      );
      tasks.forEach((task, index) => {
        task.group = null;
        state.tasks.splice(index, 1, task);
      });
    },
    REMOVE_LOCATIONS(state, locationsId) {
      const tasks = state.tasks.filter(
        task =>
          task.location &&
          locationsId.find(locationId => locationId == task.location.id)
      );
      tasks.forEach((task, index) => {
        task.location = null;
        state.tasks.splice(index, 1, task);
      });
    },
    REMOVE_SKILL(state, skillId) {
      const tasks = state.tasks;
      tasks.forEach((task, index) => {
        task.skills = task.skills.filter(skill => {
          return skill.id != skillId;
        });
        state.tasks.splice(index, 1, task);
      });
    },
    RESET(state) {
      state.tasks = [];
    }
  },
  actions: {
    initializeTasks({ commit, rootGetters }) {
      return repositoryTask
        .fetchAllTasks(rootGetters["configurations/activeSetupId"])
        .then(tasks => {
          commit("SET_TASKS", tasks);
          return tasks;
        });
    },
    createTask({ commit, rootGetters }, data) {
      return repositoryTask
        .createTask(rootGetters["configurations/activeSetupId"], data)
        .then(task => {
          commit("ADD_TASK", task);
          return task;
        });
    },
    updateTask({ commit }, data) {
      return repositoryTask.updateTask(data.id, data.data).then(task => {
        commit("UPDATE_TASK", task);
        return task;
      });
    },
    updateTasksScheduleDates({ commit }, data) {
      return repositoryTask
        .updateTasksScheduleDates(data.tasksId, data.data)
        .then(tasks => {
          commit("UPDATE_TASKS_SCHEDULE_DATES", tasks);
          return tasks;
        });
    },
    removeTask({ commit }, taskId) {
      return repositoryTask.deleteTask(taskId).then(() => {
        commit("REMOVE_TASKS", [taskId]);
      });
    },
    removeTasks({ commit }, tasksId) {
      return repositoryTask.deleteTasks(tasksId).then(() => {
        commit("REMOVE_TASKS", tasksId);
      });
    },
    removeGroup({ commit }, groupId) {
      commit("REMOVE_GROUP", groupId);
    },
    removeLocations({ commit }, locationsId) {
      commit("REMOVE_LOCATIONS", locationsId);
    },
    removeSkill({ commit }, skillId) {
      commit("REMOVE_SKILL", skillId);
    },
    removeCheckpoint({ commit }, checkpointId) {
      commit("REMOVE_CHECKPOINT", checkpointId);
    },
    reset({ commit }) {
      commit("RESET");
    }
  }
};
