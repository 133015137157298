<template>
  <MainLayout headerContentOn="center">
    <template #header-title
      ><p>{{ $t("workPlanCreation") }}</p></template
    >
    <template #header-content>
      <div :class="$style['progress-wrapper']">
        <CreationProgressBar :current="currentStep" />
      </div>
    </template>
    <template #content>
      <div :class="$style['content-wrapper']">
        <WorkersTable
          v-show="currentStep == 0"
          :selectedWorkers.sync="selectedWorkers"
        />
        <LocationsTable
          v-show="currentStep == 1"
          :selectedLocations.sync="selectedLocations"
        />
        <TasksTable
          v-show="currentStep == 2"
          :locationsFilter="selectedLocations.map(location => location.id)"
          :selectedTasks.sync="selectedTasks"
        />

        <div v-show="currentStep == 3">
          <div :class="$style['name-edit']">
            <BaseTextEditable v-model="planName" updateOnClickOutside />
          </div>
          <div :class="$style['date-range-wrapper']">
            <p :class="$style['table-name']">{{ $t("dates") }}</p>
            <BaseDateRangePicker
              v-model="dateRange"
              pastDisabled
              enormousDisabled
            />
          </div>
          <div :class="$style['summary-wrapper']">
            <Plane>
              <div :class="$style['review-title']">
                <Icon name="users" :height="52" />
                <p>{{ $t("workers") }}</p>
              </div>
              <div
                :class="$style['review-item']"
                v-for="(item, i) in selectedWorkers"
                :key="i"
              >
                <PicCircle
                  :labeled="true"
                  helper="initials"
                  :label="`${item.first_name} ${item.last_name}`"
                />
              </div>
            </Plane>
            <Plane>
              <div :class="$style['review-title']">
                <Icon name="locations" :height="52" />
                <p>{{ $t("locations") }}</p>
              </div>
              <div
                :class="$style['review-item']"
                v-for="(item, i) in selectedLocations"
                :key="i"
              >
                <PicCircle
                  :labeled="true"
                  helper="initials"
                  :label="
                    item.position.address
                      .split(', ')
                      .slice(0, 2)
                      .join(', ')
                  "
                />
              </div>
            </Plane>
            <Plane>
              <div :class="$style['review-title']">
                <Icon name="tasks" :height="52" />
                <p>{{ $t("tasks") }}</p>
              </div>
              <div
                :class="$style['review-item']"
                v-for="(item, i) in filteredSelectedTasks"
                :key="i"
              >
                <PicCircle
                  :labeled="true"
                  helper="initials"
                  :label="
                    item.group ? taskGroup(item.group.id).name : 'Undefined'
                  "
                />
              </div>
            </Plane>
          </div>
        </div>

        <div v-if="currentStep < 4" :class="$style['btn-wrapper']">
          <Button
            :disabled="previousDisabled"
            @click="previousStep"
            size="large"
          >
            {{ $t("back") }}
          </Button>
          <Button
            v-if="currentStep < 3"
            :disabled="nextDisabled"
            @click="nextStep"
            size="large"
          >
            {{ $t("next") }}
          </Button>
          <Button v-else @click="createBuildRequest" size="large">
            {{ $t("createWorkPlan") }}
          </Button>
        </div>

        <div v-if="currentStep == 4">
          <div :class="$style['last-step']">
            <Icon name="rp_success" :height="282" />
            <p :class="$style.status">{{ $t("workPlanCreationInProgress") }}</p>
            <p :class="$style['status-sub']">
              {{ $t("pleaseWaitOrStartOver") }}
            </p>
          </div>
        </div>
      </div>
    </template>
  </MainLayout>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";

import MainLayout from "@/layouts/MainLayout";

import WorkersTable from "@/components/tables/WorkersTable";
import LocationsTable from "@/components/tables/LocationsTable";
import TasksTable from "@/components/tables/TasksTable";

import CreationProgressBar from "@/components/build/CreationProgressBar";

import BaseTextEditable from "@/components/form/BaseTextEditable";

import BaseDateRangePicker from "@/components/form/BaseDateRangePicker";

export default {
  page: {
    title: "Create work plan",
    meta: [{ name: "description", content: "The Create work plan page." }]
  },
  components: {
    MainLayout,
    CreationProgressBar,
    WorkersTable,
    LocationsTable,
    TasksTable,
    BaseTextEditable,
    BaseDateRangePicker
  },
  data() {
    return {
      currentStep: 0,
      selectedWorkers: [],
      selectedLocations: [],
      selectedTasks: [],
      planName: "Untitled",
      dateRange: [
        moment().format("YYYY-MM-DD"),
        moment()
          .add(1, "month")
          .format("YYYY-MM-DD")
      ]
    };
  },
  computed: {
    ...mapGetters("configurations", ["taskGroup"]),
    nextDisabled() {
      return (
        (this.currentStep == 0 && this.selectedWorkers.length === 0) ||
        (this.currentStep == 1 && this.selectedLocations.length === 0) ||
        (this.currentStep == 2 && this.filteredSelectedTasks.length === 0)
      );
    },
    previousDisabled() {
      return this.currentStep == 0;
    },
    planNameFormatted() {
      return this.planName == "" || this.planName == " "
        ? "Untitled"
        : this.planName;
    },
    filteredSelectedTasks() {
      return this.selectedTasks.filter(task =>
        this.selectedLocations.find(location => location.id == task.location.id)
      );
    }
  },
  methods: {
    ...mapActions("builds", ["launchBuild"]),
    nextStep() {
      this.currentStep = this.currentStep + 1;
    },
    previousStep() {
      this.currentStep = this.currentStep - 1;
    },
    createBuildRequest() {
      setTimeout(() => {
        this.nextStep();
        this.launchBuild({
          title: this.planNameFormatted,
          workers: this.selectedWorkers.map(item => {
            return item.id;
          }),
          tasks: this.filteredSelectedTasks.map(item => {
            return item.id;
          }),
          from: this.dateRange[0],
          to: this.dateRange[1]
        }).then(build => {
          this.$router.push({
            name: "route_plan",
            params: { id: build.id }
          });
        });
      }, 500);
    }
  }
};
</script>

<style lang="scss" module>
.content-wrapper {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 34px;
  .title {
    display: flex;
    justify-content: space-between;
    .actions div {
      padding: 0 10px 0 10px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      span {
        margin-left: 11px;
      }
      float: left;
    }
  }
  .table-name {
    font-weight: 600;
    font-size: 16px;
    line-height: 25px;
    color: #1c2a4a;
  }
  .plan-name {
    font-weight: 600;
    font-size: 16px;
    line-height: 25px;
    color: #1c2a4a;
    margin-left: 5px;
  }
  .name-edit {
    display: flex;
    align-items: center;
  }
  .date-range-wrapper {
    width: 500px;
    margin-top: 20px;
  }
  .btn-wrapper {
    margin: 0 auto;
    display: flex;
    column-gap: 20px;
  }
}
.progress-wrapper {
  margin-left: 40px;
}
.summary-wrapper {
  margin-top: 9px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 35px;
}
.review-title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 25px;
  p {
    margin-left: 10px;
    font-size: 16px;
    line-height: 25px;
    font-weight: 600;
    color: $primary-black;
  }
}
.review-item {
  margin-bottom: 18px;
}
.last-step {
  width: 350px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  text-align: center;
  .status {
    font-weight: 600;
    font-size: 32px;
    line-height: 38px;
    color: $primary-black;
    margin-bottom: 24px;
  }
  .status-sub {
    font-size: 24px;
    line-height: 29px;
    color: #000000;
  }
}
</style>
