<template>
  <div :class="$style['guest-layout-container']">
    <AntRow>
      <AntCol :span="10">
        <AuthLeftBar>
          <slot name="illustration"></slot>
        </AuthLeftBar>
      </AntCol>
      <AntCol :span="14" :class="$style['container']">
        <div :class="$style.header">
          <slot name="header"></slot>
        </div>
        <slot name="content"></slot>
      </AntCol>
    </AntRow>
  </div>
</template>

<script>
import { Row as AntRow, Col as AntCol } from "ant-design-vue";
import AuthLeftBar from "@/components/layout/AuthLeftbar";
import "ant-design-vue/lib/row/style/css";
import "ant-design-vue/lib/col/style/css";

export default {
  name: "GuestLayout",
  data() {
    return {};
  },
  components: { AntRow, AntCol, AuthLeftBar },
  props: {},
  computed: {},
  methods: {}
};
</script>

<style lang="scss" module>
.guest-layout-container {
  height: 100vh;
  margin: 0 auto;
  .container {
    height: 100vh;
    padding: 0 10% 0 10%;
    position: relative;
    .header {
      padding-top: 50px;
      padding-left: inherit;
      padding-right: inherit;
      position: absolute;
      left: 0;
      right: 0;
    }
  }
}
</style>
