<template>
  <div :class="$style['tasks-table-wrapper']">
    <BaseTable
      :columns="columns"
      :data="finalData"
      :loading="loading"
      :actionable="actionable"
      :search="search"
      @select="select"
      @edit="edit"
      @remove="remove"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";
import moment from "moment";
import { parseExpression } from "@/utils/cron";

import BaseTable from "@/components/table/BaseTable";

export default {
  name: "TasksTable",
  data() {
    return {
      loading: false
    };
  },
  components: { BaseTable },
  props: {
    selectedTasks: {
      type: Array,
      required: true
    },
    actionable: {
      type: Boolean,
      default: false
    },
    searchQuery: {
      type: String,
      default: ""
    },
    locationsFilter: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  computed: {
    ...mapState("locations", ["locations"]),
    ...mapState("configurations", ["skills", "taskGroups", "checkpoints"]),
    ...mapGetters("configurations", ["taskGroup", "skill", "checkpoint"]),
    ...mapGetters("locations", ["location"]),
    ...mapState("tasks", ["tasks"]),
    finalData() {
      return this.locationsFilter.length > 0
        ? this.tasks.filter(task => {
            return (
              task.location && this.locationsFilter.includes(task.location.id)
            );
          })
        : this.tasks;
    },
    search() {
      return {
        filter: function(task) {
          return (
            this.searchQuery == "" ||
            (task.group &&
              ~this.taskGroup(task.group.id)
                .name.toLowerCase()
                .replace(/\s/g, "")
                .indexOf(this.searchQuery.toLowerCase().replace(/\s/g, ""))) ||
            (task.location &&
              ~this.location(task.location.id)
                .position.address.toLowerCase()
                .replace(/\s/g, "")
                .indexOf(this.searchQuery.toLowerCase().replace(/\s/g, "")))
          );
        }.bind(this)
      };
    },
    columns() {
      return [
        {
          title: this.$i18n.t("taskType"),
          dataIndex: "group",
          width: "12%",
          key: "task-type",
          customRender: group => {
            return this.$createElement("PicCircle", {
              scopedSlots: {
                default: () =>
                  this.$createElement("Icon", {
                    props: {
                      name: "task",
                      fill: "#ffffff"
                    }
                  })
              },
              props: {
                label: group ? this.taskGroup(group.id).name : null,
                labeled: true
              }
            });
          },
          sorter: (a, b) => {
            if (
              this.taskGroup(a.group.id)
                .name.toString()
                .toLowerCase() >
              this.taskGroup(b.group.id)
                .name.toString()
                .toLowerCase()
            ) {
              return 1;
            } else {
              return -1;
            }
          },
          sortDirections: ["descend", "ascend"],
          filters: this.taskGroups.map(group => {
            return {
              text: group.name,
              value: group.id
            };
          }),
          onFilter: (value, record) => record.group.id == value
        },
        {
          title: this.$i18n.t("location"),
          dataIndex: "location",
          key: "location",
          customRender: location => {
            return location ? this.location(location.id).position.address : "-";
          },
          sorter: (a, b) => {
            if (
              this.location(a.location.id)
                .position.address.toString()
                .toLowerCase() >
              this.location(b.location.id)
                .position.address.toString()
                .toLowerCase()
            ) {
              return 1;
            } else {
              return -1;
            }
          },
          sortDirections: ["descend", "ascend"],
          filters: this.locations.map(location => {
            return {
              text: location.position.address,
              value: location.id
            };
          }),
          onFilter: (value, record) => record.location.id == value
        },
        {
          title: this.$i18n.t("duration"),
          dataIndex: "duration",
          key: "duration",
          customRender: duration => {
            return duration + " " + this.$i18n.t("min");
          },
          sorter: (a, b) => a.duration - b.duration,
          sortDirections: ["descend", "ascend"]
        },
        {
          title: this.$i18n.t("periodicity"),
          dataIndex: "schedule.expression",
          key: "periodicity",
          customRender: expression => {
            return this.$i18n.t(this.parseExpression(expression).periodicity);
          },
          sorter: (a, b) => {
            switch (this.parseExpression(a.schedule.expression).periodicity) {
              case "once":
                a = 5;
                break;
              case "daily":
                a = 4;
                break;
              case "weekly":
                a = 3;
                break;
              case "monthly":
                a = 2;
                break;
              case "yearly":
                a = 1;
                break;
              default:
                a = 0;
            }
            switch (this.parseExpression(b.schedule.expression).periodicity) {
              case "once":
                b = 5;
                break;
              case "daily":
                b = 4;
                break;
              case "weekly":
                b = 3;
                break;
              case "monthly":
                b = 2;
                break;
              case "yearly":
                b = 1;
                break;
              default:
                b = 0;
            }
            return a - b;
          },
          sortDirections: ["descend", "ascend"],
          filters: [
            {
              text: this.$i18n.t("once"),
              value: "once"
            },
            {
              text: this.$i18n.t("daily"),
              value: "daily"
            },
            {
              text: this.$i18n.t("weekly"),
              value: "weekly"
            },
            {
              text: this.$i18n.t("monthly"),
              value: "monthly"
            },
            {
              text: this.$i18n.t("yearly"),
              value: "yearly"
            }
          ],
          filterMultiple: false,
          onFilter: (value, record) =>
            this.parseExpression(
              record.schedule.expression
            ).periodicity.indexOf(value) === 0
        },
        {
          title: this.$i18n.t("repeatOn"),
          dataIndex: "schedule.expression",
          key: "repeat-on",
          customRender: expression => {
            return this.parseExpression(expression).repeatOnText;
          }
        },
        {
          title: this.$i18n.t("rule"),
          dataIndex: "schedule.expression",
          key: "rule",
          customRender: expression => {
            const parsedExpression = this.parseExpression(expression);
            switch (parsedExpression.periodicity) {
              case "weekly":
                return this.$createElement("DaysPicker", {
                  props: {
                    value: parsedExpression.daysOfWeek,
                    size: "small"
                  }
                });
              case "monthly":
                return this.$createElement("DaysPicker", {
                  props: {
                    value: parsedExpression.daysOfWeek,
                    size: "small"
                  }
                });
              case "yearly":
                return this.$createElement("DaysPicker", {
                  props: {
                    value: parsedExpression.daysOfWeek,
                    size: "small"
                  }
                });
              default:
                return "-";
            }
          }
        },
        {
          title: this.$i18n.t("startDate"),
          dataIndex: "schedule.from",
          width: "10%",
          key: "start-date",
          customRender: date => {
            return date ? moment(date).format("YYYY-MM-DD") : "-";
          },
          sorter: (a, b) => {
            const dateA = moment(a.schedule.from, "YYYY-MM-DD").unix();
            const dateB = moment(b.schedule.from, "YYYY-MM-DD").unix();

            return dateA - dateB;
          },
          sortDirections: ["descend", "ascend"]
        },
        {
          title: this.$i18n.t("endDate"),
          dataIndex: "schedule.to",
          width: "10%",
          key: "end-date",
          customRender: date => {
            return date ? moment(date).format("YYYY-MM-DD") : "-";
          },
          sorter: (a, b) => {
            const dateA = moment(a.schedule.from, "YYYY-MM-DD").unix();
            const dateB = moment(b.schedule.from, "YYYY-MM-DD").unix();

            return dateA - dateB;
          },
          sortDirections: ["descend", "ascend"]
        },
        {
          title: this.$i18n.t("requiredSkills"),
          dataIndex: "skills",
          width: "15%",
          key: "skills",
          customRender: skills => {
            return this.$createElement("TagsGroup", {
              props: {
                tags: skills.map(skill => {
                  return this.skill(skill.id).name;
                })
              }
            });
          },
          filters: this.skills.map(skill => {
            return {
              text: skill.name,
              value: skill.id
            };
          }),
          onFilter: (value, record) =>
            (value == -1 && record.skills.length == 0) ||
            record.skills.find(skill => skill.id == value)
        },
        {
          title: this.$i18n.t("checkpoints"),
          dataIndex: "checkpoints",
          width: "15%",
          key: "checkpoints",
          customRender: checkpoints => {
            return this.$createElement("TagsGroup", {
              props: {
                tags: checkpoints.map(checkpoint => {
                  return this.checkpoint(checkpoint.id).name;
                })
              }
            });
          },
          filters: this.checkpoints.map(checkpoint => {
            return {
              text: checkpoint.name,
              value: checkpoint.id
            };
          }),
          onFilter: (value, record) =>
            (value == -1 && record.checkpoints.length == 0) ||
            record.checkpoints.find(checkpoint => checkpoint.id == value)
        }
      ];
    }
  },
  methods: {
    ...mapActions("tasks", ["initializeTasks"]),
    ...mapActions("locations", ["initializeLocations"]),
    parseExpression,
    select(rows) {
      this.$emit("update:selectedTasks", rows.rows);
    },
    edit(data) {
      this.$emit("editTask", data);
    },
    remove(data) {
      this.$emit("removeTask", data);
    }
  },
  async mounted() {
    this.loading = true;
    await this.initializeLocations();
    this.initializeTasks()
      .then(() => {
        this.loading = false;
      })
      .catch(() => {
        this.loading = false;
      });
  }
};
</script>

<style lang="scss" module>
.tasks-table-wrapper {
}
</style>
