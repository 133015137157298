<template>
  <div :class="$style['days-picker-wrapper']">
    <div
      v-for="(active, day) in value"
      :key="day"
      :class="{
        [$style.active]: active,
        [$style.weekday]: true,
        [$style.large]: size === 'large',
        [$style.small]: size === 'small',
        [$style.medium]: size === 'medium',
        [$style.loading]: loading
      }"
      @click="handler(day)"
    >
      {{
        $t(day)
          .charAt(0)
          .toUpperCase()
      }}
    </div>
  </div>
</template>

<script>
export default {
  name: "DaysPicker",
  components: {},
  props: {
    readOnly: {
      type: Boolean,
      default: false
    },
    value: {
      type: Object,
      default() {
        return {
          monday: false,
          tuesday: false,
          wednesday: false,
          thursday: false,
          friday: false,
          saturday: false,
          sunday: false
        };
      }
    },
    size: {
      type: String,
      default: "large"
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {},
  methods: {
    handler(key) {
      if (!this.readOnly && !this.loading) {
        let days = this.value;
        days[key] = !days[key];
        this.$emit("input", days);
      }
    }
  }
};
</script>

<style lang="scss" module>
.days-picker-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  .weekday {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $primary-gray;
    border-radius: 1px;
    margin-right: 5px;
    color: $primary-black;
    &.active {
      background: $primary-blue;
      color: #ffffff;
    }
  }
  .large {
    width: 44px;
    height: 32px;
    font-size: 14px;
  }
  .medium {
    width: 34px;
    height: 24px;
    font-size: 11px;
  }
  .small {
    width: 19px;
    height: 19px;
    font-size: 10px;
  }
  .loading {
    opacity: 0.4;
  }
}
</style>
