<template>
  <div :class="$style['date-wrapper']">
    <AntDatePicker
      :class="{ [$style['date-picker']]: true, error: error }"
      v-model="model"
      :size="size"
      :disabled="disabled || loading"
      :allowClear="allowClear"
    />
  </div>
</template>

<script>
import moment from "moment";
import { DatePicker as AntDatePicker } from "ant-design-vue";
import "ant-design-vue/lib/date-picker/style/css";

export default {
  name: "BaseDatePicker",
  components: { AntDatePicker },
  props: {
    value: {
      type: String,
      default: ""
    },
    format: {
      type: String,
      default: "YYYY-MM-DD"
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: "large"
    },
    error: {
      type: Boolean,
      default: false
    },
    allowClear: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    model: {
      get() {
        return this.value ? this.moment(this.value, this.format) : "";
      },
      set(value) {
        value
          ? this.$emit("input", value.format(this.format))
          : this.$emit("input", null);
      }
    }
  },
  methods: {
    moment
  }
};
</script>

<style lang="scss" module>
.date-wrapper {
  width: 100%;
  .error {
    border: 2px solid red;
    border-radius: 5px;
  }
  .date-picker {
    width: 100%;
  }
}
</style>
