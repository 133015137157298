import * as repositoryBuild from "@/api/repositories/build";

export const builds = {
  state: {
    builds: [],
    activeBuildId: localStorage.getItem("build_id") || undefined,

    setup: {},
    dayPlans: []
  },
  getters: {
    build: state => buildId => state.builds.find(build => build.id == buildId),
    activeBuild: state =>
      state.builds.find(build => build.id == state.activeBuildId),

    taskGroups: state => state.setup["task-groups"],
    skills: state => state.setup.skills,
    locations: state => state.setup.locations,
    tasks: state => state.setup.tasks,
    workers: state => (state.setup.workers ? state.setup.workers : []),
    taskGroup: state => taskGroupId =>
      state.setup["task-groups"].find(taskGroup => taskGroup.id == taskGroupId),
    skill: state => skillId =>
      state.setup.skills.find(skill => skill.id == skillId),
    location: state => locationId =>
      state.setup.locations.find(location => location.id == locationId),
    task: state => taskId => state.setup.tasks.find(task => task.id == taskId),
    worker: state => workerId =>
      state.setup.workers.find(worker => worker.id == workerId),

    dayPlan: (state, date) =>
      state.dayPlans.find(dayPlan => dayPlan.date == date)
  },
  mutations: {
    INITIALIZE_BUILDS(state, builds) {
      state.builds = builds;
    },
    ADD_BUILD(state, build) {
      state.builds.push(build);
    },
    REMOVE_BUILD(state, buildId) {
      const buildIndex = state.builds.findIndex(build => build.id == buildId);
      if (state.activeBuildId == buildId) {
        state.activeBuildId = undefined;
        localStorage.removeItem("build_id");
      }
      state.builds.splice(buildIndex, 1);
    },
    UPDATE_BUILD(state, buildUpdated) {
      const buildIndex = state.builds.findIndex(
        build => build.id == buildUpdated.id
      );
      state.builds.splice(buildIndex, 1, buildUpdated);
    },
    UPDATE_BUILD_STATUS(state, data) {
      const buildIndex = state.builds.findIndex(
        build => build.id == data.buildId
      );
      const build = state.builds[buildIndex];
      build.status = data.status;

      state.builds.splice(buildIndex, 1, build);
    },
    SET_BUILD_AS_ACTIVE(state, buildId) {
      state.activeBuildId = buildId;
      localStorage.setItem("build_id", buildId);
      state.setup = {};
      state.dayPlans = [];
    },
    INITIALIZE_SETUP(state, setup) {
      state.setup = setup;
    },
    INITIALIZE_DAYPLANS(state, dayPlans) {
      state.dayPlans = dayPlans;
    },
    RESET(state) {
      state.builds = [];
      state.activeBuildId = undefined;
      state.setup = {};
      state.dayPlans = [];

      localStorage.removeItem("build_id");
    }
  },
  actions: {
    initializeBuilds({ commit, rootState }) {
      return repositoryBuild
        .fetchBuilds(rootState.configurations.activeConfigurationId)
        .then(builds => {
          commit("INITIALIZE_BUILDS", builds);
          return builds;
        });
    },
    setBuildAsActive({ commit, getters }, buildId) {
      if (getters.build(buildId)) {
        commit("SET_BUILD_AS_ACTIVE", buildId);
      }
      return repositoryBuild.fetchBuild(buildId).then(build => {
        if (!getters.build(buildId)) {
          commit("ADD_BUILD", build);
          commit("SET_BUILD_AS_ACTIVE", buildId);
        }
        commit("INITIALIZE_SETUP", build.setup);
        commit("INITIALIZE_DAYPLANS", build["day-plans"]);
        return build;
      });
    },
    launchBuild({ commit, rootState }, data) {
      return repositoryBuild
        .launchBuild(rootState.configurations.activeConfigurationId, data)
        .then(response => {
          let build = response.data.build;
          build.status = 0;
          commit("ADD_BUILD", build);
          return build;
        });
    },
    updateBuild({ commit }, data) {
      return repositoryBuild.updateBuild(data.id, data.data).then(build => {
        commit("UPDATE_BUILD", build);
        return build;
      });
    },
    updateBuildStatus({ commit }, data) {
      commit("UPDATE_BUILD_STATUS", data);
    },
    deleteBuild({ commit }, buildId) {
      return repositoryBuild.deleteBuild(buildId).then(() => {
        commit("REMOVE_BUILD", buildId);
      });
    },
    saveBuildUpdates({ dispatch, state }, data) {
      return repositoryBuild
        .rebuildBuild(state.activeBuildId, data)
        .then(() => {
          return dispatch("setBuildAsActive", state.activeBuildId);
        });
    },
    reset({ commit }) {
      commit("RESET");
    }
  }
};
