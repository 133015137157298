<template>
  <div :class="$style['popover-active-route-plan-actions-wrapper']">
    <Popover placement="bottom" :closeOnClick="false">
      <template v-slot:content>
        <loading :loading="updatesSaving">
          <div :class="$style['content-wrapper']">
            <div
              @click="$emit('undo')"
              :class="{ 'route-plan-actions-popover-item': true }"
            >
              <Icon name="undo" :active="undo" />
              <p>{{ $t("undo") }}</p>
            </div>
            <div
              @click="$emit('redo')"
              :class="{ 'route-plan-actions-popover-item': true }"
            >
              <Icon name="redo" :active="redo" />
              <p>{{ $t("redo") }}</p>
            </div>
            <div class="divider">
              <Divider />
            </div>
            <div
              @click="save"
              :class="{ 'route-plan-actions-popover-item': true }"
            >
              <Icon name="save" :active="undo || rebuild" />
              <p>{{ $t("save") }}</p>
            </div>
          </div>
        </loading>
      </template>
      <Icon
        name="arrow-down"
        :class="$style['arrow-icon']"
        ref="popover-open-icon"
      />
    </Popover>
  </div>
</template>

<script>
import { EventBus } from "@/main.js";

import Divider from "ant-design-vue/lib/divider";
import Icon from "@/components/ui/Icon";
import Popover from "@/components/ui/Popover";

export default {
  name: "PopoverActiveRoutePlanActions",
  data() {
    return {};
  },
  components: { Icon, Popover, Divider },
  props: {
    updates: {
      type: Array,
      default() {
        return [];
      }
    },
    updatesSaving: {
      type: Boolean,
      default: false
    },
    errorHandles: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  computed: {
    undo() {
      return (
        !this.updatesSaving &&
        this.updates.filter(update => {
          return update.active;
        }).length > 0
      );
    },
    redo() {
      return (
        !this.updatesSaving &&
        this.updates.filter(update => {
          return !update.active;
        }).length > 0
      );
    },
    rebuild() {
      return this.errorHandles.length > 0;
    }
  },
  methods: {
    save() {
      if (this.undo || this.rebuild) {
        this.$emit("save");
      }
    }
  },
  beforeMount() {
    EventBus.$on(
      "popover-active-route-plan-actions_open",
      function() {
        this.$refs["popover-open-icon"].$el.click();
      }.bind(this)
    );
  }
};
</script>

<style lang="scss" module>
.popover-active-route-plan-actions-wrapper {
  float: right;
  margin-top: 10px;
  margin-left: 10px;
  .arrow-icon {
    cursor: pointer;
  }
}
</style>
