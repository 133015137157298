export default class TaskGroup {
  #id;
  #name;

  constructor(taskGroup) {
    this.#id = taskGroup.id;
    this.#name = taskGroup.name;
  }

  getId() {
    return this.#id;
  }

  getName() {
    return this.#name;
  }
}
