<template>
  <div :class="$style['base-form-wrapper']">
    <form
      :name="name"
      @submit.prevent="onSubmit"
      @input="form.onFieldUpdate($event.target.name)"
    >
      <slot />
      <input type="submit" value="submit" :class="$style['submit-button']" />
    </form>
  </div>
</template>

<script>
export default {
  name: "BaseForm",
  data() {
    return {};
  },
  provide() {
    return {
      formEventBus: this.form.eventBus
    };
  },
  components: {},
  props: {
    name: {
      type: String,
      default: "default"
    },
    form: {
      type: Object,
      required: true
    }
  },
  computed: {},
  methods: {
    onSubmit() {
      this.$emit("submit");
    }
  },
  created() {
    this.form.eventBus.$on("fieldUpdate", data => {
      this.form.onFieldUpdate(data.name);
    });
  }
};
</script>

<style lang="scss" module>
.base-form-wrapper {
  .submit-button {
    display: none;
  }
}
</style>
