import Skill from "@/services/configuration/Skill";
import TaskGroup from "@/services/configuration/TaskGroup";
import Worker from "@/services/worker/Worker";
import Task from "@/services/task/Task";
import Location from "@/services/location/Location";

export default class Setup {
  #id;
  #weeklyWorkingHours;

  #skills;
  #taskGroups;
  #workers;
  #tasks;
  #locations;
  constructor(setup) {
    this.#id = setup.id;
    this.#weeklyWorkingHours = setup.weekly_working_hours;

    this.#skills = setup.skills
      ? setup.skills.map(skill => new Skill(skill))
      : undefined;
    this.#taskGroups = setup["task-groups"]
      ? setup["task-groups"].map(taskGroup => new TaskGroup(taskGroup))
      : undefined;
    this.#workers = setup.workers
      ? setup.workers.map(worker => new Worker(worker))
      : undefined;
    this.#tasks = setup.tasks
      ? setup.tasks.map(task => new Task(task))
      : undefined;
    this.#locations = setup.locations
      ? setup.locations.map(location => new Location(location))
      : undefined;
  }

  getId() {
    return this.#id;
  }

  getWeeklyWorkingHours() {
    return this.#weeklyWorkingHours;
  }

  getSkills() {
    return this.#skills;
  }

  getTaskGroups() {
    return this.#taskGroups;
  }

  getWorkers() {
    return this.#workers;
  }

  getTasks() {
    return this.#tasks;
  }

  getLocations() {
    return this.#locations;
  }

  getWorkerById(id) {
    return this.#workers.find(worker => worker.getId() == id);
  }

  getLocationById(id) {
    return this.#locations.find(location => location.getId() == id);
  }

  getTaskById(id) {
    return this.#tasks.find(task => task.getId() == id);
  }

  getTaskGroupById(id) {
    return this.#taskGroups.find(taskGroup => taskGroup.getId() == id);
  }

  getSkillsGroupById(id) {
    return this.#skills.find(skill => skill.getId() == id);
  }
}
