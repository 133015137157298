<template>
  <div class="users-table">
    <BaseTable
      :columns="columns"
      :data="users"
      :loading="loading"
      :actionable="actionable"
      :search="search"
      @select="select"
      @edit="edit"
      @remove="remove"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

import BaseTable from "@/components/table/BaseTable";
import StatusLabel from "@/components/ui/StatusLabel";

export default {
  name: "UsersTable",
  data() {
    return {
      loading: false
    };
  },
  components: { BaseTable },
  props: {
    selectedUsers: {
      type: Array,
      required: true
    },
    actionable: {
      type: Boolean,
      default: false
    },
    searchQuery: {
      type: String,
      default: ""
    }
  },
  computed: {
    ...mapState("users", ["users"]),
    search() {
      return {
        filter: function(user) {
          return (
            this.searchQuery == "" ||
            ~user.email
              .toLowerCase()
              .replace(/\s/g, "")
              .indexOf(this.searchQuery.toLowerCase().replace(/\s/g, "")) ||
            ~(user.profile.first_name + user.profile.last_name)
              .toLowerCase()
              .replace(/\s/g, "")
              .indexOf(this.searchQuery.toLowerCase().replace(/\s/g, ""))
          );
        }.bind(this)
      };
    },
    columns() {
      return [
        {
          title: this.$i18n.t("email"),
          width: "40%",
          key: "email",
          customRender: user => {
            return user.email;
          },
          sorter: (a, b) => {
            if (
              a.email.toString().toLowerCase() >
              b.email.toString().toLowerCase()
            ) {
              return 1;
            } else {
              return -1;
            }
          },
          sortDirections: ["descend", "ascend"]
        },
        {
          title: this.$i18n.t("name"),
          width: "20%",
          key: "name",
          customRender: user => {
            return user.profile.first_name;
          },
          sorter: (a, b) => {
            if (
              a.profile.first_name.toString().toLowerCase() >
              b.profile.first_name.toString().toLowerCase()
            ) {
              return 1;
            } else {
              return -1;
            }
          },
          sortDirections: ["descend", "ascend"]
        },
        {
          title: this.$i18n.t("lastName"),
          width: "20%",
          key: "last_name",
          customRender: user => {
            return user.profile.last_name;
          },
          sorter: (a, b) => {
            if (
              a.profile.last_name.toString().toLowerCase() >
              b.profile.last_name.toString().toLowerCase()
            ) {
              return 1;
            } else {
              return -1;
            }
          },
          sortDirections: ["descend", "ascend"]
        },
        {
          title: this.$i18n.t("status"),
          width: "20%",
          key: "status",
          customRender: user => {
            return this.$createElement(StatusLabel, {
              props: {
                status: user.active == 1
              }
            });
          },
          sorter: (a, b) => {
            if (
              a.profile.last_name.toString().toLowerCase() >
              b.profile.last_name.toString().toLowerCase()
            ) {
              return 1;
            } else {
              return -1;
            }
          },
          sortDirections: ["descend", "ascend"]
        }
      ];
    }
  },
  methods: {
    ...mapActions("users", ["initializeUsers"]),
    select(rows) {
      this.$emit("update:selectedUsers", rows.rows);
    },
    edit(data) {
      this.$emit("editUser", data);
    },
    remove(data) {
      this.$emit("removeUser", data);
    }
  },
  async mounted() {
    this.loading = true;
    this.initializeUsers()
      .then(() => {
        this.loading = false;
      })
      .catch(() => {
        this.loading = false;
      });
  }
};
</script>

<style lang="scss">
.users-table {
}
</style>
