<template>
  <div :class="$style['route-plans-list-wrapper']">
    <Loading :loading="loading">
      <p :class="$style.title">{{ $t("workPlans") }}</p>
      <div :class="$style['button-wrapper']">
        <Button @click="createBuild">
          <Icon name="plus" fill="#ffffff" />
          <p :class="$style['btn-title']">{{ $t("createPlan") }}</p>
        </Button>
      </div>
      <div :class="$style['plans-list']">
        <div
          :class="$style['plans-list-item']"
          v-for="(build, i) in builds"
          :key="i"
        >
          <div :class="$style['left-side']" @click="switchToBuild(build.id)">
            <div :class="$style['icon-wrapper']">
              <Icon name="list" :active="activeBuild == build.id" />
            </div>
            <p
              :class="{
                [$style['left-side-text']]: true,
                [$style['active-item']]: activeBuild == build.id
              }"
            >
              {{
                build.title.length > 22
                  ? build.title.substring(0, 19) + ".."
                  : build.title
              }}
            </p>
          </div>
          <div :class="$style['right-side']">
            <p :class="$style['right-side-text']">
              {{ moment(build.created_at).format("YYYY-MM-DD") }}
            </p>
            <PopoverRoutePlanActions
              @saveAs="saveAsBuild"
              @rename="renameBuild"
              @delete="removeBuild"
              :plan="build"
            />
          </div>
        </div>
      </div>
    </Loading>
    <ModalPlugin name="build-edit-modal">
      <BuildUpdateForm
        :buildId="buildId"
        @cancel="$modal.hide('build-edit-modal')"
        @close="$modal.hide('build-edit-modal')"
      />
    </ModalPlugin>
  </div>
</template>

<script>
import moment from "moment";

import Button from "@/components/ui/Button";
import Icon from "@/components/ui/Icon";
import PopoverRoutePlanActions from "@/components/ui/PopoverRoutePlanActions";

import BuildUpdateForm from "@/components/forms/BuildUpdateForm";

export default {
  name: "RoutePlansList",
  inject: ["deleteBuild"],
  data() {
    return {
      loading: false,
      buildId: undefined
    };
  },
  components: { Button, Icon, PopoverRoutePlanActions, BuildUpdateForm },
  props: {
    builds: {
      type: Array,
      required: true
    },
    activeBuild: {
      // type: String
    }
  },
  computed: {},
  methods: {
    moment,
    saveAsBuild() {
      this.$modal.show("build-edit-modal");
    },
    renameBuild(build) {
      this.buildId = build.id;
      this.$modal.show("build-edit-modal");
    },
    createBuild() {
      this.$router.push("/dashboard/route-plan-creation");
    },
    switchToBuild(id) {
      if (this.activeBuild !== id) {
        this.$router.push({
          name: "route_plan",
          params: { id: id }
        });
      }
    },
    removeBuild(build) {
      this.$confirmation
        .show({
          action: "delete",
          title: this.$i18n.t("deleteBuild"),
          message: this.$i18n.t("deleteBuildDescription")
        })
        .then(() => {
          this.loading = true;
          this.deleteBuild(build.id)
            .then(() => {
              this.loading = false;
              if (this.activeBuild == build.id && this.builds.length > 0) {
                this.switchToBuild(this.builds[0].id);
              }
            })
            .catch(() => {
              this.loading = false;
            });
        });
    }
  }
};
</script>

<style lang="scss" module>
.route-plans-list-wrapper {
  // background: #f7f8fc;
  width: 100%;
  padding: 27px 18px 0 24px;
  .title {
    font-weight: 600;
    font-size: 16px;
    line-height: 25px;
    color: $primary-black;
    margin-bottom: 28px;
  }
  .button-wrapper {
    button {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .plans-list {
    margin-top: 28px;
    .plans-list-title {
      font-weight: 600;
      font-size: 10px;
      line-height: 25px;
      color: $primary-black;
    }
    .plans-list-item {
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      margin-bottom: 24px;
      .left-side {
        display: flex;
        align-items: center;
        .icon-wrapper {
          margin-right: 10px;
        }
        .left-side-text {
          font-weight: 300;
          font-size: 12px;
          line-height: 16px;
          color: $primary-black;
          margin-bottom: 0;
        }
        .active-item {
          font-weight: 700 !important;
        }
      }
      .right-side {
        display: flex;
        align-items: center;
        .right-side-text {
          font-weight: 300;
          font-size: 11px;
          line-height: 13px;
          color: $primary-black;
          margin-bottom: 0;
          margin-right: 17px;
        }
      }
    }
  }
}
.btn-title {
  margin-left: 10px;
}
</style>
