<template>
  <div :class="$style['schedule-wrapper']">
    <div :class="$style.row" v-for="(day, index) in model" :key="day.day">
      <Checkbox
        :class="$style.checkbox"
        :disabled="loading"
        v-model="day.active"
        @input="onUpdateCheckbox($event, index)"
        >{{ formatDayLabel(day.day) }}</Checkbox
      >
      <TimeRangePicker
        v-model="day.range"
        :class="$style['time-range']"
        :disabled="!day.active || loading"
        @input="onUpdate($event, index)"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "Schedule",
  data() {
    return {
      model: [
        {
          day: this.$t("monday"),
          range: { from: "08:00", to: "20:00" },
          active: true
        },
        {
          day: this.$t("tuesday"),
          range: { from: "08:00", to: "20:00" },
          active: true
        },
        {
          day: this.$t("wednesday"),
          range: { from: "08:00", to: "20:00" },
          active: true
        },
        {
          day: this.$t("thursday"),
          range: { from: "08:00", to: "20:00" },
          active: true
        },
        {
          day: this.$t("friday"),
          range: { from: "08:00", to: "20:00" },
          active: true
        },
        {
          day: this.$t("saturday"),
          range: { from: null, to: null },
          active: false
        },
        {
          day: this.$t("sunday"),
          range: { from: null, to: null },
          active: false
        }
      ]
    };
  },
  components: {},
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    value: {
      type: Object,
      default() {
        return {
          monday_from: null,
          monday_to: null,
          tuesday_from: null,
          tuesday_to: null,
          wednesday_from: null,
          wednesday_to: null,
          thursday_from: null,
          thursday_to: null,
          friday_from: null,
          friday_to: null,
          saturday_from: null,
          saturday_to: null,
          sunday_from: null,
          sunday_to: null
        };
      }
    },
    error: {
      type: Boolean,
      default: false
    }
  },
  computed: {},
  mounted() {
    this.$nextTick(() => {
      this.onUpdate();
    });
  },
  methods: {
    onUpdateCheckbox(value, index) {
      if (!value) {
        this.model[index].range = {
          from: null,
          to: null
        };
      } else {
        this.model[index].range = {
          from: "09:00",
          to: "18:00"
        };
      }
      this.onUpdate();
    },
    onUpdate() {
      this.$emit("input", this.formatModel(this.model));
    },
    formatDayLabel(label) {
      const labelShort = label.substring(0, 3);
      return labelShort.charAt(0).toUpperCase() + labelShort.slice(1);
    },
    formatModel(model) {
      let result = {};
      model.forEach(day => {
        result[day.day + "_to"] = day.range.to;
        result[day.day + "_from"] = day.range.from;
      });
      return result;
    }
  },
  watch: {
    value() {
      this.model.map((modelDay, key) => {
        Object.entries(this.value).filter(valueDay => {
          const name = valueDay[0].split("_")[0];
          const type = valueDay[0].split("_")[1];
          if (modelDay.day === name) this.model[key].range[type] = valueDay[1];
        });
        this.model[key].active =
          this.model[key].range.from !== null ||
          this.model[key].range.to !== null;
      });
    }
  }
};
</script>

<style lang="scss" module>
.schedule-wrapper {
  width: 100%;
  .schedule-wrapper-header {
    height: 30px;
    width: 100%;
    span {
      font-size: 15px;
      font-weight: 500;
      float: left;
    }
  }
  .row {
    height: 60px;
    .checkbox {
      float: left;
      line-height: 60px;
    }
    .time-range {
      width: calc(100% - 100px);
      float: right;
    }
  }
}
</style>
