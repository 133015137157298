<template>
  <MainLayout>
    <template #header-title>{{ $t("tasks") }}</template>
    <template #header-content>
      <HeaderTableContent
        :entity="$t('task')"
        @add="add"
        @search="tableSearch"
        :location="filterLocation"
        @locationCancel="locationFilterCancel"
      />
    </template>
    <template #content>
      <BulkNavigation
        :buttons="buttons"
        v-show="selected.length > 1"
        @edit="$drawer.show('right-bar')"
        @remove="removeBulk"
      />
      <TasksTable
        :selectedTasks.sync="selected"
        :searchQuery="searchQuery"
        :locationsFilter="locationFilter"
        @editTask="edit"
        @removeTask="remove"
        actionable
      />
      <ModalPlugin name="task-form-modal">
        <task-entity-form
          :taskId="taskId"
          @taskCreate="setTaskAsActive"
          @success="$modal.hide('task-form-modal')"
        />
      </ModalPlugin>
      <DrawerPlugin name="right-bar" type="right">
        <TasksRightBar
          :tasks="
            selected.map(task => {
              return {
                id: task.id,
                name: task.group
                  ? taskGroup(task.group.id).name
                  : $t('undefined'),
                from: task.schedule.from,
                to: task.schedule.to
              };
            })
          "
        />
      </DrawerPlugin>
    </template>
  </MainLayout>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import MainLayout from "@/layouts/MainLayout";
import HeaderTableContent from "@/components/layout/HeaderTableContent";

import TaskEntityForm from "@/components/forms/TaskEntityForm";
import TasksRightBar from "@/components/right-bars/TasksRightBar";
import TasksTable from "@/components/tables/TasksTable";

import PicCircle from "@/components/ui/PicCircle";

export default {
  page: {
    title: "Tasks",
    meta: [{ name: "description", content: "The Tasks page." }]
  },
  components: {
    MainLayout,
    HeaderTableContent,
    TaskEntityForm,
    TasksRightBar,
    TasksTable
  },
  props: {
    locationId: {
      type: String,
      default: undefined
    }
  },
  data() {
    return {
      taskId: undefined,
      searchQuery: "",
      selected: [],
      buttons: {
        edit: {
          name: this.$i18n.t("updateDates"),
          icon: "calendar",
          iconFillColor: "#333333"
        },
        delete: { name: this.$i18n.t("deleteTasks") }
      }
    };
  },
  computed: {
    ...mapGetters("locations", ["location"]),
    ...mapGetters("configurations", ["taskGroup"]),
    locationFilter() {
      return this.locationId ? [this.locationId] : [];
    },
    filterLocation() {
      return this.locationId
        ? this.location(this.locationId).position.address
        : null;
    }
  },
  methods: {
    ...mapActions("tasks", ["removeTask", "removeTasks"]),
    setTaskAsActive(taskId) {
      this.taskId = taskId;
    },
    locationFilterCancel() {
      this.$router.push({
        name: "tasks",
        params: {}
      });
    },
    tableSearch(query) {
      this.searchQuery = query;
    },
    success() {
      this.$modal.hide("task-form-modal");
    },
    add() {
      this.taskId = undefined;
      this.$modal.show("task-form-modal");
    },
    edit(event) {
      this.taskId = event.id;
      this.$modal.show("task-form-modal");
    },
    remove(items) {
      this.$confirmation
        .show({
          items: items.map(item => {
            return {
              component: PicCircle,
              options: {
                helper: "initials",
                label: item.group ? item.group.name : this.$i18n.t("undefined"),
                labeled: true
              }
            };
          }),
          action: "delete",
          title: this.$i18n.t("deleteTask"),
          message: this.$i18n.t("deleteTaskDescription")
        })
        .then(() => {
          this.removeTask(items[0].id).then(() => {
            //
          });
        })
        .catch(() => {
          console.log("Deletion canceled");
        });
    },
    removeBulk() {
      this.$confirmation
        .show({
          items: this.selected.map(item => {
            return {
              component: PicCircle,
              options: {
                helper: "initials",
                label: item.group ? item.group.name : "Undefined",
                labeled: true
              }
            };
          }),
          action: "delete",
          title: this.$i18n.t("deleteTasks"),
          message: this.$i18n.t("deleteTasksDescription")
        })
        .then(() => {
          this.removeTasks(this.selected.map(task => task.id)).then(() => {
            this.selected = [];
          });
        })
        .catch(() => {
          console.log("Deletion canceled");
        });
    }
  },
  mounted() {
    this.$store.dispatch("workers/initializeWorkers");
  }
};
</script>
