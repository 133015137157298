const _ = require("underscore");
import moment from "moment";

import RoutePoint from "@/services/build/RoutePoint";
import RouteBreak from "@/services/build/RouteBreak";
import RouteDriving from "@/services/build/RouteDriving";
import Visit from "@/services/build/Visit";

export default class Route {
  #id;
  #workerId;
  #routePoints;
  #routeBreaks;
  #wayPoints;

  constructor(route) {
    this.#id = route.id;
    this.#workerId = route.worker.id;
    this.#routePoints = route["route-points"].map(
      routePoint => new RoutePoint(routePoint)
    );
    this.#routeBreaks = route["route-breaks"].map(
      routeBreak => new RouteBreak(routeBreak)
    );
    this.#wayPoints = route["route-way-points-list"]
      ? JSON.parse(route["route-way-points-list"].data)
      : [];
  }

  getId() {
    return this.#id;
  }

  getWorkerId() {
    return this.#workerId;
  }

  getWayPoints() {
    return this.#wayPoints;
  }

  getRoutePoints() {
    return this.#routePoints;
  }

  getVisits() {
    return Object.entries(
      _.groupBy(this.getRoutePoints(), function(routePoint) {
        return routePoint.isHome()
          ? "home-at-route-point-id-" + routePoint.getId()
          : routePoint.getPositionId();
      })
    ).map(entry => {
      return new Visit(entry[1]);
    });
  }

  getBreaks() {
    return this.#routeBreaks;
  }

  getDrivings() {
    const drivings = [];
    this.getVisits()
      .sort((a, b) => {
        if (
          moment(a.getStartTimeMarker(), "hh:mm:ss") <
          moment(b.getStartTimeMarker(), "hh:mm:ss")
        ) {
          return -1;
        }
        if (
          moment(a.getStartTimeMarker(), "hh:mm:ss") >
          moment(b.getStartTimeMarker(), "hh:mm:ss")
        ) {
          return 1;
        }
        return 0;
      })
      .forEach((visit, index, visits) => {
        if (index + 1 == visits.length) return;
        drivings.push(
          new RouteDriving(
            visit.getEndTimeMarker(),
            visits[index + 1].getStartTimeMarker()
          )
        );
      });

    return drivings;
  }

  hasRoutePoints() {
    return this.#routePoints.length > 0;
  }
}
