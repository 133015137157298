<template>
  <div :class="$style['popover-wrapper']">
    <AntPopover
      :title="title"
      :trigger="trigger"
      :visible="visible"
      :placement="placement"
      @visibleChange="updateVisible"
    >
      <div slot="content" @click="contentClick">
        <slot name="content"></slot>
      </div>
      <slot></slot>
    </AntPopover>
  </div>
</template>

<script>
import { Popover as AntPopover } from "ant-design-vue";
import "ant-design-vue/lib/popover/style/css";

export default {
  name: "Popover",
  data() {
    return {
      visible: false
    };
  },
  components: {
    AntPopover
  },
  props: {
    title: {
      type: String,
      default: ""
    },
    placement: {
      type: String,
      default: "left"
    },
    trigger: {
      type: String,
      default: "click"
    },
    closeOnClick: {
      type: Boolean,
      default: true
    }
  },
  computed: {},
  methods: {
    updateVisible(value) {
      this.visible = value;
    },
    contentClick() {
      if (this.closeOnClick) this.visible = false;
    }
  }
};
</script>

<style lang="scss" module>
.popover-wrapper {
}
</style>
