<template>
  <div :class="$style['test-component-wrapper']"></div>
</template>

<script>
export default {
  name: "TestComponent",
  data() {
    return {};
  },
  components: {},
  props: {},
  computed: {},
  methods: {}
};
</script>

<style lang="scss" module>
.test-component-wrapper{}
</style>
