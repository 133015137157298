import { login, logout, register } from "@/api/repositories/auth";
import store from "@/store";
import router from "@/router";
import Vue from "vue";

export const loginService = data => {
  return new Promise((resolve, reject) => {
    login(data).then(response => {
      if (response.success) {
        Vue.prototype.$socket.options.auth.headers["Authorization"] = "Bearer " + response.data.token.accessToken;
        store
          .dispatch("auth/login", {
            token: response.data.token.accessToken,
            user: response.data.user
          })
          .then(() => {
            setTimeout(() => {
              router.push("/");
            }, 1500);
          });
        resolve(response);
      } else {
        reject(response);
      }
    });
  });
};

export const registerService = data => {
  return register(data);
};

export const logoutService = () => {
  return logout().then(() => {
    store.dispatch("auth/logout");

    store.dispatch("configurations/reset");
    store.dispatch("builds/reset");
    store.dispatch("locations/reset");
    store.dispatch("tasks/reset");
    store.dispatch("workers/reset");
  });
};

export const resetAfterUnauthenticatedError = () => {
  store.dispatch("auth/logout");

  store.dispatch("configurations/reset");
  store.dispatch("builds/reset");
  store.dispatch("locations/reset");
  store.dispatch("tasks/reset");
  store.dispatch("workers/reset");

  router.go("/login");
};
