export default class RouteDriving {
  #start;
  #end;

  constructor(start, end) {
    this.#start = start;
    this.#end = end;
  }

  getStart() {
    return this.#start;
  }

  getEnd() {
    return this.#end;
  }
}
