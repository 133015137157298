<template>
  <div :class="$style.plane">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "Plane",
  components: {},
  props: {},
  computed: {},
  methods: {}
};
</script>

<style lang="scss" module>
.plane {
  background: white;
  border: 1px solid #b8c1cc;
  border-radius: 5px;
  width: 100%;
  padding: 15px;
}
</style>
