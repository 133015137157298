<template>
  <GuestLayout>
    <template #illustration>
      <img :src="illustration" />
    </template>
    <template #content>
      <Grid>
        <div slot="header">{{ $t("login") }}</div>
        <div slot="form">
          <login-form />
        </div>
        <div slot="footer" class="footer">
          <div class="separate-navigation">
            {{ $t("dontHaveAccout") }}
            <b @click="$router.push('/register')">{{ $t("register") }}</b>
          </div>
          <LocalizationSelector />
        </div>
      </Grid>
    </template>
  </GuestLayout>
</template>

<script>
import GuestLayout from "@/layouts/GuestLayout";
import Grid from "@/components/grid/AuthFormGrid";
import AuthIllustration from "@/assets/auth-img.png";

import LoginForm from "@/components/forms/AuthLoginForm";

import LocalizationSelector from "@/components/LocalizationSelector";

export default {
  page: {
    title: "Login",
    meta: [{ name: "login", content: "The Login page." }]
  },
  components: { GuestLayout, Grid, LoginForm, LocalizationSelector },
  computed: {
    illustration() {
      return AuthIllustration;
    }
  }
};
</script>


<style lang="scss">
.footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
</style>
