import { jsonApi } from "@/api";

export const fetchAllTasks = setupId => {
  return jsonApi.index("setups/" + setupId + "/tasks", {
    include: "schedule,skills,worker,checkpoints"
  });
};

export const createTask = (setupId, data) => {
  let payload = {
    duration: data.duration.toString(),
    from: data.schedule.from,
    to: data.schedule.to,
    expression: data.schedule.expression
  };

  let relations = [];

  if (data["task-type"]) {
    payload.group = {
      type: "task-groups",
      id: data["task-type"].id
    };
    relations.push("group");
  }
  if (data.location) {
    payload.location = {
      type: "locations",
      id: data.location.id
    };
    relations.push("location");
  }

  if (data.skills) {
    payload.skills = data.skills.map(skill => {
      return { id: skill.id };
    });
    relations.push("skills");
  }
  if (data.checkpoints) {
    payload.checkpoints = data.checkpoints.map(checkpoint => {
      return { id: checkpoint.id };
    });
    relations.push("checkpoints");
  }

  if (data.worker) {
    payload.worker = {
      type: "workers",
      id: data.worker.id
    };
    relations.push("worker");
  } else {
    payload.worker = null;
    relations.push("worker");
  }

  payload.setup = {
    id: setupId,
    type: "setups"
  };
  relations.push("setup");

  return jsonApi.store("tasks", payload, relations, {
    include: "schedule,skills,worker,checkpoints"
  });
};

export const updateTask = (taskId, data) => {
  let payload = {
    duration: data.duration.toString(),
    from: data.schedule.from,
    to: data.schedule.to,
    expression: data.schedule.expression
  };
  let relations = [];

  if (data["task-type"]) {
    payload.group = {
      type: "task-groups",
      id: data["task-type"].id
    };
    relations.push("group");
  }
  if (data.location) {
    payload.location = {
      type: "locations",
      id: data.location.id
    };
    relations.push("location");
  }

  if (data.skills) {
    payload.skills = data.skills.map(skill => {
      return { id: skill.id };
    });
    relations.push("skills");
  }

  if (data.checkpoints) {
    payload.checkpoints = data.checkpoints.map(checkpoint => {
      return { id: checkpoint.id };
    });
    relations.push("checkpoints");
  }

  if (data.worker) {
    payload.worker = {
      type: "workers",
      id: data.worker.id
    };
    relations.push("worker");
  } else {
    payload.worker = null;
    relations.push("worker");
  }

  return jsonApi.update("tasks", taskId, payload, relations, {
    include: "schedule,skills,worker,checkpoints"
  });
};

export const deleteTask = taskId => {
  return jsonApi.remove("tasks", taskId);
};

export const deleteTasks = tasksId => {
  return jsonApi.bulkDelete("tasks", tasksId);
};

export const updateTasksScheduleDates = (schedulesId, data) => {
  const payload = {
    from: data.from,
    to: data.to
  };
  return jsonApi.bulkUpdate("tasks/bulk/schedule/dates", schedulesId, payload, {
    include: "schedule"
  });
};
