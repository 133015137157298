<template>
  <div :class="$style['input-number-wrapper']">
    <AntInputNumber
      :class="{ [$style.error]: error }"
      v-model="model"
      :disabled="disabled || loading"
      :min="min"
      :max="max"
    />
  </div>
</template>

<script>
import { InputNumber as AntInputNumber } from "ant-design-vue";
import "ant-design-vue/lib/input-number/style/css";

export default {
  name: "BaseInputNumber",
  components: { AntInputNumber },
  props: {
    min: {
      type: [Number, String],
      default: 0
    },
    max: {
      type: [Number, String],
      default: 20
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    },
    value: {
      type: [String, Number]
    }
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(value) {
        if (/^\d+$/.test(value)) {
          this.$emit("input", value);
        } else {
          this.$emit("input", value.replace(/[^0-9]/g, ""));
        }
      }
    }
  },
  methods: {}
};
</script>

<style lang="scss" module>
.input-number-wrapper {
  .error {
    border: 2px solid red;
  }
}
</style>
