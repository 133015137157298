<template>
  <div :class="$style['search-wrapper']">
    <div :class="$style['search-icon-wrapper']">
      <Icon name="search" />
    </div>
    <div :class="$style['search-input-wrapper']">
      <Input @input="$emit('searchInput', $event)" :placeholder="placeholder" />
    </div>
  </div>
</template>

<script>
import Icon from "@/components/ui/Icon";
import Input from "@/components/ui/Input";
export default {
  name: "Search",
  data() {
    return {};
  },
  components: { Icon, Input },
  props: {
    placeholder: {
      type: String,
      default: ""
    }
  },
  computed: {},
  methods: {}
};
</script>

<style lang="scss" module>
.search-wrapper {
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  position: relative;
  .search-icon-wrapper {
    position: absolute;
    left: 5px;
    height: 20px;
    z-index: 1;
  }
  .search-input-wrapper {
    input {
      padding-left: 30px;
      &::placeholder {
        font-weight: 300;
        font-size: 14px;
      }
    }
  }
}
</style>
