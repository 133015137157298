<template>
  <div :class="$style['build-visit-tasks-modal-wrapper']">
    <div :class="$style.header">
      <div :class="$style['location']">
        <div :class="$style['icon']">
          <BaseIcon name="location" />
        </div>
        <div :class="$style['info']">
          <div :class="$style['name']">
            {{
              location(visit.getFirstRoutePoint().getLocationId())
                .position.address.split(",")
                .slice(0, 2)
                .join(", ")
            }}
          </div>
          <div :class="$style['time-window']">
            {{ visit.getStartTimeMarker() }} - {{ visit.getEndTimeMarker() }}
          </div>
        </div>
      </div>
      <div :class="$style['worker']">
        <BasePicCircle
          helper="helper"
          :label="`${setupWorker.first_name} ${setupWorker.last_name}`"
          :fill="setupWorker.color"
          labeled
        />
      </div>
    </div>
    <div :class="$style.body">
      <BaseTable :columns="columns" :data="routePoints" disableSelection />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import BaseIcon from "@/components/ui/BaseIcon";
import BaseTable from "@/components/table/BaseTable";

import BasePicCircle from "@/components/ui/BasePicCircle";

export default {
  name: "BuildVisitTasksModal",
  data() {
    return {
      columns: [
        {
          title: this.$i18n.t("taskType"),
          width: "50%",
          key: "task-type",
          customRender: routePoint => {
            return this.$createElement(BasePicCircle, {
              scopedSlots: {
                default: () =>
                  this.$createElement(BaseIcon, {
                    props: {
                      name: "task",
                      fill: "#ffffff"
                    }
                  })
              },
              props: {
                label: this.taskGroup(
                  routePoint.getRoutePointTask().getGroupId()
                ).name,
                labeled: true
              }
            });
          }
        },
        {
          title: this.$i18n.t("duration"),
          key: "duration",
          customRender: routePoint => {
            return routePoint.getDuration() + " min";
          }
        },
        {
          title: this.$i18n.t("checkpoints"),
          key: "checkpoints",
          customRender: routePoint => {
            const task = this.task(routePoint.getTaskId());
            return this.$createElement("TagsGroup", {
              props: {
                tags: task.checkpoints
                  ? task.checkpoints.map(checkpoint => {
                      return this.checkpoint(checkpoint.id).name;
                    })
                  : []
              }
            });
          }
        }
      ]
    };
  },
  components: { BaseIcon, BaseTable, BasePicCircle },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters("builds", ["location", "taskGroup", "worker", "task"]),
    visit() {
      return this.data.visit;
    },
    setupWorker() {
      return this.worker(this.data.workerId);
    },
    routePoints() {
      return this.visit.getRoutePoints();
    }
  }
};
</script>

<style lang="scss" module>
.build-visit-tasks-modal-wrapper {
  .header {
    width: 100%;
    display: flex;

    .location {
      width: 50%;
      display: flex;

      .icon {
        width: 30px;
      }

      .info {
        display: flex;
        flex-direction: column;
        row-gap: 10px;

        .name {
          font-size: 18px;
          font-weight: bold;
        }
      }
    }

    .worker {
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: right;
    }
  }
}
</style>
