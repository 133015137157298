<template>
  <PageLayout>
    <div :class="$style['terms-wrapper']">
      <Logo labeled />
      <h1 :class="$style['header']">
        Allgemeine Geschäftsbedingungen - ImmoTrack-Webanwendung und mobile App
      </h1>
      <div :class="$style['text']">
        <h2>1. Geltungsbereich und Vertragsgegenstand</h2>
        Die vorliegenden Allgemeinen Geschäftsbedingungen („AGB“) regeln die
        vertragliche Beziehung zwischen ImmoTrack AG („Lizenzgeber“) und dem
        Kunden („Lizenznehmer“). Der Lizenzgeber betreibt die Website
        <a href="https://workplanner.com">www.workplanner.com</a> und stellt dem
        Lizenznehmer das webbasierte Prozessmanagement-Tool «ImmoTrack»
        („Web-Applikation“) sowie die entsprechende mobile Applikation
        („Mobile-App“) zur Verfügung. Anhang 1 enthält einen Beschrieb der
        Web-Applikation und der Mobile-App. <br /><br />
        Die AGB gelten jeweils in der zum Zeitpunkt des Vertragsschlusses
        gültigen Fassung. Anderslautende Vertragsbedingungen, namentlich auch
        solche, welche der Lizenznehmer zusammen mit der Vertragsannahme für
        anwendbar erklärt, haben nur Gültigkeit, wenn und soweit sie vom
        Lizenzgeber ausdrücklich und in schriftlicher Form akzeptiert worden
        sind.
        <br /><br />
        Jeder diesen AGB beigefügte Anhang ist dessen integrierender
        Bestandteil.
        <h2>2. Leistung</h2>
        Der Lizenzgeber stellt dem Lizenznehmer die Web-Applikation in der
        jeweils aktuellsten Version am Routerausgang des Rechenzentrums, in dem
        der Server mit der Web-Applikation steht („Übergabepunkt“) zur Nutzung
        bereit. Die Web-Applikation, die für die Nutzung erforderliche
        Rechenleistung und der erforderliche Speicher- und
        Datenverarbeitungsplatz werden vom Lizenzgeber bereitgestellt. Die
        Mobile-App steht in der jeweils aktuellsten Version im jeweiligen Shop
        für Apple und Android zum Download zur Verfügung.
        <br /><br />
        Der Lizenzgeber ist dagegen nicht für die Herstellung und
        Aufrechterhaltung der Datenverbindung zwischen IT-Systemen des
        Lizenznehmers und dem beschriebenen Übergabepunkt verantwortlich. Dies
        ist Aufgabe des Lizenznehmers. Der Lizenznehmer ist insbesondere dafür
        verantwortlich, dass die Latenzzeit und Bandbreite von den
        Nutzer-Desktops zum Router des Lizenzgebers die vom Lizenznehmer
        gewünschte Leistung erfüllen. Falls der Lizenznehmer eine spezielle
        Netzwerkkonnektivität wünscht, ist er zu deren Einrichtung und für deren
        Kosten verantwortlich.
        <br /><br />
        Der Lizenzgeber weist den Lizenznehmer darauf hin, dass Einschränkungen
        oder Beeinträchtigungen der erbrachten Dienste entstehen können, die
        ausserhalb des Einflussbereiches des Lizenzgebers liegen. Hierunter
        fallen insbesondere Handlungen von Dritten, die nicht im Auftrag des
        Lizenzgebers handeln, vom Lizenzgeber nicht beeinflussbare technische
        Bedingungen des Internets sowie höhere Gewalt. Auch die vom Lizenznehmer
        genutzte, nicht vom Lizenzgeber zur Verfügung gestellte Hard- und
        Software sowie technische Infrastruktur kann Einfluss auf die
        Verfügbarkeit und Funktionalität der vom Lizenzgeber erbrachten
        Leistungen haben. Soweit derartige Umstände Einfluss auf die
        Verfügbarkeit oder Funktionalität der vom Lizenzgeber erbrachten
        Leistung haben, hat dies keine Auswirkungen auf die vertragsgemässe
        Erbringung der vereinbarten Leistungen.
        <br /><br />
        Die Betriebszeiten erstrecken sich über 24 Stunden am Tag an 7 Tagen der
        Woche, abzüglich der Zeiten, die für notwendige Wartungsarbeiten im
        Rechenzentrum mit Auswirkungen auf die Web-Applikation und die
        Mobile-App anfallen können. Allfällige Ausfallzeiten würden dem
        Lizenznehmer rechtzeitig bekanntgegeben. Der Lizenzgeber gewährleistet
        eine durchschnittliche zeitliche Verfügbarkeit von 98% pro Monat.
        Geplante Ausfallzeiten gelten dabei nicht als Unterbruch der
        Verfügbarkeit des Systems.
        <br /><br />
        Der Lizenznehmer ist verpflichtet, Funktionsausfälle, -störungen oder
        –beeinträchtigungen der Web-Applikation oder der Mobile-App unverzüglich
        und so präzise wie möglich zu melden.
        <h2>3. Nutzungsrechte</h2>
        Der Lizenzgeber räumt dem Lizenznehmer für die Laufzeit dieses Vertrages
        das nicht-ausschliessliche, nicht übertragbare, nicht sub-lizenzierbare
        und zeitlich beschränkte Recht ein, das Web-Applikation und die
        Mobile-App für die vertragsgemässen Zwecke zu nutzen. Rechte, welche
        weder in diesem Vertrag, noch in dessen Anhängen noch in den jeweils vom
        Lizenzgeber allenfalls erlassenen Benutzungsrichtlinien ausdrücklich
        genannt werden, werden ausdrücklich nicht eingeräumt.
        <br /><br />
        Der Lizenznehmer darf die Web-Applikation und die Mobile-App
        ausschliesslich für eigene Zwecke verwenden. Dem Lizenznehmer ist es
        ausdrücklich untersagt, die Web-Applikation und die Mobile-App dazu zu
        nutzen, um anderen Unternehmen Dienstleistungen zu erbringen. Handelt es
        sich beim Lizenznehmer um ein Facility Management Unternehmen, gelten
        die Liegenschaftsverwaltungen von Liegenschaften, welche in der
        Web-Applikation und der Mobile-App erfasst werden, jedoch nicht als
        Drittunternehmen. Sinn und Zweck der Web-Applikation und der Mobile-App
        ist es nachgerade, dass Liegenschaftsverwaltungen von der Nutzung
        profitieren. Abweichende Vereinbarungen sind vorbehalten.
        <h2>4. Urheberrecht</h2>
        Die Web-Applikation und die Mobile-App sind nach den Bestimmungen über
        den Schutz von Computerprogrammen urheberrechtlich geschützt. Der
        Lizenznehmer erkennt den vorstehend genannten Schutz ausdrücklich an.
        Das Urheberrecht umfasst insbesondere den Programmcode, die
        Dokumentation, das Erscheinungsbild der Web-Applikation und der
        Mobile-App, die Gestaltung der Benutzeroberfläche und der Ein- und
        Ausgabemasken und Ausdrucke, Inhalt, Struktur und Organisation der
        Programmdateien, den Programmnamen, Logos und andere Darstellungsformen
        innerhalb der Web-Applikation und der Mobile-App. Alle aus dem
        Urheberrecht resultierenden Rechte stehen dem Lizenzgeber zu.
        <br /><br />
        Urhebervermerke, Seriennummern sowie sonstige der Programmidentifikation
        dienende Merkmale dürfen auf keinen Fall entfernt oder verändert werden.
        <br /><br />
        Dem Lizenznehmer werden durch die Nutzung der Web-Applikation und der
        Mobile-App keinerlei Eigentümerrechte an der Web-Applikation und der
        Mobile-App eingeräumt. Insbesondere werden keine Urheberrechte an der
        Web-Applikation und der Mobile-App auf den Lizenznehmer übertragen. Der
        Lizenznehmer erhält lediglich Nutzungsbefugnisse (siehe Ziff. 3.
        vorangehend).
        <br /><br />
        Soweit der Lizenzgeber aufgrund von Hinweisen oder
        Verbesserungsvorschlägen des Lizenznehmers Änderungen an der
        Web-Applikation oder der Mobile-App vornimmt, erkennt der Lizenznehmer
        an, dass der Lizenzgeber Inhaber aller Rechte an diesen Änderungen wird.
        Der Lizenznehmer tritt hiermit für den Fall, dass Rechte an den
        Änderungen bei ihm entstehen sollten, alle diese Rechte ohne
        Entschädigung an den Lizenzgeber ab. Sollte eine solche Abtretung nach
        dem anwendbaren Recht nicht zulässig sein, räumt der Lizenznehmer
        hiermit dem Lizenzgeber das ausschliessliche, gebührenfreie, zeitlich
        und räumlich unbegrenzte, sub-lizenzierbare und übertragbare Recht zur
        Nutzung der Änderungen ein. In dieser Rechteeinräumung inbegriffen ist
        das Recht zur Bearbeitung und Weiterentwicklung der Änderungen. Der
        Lizenznehmer verpflichtet sich, auf erste Aufforderung des Lizenzgebers
        hin alle Handlungen vorzunehmen, welche notwendig sind, um die
        vorgenannte Abtretung bzw. Rechteeinräumung umzusetzen.
        <h2>5. Vergütung / Lizenzmodelle</h2>
        Die verschiedenen Lizenzmodelle (Pakete) sowie die entsprechenden
        Lizenzgebühren sind im Order Form beschrieben. Der Lizenznehmer kann
        zwischen den verschiedenen Lizenzmodellen wählen.
        <br /><br />
        Die Lizenzgebühren sind gemäss den gewählten Lizenzmodellen jeweils im
        Voraus zu entrichten. Endet der Vertrag innerhalb einer
        Abrechnungsperiode, werden die vereinbarten Lizenzgebühren nicht
        zurückerstattet.
        <br /><br />
        Die Lizenzgebühren sind ohne abweichende Vereinbarung innert 30 Tagen
        nach Eingang der Rechnung zu bezahlen. Meinungsverschiedenheiten der
        Vertragsparteien über die Auslegung oder Erfüllung dieses Vertrages
        berechtigen den Lizenznehmer nicht, Zahlungen aufzuschieben oder
        Zahlungsmodalitäten abzuändern.
        <br /><br />
        Ist der Lizenznehmer mit der Zahlung einer fälligen Lizenzgebühr um mehr
        als vier Wochen in Verzug, ist der Lizenzgeber nach vorheriger Abmahnung
        und Nachfristansetzung berechtigt, den Zugriff des Lizenznehmers auf der
        Web-Applikation und/oder die Mobile-App auszusetzen. Der Anspruch des
        Lizenzgebers auf Zahlung der fälligen Lizenzgebühr wird durch die
        Aussetzung nicht berührt. Der Zugang zur Web-Applikation und/oder der
        Mobile-App wird nach Bezahlung der Ausstände durch den Lizenznehmer
        unverzüglich wieder freigeschaltet.
        <br /><br />
        Der Lizenzgeber kann nach Ablauf der Vertragslaufzeit des betreffenden
        Lizenzmodells die Lizenzgebühren der allgemeinen Preisentwicklung
        anpassen. Beträgt die Preiserhöhung mehr als 5% der gesamthaft
        ursprünglich vereinbarten Lizenzgebühren kann der Lizenznehmer das
        Vertragsverhältnis auf Ende des laufenden Vertragsmonates kündigen.
        <h2>6. Gewährleistungen des Lizenzgebers</h2>
        <h3>6.1. Sachgewährleistung</h3>
        Der Lizenzgeber gewährleistet für die Laufzeit dieses Vertrages, dass
        die Web-Applikation und die Mobile-App dem in Anhang 1 beschriebenen
        Leistungsumfang entsprechen und nicht mit Mängeln behaftet sind, die den
        Wert oder die Tauglichkeit zum gewöhnlichen oder nach dem Vertrag
        vorausgesetzten Gebrauch aufheben oder wesentlich mindern. Dem
        Lizenznehmer ist bekannt, dass es nach dem Stand der Technik nicht
        möglich ist, eine von Fehlern vollkommen freie Software zu erstellen.
        Die Gewährleistung wird bei leichter Fahrlässigkeit wegbedungen.
        <br /><br />
        Der Lizenzgeber übernimmt keine Gewährleistung dafür, dass die
        Web-Applikation und die Mobile-App ununterbrochen und fehlerfrei in
        allen vom Lizenznehmer gewünschten Kombinationen mit beliebigen Daten,
        Informatiksystemen und anderen Programmen eingesetzt werden können,
        noch, dass durch die Korrektur eines Programmfehlers das Auftreten
        weiterer Programmfehler ausgeschlossen wird.
        <br /><br />
        Soweit eine ordnungsgemäss erstattete Mängelanzeige (d.h. schriftlich
        oder per E-Mail, mit Beschreibung des Mangels und unverzüglich nach
        Entdeckung des Mangels) begründet ist, behebt der Lizenzgeber den
        Mangel. Die Leistung des Lizenzgebers umfasst, nach Wahl des
        Lizenzgebers, entweder die Abgabe eines Programm-Updates (-fix) oder
        einer korrigierten Version. Sollte es dem Lizenzgeber nicht möglich
        sein, den Fehler zu beheben oder gelingt ihm die Fehlerbehebung nicht
        innert angemessener Frist, kann der Lizenznehmer vom Vertrag
        zurücktreten.
        <br /><br />
        Für die Mängelbehebung gelten die folgenden Reaktions- und
        Fehlerbehebungszeiten:
        <br /><br />
        Reaktionszeiten (Montag bis Freitag von 08:00 – 18.00 Uhr; Reaktion ggf.
        am nächsten Arbeitstag)
        <h3>6.2. Rechtsgewährleistung</h3>
        Der Lizenzgeber steht dafür ein, dass die gültigen, unveränderten
        Versionen der Web-Applikations und der Mobile-App nach bestem Wissen und
        Gewissen keine Schutzrechte, namentlich Urheberrechte, Dritter verletzen
        und dass der bestimmungsgemässe Gebrauch der gültigen, unveränderten
        Web-Applikation und der Mobile-App keinen unlauteren Wettbewerb
        darstellt. Die Rechtsgewährleistung bei leichter Fahrlässigkeit wird
        wegbedungen.
        <br /><br />
        Sind Schutzrechte Dritter verletzt worden, hat der Lizenzgeber die Wahl,
        entweder dem Lizenznehmer das Recht zur Weiterbenutzung zu beschaffen,
        die betreffende Software auszutauschen oder so zu verändern, dass eine
        Schutzrechtsverletzung nicht mehr vorliegt. Wenn das vorangehende nicht
        im Rahmen der vertretbaren Möglichkeiten liegt oder nicht innert einer
        Frist von 30 Tagen ab Mängelanzeige bewerkstelligt werden kann, hat der
        Lizenzgeber dem Lizenznehmer die von diesem geleistete Vergütung unter
        Abzug einer angemessenen Entschädigung für die zwischenzeitlich erfolgte
        Nutzung zurückzuzahlen.
        <br /><br />
        Der Lizenznehmer ist verpflichtet, den Lizenzgeber unverzüglich über
        mögliche oder abgemahnte Schutzrechtsverletzungen zu informieren. Der
        Lizenznehmer muss, falls notwendig und gesetzlich möglich, dem
        Lizenzgeber die Abwehrrechte einräumen, die der Lizenzgeber zur
        Verteidigung gegen entsprechende Ansprüche Dritter benötigt. Der
        Lizenznehmer ist verpflichtet, dem Lizenzgeber die Verteidigung zu
        überlassen und darf nicht selber, zumindest nicht ohne vorherige
        Zustimmung des Lizenzgebers aktiv werden. Der Lizenznehmer ist
        verpflichtet, den Lizenzgeber bei der Verteidigung gegen Ansprüche
        Dritter zu unterstützen.
        <br /><br />
        Die vorangehenden Bestimmungen zur Rechtsgewährleistung sind
        abschliessend und ersetzen insbesondere die gesetzlichen
        Rechtsgewährleistungsregeln. Der Lizenzgeber übernimmt weder
        ausdrücklich noch konkludent irgendeine andere Rechtsgewährleistung.
        <h2>7. Mitwirkungspflichten des Lizenznehmers</h2>
        Der Lizenznehmer verpflichtet sich, die Web-Applikation und/oder die
        Mobile-App nur entsprechend der jeweils aktuellen Funktionsbeschreibung
        zu gebrauchen. Er verpflichtet sich ferner zur Geheimhaltung von
        Passwörtern und anderen Zugangsschutzmechanismen und trifft geeignete
        Vorkehren, welche die unautorisierte Nutzung der Web-Applikations
        und/oder der Mobile-App nach Möglichkeit ausschliessen.
        <h2>8. Datenschutz</h2>
        Der Lizenzgeber hält sich an die gesetzlichen Datenschutzbestimmungen in
        der Schweiz bzw. an allenfalls andere anwendbare datenschutzrechtliche
        Bestimmungen.
        <br /><br />
        Für die Bearbeitung von Daten durch den Lizenznehmer in der
        Web-Applikation und der Mobile-App ist der Lizenznehmer
        datenschutzrechtlich verantwortlich («Data Controller»). Der
        Lizenznehmer sichert zu, dass er bei der Beschaffung von
        personenbezogenen Daten und deren Bearbeitung in der Web-Applikation und
        der Mobile-App die datenschutzrechtlichen und weiteren gesetzlichen
        Anforderungen jederzeit einhält. Insbesondere sichert der Lizenznehmer
        zu, dass er die Informationspflichten einhält und, falls gesetzlich
        verlangt, die notwendigen Zustimmungen der betroffenen Personen für die
        Bearbeitungen in der Web-Applikation und der Mobile-App einholt. Dies
        gilt insbesondere dann, wenn der Lizenznehmer in der Web-Applikation
        oder der Mobile-App Daten von Dritten eingibt und bearbeitet (z.B.
        Informationen über Mitarbeiter und die Liegenschaftsverwaltungen).
        <br /><br />
        Daten im Sinne dieser Bestimmung sowie des Lizenzvertrages insgesamt
        sind Informationen, die vom Lizenznehmer bzw. von autorisierten Nutzern
        der Web-Applikation oder der Mobile-App bei der bzw. zur Nutzung der
        Web-Applikation oder der Mobile-App zur Verfügung gestellt, eingegeben
        oder heraufgeladen werden. Daten beinhalten insbesondere
        personenbezogene Daten, die dem Lizenzgeber durch den Lizenznehmer oder
        auf dessen Anweisung hin übergeben werden oder auf welche der
        Lizenzgeber im Zusammenhang mit der Erfüllung seiner Pflichten aus
        diesem Vertrag Zugang erhält und die es erlauben, i) eine natürliche
        oder juristische Person zu identifizieren (z.B. Name, Adressen,
        Kontaktpersonen, Telefonnummer, E-Mail-Adressen) oder ii) eine
        natürliche oder juristische Person zu authentifizieren (z.B. Passwörter,
        PINs, etc.).
        <br /><br />
        Der Lizenzgeber erhält kein Recht an den Daten. Dem Lizenzgeber ist es
        jedoch erlaubt, auf anonymer Basis aggregierte statistische Daten
        betreffend die Nutzung durch die verschiedenen Lizenznehmer und die
        Funktionsfähigkeit seines Systems zu generieren. Der Lizenzgeber hat
        auch das Recht, die Daten, soweit möglich in aggregierter Form, zu
        analysieren, um damit die Funktionalitäten der Web-Applikation bzw. der
        Mobile-App zu verbessern. Der automatische Routenplaner basiert z.B. auf
        Technologien der künstlichen Intelligenz und verwendet bestehende
        Informationen zur Berechnung der optimalen Route zwischen verschiedenen
        Liegenschaften. Auch andere Funktionalitäten der Web-Applikation und der
        Mobile-App verwenden bestehende Nutzungsdaten, um optimale Lösungen und
        Informationen zu bieten. Der Nutzer erklärt sich mit diesen
        Datenbearbeitungen einverstanden.
        <br /><br />
        Der Lizenzgeber ist zudem berechtigt, Daten für Abrechnungs- und
        Verwaltungszwecke zu bearbeiten.
        <br /><br />
        Der Lizenzgeber speichert als technischer Dienstleister Inhalte und
        Daten für den Lizenznehmer, die dieser bei der Nutzung der
        Web-Applikation und der Mobile-App eingibt und speichert bzw. zum Abruf
        bereitstellt. Der Lizenznehmer verpflichtet sich dem Lizenzgeber
        gegenüber, keine strafbaren oder sonst absolut oder im Verhältnis zu
        einzelnen Dritten rechtswidrigen Inhalte und Daten einzustellen und
        keine Viren oder sonstige Schadsoftware enthaltende Programme im
        Zusammenhang mit der Web-Applikation und der Mobile-App zu nutzen. Der
        Lizenznehmer bleibt im Zusammenhang mit der Bearbeitung von Daten die
        verantwortliche Stelle im Sinne des Datenschutzgesetzes und hat daher
        stets zu prüfen, ob die Bearbeitung der Daten über die Web-Applikation
        und die Mobile-App datenschutzrechtlich zulässig ist. Der Lizenzgeber
        ist datenschutzrechtlich als Data Processor zu qualifizieren. Der
        Lizenzgeber und Lizenznehmer schliessen, sofern zur Einhaltung der
        anwendbaren Datenschutzgesetze notwendig, eine
        Auftragsdatenbearbeitungsvereinbarung ab.
        <br /><br />
        Weitere Informationen zu Datenverarbeitungen sind in der
        Datenschutzerklärung enthalten.
        <h2>9. Haftung</h2>
        Für Ansprüche aufgrund von Schäden, die durch den Lizenzgeber oder
        dessen gesetzlichen Vertreter oder Erfüllungsgehilfen verursacht wurden,
        haftet der Lizenzgeber unbeschränkt – soweit anwendbar – bei Verletzung
        des Lebens, des Körpers oder der Gesundheit, bei vorsätzlicher oder
        grobfahrlässiger Pflichtverletzung.
        <br /><br />
        Mit Ausnahme der vorangehend aufgeführten zwingenden Haftungsfälle wird
        jegliche Haftung, insbesondere auch die Haftung für Hilfspersonen (Art.
        101 schweizerisches Obligationenrecht), vollumfänglich ausgeschlossen.
        <h2>10. Schadloshaltung</h2>
        Der Lizenznehmer verpflichtet sich, den Lizenzgeber sowie dessen
        gesellschaftsrechtliche Organe und Arbeitnehmer von jeder Haftung und
        jeglichen Kosten, einschliesslich möglicher und tatsächlicher Kosten
        eines gerichtlichen Verfahrens, freizustellen, falls der Lizenzgeber von
        Dritten, auch von Arbeitnehmern des Lizenznehmers, infolge von
        behaupteten Handlungen oder Unterlassungen des Lizenznehmers in Anspruch
        genommen wird. Die Schadloshaltung besteht insbesondere in Verbindung
        mit Ansprüchen Dritter in Bezug auf:

        <ul>
          <li>
            Die vertragswidrige Nutzung der Leistungen oder Dienstleistungen aus
            diesem Vertrag durch den Lizenznehmer;
          </li>
          <li>
            Verstösse gegen diesen Vertrag oder gegen anwendbares Recht durch
            den Lizenznehmer;
          </li>
          <li>
            Die vom Lizenznehmer mit der Web-Applikation und der Mobile-App
            durchgeführten Datenbearbeitungen, einschliesslich die behauptete
            Verletzung von Rechten Dritter durch solche Datenbearbeitungen.
          </li>
        </ul>

        Der Lizenzgeber wird den Lizenznehmer unverzüglich über die
        Inanspruchnahme unterrichten und ihm, soweit dies rechtlich möglich ist,
        Gelegenheit zur Abwehr der Ansprüche geben. Gleichzeitig wird der
        Lizenznehmer dem Lizenzgeber unverzüglich alle ihm zur Verfügung
        stehenden Informationen über den Sachverhalt, der Gegenstand der
        Inanspruchnahme ist, liefern.
        <h2>11. Höhere Gewalt</h2>
        Kann der Lizenzgeber die vertraglichen Leistungen infolge höherer
        Gewalt, Krieg, Pandemie, Aufruhr oder anderer, für den Lizenzgeber
        unabwendbarer Umstände nicht oder nicht fristgerecht erbringen, treten
        für den Lizenzgeber keine nachteiligen Rechtsfolgen ein.
        <h2>12. Vertraulichkeit</h2>
        Die Parteien sind verpflichtet, alle ihnen im Zusammenhang mit diesem
        Vertrag bekannt gewordenen oder bekannt werdenden Informationen der
        jeweils anderen Partei, die als vertraulich gekennzeichnet werden oder
        anhand sonstiger Umstände als Geschäfts- und Betriebsgeheimnisse
        erkennbar sind („vertrauliche Informationen“), inklusive, aber nicht
        abschliessend, aller Informationen zu der Web-Applikation oder zur
        Mobile-App geheim zu halten, nicht an Dritte weiterzugeben,
        aufzuzeichnen oder in anderer Weise zu verwerten, sofern dies nicht zu
        Zwecken der Vertragsdurchführung notwendig ist oder die jeweils andere
        Partei der Offenlegung oder Verwertung ausdrücklich zugestimmt hat.
        <br /><br />
        Informationen sind keine vertraulichen Informationen, wenn sie:

        <ul>
          <li>
            der anderen Partei bereits zuvor bekannt waren, ohne dass die
            betreffende Informa-tion einer Vertraulichkeitsverpflichtung
            unterlegen hätte oder sonstwie unrechtmässig erlangt wurde;
          </li>
          <li>
            allgemein bekannt sind oder ohne Verletzung einer
            Vertraulichkeitsverpflichtung be-kannt wurden;
          </li>
          <li>
            der anderen Partei ohne Verletzung einer Vertraulichkeitspflicht von
            einem Dritten offenbart werden.
          </li>
        </ul>
        Diese Vertraulichkeitspflicht gilt auch nach Beendigung dieses
        Vertrages.
        <h2>13. Inkrafttreten / Laufzeit / Wirkung der Kündigung</h2>
        <h3>13.1. Dauer und Beendigung</h3>
        Der Vertrag tritt unmittelbar nach der Registrierung des Lizenznehmers
        und mit Erhalt der Bestätigungsemail in Kraft. Die minimale
        Vertragsdauer beträgt ein Monat. Die Laufzeit ergibt sich aus dem
        gewählten Lizenzmodell / Paket. Wird der Vertrag nicht vor Ablauf der
        Vertragsperiode gekündigt, verlängert sich der Vertrag jeweils
        automatisch um einen Monat.
        <h3>13.2. Wirkungen der Beendigung</h3>
        Betreffend die Lizenzgebühren gilt die Regelung in Ziff. 5. vorangehend.
        <br /><br />
        Nach Beendigung des Vertrages erlischt das Recht des Lizenznehmers zur
        Nutzung der Web-Applikation und der Mobile-App unverzüglich. Die
        Beendigung des Lizenzvertrages führt dazu, dass alle während der
        Vertragslaufzeit entstandenen Zahlungspflichten fällig werden. Die
        Beendigung des Lizenzvertrages hat jedoch keine Präjudizwirkung auf
        andere Rechtsbehelfe, welche die Parteien gemäss diesem Lizenzvertrag
        allenfalls haben.
        <br /><br />
        Bestimmungen, welche ausdrücklich oder konkludent auch nach Beendigung
        dieses Vertrages weiterhin Gültigkeit haben (z.B. die
        Vertraulichkeitspflicht), werden von der Beendigung ebenfalls nicht
        beeinflusst.
        <br /><br />
        Nach Beendigung des Lizenzvertrages hat der Lizenzgeber dem Lizenznehmer
        zuletzt sämtliche von diesem überlassenen und sich noch im Besitz des
        Lizenzgebers befindlichen Unterlagen, Daten sowie Datenträger, die im
        Zusammenhang mit diesem Vertrag stehen, herauszugeben. Der Lizenzgeber
        wird bei Vertragsende auf Wunsch des Lizenznehmers sämtliche Daten zum
        Download zur Verfügung stellen. Eine Aushändigung der Daten auf
        transportablen Datenträgern ist nicht vorgesehen bzw. erfolgt nur gegen
        eine entsprechende Aufwandentschädigung. Nach einer Kontrolle durch den
        Lizenznehmer wird der Lizenzgeber sämtliche Daten löschen.
        <h2>14. Weitere Bestimmungen</h2>
        Der Lizenznehmer darf – vorbehaltlich abweichender Bestimmungen in
        diesem Lizenzvertrag – einzelne Rechte aus diesem Vertrag sowie den
        Vertrag im Ganzen nicht auf Dritte übertragen, es sei denn der
        Lizenzgeber erteilt hierzu ausdrücklich seine schriftliche Zustimmung.
        <br /><br />
        Dieser Vertrag, dessen Anhänge sowie allfällige Änderungen und
        Ergänzungen bedürfen zu deren Gültigkeit der schriftlichen Festlegung
        durch beide Vertragsparteien. Auf dieses Formerfordernis kann nur durch
        schriftliche Abrede verzichtet werden.
        <br /><br />
        Für den Fall, dass Bestimmungen dieses Lizenzvertrages ganz oder
        teilweise unwirksam sind oder werden, berührt dies die Wirksamkeit des
        Lizenzvertrages im Übrigen nicht. Die Parteien werden in diesem Fall
        zusammenwirken, um unwirksame Regelungen durch solche Regelungen zu
        ersetzen, die den unwirksamen Bestimmungen soweit möglich entsprechen.
        <br /><br />
        Diese Vereinbarung untersteht Schweizer Recht. Ausschliesslicher
        Gerichtsstand für alle Streitigkeiten im Zusammenhang mit dieser
        Vereinbarung ist Zug/Schweiz.
        <br /><br />
        Stand: März 2021
      </div>
      <h1 :class="$style['header']">
        Allgemeine Geschäftsbedingungen - ImmoTrack-Webanwendung und mobile App
      </h1>
      <div :class="$style['text']">
        <h2>1. Web-Applikation</h2>
        <h3>1.1. Verwaltung</h3>
        <ul>
          <li>
            Nach Unterzeichnung des Vertrags mit ImmoTrack AG stellt ImmoTrack
            dem Unternehmen die Zugangsdaten für die Webanwendung sowie bei
            Bedarf Anweisungen zu Web- und mobilen Anwendungen zur Verfügung.
            Der Kunde erhält einen Bestätigungslink an die E-Mail und richtet
            das Passwort ein.
          </li>
          <li>
            Die Webanwendung "ImmoTrack" (platform.immotrack.com) ist eine
            Cloud-Lösung für Facility-Management-Unternehmen. Benutzer können
            über die Browser auf die Webanwendung zugreifen: Safari, Mozilla
            FireFox, Google Chrome.
          </li>
          <li>
            Im Falle einer Vertragskündigung von Seiten ImmoTrack oder des
            Kunden muss ImmoTrack den Zugang zu den Web- und mobilen Anwendungen
            sperren. Alle mit dem Kunden verbundenen Daten werden für 26 Monate
            gespeichert.
          </li>
          <li>Übersetzt mit www.DeepL.com/Translator (kostenlose Version).</li>
        </ul>
        <h3>1.2. Infrastruktur & Sicherheit</h3>
        <ul>
          <li>
            Alle Daten (aus Mobile und Web Apps) werden in einem Rechenzentrum
            in Frankfurt gespeichert.
          </li>
          <li>Backup - einmal pro Tag, gespeichert für 14 Tage.</li>
          <li>
            Kundendaten sind verschlüsselt (Login, Passwort). Datenbanken werden
            getrennt vom Hauptserver gespeichert, um Datenverluste zu vermeiden.
          </li>
          <li>Es sind keine Dritt-Parteien mit dem ImmoTrack verbunden.</li>
        </ul>
        <h2>2. Mobile-Applikation</h2>
        <h3>2.1. Verwaltung</h3>
        <ul>
          <li>
            Die mobile Anwendung "ImmoTrack" ist eine Multiplattform-Lösung für
            Facility Arbeiter. Die mobilen Anwendungen erfordern Android 5.5+,
            iOS 12.0+ Versionen.
          </li>
          <li>
            Die Anmeldedaten für das Konto werden vom Kunden über die
            Webanwendung vergeben. Der Mitarbeiter des Kunden erhält einen
            Bestätigungslink per E-Mail und richtet ein Passwort ein.
          </li>
        </ul>
        <h3>2.2. Infrastruktur & Sicherheit</h3>
        <ul>
          <li>
            Die mobile Anwendung stellt dem Mitarbeiter einen täglichen
            Arbeitsplan zur Verfügung. Alle Daten werden von der Web-Applikation
            übertragen und entsprechend dem Abschnitt 1.2 gespeichert.
          </li>
          <li>
            Die Mitarbeiterdatenbank wird verschlüsselt und getrennt vom
            Web-App-Server gespeichert, um Datenverluste zu vermeiden.
          </li>
          <li>
            Wir verfolgen den Standort des Mitarbeiters, wenn er (Mitarbeiter)
            die Erlaubnis zur Verfolgung seines Standorts erteilt.
          </li>
          <li>
            Bei Abstürzen von Android- und iOS-Apps verwenden wir eine
            Drittanbieterlösung - Firebase Crashlytics, die die iOS-Version und
            das Gerätemodell des Benutzers erfassen kann.
          </li>
          <li>
            Bei Abstürzen von Android- und iOS-Apps verwenden wir eine
            Drittanbieterlösung - Firebase Crashlytics, die die iOS-Version und
            das Gerätemodell des Benutzers erfassen kann.
          </li>
        </ul>
      </div>
    </div>
  </PageLayout>
</template>

<script>
import PageLayout from "@/layouts/PageLayout";

export default {
  page: {
    title: "",
    meta: [{ name: "description", content: "The  page." }]
  },
  components: { PageLayout }
};
</script>

<style lang="scss" module>
.terms-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
}
</style>
