<template>
  <AntConfigProvider :locale="antLocale">
    <div id="app">
      <InitializingScreen v-if="initializing" />
      <router-view v-else />
      <ConfirmationPlugin />
    </div>
  </AntConfigProvider>
</template>

<script>
import { ConfigProvider as AntConfigProvider } from "ant-design-vue";
import enUS from "ant-design-vue/es/locale/en_US";
import deDE from "ant-design-vue/es/locale/de_DE";

import InitializingScreen from "@/views/InitializingScreen";
import { mapState, mapGetters } from "vuex";

export default {
  components: { InitializingScreen, AntConfigProvider },
  mounted() {
    if (this.user) {
      this.initIntercomChat(this.user);
    }
  },
  beforeMount() {
    this.initLocalization();
  },
  computed: {
    ...mapState("configurations", ["initializing"]),
    ...mapGetters({ user: "auth/user", locale: "localization/locale" }),
    antLocale() {
      switch (this.locale) {
        case "en":
          return enUS;
        case "de":
          return deDE;
        default:
          return enUS;
      }
    }
  },
  watch: {
    user(data) {
      if (data) {
        this.initIntercomChat(data);
      } else {
        this.$intercom.shutdown();
      }
    }
  },
  methods: {
    initIntercomChat(user) {
      this.$intercom.boot({
        user_id: user.id,
        name: user.profile.first_name,
        email: user.email
      });
    },
    initLocalization() {
      this.$i18n.locale = this.locale;
    }
  }
};
</script>

<style lang="scss" module></style>
