<template>
  <div :class="$style['checkbox-wrapper']">
    <AntCheckbox v-model="model" :disabled="disabled">
      <slot></slot>
    </AntCheckbox>
  </div>
</template>

<script>
import { Checkbox as AntCheckbox } from "ant-design-vue";
import "ant-design-vue/lib/checkbox/style/css";
export default {
  name: "Checkbox",
  components: {
    AntCheckbox
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    }
  },
  methods: {}
};
</script>

<style lang="scss" module>
.checkbox-wrapper {
}
</style>
