<template>
  <div class="build-modal-break-information">
    <div class="build-modal-break-information__worker">
      <BasePicCircle
        helper="helper"
        :label="`${setupWorker.first_name} ${setupWorker.last_name}`"
        :fill="setupWorker.color"
        labeled
      />
    </div>
    <div class="build-modal-break-information__information">
      Break for {{ breakDuration }} minutes, {{ breakWindow }}
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import BasePicCircle from "@/components/ui/BasePicCircle";

export default {
  name: "BuildModalBreakInformation",
  data() {
    return {};
  },
  components: { BasePicCircle },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters("builds", ["worker"]),
    setupWorker() {
      return this.worker(this.data.workerId);
    },
    breakDuration() {
      return this.data.routeBreak.getDuration();
    },
    breakWindow() {
      return (
        "from " +
        this.data.routeBreak.getStartTimeMarker() +
        " to " +
        this.data.routeBreak.getEndTimeMarker()
      );
    }
  },
  methods: {}
};
</script>

<style lang="scss">
.build-modal-break-information {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
