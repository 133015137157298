<template>
  <GuestLayout>
    <template #illustration>
      <img :src="illustration" />
    </template>
    <template #content>
      <Grid>
        <div slot="header">{{ $t("newPassword") }}</div>
        <div slot="form">
          <Loading :loading="loading">
            <ResetPasswordForm
              :token="token"
              :email="email"
              v-if="loading || user"
            />
            <span v-if="error">
              {{ $t("passwordResetTokenError") }}
            </span>
          </Loading>
        </div>
      </Grid>
    </template>
  </GuestLayout>
</template>

<script>
import AuthIllustration from "@/assets/auth-img.png";

import GuestLayout from "@/layouts/GuestLayout";
import Grid from "@/components/grid/AuthFormGrid";

import ResetPasswordForm from "@/components/forms/AuthResetPasswordForm";

import { verifyToken } from "@/api/repositories/auth";

export default {
  page: {
    title: "Reset old password",
    meta: [{ name: "description", content: "The Reset old password page." }]
  },
  components: { GuestLayout, Grid, ResetPasswordForm },
  computed: {
    illustration() {
      return AuthIllustration;
    }
  },
  data() {
    return {
      error: false,
      loading: true,
      token: undefined,
      email: undefined,
      user: undefined
    };
  },
  mounted() {
    verifyToken({
      token: this.$route.query.token,
      email: this.$route.query.email
    })
      .then(response => {
        this.loading = false;
        this.token = this.$route.query.token;
        this.email = this.$route.query.email;
        this.user = response.data.user;
      })
      .catch(() => {
        this.loading = false;
        this.error = true;
      });
  }
};
</script>
