<template>
  <div :class="$style['task-entity-form-wrapper']">
    <TwoColumnGrid>
      <template #left-side>
        <base-form name="task-entity-form" :form="form" @submit="onSubmit">
          <base-field
            :label="$t('taskType')"
            :errors="form.errors.get('task-type')"
          >
            <base-select-tags
              name="task-type"
              v-model="form['task-type']"
              :options="taskGroups"
              :mappers="{
                options: option => {
                  return { label: option.name, value: option.id };
                },
                value: value => {
                  return value.id;
                }
              }"
              @input="form.errors.clear('task-type')"
            />
          </base-field>
          <base-field
            :label="$t('location')"
            :errors="form.errors.get('location')"
          >
            <base-select-tags
              name="location"
              v-model="form.location"
              :options="locations"
              :mappers="{
                options: option => {
                  return { label: option.position.address, value: option.id };
                },
                value: value => {
                  return value.id;
                }
              }"
              @input="form.errors.clear('location')"
            />
          </base-field>
          <base-field
            :label="$t('duration')"
            :errors="form.errors.get('duration')"
          >
            <base-minutes-picker
              name="duration"
              v-model="form.duration"
              @input="form.errors.clear('duration')"
            />
          </base-field>
          <base-field :label="$t('skills')" :errors="form.errors.get('skills')">
            <base-select-tags
              name="skills"
              mode="multiple"
              v-model="form.skills"
              :options="skills"
              :mappers="{
                options: option => {
                  return { label: option.name, value: option.id };
                },
                value: value => {
                  return value.id;
                }
              }"
              :disabled="form.worker"
              @input="skillsAssignChanged"
            />
          </base-field>
          <base-field :label="$t('worker')" :errors="form.errors.get('worker')">
            <base-select-tags
              name="worker"
              v-model="form.worker"
              :options="workers"
              allowClear
              :mappers="{
                options: option => {
                  return { label: option.first_name, value: option.id };
                },
                value: value => {
                  return value.id;
                }
              }"
              :disabled="form.skills.length > 0"
              @input="workerAssignChanged"
            />
          </base-field>
          <base-field
            :label="$t('checkpoints')"
            :errors="form.errors.get('checkpoints')"
          >
            <base-select-tags
              name="checkpoints"
              mode="multiple"
              v-model="form.checkpoints"
              :options="checkpoints"
              allowClear
              :mappers="{
                options: option => {
                  return { label: option.name, value: option.id };
                },
                value: value => {
                  return value.id;
                }
              }"
              @input="checkpointsAssignChanged"
            />
          </base-field>
        </base-form>
      </template>
      <template #right-side>
        <base-field :errors="form.errors.get('schedule')">
          <schedule-field v-model="form.schedule" name="schedule" />
        </base-field>
      </template>
    </TwoColumnGrid>
    <base-button
      size="large"
      @click="onSubmit"
      :loading="form.loading"
      :disabled="form.loading || form.errors.any()"
      >{{ form.hasUpdateType() ? $t("update") : $t("create") }}</base-button
    >
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";

import Form from "@/services/form/Form.js";

import BaseForm from "@/components/form/BaseForm";
import BaseButton from "@/components/form/BaseButton";
import BaseField from "@/components/form/BaseField";
import BaseSelectTags from "@/components/form/BaseSelectTags";
import BaseMinutesPicker from "@/components/form/BaseMinutesPicker";

import ScheduleField from "@/components/task/ScheduleField";

import TwoColumnGrid from "@/components/grid/TwoColumnGrid";

export default {
  name: "TaskEntityForm",
  data() {
    return {
      form: new Form(
        {
          "task-type": null,
          location: null,
          duration: null,
          skills: [],
          checkpoints: [],
          schedule: undefined,
          worker: null
        },
        {
          "task-type": {
            presence: { allowEmpty: false }
          },
          location: {
            presence: { allowEmpty: false }
          },
          duration: {
            presence: { allowEmpty: false }
          },
          schedule: {
            presence: { allowEmpty: false }
          }
        },
        {
          id: this.taskId
        }
      )
    };
  },
  components: {
    TwoColumnGrid,
    BaseForm,
    BaseButton,
    BaseField,
    BaseSelectTags,
    BaseMinutesPicker,
    ScheduleField
  },
  props: {
    taskId: {
      type: [Number, String],
      default: undefined
    }
  },
  computed: {
    ...mapGetters("tasks", ["task"]),
    ...mapState("configurations", ["skills", "taskGroups", "checkpoints"]),
    ...mapState("locations", ["locations"]),
    ...mapGetters("workers", ["workers"])
  },
  methods: {
    ...mapActions("tasks", ["createTask", "updateTask"]),
    initializeTask(taskId) {
      const task = this.task(taskId);
      if (task) {
        this.form.setData(
          {
            "task-type": task.group,
            location: task.location,
            duration: task.duration,
            skills: task.skills,
            schedule: task.schedule,
            worker: task.worker,
            checkpoints: task.checkpoints
          },
          {
            id: taskId
          }
        );
      }
    },
    onSubmit() {
      this.success = undefined;
      this.form
        .submit(this.form.hasUpdateType() ? this.updateTask : this.createTask)
        .then(task => {
          this.success = true;

          if (!this.form.hasUpdateType()) {
            this.$emit("taskCreate", task.id);
          }

          this.$emit("success");
        })
        .catch(error => {
          this.success = false;
          console.log(error);
        });
    },
    workerAssignChanged() {
      this.form.errors.clear("worker");
      if (this.form.worker) {
        this.form.skills = [];
      }
    },
    skillsAssignChanged() {
      if (this.form.skills.length > 0) {
        this.form.worker = null;
      }
    },
    checkpointsAssignChanged() {}
  },
  mounted() {
    if (this.form.hasUpdateType()) {
      this.initializeTask(this.taskId);
    }
  },
  watch: {
    taskId(taskId) {
      this.initializeTask(taskId);
    }
  }
};
</script>

<style lang="scss" module>
.task-entity-form-wrapper {
}
</style>
