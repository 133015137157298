<template>
  <div :class="$style['worker-schedule-form-wrapper']">
    <base-form name="worker-schedule-form" :form="form" @submit="onSubmit">
      <base-field :label="$t('title')" :errors="form.errors.get('title')">
        <base-input name="title" type="text" v-model="form.title" />
      </base-field>
      <base-field
        :label="$t('workingDays')"
        :errors="form.errors.get('weekdays')"
      >
        <base-week-days-picker
          v-model="form.weekdays"
          name="weekdays"
          magnitude="h"
          :min="0"
          :max="168"
        />
      </base-field>
      <base-field
        :label="$t('workingHoursPerDay')"
        :errors="form.errors.get('daily_working_hours')"
      >
        <worker-schedule-working-hours-input
          :workingHoursPerWeek="form.weekly_working_hours"
          :percentageOfEmployment="form.employmentPercentage"
          :reservedTime="form.reservedTime"
          :activeDaysInWeek="form.weekdays"
          :scheduleBreaks="form.breaks.validBreaks"
          v-model="form.start_of_working_day"
        />
      </base-field>
      <base-field
        :label="$t('breaks')"
        :errors="form.errors.get('breaks')"
        name="breaks"
      >
        <template #default="{ slotInputEvent }">
          <worker-schedule-breaks
            v-model="form.breaks"
            @input="slotInputEvent"
          />
        </template>
      </base-field>
      <base-field
        :label="$t('workingHoursPerWeek')"
        :errors="form.errors.get('weekly_working_hours')"
      >
        <base-range-picker
          v-model="form.weekly_working_hours"
          name="weekly_working_hours"
          magnitude="h"
          :min="0"
          :max="168"
        />
      </base-field>
      <base-field
        :label="$t('percentageOfEmployment')"
        :errors="form.errors.get('employmentPercentage')"
      >
        <base-range-picker
          v-model="form.employmentPercentage"
          name="employmentPercentage"
          magnitude="%"
          :min="0"
          :max="100"
        />
      </base-field>
      <base-field
        :label="$t('reservedTime')"
        :errors="form.errors.get('reservedTime')"
      >
        <base-input
          name="reservedTime"
          type="text"
          v-model="form.reservedTime"
        />
      </base-field>
      <base-field :label="$t('dates')" :errors="form.errors.get('dates')">
        <base-date-range-picker
          name="dates"
          v-model="form.dates"
          @input="datesUpdate"
          pastDisabled
          enormousDisabled
        />
      </base-field>
    </base-form>
    <base-button
      type="link"
      :loading="form.loading"
      :disabled="form.loading || form.errors.any() || deleting"
      @click="onSubmit"
    >
      {{ form.hasUpdateType() ? $t("update") : $t("create") }}
    </base-button>
    <base-button
      type="link"
      :loading="deleting"
      :disabled="deleting || form.loading"
      @click="deleteSchedule"
    >
      {{ $t("delete") }}
    </base-button>
  </div>
</template>

<script>
import moment from "moment";
import { mapActions } from "vuex";

import Form from "@/services/form/Form.js";

import BaseForm from "@/components/form/BaseForm";
import BaseField from "@/components/form/BaseField";
import BaseInput from "@/components/form/BaseInput";
import BaseRangePicker from "@/components/form/BaseRangePicker";
import BaseWeekDaysPicker from "@/components/form/BaseWeekDaysPicker";
import BaseDateRangePicker from "@/components/form/BaseDateRangePicker";
import BaseButton from "@/components/form/BaseButton";
import WorkerScheduleBreaks from "@/components/WorkerScheduleBreaks";

import WorkerScheduleWorkingHoursInput from "@/components/form/WorkerScheduleWorkingHoursInput";

import { parseExpression } from "@/utils/cron.js";

export default {
  name: "WorkerScheduleForm",
  data() {
    return {
      deleting: false,
      form: new Form(
        {
          title: this.schedule.id ? this.schedule.title : "",
          weekdays: this.schedule.id
            ? parseExpression(this.schedule["expression"]).daysOfWeek
            : this.defaultWorkingDays,
          start_of_working_day: this.schedule.start_of_working_day
            ? this.schedule.start_of_working_day
            : "08:00",
          breaks: this.schedule.breaks
            ? {
                validation: true,
                breaks: this.schedule.breaks,
                validBreaks: this.schedule.breaks
              }
            : {
                validation: true,
                breaks: [],
                validBreaks: []
              },
          weekly_working_hours: this.schedule.id
            ? this.schedule.weekly_working_hours
            : this.weeklyWorkingHours,
          employmentPercentage: this.schedule.id
            ? this.schedule.employment_percentage
            : 100,
          reservedTime: this.schedule.id ? this.schedule.reserved_time : 0,
          dates: this.schedule.id
            ? [
                this.schedule.from
                  ? moment(this.schedule.from).format("YYYY-MM-DD")
                  : null,
                this.schedule.to
                  ? moment(this.schedule.to).format("YYYY-MM-DD")
                  : null
              ]
            : [
                moment().format("YYYY-MM-DD"),
                moment()
                  .add(1, "years")
                  .format("YYYY-MM-DD")
              ]
        },
        {
          weekdays: {
            presence: { allowEmpty: false }
          },
          breaks: {
            validationByObjectProperty: {
              suggest: this.$i18n.t("resolveIntersections")
            }
          },
          weekly_working_hours: {
            presence: { allowEmpty: false }
          },
          employmentPercentage: {
            presence: { allowEmpty: false }
          },
          reservedTime: {
            presence: { allowEmpty: false },
            numericality: true
          },
          dates: {
            presence: { allowEmpty: false }
          }
        },
        {
          id: this.schedule.id
        }
      )
    };
  },
  components: {
    BaseForm,
    BaseField,
    BaseInput,
    BaseRangePicker,
    BaseWeekDaysPicker,
    BaseDateRangePicker,
    BaseButton,
    WorkerScheduleWorkingHoursInput,
    WorkerScheduleBreaks
  },
  props: {
    schedule: {
      type: Object,
      required: true
    },
    workerId: {
      type: [Number, String],
      required: true
    },
    weeklyWorkingHours: {
      type: [Number, String],
      required: true
    },
    defaultWorkingDays: {
      type: Object,
      required: true
    },
    datesIntervals: {
      type: Array,
      required: true
    }
  },
  computed: {},
  methods: {
    ...mapActions("workers", [
      "createWorkerSchedule",
      "updateWorkerSchedule",
      "deleteWorkerSchedule"
    ]),
    datesIntervalIsInValid() {
      const intersections = this.datesIntervals.filter(interval => {
        const finalScheduleFrom = interval.dates[0]
          ? interval.dates[0]
          : new moment().subtract(20, "years").format("YYYY-MM-DD");
        const finalScheduleTo = interval.dates[1]
          ? interval.dates[1]
          : new moment().add(20, "years").format("YYYY-MM-DD");
        const ScheduleFrom = this.form.dates[0]
          ? this.form.dates[0]
          : new moment().subtract(20, "years").format("YYYY-MM-DD");
        const ScheduleTo = this.form.dates[1]
          ? this.form.dates[1]
          : new moment().add(20, "years").format("YYYY-MM-DD");
        return (
          interval.scheduleId !== this.schedule.id &&
          (moment(ScheduleFrom).isBetween(finalScheduleFrom, finalScheduleTo) ||
            moment(ScheduleTo).isBetween(finalScheduleFrom, finalScheduleTo) ||
            moment(finalScheduleFrom).isBetween(ScheduleFrom, ScheduleTo) ||
            moment(finalScheduleTo).isBetween(ScheduleFrom, ScheduleTo) ||
            moment(ScheduleTo).isSame(moment(finalScheduleFrom)) ||
            moment(ScheduleFrom).isSame(moment(finalScheduleTo)) ||
            moment(ScheduleTo).isSame(moment(finalScheduleTo)) ||
            moment(ScheduleFrom).isSame(moment(finalScheduleFrom)))
        );
      });

      return intersections.length > 0 ? intersections : false;
    },
    onSubmit() {
      this.success = undefined;

      const intersections = this.datesIntervalIsInValid();

      if (intersections) {
        this.form.errors.push("dates", [
          this.$i18n.t("resolveIntersectionsDescriptions")
        ]);
        this.$emit("datesIntersectionError", intersections);
        return;
      }

      this.form
        .submit(
          this.form.hasUpdateType() ? this.updateSchedule : this.createSchedule
        )
        .then(() => {
          this.success = true;
        })
        .catch(() => {
          this.success = false;
        });
    },
    datesUpdate() {
      this.form.errors.clear("dates");
      this.$emit("datesUpdate");
    },
    createSchedule(data) {
      return this.createWorkerSchedule({
        workerId: this.workerId,
        data: data
      }).then(response => {
        this.$emit("deleteLocalSchedule", this.schedule.tempId);
        return response;
      });
    },
    updateSchedule(data) {
      return this.updateWorkerSchedule({
        workerId: this.workerId,
        scheduleId: data.id,
        data: data.data
      });
    },
    deleteSchedule() {
      if (this.schedule.id) {
        this.deleting = true;
        this.deleteWorkerSchedule({
          workerId: this.workerId,
          scheduleId: this.schedule.id
        })
          .then(() => {
            this.deleting = false;
          })
          .catch(() => {
            this.deleting = false;
          });
        return;
      }

      this.$emit("deleteLocalSchedule", this.schedule.tempId);
    }
  }
};
</script>

<style lang="scss" module>
.worker-schedule-form-wrapper {
}
</style>
