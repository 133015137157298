<template>
  <div :class="$style['range-picker-wrapper']">
    <Loading :loading="loading">
      <p :class="$style.min">{{ min + magnitude }}</p>
      <span :class="$style.max">{{ max + magnitude }}</span>
      <CustomSlider
        v-model="model"
        :min="min"
        :max="max"
        :step="0.5"
        :format="format"
      />
    </Loading>
  </div>
</template>

<script>
import Vue from "vue";
import CustomSlider from "@vueform/slider/dist/slider.vue2.js";
import "@vueform/slider/themes/default.css";
import VueCompositionAPI from "@vue/composition-api";

Vue.use(VueCompositionAPI);

export default {
  name: "RangePicker",
  components: { CustomSlider },
  data() {
    return {
      delay: null
    };
  },
  props: {
    placeholder: {
      type: String,
      default: "Placeholder"
    },
    value: {
      type: Number,
      default: 0
    },
    min: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: 100
    },
    magnitude: {
      type: String,
      default: ""
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    triggerDelay: {
      type: Number,
      default: 0
    }
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);

        if (this.delay) {
          clearTimeout(this.delay);
          this.delay = null;
        }
        this.delay = setTimeout(() => {
          this.$emit("change", value);
        }, this.triggerDelay);
      }
    }
  },
  methods: {
    format(value) {
      let finalValue = value;
      const left = value % 1;
      if ((left > 0 && left < 0.5) || (left > 0.5 && left < 1)) {
        finalValue = value.toFixed(1);
      }
      return finalValue + " " + this.magnitude;
    }
  }
};
</script>

<style lang="scss" module>
.range-picker-wrapper {
  align-items: center;
  height: 50px;
  .min {
    float: left;
  }
  .max {
    float: right;
  }
}
</style>
<style lang="scss">
.slider-horizontal {
  width: 100%;
  padding-left: 30px;
  padding-right: 30px;
  float: left;
}
.slider-connect {
  background: $primary-blue;
}
.slider-tooltip {
  background: #ffffff;
  filter: drop-shadow(0px 3px 2px rgba(0, 0, 0, 0.12));
  border: none;
  color: $primary-black;
}
.slider-touch-area {
  background: $primary-blue;
  position: relative;
  border-radius: 100%;
  &:after {
    content: "";
    display: block;
    position: absolute;
    right: 6px;
    top: 50%;
    transform: translate(0, -50%);
    background: rgba(255, 255, 255, 0.3);
    width: 1px;
    height: 5px;
  }
  &:before {
    content: "";
    display: block;
    position: absolute;
    left: 6px;
    top: 50%;
    transform: translate(0, -50%);
    background: rgba(255, 255, 255, 0.3);
    width: 1px;
    height: 5px;
  }
}
.slider-horizontal .slider-tooltip:before {
  border-top-color: #ffffff;
}
</style>
