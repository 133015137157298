<template>
  <div :class="$style['base-name-display-wrapper']">
    <span>
      Name: <b><slot /></b>
    </span>
  </div>
</template>

<script>
export default {
  name: "BaseNameDisplay",
  data() {
    return {};
  },
  components: {},
  props: {},
  computed: {},
  methods: {}
};
</script>

<style lang="scss" module>
.base-name-display-wrapper {
}
</style>
