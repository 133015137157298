<template>
  <div :class="$style['header-wrapper']">
    <div :class="$style.title">
      <slot name="title">Title</slot>
    </div>
    <div :class="$style.content" :style="style">
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "Header",
  components: {},
  props: {
    contentOn: {
      type: String,
      default: "right"
    }
  },
  computed: {
    style() {
      return {
        "justify-content": this.contentOn
      };
    }
  },
  methods: {}
};
</script>

<style lang="scss" module>
.header-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 23px;
  position: relative;
  .title {
    padding: 0 20px 0 20px;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
    color: $primary-black;
    z-index: 2;
  }
  .content {
    position: absolute;
    padding: 0 20px 0 20px;
    width: 100%;
    display: flex;
    z-index: 1;
  }
}
</style>
