import axios from "axios";
import { getAuthToken } from "@/utils/auth";
import { message } from "ant-design-vue";
import "ant-design-vue/lib/message/style/css";
import { resetAfterUnauthenticatedError } from "@/services/auth";
import store from '@/store'



const httpClient = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
    "Accept-Language": store.getters['localization/locale']
  }
});

httpClient.interceptors.request.use(request => {
  request.headers["Authorization"] = getAuthToken();
  return request;
});

httpClient.interceptors.response.use(
  response => {
    switch (response.status) {
      case 200:
        break;
      default:
    }

    return response.data;
  },
  error => {
    if (!error.response) {
      message.error("Something went wrong. Check you Internet connection.");
      return Promise.reject(error);
    }

    switch (error.response.status) {
      case 400:
        message.error(
          "Something went wrong. Contact the administration, please."
        );
        break;

      case 403:
        // message.error(
        //   "Something went wrong. Contact the administration, please."
        // );
        return Promise.resolve(error);

      case 401:
        resetAfterUnauthenticatedError();
        break;

      case 422:
        return Promise.reject({
          status: 422,
          fields: Object.fromEntries(
            Object.entries(error.response.data.errors.fields).map(field => {
              return [field[0], field[1].map(error => error.message)];
            })
          )
        });

      default:
        return Promise.reject(error);
    }
  }
);

export default httpClient;
