<template>
  <MainLayout>
    <template #header-title>{{ $t("locations") }}</template>
    <template #header-content
      ><HeaderTableContent
        @add="addLocationEntity"
        :entity="$t('location')"
        @search="tableSearch"
    /></template>
    <template #content>
      <BulkNavigation
        :buttons="{
          delete: { name: $t('deleteLocations') }
        }"
        v-show="selected.length"
        @edit="$drawer.show('right-bar')"
        @remove="removeBulk"
      />
      <LocationsTable
        :selectedLocations.sync="selected"
        :searchQuery="searchQuery"
        @editLocation="edit"
        @removeLocation="remove"
        actionable
      />
      <ModalPlugin name="location-modal">
        <location-entity-form
          :locationId="locationId"
          @locationCreate="setLocationAsActive"
          @success="$modal.hide('location-modal')"
        />
      </ModalPlugin>
    </template>
  </MainLayout>
</template>

<script>
import { mapActions } from "vuex";

import MainLayout from "@/layouts/MainLayout";

import LocationEntityForm from "@/components/forms/LocationEntityForm";
import LocationsTable from "@/components/tables/LocationsTable";

import BaseNameDisplay from "@/components/ui/BaseNameDisplay";
import HeaderTableContent from "@/components/layout/HeaderTableContent";

export default {
  page: {
    title: "Locations",
    meta: [{ name: "description", content: "The Locations page." }]
  },
  data() {
    return {
      locationId: undefined,
      searchQuery: "",
      selected: []
    };
  },
  components: {
    MainLayout,
    LocationEntityForm,
    HeaderTableContent,
    LocationsTable
  },
  computed: {
    // ...mapGetters("locations", ["location"]),
    // ...mapState("locations", ["locations"])
  },
  methods: {
    ...mapActions("locations", ["removeLocation", "removeLocations"]),
    setLocationAsActive(locationId) {
      this.locationId = locationId;
    },
    tableSearch(query) {
      this.searchQuery = query;
    },
    success() {
      this.$modal.hide("location-modal");
    },
    addLocationEntity() {
      this.locationId = undefined;
      this.$modal.show("location-modal");
    },
    edit(e) {
      this.locationId = e.id;
      this.$modal.show("location-modal");
    },
    remove(e) {
      this.$confirmation
        .show({
          items: [
            {
              component: BaseNameDisplay,
              slot: e[0].position.address
            }
          ],
          action: "delete",
          title: this.$i18n.t("deleteLocation"),
          message: this.$i18n.t("deleteLocationDescription"),
          width: 440
        })
        .then(() => {
          this.removeLocation(e[0].id).then(() => {
            //
          });
        })
        .catch(() => {
          console.log("Deletion canceled");
        });
    },
    removeBulk() {
      this.$confirmation
        .show({
          items: this.selected.map(item => {
            return {
              component: BaseNameDisplay,
              slot: item.description
            };
          }),
          action: "delete",
          title: this.$i18n.t("deleteLocations"),
          message: this.$i18n.t("deleteLocationsDescription")
        })
        .then(() => {
          this.removeLocations(this.selected.map(worker => worker.id)).then(
            () => {
              this.selected = [];
            }
          );
        })
        .catch(() => {
          console.log("Deletion canceled");
        });
    }
  },
  mounted() {
    //
  }
};
</script>
