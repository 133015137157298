<template>
  <div :class="$style['right-bar-wrapper']">
    <working-days-editor
      :workers="formatedWorkers"
      :loading="loading"
      @update="update"
    />

    <div :class="$style['workers-selected']">
      <span :class="$style['right-bar-header']">{{
        $t("workersSelected")
      }}</span>
      <base-plane-list
        :items="
          workersSelected.map(
            worker => worker.first_name + ' ' + worker.last_name
          )
        "
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

import WorkingDaysEditor from "@/components/worker/WorkingDaysEditor";
import BasePlaneList from "@/components/ui/BasePlaneList";

import { parseExpression } from "@/utils/cron.js";

export default {
  name: "RightBar",
  components: { WorkingDaysEditor, BasePlaneList },
  data() {
    return {
      loading: false
    };
  },
  props: {
    workersSelected: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  computed: {
    ...mapState("workers", ["workers"]),
    formatedWorkers() {
      return this.workers
        .filter(worker =>
          this.workersSelected.find(
            workerSelected => worker.id == workerSelected.id
          )
        )
        .map(worker => {
          return {
            id: worker.id,
            firstName: worker.first_name,
            lastName: worker.last_name,
            schedules: worker.schedules.map(schedule => {
              return {
                weekdays: this.parseExpression(schedule.expression).daysOfWeek,
                employment_percentage: schedule.employment_percentage,
                weekly_working_hours: schedule.weekly_working_hours,
                reserved_time: schedule.reserved_time,
                from_date: schedule.from,
                to_date: schedule.to
              };
            }),
            "day-offs": worker["custom-days"]
              .filter(day => {
                return day.type === "day-off";
              })
              .map(day => {
                return { day: day.date };
              }),
            "working-days": worker["custom-days"]
              .filter(day => {
                return day.type === "working-day";
              })
              .map(day => {
                return { day: day.date };
              })
          };
        });
    }
  },
  methods: {
    ...mapActions("workers", ["updateWorkersCustomDays"]),
    parseExpression,
    update(days) {
      this.loading = true;
      this.updateWorkersCustomDays({
        workersId: this.workersSelected.map(worker => worker.id),
        data: {
          "working-days": days.filter(day => day.status).map(day => day.date),
          "day-offs": days.filter(day => !day.status).map(day => day.date)
        }
      }).then(() => {
        this.loading = false;
      });
    }
  }
};
</script>

<style lang="scss" module>
.right-bar-wrapper {
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  .right-bar-header {
    font-size: 20px;
    margin-bottom: 10px;
  }
}
</style>
