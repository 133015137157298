<template>
  <div
    :class="$style['base-text-editable-wrapper']"
    v-click-outside="clickOutside"
  >
    <BaseIcon @click="updateMode" :name="icon" fill="#1c2a4a" :height="16" />
    <BaseInput v-if="editing" v-model="input" />
    <p v-else :class="$style['text']">{{ input }}</p>
  </div>
</template>

<script>
import BaseInput from "@/components/form/BaseInput";
import BaseIcon from "@/components/ui/BaseIcon";

export default {
  name: "BaseTextEditable",
  data() {
    return {
      editing: false,
      input: ""
    };
  },
  components: { BaseInput, BaseIcon },
  props: {
    value: {
      type: [String, Number],
      required: true
    },
    updateOnClickOutside: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    icon() {
      return this.editing ? "done" : "edit";
    }
  },
  methods: {
    updateMode() {
      if (this.editing) {
        this.$emit("input", this.input);
      }

      this.editing = !this.editing;
    },
    clickOutside() {
      if (this.updateOnClickOutside && this.editing) {
        this.updateMode();
      }
    }
  },
  directives: {
    "click-outside": {
      bind: function(el, binding, vnode) {
        el.clickOutsideEvent = function(event) {
          if (!(el == event.target || el.contains(event.target))) {
            vnode.context[binding.expression](event);
          }
        };
        document.body.addEventListener("click", el.clickOutsideEvent);
      },
      unbind: function(el) {
        document.body.removeEventListener("click", el.clickOutsideEvent);
      }
    }
  },
  mounted() {
    this.input = this.value;
  },
  watch: {
    value(value) {
      this.input = value;
    }
  }
};
</script>

<style lang="scss" module>
.base-text-editable-wrapper {
}
</style>
