import Vue from "vue";
import Router from "vue-router";
import routes from "@/router/routes/index.js";
import store from "@/store";

import { logoutService } from "@/services/auth";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      redirect: "/dashboard/workers"
    },
    {
      path: "/logout",
      name: "logout",
      meta: {
        public: false
      },
      beforeEnter(routeTo, routeFrom, next) {
        logoutService().then(() => {
          const previousRouteIsPrivate = routeFrom.matched.some(
            route => !route.meta.public
          );
          next(previousRouteIsPrivate ? { path: "/login" } : { ...routeFrom });
        });
      }
    }
  ].concat(routes)
});

router.beforeEach(async (to, from, next) => {
  const authenticated = store.getters["auth/authenticated"];
  const isAdmin = store.getters["auth/isAdmin"];
  const initialized = store.getters["configurations/initialized"];
  const onlyGuests = to.matched.some(record => record.meta.onlyLoggedOut);
  const onlyAdmin = to.matched.some(record => record.meta.onlyAdmin);
  const isPublic = to.matched.some(record => record.meta.public);
  if (!isPublic && !authenticated) {
    return next({
      path: "/login",
      query: { redirect: to.fullPath }
    });
  }
  if (authenticated) {
    if (onlyGuests) return next("/");
    if (onlyAdmin && !isAdmin) return next("/");

    if (!initialized) {
      // Можно убрать это в сервис
      store.dispatch("configurations/setInitializeStatus", true);

      const configurations = await store.dispatch(
        "configurations/initializeConfigurations"
      );
      await store.dispatch(
        "configurations/setActiveConfiguration",
        configurations[0].id
      );

      await store.dispatch("builds/initializeBuilds");

      store.dispatch("configurations/setInitializeStatus", false);
    }
  }

  next();
});

export default router;
