<template>
  <div :class="$style['select-wrapper']">
    <AntSelect
      :class="{ [$style.select]: true, [$style.error]: error }"
      :size="size"
      :mode="mode"
      :options="finalOptions"
      :loading="loading"
      :disabled="disabled || loading"
      :showArrow="true"
      :filterOption="filterOption"
      v-model="model"
      :getPopupContainer="trigger => trigger.parentNode"
      :allowClear="allowClear"
    >
      <template v-if="loading" slot="notFoundContent">
        Loading..
      </template>
    </AntSelect>
  </div>
</template>

<script>
import { Select as AntSelect } from "ant-design-vue";
import "ant-design-vue/lib/select/style/css";

export default {
  name: "BaseSelectTags",
  components: { AntSelect },
  data() {
    return {};
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: "large"
    },
    mode: {
      type: String,
      default: "default"
    },
    placeholder: {
      type: String,
      default: "Placeholder"
    },
    error: {
      type: Boolean,
      default: false
    },
    value: {
      type: [Array, Object, String, Number]
    },
    options: {
      type: Array,
      default() {
        return [];
      }
    },
    mappers: {
      type: Object,
      default() {
        return {
          options: false,
          value: false
        };
      }
    },
    disableNull: {
      type: Boolean,
      default: false
    },
    allowClear: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    model: {
      get() {
        if (this.mappers.value) {
          if (Array.isArray(this.value)) {
            return this.value.map(this.mappers.value);
          } else {
            return this.value ? this.mappers.value(this.value) : this.value;
          }
        }

        return this.value;
      },
      set(value) {
        this.detectAction(value);
      }
    },
    finalOptions() {
      if (this.mappers.options) {
        return this.options.map(this.mappers.options);
      }

      return this.options;
    }
  },
  methods: {
    detectAction(value) {
      if (this.disableNull && value.length == 0) {
        return;
      }
      if (this.mode == "tags") {
        if (this.value.length > value.length) {
          const difference = this.model.filter(x => !value.includes(x));
          this.$emit("delete", difference);
        } else {
          const difference = value.filter(x => !this.model.includes(x));
          this.$emit("add", difference);
        }
      } else {
        if (this.mappers.value) {
          if (Array.isArray(value)) {
            this.$emit(
              "input",
              value.map(_value => {
                const optionIndex = this.finalOptions.findIndex(finalOption => {
                  return finalOption.value == _value;
                });
                return this.options[optionIndex];
              })
            );
            return;
          } else {
            const optionIndex = this.finalOptions.findIndex(finalOption => {
              return finalOption.value == value;
            });
            this.$emit("input", this.options[optionIndex]);
            return;
          }
        }

        this.$emit("input", value);
      }
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    }
  }
};
</script>

<style lang="scss" module>
.select-wrapper {
  min-width: 100px;
  width: 100%;
  .select {
    width: 100%;
  }
  .error {
    border: 2px solid red;
    border-radius: 5px;
  }
}
</style>
<style lang="scss">
.ant-select {
  .ant-select-selection--multiple {
    .ant-select-selection__rendered {
      height: 50px;
      .ant-select-selection__choice {
        background: #d9eefe;
        margin-top: 9px;
        color: $primary-black;
      }
    }
    .ant-select-arrow {
      cursor: pointer;
      top: 27px;
    }
  }
}
</style>
