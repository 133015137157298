const Confirmation = {
  install(Vue) {
    this.EventBus = new Vue();

    Vue.prototype.$confirmation = {
      show(params) {
        return new Promise((resolve, reject) => {
          Confirmation.EventBus.$emit("show", {
            params: params,
            resolve: resolve,
            reject: reject
          });
        });
      }
    };
  }
};

export default Confirmation;
