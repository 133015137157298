<template>
  <div :class="$style['date-range-wrapper']">
    <AntDatePicker
      :class="$style['date-range-picker']"
      v-model="startValue"
      :disabled-date="disabledStartDate"
      :format="format"
      :placeholder="$t('startDate')"
      @openChange="handleStartOpenChange"
    />
    <AntDatePicker
      :class="$style['date-range-picker']"
      v-model="endValue"
      :disabled-date="disabledEndDate"
      :format="format"
      :placeholder="$t('endDate')"
      :open="endOpen"
      @openChange="handleEndOpenChange"
    />
  </div>
</template>

<script>
import { DatePicker as AntDatePicker } from "ant-design-vue";
import "ant-design-vue/lib/date-picker/style/css";
import moment from "moment";
// import locale from "ant-design-vue/es/date-picker/locale/en_US";
export default {
  name: "DateRange",
  components: { AntDatePicker },
  data() {
    return {
      endOpen: false
    };
  },
  computed: {
    startValue: {
      get() {
        return this.value[0] ? this.moment(this.value[0], this.format) : null;
      },
      set(value) {
        this.$emit("input", [
          value ? value.format(this.format) : null,
          this.endValue ? this.endValue.format(this.format) : null
        ]);
      }
    },
    endValue: {
      get() {
        return this.value[1] ? this.moment(this.value[1], this.format) : null;
      },
      set(value) {
        this.$emit("input", [
          this.startValue ? this.startValue.format(this.format) : null,
          value ? value.format(this.format) : null
        ]);
      }
    }
  },
  props: {
    value: {
      type: Array,
      default() {
        return [null, null];
      }
    },
    format: {
      type: String,
      default: "YYYY-MM-DD"
    },
    size: {
      type: String,
      default: "large"
    },
    loading: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    moment,
    disabledStartDate(startValue) {
      const endValue = this.endValue;
      if (!startValue || !endValue) {
        return false;
      }
      return startValue.valueOf() > endValue.valueOf();
    },
    disabledEndDate(endValue) {
      const startValue = this.startValue;
      if (!endValue || !startValue) {
        return false;
      }
      return startValue.valueOf() >= endValue.valueOf();
    },
    handleStartOpenChange(open) {
      if (!open) {
        this.endOpen = true;
      }
    },
    handleEndOpenChange(open) {
      this.endOpen = open;
    }
  }
};
</script>

<style lang="scss" module>
.date-range-wrapper {
  width: 100%;
  display: flex;
  padding: inherit;
  .date-range-picker {
    width: 50%;
  }
  .date-range-picker.disabled {
    opacity: 0.6;
  }
  .date-range-picker.error {
    border: 2px solid red !important;
    border-radius: 5px;
  }
}
</style>
