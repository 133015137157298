export default class RoutePointTask {
  #id;
  #location;
  #task;
  #group;

  constructor(routePointTask) {
    this.#id = routePointTask.id;
    this.#location = routePointTask.location;
    this.#task = routePointTask.task;
    this.#group = routePointTask.group;
  }

  getId() {
    return this.#id;
  }

  getTaskId() {
    return this.#task.id;
  }

  getLocationId() {
    return this.#location.id;
  }

  getGroupId() {
    return this.#group.id;
  }
}
