import { jsonApi, restApi } from "@/api";

export const launchBuild = (configurationId, data) => {
  return restApi.post(`/custom/configurations/${configurationId}/builds`, data);
};

export const fetchBuilds = configurationId => {
  return jsonApi.index(`configurations/${configurationId}/builds`);
};

export const fetchBuild = buildId => {
  return jsonApi.index("builds/" + buildId, {
    include: [
      "setup.skills",
      "setup.task-groups",
      "setup.locations.position",
      "setup.locations.schedule",
      "setup.tasks.schedule",
      "setup.workers.schedules",
      "day-plans.routes.worker",
      "day-plans.routes.route-points.route-point-task",
      "day-plans.routes.route-breaks",
      "day-plans.routes.route-way-points-list",
      "day-plans.errors",
      "day-plans.error-handle"
    ].join(",")
  });
};

export const deleteBuild = buildId => {
  return jsonApi.remove("builds", buildId);
};

export const updateBuild = (buildId, data) => {
  return jsonApi.update("builds", buildId, {
    title: data.title
  });
};

export const saveBuildAs = (buildId, data) => {
  return restApi.post(`/custom/builds/${buildId}/clone`, data);
};

export const rebuildBuild = (buildId, data) => {
  return restApi.post(`/custom/builds/rebuild/${buildId}`, data);
};

export const getEstimation = buildId => {
  return restApi.post(`/custom/builds/${buildId}/estimation`);
};
