<template>
  <div :class="$style['not-optimized-popup-wrapper']">
    <h2 :class="$style.title">{{ $t("routeIsNotOptimized") }}</h2>
    <BaseTable
      :columns="workersColumns"
      :data="workersForTable"
      disableSelection
    />
    <BaseTable
      :columns="locationsColumns"
      :data="locationsForTable"
      disableSelection
    />
    <BaseTable
      :columns="tasksColumns"
      :data="tasksForTable"
      @select="selectTaskForDrop"
      :disableSelection="errorHandle"
    />
    <div :class="$style['footer']">
      <div v-if="errorHandle" :class="$style['error-handle-result']">
        <span :class="$style['title']" v-if="errorHandle.accept_suggestion">{{
          $t("workersWorkingTimeSuggestAccepted")
        }}</span>
        <div :class="$style['title']" v-else>{{ $t("tasksDropped") }}</div>
        <Button @click="removeErrorHandle">
          {{ $t("removeErrorHandle") }}
        </Button>
      </div>
      <div v-else :class="$style['error-handle-options']">
        <Button
          @click="handleErrorWithTasksDrops"
          :disabled="this.tasksForDrops.length == 0"
        >
          {{ $t("dropSelectedTasks") }} ({{ this.tasksForDrops.length }})
        </Button>
        <Button @click="handleErrorWithAcceptSuggestion">
          {{ $t("acceptSuggestion") }}
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import BaseIcon from "@/components/ui/BaseIcon";
import BaseTable from "@/components/table/BaseTable";

import BasePicCircle from "@/components/ui/BasePicCircle";

import WorkerScheduleBreaksOptimizationList from "@/components/WorkerScheduleBreaksOptimizationList";

export default {
  name: "NotOptimizedErrorPopup",
  data() {
    return {
      tasksForDrops: [],
      workersColumns: [
        {
          title: this.$i18n.t("name"),
          width: "30%",
          customRender: worker => {
            return this.$createElement(BasePicCircle, {
              scopedSlots: {
                default: () =>
                  this.$createElement(BaseIcon, {
                    props: {
                      name: "task",
                      fill: "#ffffff"
                    }
                  })
              },
              props: {
                label: worker.worker.getName(),
                labeled: true
              }
            });
          }
        },
        {
          title: this.$i18n.t("workingHours"),
          width: "35%",
          customRender: worker => {
            const suggestionTimeWindowsUpdates = this.day.day.find(
              suggestWorker => suggestWorker.worker_id == worker.worker.getId()
            );
            return suggestionTimeWindowsUpdates
              ? this.$i18n.t("increaseScheduleFrom") +
                  " " +
                  worker["time-window"][0] +
                  " - " +
                  worker["time-window"][1] +
                  " " +
                  this.$i18n.t("to") +
                  " " +
                  suggestionTimeWindowsUpdates["time_window"][0] +
                  " - " +
                  suggestionTimeWindowsUpdates["time_window"][1]
              : worker["time-window"][0] + " - " + worker["time-window"][1];
          }
        },
        {
          title: this.$i18n.t("breaks"),
          width: "35%",
          customRender: worker => {
            const allBreaks = worker.breaks;
            const suggestionBreakUpdates = this.day.breaksDurationsUpdates.filter(
              breakDurationUpdate =>
                breakDurationUpdate.worker_id == worker.worker.getId()
            );
            return this.$createElement(WorkerScheduleBreaksOptimizationList, {
              props: {
                breaks: allBreaks,
                breaksUpdates: suggestionBreakUpdates
              }
            });
          }
        }
      ],
      tasksColumns: [
        {
          title: this.$i18n.t("location"),
          width: "50%",
          customRender: task => {
            return this.$createElement(
              "p",
              { style: task.drop ? "color:red" : "color:black" },
              task.getLocation().position.address
            );
          }
        },
        {
          title: this.$i18n.t("taskType"),
          width: "25%",
          customRender: task => {
            return this.$createElement(BasePicCircle, {
              scopedSlots: {
                default: () =>
                  this.$createElement(BaseIcon, {
                    props: {
                      name: "task",
                      fill: "#ffffff"
                    }
                  })
              },
              props: {
                label: this.taskGroup(task.getGroup().id).name,
                labeled: true
              }
            });
          }
        },
        {
          title: this.$i18n.t("duration"),
          customRender: task => {
            return task.getDuration() + " " + this.$i18n.t("min");
          }
        },
        {
          title: "",
          customRender: task => {
            return task.drop
              ? this.$createElement(
                  "p",
                  { style: "color:red" },
                  this.$i18n.t("dropCandidate")
                )
              : "";
          }
        }
      ],
      locationsColumns: [
        {
          title: this.$i18n.t("address"),
          width: "50%",
          customRender: location => {
            return location.location.getAddress();
          }
        },
        {
          title: this.$i18n.t("preferableTime"),
          width: "50%",
          customRender: location => {
            const suggestion = this.day.day.find(
              suggestLocation =>
                suggestLocation.location_id == location.location.getId()
            );
            const from = location["time-window"][0]
              ? this.$i18n.t("from") + " " + location["time-window"][0]
              : "";
            const to = location["time-window"][1]
              ? this.$i18n.t("to") + " " + location["time-window"][1]
              : "";

            return !location["time-window"][0] && !location["time-window"][1]
              ? this.$i18n.t("noPreferableTime")
              : suggestion
              ? this.$i18n.t("increaseScheduleFrom") +
                " " +
                '"' +
                from +
                " " +
                to +
                '"' +
                " " +
                this.$i18n.t("to") +
                '"' +
                this.$i18n.t("from") +
                " " +
                suggestion["time_window"][0] +
                " " +
                this.$i18n.t("to") +
                " " +
                suggestion["time_window"][1] +
                '"'
              : from + " " + to;
          }
        }
      ]
    };
  },
  components: { BaseTable },
  props: {
    day: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  computed: {
    ...mapGetters("builds", ["taskGroup"]),
    workersForTable() {
      return this.day.data.workers;
    },
    locationsForTable() {
      return this.day.data.locations;
    },
    tasksForTable() {
      return this.day.data.tasks.map(task => {
        task.drop =
          this.day.drops.find(taskDrop => taskDrop == task.getId()) &&
          this.day.data.tasks.length > 1;
        return task;
      });
    },
    errorHandle() {
      return this.day.errorHandle;
    }
  },
  methods: {
    selectTaskForDrop(rows) {
      this.tasksForDrops = rows.keys;
    },
    handleErrorWithTasksDrops() {
      this.$emit("handleError", {
        error_id: this.day.error_id,
        tasks_drops: this.tasksForTable
          .filter((task, key) => {
            return (
              this.tasksForDrops.find(dropKey => dropKey == key) !== undefined
            );
          })
          .map(task => {
            return { id: task.getId() };
          })
      });
    },
    handleErrorWithAcceptSuggestion() {
      this.$emit("handleError", {
        error_id: this.day.error_id,
        accept_suggestion: true
      });
    },
    removeErrorHandle() {
      this.$emit("removeErrorHandle", {
        error_id: this.day.error_id
      });
    }
  }
};
</script>

<style lang="scss" module>
.not-optimized-popup-wrapper {
  color: $primary-black;
  text-align: center;
  .title {
    font-weight: 600;
    font-size: 24px;
    line-height: 25px;
    margin-bottom: 4px;
  }
  .subtitle {
    font-weight: 600;
    font-size: 16px;
    line-height: 25px;
    margin-bottom: 16px;
  }
  .date {
    font-size: 16px;
    line-height: 25px;
    margin-bottom: 21px;
  }
  .point-wrapper {
    display: flex;
    justify-content: center;
    .worker {
      display: flex;
      align-items: center;
      .time {
        margin-left: 20px;
      }
    }
    .location {
      display: flex;
      align-items: center;
      .time {
        margin-left: 20px;
      }
    }
  }

  .footer {
    margin-top: 20px;
  }

  .error-handle-options {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
  }

  .error-handle-result {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
  }
}
</style>
