<template>
  <div :class="$style['sidebar-wrapper']">
    <Logo :class="$style.logo" />
    <div :class="$style.menu">
      <div v-for="item in menu" :key="item.title" @click="navigate(item.to)">
        <Icon
          :name="item.icon"
          :class="$style['menu-item']"
          :active="isActive(item.to)"
          pointer
        />
      </div>
    </div>
    <LocalizationSelector :class="$style.localization" />
    <div @click="navigate('logout')" :class="$style.logout">
      <Icon name="logout" :class="$style.icon" pointer />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import LocalizationSelector from "@/components/LocalizationSelector";

export default {
  name: "Sidebar",
  data() {
    return {};
  },
  components: { LocalizationSelector },
  computed: {
    ...mapGetters("auth", ["isAdmin"]),
    menu() {
      const base = [
        {
          title: "Work plan",
          to: "route_plan",
          icon: "map"
        },
        {
          title: "Locations",
          to: "locations",
          icon: "location"
        },
        {
          title: "Tasks",
          to: "tasks",
          icon: "task"
        },
        {
          title: "Workers",
          to: "workers",
          icon: "workers"
        },
        {
          title: "Settings",
          to: "configuration",
          icon: "settings"
        }
      ];

      if (this.isAdmin) {
        base.push({
          title: "Users",
          to: "users",
          icon: "folder"
        });
      }

      return base;
    }
  },
  methods: {
    navigate(to) {
      if (!this.isActive(to)) {
        this.$router.push({ name: to });
      }
    },
    isActive(to) {
      return to === this.$route.name;
    }
  }
};
</script>

<style lang="scss" module>
.sidebar-wrapper {
  width: 100%;
  height: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .logo {
    cursor: pointer;
  }
  .menu {
    flex: 1;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    margin-top: 25px;
    .menu-item {
    }
  }
  .localization {
    flex: 0;
    margin-bottom: 20px;
  }
  .logout {
    flex: 0;
    justify-self: flex-end;
    .icon {
    }
  }
}
</style>
