<template>
  <div :class="$style['locations-table-wrapper']">
    <BaseTable
      :columns="columns"
      :data="locations"
      :loading="loading"
      :actionable="actionable"
      :search="search"
      @select="select"
      @edit="edit"
      @remove="remove"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

import BaseTable from "@/components/table/BaseTable";

export default {
  name: "LocationsTable",
  data() {
    return {
      loading: false
    };
  },
  components: { BaseTable },
  props: {
    selectedLocations: {
      type: Array,
      required: true
    },
    actionable: {
      type: Boolean,
      default: false
    },
    searchQuery: {
      type: String,
      default: ""
    }
  },
  computed: {
    ...mapState("locations", ["locations"]),
    search() {
      return {
        filter: function(location) {
          return (
            this.searchQuery == "" ||
            ~location.position.address
              .toLowerCase()
              .replace(/\s/g, "")
              .indexOf(this.searchQuery.toLowerCase().replace(/\s/g, ""))
          );
        }.bind(this)
      };
    },
    columns() {
      return [
        {
          title: this.$i18n.t("address"),
          width: "40%",
          key: "address",
          customRender: location => {
            return location.position.address;
          },
          sorter: (a, b) => {
            if (
              a.position.address.toString().toLowerCase() >
              b.position.address.toString().toLowerCase()
            ) {
              return 1;
            } else {
              return -1;
            }
          },
          sortDirections: ["descend", "ascend"]
        },
        {
          title: this.$i18n.t("tasks"),
          width: "40%",
          key: "tasks",
          customRender: location => {
            const taskWord = this.$i18n.t("task");
            return this.$createElement("LinkTag", {
              scopedSlots: {
                default: () => {
                  const tasksCount = location.tasks.length;
                  const tasksSuffix =
                    tasksCount !== 1 ? this.$i18n.t("multipleEnd") : "";
                  return `${tasksCount} ${taskWord}${tasksSuffix}`;
                }
              },
              on: {
                click: function() {
                  this.$router.push({
                    name: "tasks",
                    params: { locationId: location.id }
                  });
                }.bind(this)
              }
            });
          }
        },
        {
          title: this.$i18n.t("days"),
          dataIndex: "schedule",
          width: "20%",
          key: "days",
          customRender: schedule => {
            let weekdays = [
              "monday",
              "tuesday",
              "wednesday",
              "thursday",
              "friday",
              "saturday",
              "sunday"
            ];
            const days = weekdays.map(weekday => {
              return [
                weekday,
                [schedule[weekday + "_from"], schedule[weekday + "_to"]]
              ];
            });
            return this.$createElement("DaysPicker", {
              props: {
                value: Object.fromEntries(
                  days.map(day => {
                    return [
                      day[0],
                      !!(day[1] ? day[1][0] || day[1][1] : false)
                    ];
                  })
                ),
                readOnly: true,
                size: "small"
              }
            });
          }
        }
      ];
    }
  },
  methods: {
    ...mapActions("locations", ["initializeLocations"]),
    select(rows) {
      this.$emit("update:selectedLocations", rows.rows);
    },
    edit(data) {
      this.$emit("editLocation", data);
    },
    remove(data) {
      this.$emit("removeLocation", data);
    }
  },
  async mounted() {
    this.loading = true;
    this.initializeLocations()
      .then(() => {
        this.loading = false;
      })
      .catch(() => {
        this.loading = false;
      });
  }
};
</script>

<style lang="scss" module>
.locations-table-wrapper {
}
</style>
